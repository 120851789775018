
export const login = ( loginInfo ) => {
    
	return {
        type: "LOGIN",
        payload: loginInfo
    };
}

export const logout = () => {

	return {
		type: 'LOGOUT',
	}
}

export const hideLoginModal = () => {
	return {
		type: 'HIDE_LOGIN_MODAL',
	}
}

export const showLoginModal = () => {
	return {
		type: 'SHOW_LOGIN_MODAL',
	}
}


export const doAuthCheck = () => {
    return {
        type: 'DO_AUTH_CHECK',
    }
}