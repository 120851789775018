import React from "react";

// SVG Icon - Enter the file name of the icon from the svg folder. 
// Prefix will automatically be added
// EG <Icon icon="menu" />
const SvgIcon = (props) => (
	<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" className={`icon icon-${props.icon}`}>
    	<use xlinkHref={`#icon-${props.icon}`} />
	</svg>
);

export default SvgIcon;