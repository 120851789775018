export default function ( state={
    selectedGauges: [
        {
            gaugeType:"precip",
            label: "Precip",
            action: "active"
        },
        {
            gaugeType:"wl",
            label: "Water Level",
            action: "active"
        },
    ],


}, action ) {
    switch( action.type ) {
        case "UPDATE_SLIDEOUT_MENU":
        //console.log(action.payload);
        switch(action.payload.action) {
            case "remove": 
            for( var i=0; i < state.selectedGauges.length; i++ ) {
                if( state.selectedGauges[i].gaugeType === action.payload.type ) {
                    state.selectedGauges[i].action = "inactive"
                }
            }
            break;

            case "add":
            break;

            default:
            return state;

        }
        return {...state};

        //case "UPDATE_SLIDEOUT_MENU":
        //console.log(action.payload);
        //return {...state};


        default:
        return state;
    }
}