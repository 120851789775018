import React from 'react';
import { Link } from 'react-router-dom';
import slugify from "../helpers/slugify";
import SvgIcon from "./SvgIcon";

const StationTitle = ({  
    icon,
    gaugeName, 
    gaugeDate,
    sensorName, 
    missedData,
    gaugeId,
    slug }) => { 
    
    const stationSlug = slug;
    const titleLink = missedData ? <Link tabIndex="-1" to={ "/gauge/"+stationSlug }>{ gaugeName }</Link> : <Link to={ "/gauge/"+stationSlug } aria-label={gaugeName + ": " + sensorName}>{ gaugeName }</Link>
    
    return( 
        <div className="gauge-icon-section">
            <SvgIcon icon={icon} />
            <h2 className="gauge-name">{ titleLink }</h2>
            <span className="gauge-date">{ gaugeDate }</span>
            <span className="gauge-id">ID# { gaugeId }</span>  
        </div>
    );
}; 

export default StationTitle; 