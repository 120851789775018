
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import { Helmet } from "react-helmet";
import leftPad from "left-pad";
import { pageTitle } from "../helpers/pageTitle";
import Preloader from "../components/Preloader";
import { getRoute, apiRequest } from "../helpers/Api";
import ErrorMsg from "../components/ErrorMsg";
import { connect } from "react-redux"; 
import { RequireCapability } from "./RequireCapability";
import { doAuthCheck } from "../actions/Auth";
import PageWrapper from "../components/PageWrapper";
import SafetyCodeIconContainer from "../components/dam-ops/SafetyCodeIconContainer";
import DamSafetyCodeSelector from "../components/dam-ops/DamSafetyCodeSelector";
import DamSafetyLogListDetails from "../components/dam-ops/DamSafetyLogListDetails";
import DamSafetyLogListExpanding from "../components/dam-ops/DamSafetyLogListExpanding";
import DamOperationsTable from "../components/dam-ops/DamOperationsTable";
import GateStatusTable from "../components/station-detailed/GateStatusTable";
import StationReadings from "../components/StationReadings";
import { 
    togglePreloader,
    showFetchError,
    toggleModal,
} from "../actions/index";
import Modal from "../components/Modal";
import SvgIcon from "../components/SvgIcon";
import DamCalculator from "../components/DamCalculator";
import pdf from '../documents/dam_safety_codes.pdf';
//import $ from 'jquery'; 

class DamOperationsContainer extends Component {
    constructor ( props ) {
        super( props ); 
        this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
        this.buildDamSafetyInfoContainers = this.buildDamSafetyInfoContainers.bind(this);
        
        this.showDamCalculator = this.showDamCalculator.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleupdateDamSafetyCode = this.handleupdateDamSafetyCode.bind(this);
        this.damSafetyCodeUpdated = this.damSafetyCodeUpdated.bind(this);

        // New methods
        this.getDamOperationsSummary = this.getDamOperationsSummary.bind(this);
        this.renderDamOperationSummary = this.renderDamOperationSummary.bind(this);
        this.getSafetyCodeData = this.getSafetyCodeData.bind(this);

        this.state = {

            // get summary state
            fetchingSummary: false,
            damOpsSummaryData: [],

            fetchingSafteyCodes: false,
            damOpsSafetyCodeData: [],
           
            // modal state
            activeModalContent: null,
            requestedCode: null,
            reasonForChange: null,
            selectedDamId: null,
            selectedDamName: null,
        };
    }

    componentDidMount() {
        this.getDamOperationsSummary();
        this.getSafetyCodeData();

    }
    componentDidUpdate(prevProps, prevState){

        let wasPreviousLoading = false;
        let currentlyLoading = false;

        if( prevState.fetchingSummary === true ||
            prevState.fetchingSafteyCodes === true ){
            wasPreviousLoading = true;
        }

        if( this.state.fetchingSummary === true ||
            this.state.fetchingSafteyCodes === true ){
            currentlyLoading = true;
        }

        if( currentlyLoading === false && wasPreviousLoading === true ){
            //transitioning from loading
            this.props.togglePreloader(false);
        }
        else if ( currentlyLoading === true && wasPreviousLoading === false ){
            //transitioning to loading
            this.props.togglePreloader(true);
        }
    }

    dismissErrorMessage() {
		var obj = {
			isVisible: false,
			message: "",
		};

		this.props.showFetchError( obj );
    }

    getDamOperationsSummary(){
        this.setState({
            'damOpsSummaryData': [],
            'fetchingSummary': true
        });
        apiRequest( getRoute('damOpsSummary') ) 
            .then(result => {

                this.setState({
                    damOpsSummaryData: result.dams,
                    fetchingSummary: false
                }); 

            })
            .catch( (error) => {
                //console.log(error.status);
                if( error.status === 403 ){
                    this.props.doAuthCheck();
                    return error;
                }

                //console.log( 'Error', error );
                this.props.togglePreloader(false); 
                this.setState({
                    'damOpsSummaryData': [],
                    'fetchingSummary': false
                });


            })
    }

    getSafetyCodeData() {
        this.setState({
            fetchingSafteyCodes: true
        })
        apiRequest( getRoute("damSafetyCodes") )
            .then(result => {
                this.setState({
                    fetchingSafteyCodes: false,
                    damOpsSafetyCodeData: result
                });
            })
            .catch( (error) => {
                //console.log(error.status);
            })
    }


    showDamCalculator() {
        this.props.toggleModal(true);
        this.setState({ activeModalContent: "damCalculator" }); 
    }


    handleupdateDamSafetyCode(event) {
        this.props.toggleModal(true);
        this.setState({ 
            activeModalContent: "updateSafetyCode", 
            selectedDamId: event.target.dataset.id,
            selectedDamName: event.target.dataset.dam 
        });
    }


    handleCloseModal(  ) {
        this.props.toggleModal( false );
        this.setState({ activeModalContent: null }); 
    }


    damSafetyCodeUpdated( ){
        this.getDamOperationsSummary();
        this.handleCloseModal()
    }


    buildDamSafetyInfoContainers() {

        // refrence shorter
        const data = this.state.damOpsSummaryData;
        //console.log(data);
        
        //console.log(data.results);
        const infoContainers = [];
        for (let i = 0; i < data.length; i++) {

            let openings = null;
            var damCalculatorLink = null; 

            if( data[i].dam_gates.length ){
                openings = (
                    <div className="gate-openings">
                        <GateStatusTable gateData={data[i].dam_gates} />
                    </div>   
                )
            }   


            if( data[i].station_id === "HY027"){
                damCalculatorLink =  <li className="piped-nav-item"><button className={["dam-calculator-link","modal-button"]} tabIndex="0" onClick={ this.showDamCalculator } >Dam Calculator</button></li>;
            }

            let safetyCodeColour = null;
            let safetyCondition = null;

            // use the latest safety code if one exists
            if(data[i].dam_safety_logs.length){
                safetyCondition = data[i].dam_safety_logs[0].dam_safety_code_category;
                safetyCodeColour = data[i].dam_safety_logs[0].dam_safety_code_colour;
           
            }
            else{
                // use green
                safetyCondition = 'No safety set';
                safetyCodeColour = 'black';
            }

            const safetyLogs = []


            // filter the logs to only show ones for this dam
            for (let b = 0; b < data[i].dam_safety_logs.length; b++) {
                // stop looping if we are not expanded and past the max shown
                safetyLogs.push( <DamSafetyLogListDetails logDetails={data[i].dam_safety_logs[b]} key={"log-"+data[i].dam_safety_logs[b].id} /> );
            }

            if( safetyLogs.length === 0 ){
                safetyLogs.push(<span>No logs avaialble</span>);
            }

            /*
                                        { data[i].total_dam_operation_logs > data[i].dam_operations.length && 
                                <li className="piped-nav-item"><Link to="/">Show Operations Log</Link></li>
                            }
            */

            let damOperations = null;

            if( data[i].dam_gates.length ){
                damOperations = (
                    <div className="dam-operation-logs">
                        <span>Previous Operations: </span>
                        <DamOperationsTable logs={data[i].dam_operations} />
                        <ul className="piped-nav no-pipe">
                            <li className="piped-nav-item"><Link to={`/dam-ops/dam-operation-logs/${data[i].station_slug}`}>Show Operations Log</Link></li>
                            <li className="piped-nav-item"><Link className="btn" to={`/dam-ops/add-dam-operation/${data[i].station_slug}`}>Log an Operation</Link></li>
                            {damCalculatorLink}
                        </ul>
                    </div>
                )
                
            }


            infoContainers.push(
                <div className="row station-data-row" key={"station-"+data[i].station_id}>
                    <div className="col-sm-12 col-lg-8">
                        <h2>{ data[i].station_name.toLowerCase() }</h2>
                        <div className="readings-safetycode-wrap">
                            <div className="readings">
                                <span className="gauge-date">{ data[i].last_updated.split('T')[0] }</span>
                                <span className="gauge-id">ID# { data[i].station_id }</span>  
                                <StationReadings 
                                    readings={ data[i].time_series_data } 
                                    unitOfMeasure={data[i].unit_of_measure} 
                                    gaugeType={ data[i].type_of }
                                    gaugeId={ data[i].gauge_id }
                                    gaugeSlug={ data[i].unique_id }
                                />

                                
                            </div>
                            <div className="current-safety-code-container"> 
                                <span className="safety-code-label">Current dam safety code:</span>
                                <SafetyCodeIconContainer 
                                    safetyCode={ safetyCodeColour } 
                                    safetyCondition={ safetyCondition }
                                    //updateDamSafetyCode={ this.handleupdateDamSafetyCode }
                                    damId={ data[i].id }
                                />   
                                <button onClick={ this.handleupdateDamSafetyCode } data-id={data[i].id} data-dam={ data[i].station_name } className="modal-button" >Change</button>
                            </div>
                        </div>
                        <div className="dam-safety-code-logs">
                            <span>Dam safety code log: </span>
                            { safetyLogs }
                            { data[i].total_dam_safety_logs > data[i].dam_safety_logs.length && 
                                <ul className="piped-nav">
                                    <li className="piped-nav-item"><Link to={`/dam-ops/dam-safety-logs/${data[i].station_slug}`}>Show History</Link></li>
                                </ul>
                            }
                        </div>

                        {damOperations}
                        
                    </div>
                    <div className="col-sm-12 col-lg-4 gate-openings-container">
                        { openings }
                    </div>
                </div>
            )                
        }

        return infoContainers;
    }

    renderDamOperationSummary(){
        // refrence shorter
        const data = this.state.damOpsSummaryData;
        //console.log(data);

        const summary = [];



        for (let i = 0; i < data.length; i++) {

            let summaryText = null;
            let summaryColor = null;

            if( data[i].dam_safety_logs.length){
                summaryText = data[i].dam_safety_logs[0].dam_safety_code_category
                summaryColor = data[i].dam_safety_logs[0].dam_safety_code_colour
            }
            

            summary.push(
                <div className="dam-status-summary-item">
                    <span className={`safety-icon ${summaryColor}`}></span>
                    <div className="dam-status-summary-info">
                        <span className="dam-status-summary-title">{data[i].station_name}</span>
                        <span className="dam-status-summary-status">{summaryText}</span>
                    </div>
                </div>
            )
        }


        return (
            <div className="dam-status-summary">
                <h2>Dam Status Summary</h2>
                {summary}
            </div>
        );
    }


	render() {
        let damSafetyInfo = null;
        if(this.state.damOpsSummaryData.length) {
            damSafetyInfo = this.buildDamSafetyInfoContainers(this.state.damOpsStationData);
        }


        // Get content for the modal
        let modalContent = null;
        let modalContentType = null;
        if( this.state.activeModalContent === "damCalculator" ) {
            modalContentType = "damCalculator"
            modalContent = (
                <div className="dam-calculator-container">
                    <button 
                        className="closeModalBtn" 
                        onClick={ this.handleCloseModal }
                    >
                        <SvgIcon icon="menu-close" />
                    </button>
                    <h2 className="dam-calculator-title">Dam Operations Calculator</h2>
                    <DamCalculator />
                </div>
            )
            
        }
        else if( this.state.activeModalContent === "updateSafetyCode" ) {
            modalContent = (
                <DamSafetyCodeSelector 
                    safetyCodeData={ this.state.damOpsSafetyCodeData }
                    safetyCodeUpdated={ this.damSafetyCodeUpdated }
                    safetyCodeCancelled={ this.handleCloseModal }
                    selectedDamName={ this.state.selectedDamName }
                    selectedDamId={this.state.selectedDamId }
                />
            )
        }



		return (
			<PageWrapper fullWidth={true} >
                <Helmet>
                    <title>{ pageTitle('Dam Operations') }</title>
                </Helmet>
                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching Dam Information" />
                <ErrorMsg 
                    showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    message={ this.props.fetchErrorMsg.errorMessage } 
                    dismissErrorMessage={ this.dismissErrorMessage }	
                />
                <div className="dam-operations-content-container">
                    <h1 id="main-heading" className="page-title">Dam Operations</h1>
                    <div className="dam-ops-section">
                        <ul className="piped-nav">
                            <li className="piped-nav-item">
                                <a href="http://legacy.trcagauging.ca/inspections/" target="_blank" rel="noopener noreferrer">Dam Inspections Database</a>
                            </li>
                            <li className="piped-nav-item">
                                <a href={pdf} target="_blank" rel="noopener noreferrer">Dam Safety Codes (PDF)</a>
                            </li>
                        </ul>
                    </div>
                    { this.renderDamOperationSummary() }
                    { damSafetyInfo }
                    <Modal 
						isVisible={ this.props.doToggleModal.isVisible }
						isFor={ modalContentType }
						doCloseModal={ this.handleCloseModal }
						modalContent={ modalContent }
					/>
                </div>
 
            </PageWrapper>
		)
    }
}

function mapStateToProps(state) {
	return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
        doToggleModal: state.toggleModal,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
        doAuthCheck: doAuthCheck,
        toggleModal: toggleModal,
	}, dispatch)	
}

export default RequireCapability( withRouter( connect( mapStateToProps, matchDispatchToProps )( DamOperationsContainer ) ), 'canAccessDamOperationsLog' ); 