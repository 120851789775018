
export default function parseLoginApiData( result ){
	return {
		'token' : result.token_key,
		'userId' : result.profile.id,
    	'firstName' : result.profile.first_name,
    	'lastName' : result.profile.last_name,
    	'email' : result.profile.email,
    	'phone' : result.profile.phone,
    	'organizationName' : result.profile.organization_name,
    	'permissions': result.permissions,
    }
}