import React, { Component } from "react";
import { getRoute, apiRequest } from "../../../helpers/Api";
import { yyyymmdd, hhmmampm } from "../../../helpers/gaugeHelpers";
import EditWidgetMenu from "../EditWidgetMenu";

class WeatherAlertWidget extends Component {

    constructor ( props ) {
        super( props );

        //variables
        this.apiTimeout = null;

        //methods
        this.fetchData = this.fetchData.bind(this);
        this.editWidget = this.editWidget.bind(this);
		this.removeWidget = this.removeWidget.bind(this);
        this.cardRefreshLength = 60000;

        //state
        this.state = {
        	isFetching: true,
        	apiData: null,
        }
    }

    componentDidMount(){
    	this.fetchData();
    	this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);

    }

    shouldComponentUpdate(nextProps) {
		if( this.props.updateRequired === nextProps.updateRequired ) {
			return true;
		} else {
			return false;
		}
	}


    componentDidUpdate(prevProps, prevState){
        //check to see if something was changed that makes us need to update
        let requireFetch = false;


        if(this.props.region !== prevProps.region){
            requireFetch = true;
        }

        if( requireFetch === true ){
            clearInterval( this.apiTimeout );
            this.fetchData();
            this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);
        }


    }
    componentWillUnmount(){
		//clear the timeout for fetching data
		clearInterval( this.apiTimeout );
    }
    

    fetchData(){

    	this.setState({isFetching: true });
    	apiRequest( getRoute("weatherAlerts",  [], {region: this.props.region}) )
			.then(result => {  
				this.setState({isFetching: false, apiData: result });
			}) 
			.catch((error) => {
				//console.log('error fetching gauge data');
				this.setState({isFetching: false });
			});
    }

    editWidget( widgetId ) {
		this.props.handleEditWidget( this.props, null, widgetId );
	}

	removeWidget( widgetId ) {
		this.props.handleRemoveWidget(widgetId);
	}


    render() {
    	let className = `dashboard-grid-item dashboard-grid-item-weather-alert ${this.props.size}`;

        let alertInfo = null;
        if( this.state.apiData !== null ){

            let alert = this.state.apiData.results[0];
            alertInfo = (
                <div className="weather-alert"> 
                    <h2 className="weather-alert-heading">{alert.region}</h2>
                    { 
                        alert.notices.map((theAlert, index) => {
                            let theDate = new Date( theAlert.updated );
                            return(
                                <div className="weather-alert-item" key={index}>
                                    <h3 className="weather-alert-sub-heading">{ theAlert.title }</h3>
                                    <p>{theAlert.summary} - <a href={theAlert.href}>More Info</a></p>
                                    <span className="weather-alert-date">{ yyyymmdd(theDate) } - { hhmmampm(theDate) }</span>
                                </div>
                            )
                        })
                    }
                </div>
            );  
        }

        let editWidgetMenu = this.props.buildModeActive ? 
		<EditWidgetMenu 
			widgetId={ this.props.widgetId } 
			handleEditWidget={ this.editWidget }
			handleRemoveWidget={ this.removeWidget }
		/>
        : null;

    	return (
    		<div className={ className }>
                { alertInfo }
                { editWidgetMenu }
    		</div>
    	)
    }
}

export default WeatherAlertWidget;