import React, { Component } from "react";
import StationTitle from "./StationTitle";
import StationReadings from "./StationReadings";
import CameraImage from "./CameraImage";
import { Link } from 'react-router-dom';
import slugify from "../helpers/slugify";
import { 
	yyyymmdd, 
	formatGaugeAmount,
	getRainIcon,
	getTrendIcon
 } from "../helpers/gaugeHelpers"; 


class CameraCard extends Component {
    constructor ( props ) {
        
        super( props );
        this.cameraClicked = this.cameraClicked.bind(this);
    
    }
    componentDidMount() {

    }

    cameraClicked(testing) {
        alert(testing);
        //this.props.cameraClick()
    }
    
    render() { 

        //console.log('Card Render Called');
        //const graphHeight = {height:"150px"};
        //const chartStyles = { width: "370px", height:"150px" }
        //console.log("precip totals: ",this.props.precipTotals);

        var cardData = this.props.data;
        var id = this.props.id;
        //console.log(this.props);

        var viewData = {
            "gaugeId": cardData.station_id,
            "gaugeName": cardData.station_name,
            "slug": cardData.slug,
            "uniqueId": id,
            "typeOf": cardData.type_of,
            "sensorName": cardData.sensor_name,
            "gaugeDate": yyyymmdd( new Date(cardData.date) ),
            "iconName" : "plus",
            "gaugeReadings" : [],
            "imageThumb" : cardData.cameras[0].thumbnail,

        };

        // get trend icon
        if( viewData.typeOf === 'Rain Gauge'){
            viewData.iconName = getRainIcon(cardData);
        }
        else{
            viewData.iconName = getTrendIcon(cardData);
        }



        var graphData = null;
        let iconName = viewData.icon_name;
        /*

        <StationReadings 
                            readings={ this.props.data.time_series_data } 
                            isMissedData={viewData.missedData} 
                            unitOfMeasure={this.props.data.unit_of_measure} 
                            gaugeType={ viewData.type_of }
                            precipTotals={ this.props.precipTotals }
                            missedData={ viewData.missedData }
                        />
        */
        


        return (
            <div className="map-gauge-wrap camera-view-wrap" >
                <div className="card gauge card-gauge">
                    <div className="card-gauge-content">
                        <CameraImage
                            image={viewData.imageThumb}
                            cameraClick={ (e) => this.props.cameraClick([cardData.id]) }
                        />
                        <StationTitle 
                            icon={ viewData.iconName }
                            gaugeName={ viewData.gaugeName }
                            gaugeDate={ viewData.gaugeDate }
                            gaugeId={ viewData.gaugeId }
                            sensorName={ viewData.sensorName }
                            missedData={ viewData.missedData }
                            slug={viewData.slug}
                        />
                        
                    </div>
                    
                </div>
            </div>    
        )


        // Error will be thrown if a unknonwn card type is passed
        //throw new Error(`Unknown cardType prop passed to Card: ${this.props.cardType}`);

    }
}

export default CameraCard;