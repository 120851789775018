export const togglePreloader = ( bool ) => {
    return {
        type: "TOGGLE_PRELOADER",
        payload: bool
    };
};

export const toggleSlideoutPanel = ( bool ) => {
    return {
        type: "TOGGLE_SLIDEOUT_PANEL",
        payload: bool
    };
};

export const handleCheckboxUpdate = ( obj ) => {
    return {
        type: "CHECKBOX_UPDATED",
        payload: obj
    };
};

export const updateActiveCards = ( ary ) => {
    return {
        type: "UPDATE_ACTIVE_CARDS",
        payload: ary
    };
};

export const updateGaugeMapApiData = ( json ) => {
    return {
        type: "UPDATE_GAUGE_MAP_API_DATA",
        payload: json
    };
};

export const resetStationDetailData = () => {
    return {
        type: "RESET_STATION_DETAIL_DATA",
    }
}

export const gaugeNotFound = () => {
    return {
        type: "GAUGE_NOT_FOUND",
    };
};

export const updateStationDetailData = ( json ) => {
    return {
        type: "UPDATE_STATION_DETAIL_DATA",
        payload: json
    };
};


export const updateSpecificNeighbourhoodGaugeData = ( arrayPos, apiData ) => {
    return {
        type: "UPDATE_SPECIFIC_NEIGHBOURING_GAUGE_DATA",
        arrayPos: arrayPos,
        payload: apiData
    }
}

export const updateBatteryLevelChartData = ( obj ) => {
    return {
        type: "UPDATE_BATTERY_LEVEL_CHART",
        payload: obj
    };
};

export const updateSlideoutMenu = ( obj ) => {
    return {
        type: "UPDATE_SLIDEOUT_MENU",
        payload: obj
    };
};

export const toggleModal = ( obj ) => {
    return {
        type: "TOGGLE_MODAL",
        payload: obj
    };
};

export const handleDateTimeInteraction = ( obj ) => {
    return {
        type: "DATE_SELECTED",
        payload: obj
    };
};

export const handleTimeValueChange = ( obj ) => {
    return {
        type: "TIME_VALUE_CHANGED",
        payload: obj
    };
};

export const resetTimeValues = ( obj ) => {
    return {
        type: "TIME_VALUE_RESET",
        payload: obj
    };
};

export const searchFilterInputChange = ( input ) => {
    return {
        type: "SEARCH_INPUT_CHANGE",
        payload: input
    };
};

export const resetReportState = () => {
    return {
        type: "RESET_DEFAULT_STATE",
    }
}

export const doShowSearchList = ( ) => {
    return {
        type: "SEARCH_LIST_TOGGLE_CLICKED",
        payload: ""
    };
};

export const setReportTemplateData = ( result ) => {
    return {
        type: "SET_REPORT_TEMPLATE_DATA",
        payload: result
    };
};

export const stationAddedToReport = ( obj ) => {
    return {
        type: "ADD_STATION_TO_REPORT",
        payload: obj
    };
};

export const allStationsAddedToReport = ( stations ) => {
    return {
        type: "ADD_ALL_STATIONS_TO_REPORT",
        payload: stations
    };
};

export const updateSelectedStationGaugesForModal = ( obj ) => {
    return {
        type: "UPDATE_SELECTED_STATION_GAUGES",
        payload: obj
    };
};

export const addStationGaugesToReport = ( id ) => {
    return {
        type: "ADD_STATION_GAUGES_TO_REPORT",
        payload: id
    };
};

export const stationRemovedFromReport = ( obj ) => {
    return {
        type: "REMOVE_STATION_FROM_REPORT",
        payload: obj
    };
};

export const gaugeAddedToReport = ( obj ) => {
    return {
        type: "GAUGE_ADDED_TO_REPORT",
        payload: obj
    };
};

export const gaugeRemovedFromReport = ( obj ) => {
    return {
        type: "GAUGE_REMOVED_FROM_REPORT",
        payload: obj
    };
};

export const handleDateReset = ( obj ) => {
    return {
        type: "RESET_DATE",
        payload: obj
    };
};

export const throwValidationError = ( str ) => {
    return {
        type: "VALIDATION_ERROR_MSG",
        payload: str
    };
};

export const formValidated = ( bool ) => {
    return {
        type: "FORM_VALIDATED",
        payload: bool
    };
};

export const handleSetReportResults = ( obj ) => {
    return {
        type: "SET_REPORT_RESULTS",
        payload: obj
    };
};

export const handleShowReportBuilderView = ( bool ) => {
    return {
        type: "SHOW_REPORT_BUILDER_VIEW",
        payload: bool
    };
};

export const plotGraphsOnSeperateContainer = ( bool ) => {
    return {
        type: "PLOT_GRAPH_ON_SEPERATE_CONTAINER",
        payload: bool
    };
};

export const setupStationsAndGauges = ( ary ) => {
    return {
        type: "SETUP_STATIONS_GAUGES",
        payload: ary
    };
};

export const storeChartDataToProps = ( data ) => {
    return {
        type: "UPDATE_CHART_OBJECTS",
        payload: data
    };
};

export const addAllGaugesForSelectedStation = ( id ) => {
    return {
        type: "ADD_ALL_GAUGES_FOR_SELECTED_STATION",
        payload: id
    };
};

export const storeMasterStationList = ( ary ) => {
    return {
        type: "STORE_MASTER_STATION_LIST",
        payload: ary
    };
};

export const showAlarmsForStandardDonReport = ( bool ) => {
    return {
        type: "TOGGLE_ALARMS_FOR_DON",
        payload: bool
    };
};

export const setTimeIsValid = ( obj ) => {
    return {
        type: "SET_TIME_IS_VALID",
        payload: obj
    };
};

export const throwDateRangeError = ( str ) => {
    return {
        type: "SET_DATE_RANGE_ERROR",
        payload: str
    };
};

export const throwSearchInputError = ( str ) => {
    return {
        type: "SET_SEARCH_INPUT_ERROR",
        payload: str
    };
};

export const clearAllStationsFromMenu = ( str ) => {
    return {
        type: "CLEAR_ALL_STATIONS_FROM_MENU",
        payload: str
    };
};

export const setDamOpsCalculatorData = ( obj ) => {
    return {
        type: "SET_DAM_OPS_CALCULATOR_DATA",
        payload: obj
    };
};

export const setWaterElevationData = ( obj ) => {
    return {
        type: "SET_WATER_ELEVATION_DATA",
        payload: obj
    };
};

export const calculateExpectedOutflow = ( obj ) => {
    return {
        type: "CALCULATE_EXPECTED_OUTFLOW",
        payload: obj
    };
};

export const determineReachedThreshold = ( obj ) => {
    return {
        type: "DETERMINE_THRESHOLD_REACHED",
        payload: obj
    };
};

export const setDamCalculatorWaterLevelValidationError = ( str ) => {
    return {
        type: "DAM_CALCULATOR_WL_VALIDATION_ERROR",
        payload: str
    };
};

export const setDamCalculatorDesiredLLValidationError = ( str ) => {
    return {
        type: "DAM_CALCULATOR_LL_VALIDATION_ERROR",
        payload: str
    };
};

export const setDamCalculatorDesiredRadialValidationError = ( str ) => {
    return {
        type: "DAM_CALCULATOR_RADIAL_VALIDATION_ERROR",
        payload: str
    };
};

export const setDocumentLibrary = ( obj ) => {
    return {
        type: "SET_DOCUMENT_LIBRARY",
        payload: obj 
    };
};

export const setDateTimeToPresent = ( bool ) => {
    return {
        type: "SET_DATETIME_TO_PRESENT",
        payload: bool 
    };
};

export const autoUpdateTime = () => {
    return {
        type: "AUTO_UPDATE_TIME"
    };
};

export const setMonthForDatepickerDropdown = ( date ) => {
    return {
        type: "SET_MONTH_FOR_DATE_PICKER",
        payload: date,
    };
};

export const deselectGauges = ( array ) => {
    
    // allows simply passing an id for single gauge selection
    if( Number.isInteger(array) ){
        array = [array];
    }

    array = array.map((item, index) => { return parseInt(item, 10) });

    return {
        type: "DESELECT_GAUGES",
        payload: array
    }

}

export const selectGauges = ( array ) => {
    // allows simply passing an id for single gauge selection
    if( Number.isInteger(array) ){
        array = [array];
    }

    array = array.map((item, index) => { return parseInt(item, 10) });
    
    return {
        type: "SELECT_GAUGES",
        payload: array
    }

}

export const setDateRangeForDayPicker = ( obj ) => {
    return {
        type: "DATE_RANGE_FOR_DAY_PICKER",
        payload: obj
    }
}

export const removeAllGaugesFromReport = () => {
    return {
        type: "REMOVE_ALL_GAUGES",
    }
}

export const showFetchError = ( obj ) => {
    return {
        type: "SHOW_FETCH_ERROR_MSG",
        payload: obj,
    }
}

export const storeGeneratedReportUrl = ( str ) => {
    return {
        type: "STORE_GENERATED_REPORT_URL",
        payload: str,
    }
}

export const setDashboardVisualizationRequest = ( obj ) => {
    return {
        type: "DASHBOARD_VISUALIZATION_REQUEST_DATA",
        payload: obj,
    }
}
