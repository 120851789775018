import React from 'react';
//import SvgIcon from "../SvgIcon.js";

const WidgetSizeLabel = ({ size, label }) => {
    let largeClass = "large-box";
    let mediumClass = "medium-box"; 
    let smallClass = "small-box";

    switch( size ) {
        case "large": 
        largeClass = "large-box highlighted";
        break;

        case "medium": 
        mediumClass = "medium-box highlighted";
        break;

        case "small": 
        smallClass = "small-box highlighted";
        break;

        default:
        break;
    }

    return( 
        <span className="widget-container">
            <span className="widget-icon">
                <span className={largeClass}></span>
                <span className={mediumClass}></span>
                <span className={smallClass}></span>
            </span>
            <span>{ label }</span>
        </span>
    );
}; 

export default WidgetSizeLabel; 