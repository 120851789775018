import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"; 
import { withRouter, Link } from 'react-router-dom'

import Preloader from "../components/Preloader";
import PageWrapper from "../components/PageWrapper";

import { pageTitle } from "../helpers/pageTitle";
import { getRoute, apiRequest } from "../helpers/Api";
import { doAuthCheck } from "../actions/Auth";
import { RequireCapability } from "./RequireCapability";

import DamOperationsTable from "../components/dam-ops/DamOperationsTable";
import GateOperationForm from "../components/dam-ops/GateOperationForm";
import GateStatusTable from "../components/station-detailed/GateStatusTable";
import Modal from "../components/Modal";
import DamCalculator from "../components/DamCalculator";
import SvgIcon from "../components/SvgIcon";

import { 
    togglePreloader,
    showFetchError
} from "../actions/index";


function getInitialState(){
    return {
        fetchingStation: false,

        stationDetails: null,
        operationCreated: false,
        errorMessage: null,
        showDamCalculator: false,
    }
}

class DamOperationAddLogContainer extends Component {
    constructor ( props ) {
        super(props);


        this.getStationData = this.getStationData.bind(this);
        this.showDamCalculator = this.showDamCalculator.bind(this);
        this.closeDamCalculator = this.closeDamCalculator.bind(this);

        this.state = getInitialState();
    }

    componentDidMount(){
        window.scrollTo(0,0);
        this.getStationData();

    }

    componentDidUpdate(prevProps, prevState){

        let wasPreviousLoading = false;
        let currentlyLoading = false;

        // check if previously was in a loading state
        if( prevState.fetchingStation === true ){
            wasPreviousLoading = true;
        }

        // check if currently in a loading state
        if( this.state.fetchingStation === true ){
            currentlyLoading = true;
        }

        if( currentlyLoading === false && wasPreviousLoading === true ){
            //transitioning from loading
            this.props.togglePreloader(false);
        }
        else if ( currentlyLoading === true && wasPreviousLoading === false ){
            //transitioning to loading
            this.props.togglePreloader(true);
        }

        if( prevState.operationCreated === false && this.state.operationCreated === true ){
            window.scrollTo(0,0);
            this.getStationData();
        }


    }




    getStationData(){

        this.setState({
            fetchingStation: true,
        })
        apiRequest( getRoute("stationDetails", this.props.match.params.stationSlug) )
            .then(result => {
                this.setState({
                    fetchingStation: false,
                    stationDetails: result
                });
            })
            .catch( (error) => {
                // on error restting to the initial state and showing the loading message
                const netState = getInitialState();
                netState.errorMessage = true;
                this.setState(netState);
            })

    }

    showDamCalculator(){
        this.setState({'showDamCalculator': true});
    }

    closeDamCalculator(){
        this.setState({'showDamCalculator': false});
    }



	render() {

        let title = "Loading Station Details";
        let headingTitle = title;
        let content = null;
        //console.log('state', this.state);
        if( this.state.errorMessage !== null ){
            // content for when in an error state
            title = "Error loading station details"
            content = (
                <div>
                    <h2>Error</h2>
                    <p>An error occured while loading the station details.</p>
                </div>
            )
        }
        else if( this.state.fetchingStation || this.state.stationDetails === null ){
            // content for when on initiail load
            content = (
                <h2>{title}</h2>
            )
        }
        else if( this.state.stationDetails.dam_gates.length === 0 ){
             // station doesn't have dam gates
            title = "Error loading station details"
            content = (
                <div>
                    <h2>Error</h2>
                    <p>An error occured while loading the station details.</p>
                </div>
            )
        }
        else if( this.state.operationCreated === true ){
            content = (
                <div>
                    <h2 className="dam-ops-title-with-back">Dam Operations - { this.state.stationDetails.station_name }</h2>
                    <Link to={`/dam-ops/`}>Back to Dam Ops</Link>
                    <div className="dam-operation-add">
                        <div className="gate-openings-wrap">
                            <GateStatusTable gateData={this.state.stationDetails.dam_gates} />
                        </div>   
                        <div className="gate-operation-form-warp">
                            <p className="success-message">Dam operation log was successfully created.</p>
                        </div>
                    </div>
                </div>
            );
        }
        else{
            // content for when we have dam safety codes
            const opLogs = [];
            let headingTitle = title;

            // if we have a station set the title
            if( this.state.stationDetails !== null ){
                title = this.state.stationDetails.station_name + "Add Dam Operation Log";
                headingTitle = this.state.stationDetails.station_name;
            }


            let canUseEmergencyGates = false;
            const damGates = this.state.stationDetails.dam_gates.map( gate => {
                if( canUseEmergencyGates === false && gate.emergency_gate === true){
                    canUseEmergencyGates = true;
                }
                return {
                    'gateName': gate.gate_name,
                    'gateId': gate.id ,
                    'emergencyGate': gate.emergency_gate
                }
            });

            //console.log(damGates);

            let currentWL = null;
            for (var i = 0; i < this.state.stationDetails.gauges.length; i++) {
                if( this.state.stationDetails.gauges[i].sensor_name === 'WL' ){
                    currentWL = (
                        <div>
                            <div className="dam-current-water-level">
                                <span className="dam-current-water-level-title">Current water level:</span> {`${this.state.stationDetails.gauges[i].latest_reading}${this.state.stationDetails.gauges[i].unit_of_measure}`}
                            </div>
                        </div>
                    );
                    break;
                }
            }

            const includeForestValley = ( this.state.stationDetails.station_id == 'HY027' ? true : false );
            const damCalcAvaialble = ( this.state.stationDetails.station_id == 'HY027' ? true : false );

            // Get content for the modal
            let modalContent = null;
            let modalContentType = null;
            if( this.state.showDamCalculator  ) {
                modalContentType = "damCalculator"
                modalContent = (
                    <div className="dam-calculator-container">
                        <button 
                            className="closeModalBtn" 
                            onClick={ this.closeDamCalculator }
                        >
                            <SvgIcon icon="menu-close" />
                        </button>
                        <h2 className="dam-calculator-title">Dam Operations Calculator</h2>
                        <DamCalculator />
                    </div>
                )
                
            }

            let damCalcBtn = null;

            if( this.state.stationDetails.station_id == 'HY027'){

                damCalcBtn = (
                    <button
                        className=""
                        onClick={this.showDamCalculator}
                        style={{marginTop: '1em'}}
                    >
                        Dam Calculator
                    </button>
                );
            }


            content = (
                <div>
                    <h2 className="dam-ops-title-with-back">Dam Operations - { this.state.stationDetails.station_name }</h2>
                    <Link to={`/dam-ops/`}>Back to Dam Ops</Link>
                    <div className="dam-operation-add">
                        <div className="gate-openings-wrap">
                            <GateStatusTable gateData={this.state.stationDetails.dam_gates} />
                        </div>   
                        <div className="gate-operation-form-warp">
                            {currentWL}
                            {damCalcBtn}
                            <GateOperationForm
                                gates={damGates}
                                stationId={this.state.stationDetails.id}
                                canUseEmergencyGates={canUseEmergencyGates}
                                includeForestValley={includeForestValley}
                                operationLogCreated={()=>this.setState({'operationCreated': true})}
                            />
                        </div>
                    </div>

                    <Modal 
                        isVisible={ this.state.showDamCalculator  }
                        isFor={ modalContentType }
                        doCloseModal={ this.closeDamCalculator }
                        modalContent={ modalContent }
                    />
                </div>
            )


        }

        return (
            <PageWrapper fullWidth={true} >
                <Helmet>
                    <title>{ pageTitle(title) }</title>
                </Helmet>
                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching Station Details" />
                {content}
            </PageWrapper>
        )

    }
}


function mapStateToProps(state) {
    return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
        doAuthCheck: doAuthCheck
    }, dispatch)    
}

export default RequireCapability( 
    withRouter( 
        connect( mapStateToProps, matchDispatchToProps )( DamOperationAddLogContainer ) 
    ), 
    'canAccessDamOperationsLog' 
); 