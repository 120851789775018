export default function ( state={
    isVisible: false
}, action ) {
    switch( action.type ) {

        case "TOGGLE_MODAL":
        document.body.classList.toggle('disable-scroll', action.payload);
        state.isVisible = action.payload;
        return {...state};

        default:
        return state;
    }
}