import React, { Component } from "react";
import { 
    formatMeasurement,
    yyyymmdd,
    hhmmampm
 } from "../../helpers/gaugeHelpers";


class GateStatusTable extends Component {
    constructor ( props ) {
        super( props );

        this.getLatestDateString = this.getLatestDateString.bind(this);

    }

    getLatestDateString(){

        // check to see if all value are null
        let allNull = true;
        for (let i = 0; i < this.props.gateData.length; i++) {
            if( this.props.gateData[i].last_record !== null ){
                allNull = false;
                break;
            }
        }

        if(allNull === true){
            return "None";
        }

        let latestDate = null;
        for (let i = 0; i < this.props.gateData.length; i++) {
            const  thisDate = new Date(this.props.gateData[i].last_record);
            if( latestDate === null){
                //always set the first date
                latestDate = thisDate;
            }
            else if( thisDate > latestDate ){
                latestDate = thisDate;
            }
        }


        return yyyymmdd(latestDate) + ' ' + hhmmampm(latestDate)

    }


    render() { 

                // emergency gate
        let emergencyGateStatus = [];
        let normalGates = [];
        let hasEmergencyGates = false;
        for (let i = 0; i < this.props.gateData.length; i++) {
            const object = this.props.gateData[i];

            if( object.emergency_gate === true ){
                hasEmergencyGates = true;
                if(object.current_opening !== 0){
                    emergencyGateStatus.push(<span className="emergency-status-active" key={i}>, In use {object.gate_name}</span>);
                }  
            }
            else{
                normalGates.push(
                    <tr key={i}>
                        <th>{object.gate_name}</th>
                        <td>{ (object.current_opening === 0 ? 'Closed' : 'Open') }</td>
                        <td>{ `${object.current_opening}${formatMeasurement(object.current_opening_unit_of_measure)}` }</td>
                    </tr>
                );
            }
        }

        if( !emergencyGateStatus.length ){
            emergencyGateStatus = <span>, Not In Use</span>
        }

          
        return(

                <div className="dam-gate-status">
                    <h3 className="dam-gate-status-title">Current Gate Status:</h3>
                    <table className="dam-gate-status-table">
                        <tbody>
                            {normalGates}
                        </tbody>
                    </table>
                    { hasEmergencyGates === true && 
                        <div className="dam-gate-status-emergency">Maintenance Gate Status{ emergencyGateStatus  }</div>
                    }
                    <div className="dam-gate-status-last-operation">Last operation: { this.getLatestDateString() }</div>
                </div>

        );
        


    }

}

export default GateStatusTable;