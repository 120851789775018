import React, { Component } from "react";
import WidgetModalSaveBtn from "./WidgetModalSaveBtn";
import Field from "../forms/Field";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"; 
import { getRoute, apiRequest } from "../../helpers/Api";
import { 

    addNewDashboard,
    closeDashboardModal

} from "../../actions/Dashboard";
import { 
    togglePreloader,
} from "../../actions/index";



class DashboardNewModalContent extends Component {
	constructor ( props ) {
	 	super( props );
	 	
	 	this.validateForm = this.validateForm.bind(this);
	 	this.handleInputChange = this.handleInputChange.bind(this);
        this.saveDashboard = this.saveDashboard.bind(this);

	 	this.state = {
			name: '',
            nameValid: true,
        };
	}
		
    componentDidMount() { 
        this.props.handleOpenModal();
    } 

    validateForm(nameValue) {
        let formValid = true;
        nameValue = nameValue.trim();
        if(nameValue === '') {
            formValid = false;
            this.setState({ nameValid: false });
        } else {
            this.setState({ nameValid: true });
        }

        return formValid;
    }

    saveDashboard(){
    	// should be set from the reducer default state so if it ever chagnes
        // it will be updated here


        if( this.validateForm( this.state.name) ){

            this.props.togglePreloader(true);

            let newDashboard = {
                widgets: [],
                layout: {
                    lg: [],
                    md: [],
                    sm: [],
                    xs: [],
                },
                is_default: false,
                name: this.state.name.trim(),
            }

            apiRequest( getRoute('getDashboards'), {
                  method: 'POST',
                  body: JSON.stringify(newDashboard)
                })
                 .then( theResult => {
                    //console.log('successfully created new dashboard', theResult);
                    this.props.addNewDashboard( theResult.json );
                    this.props.togglePreloader(false);
                    this.props.closeDashboardModal();
                })  
                .catch( theError => {
                    //console.log('new dashboard fail', theError);
                    //TODO: Error for dashboard
                })
        }

    }

    handleInputChange(value, name, event) {
    	this.setState({[name]: value});
    	this.validateForm(value);
	}

	render(){
		return (
			 <div className="dashboard-add-widget-modal-content">
                     
            	<h2>ADD NEW DASHBOARD</h2> 
  
                <Field 
                    type="input"
                    label="New Dashboard Name"
                    name="name"
                    required={true}
                    onChange={ this.handleInputChange }
                    isValid={this.state.nameValid}
                    value={ this.state.name }
                    validationMessage="Please enter a name for your dashboard"
                    shouldGetFocus={true}
                />

      
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.saveDashboard } />
            </div>
		)
	}
}



function mapStateToProps(state) {
    return { 

    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
       togglePreloader: togglePreloader,
       addNewDashboard : addNewDashboard,
       closeDashboardModal: closeDashboardModal,
    }, dispatch)    
}

export default connect( mapStateToProps, matchDispatchToProps )( DashboardNewModalContent ); 