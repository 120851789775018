export default function ( state={
    isVisible: false,
    errorMessage: "",

}, action ) {
    switch( action.type ) {

        case "SHOW_FETCH_ERROR_MSG":
        state.isVisible = action.payload.isVisible;
        state.errorMessage = action.payload.message;
        return {...state};

        default:
        return state;
    }
}