import React, { Component } from "react";
//import InputMask from 'react-input-mask';

export class RadioBtnField extends Component {

	/*	
	Required Props

	Strings:
	name -- unique name for this input
	label -- the label for this form
	type -- field type -- your typical string for an input text/email/password/ect
	required -- true/false -> is this field required?
	
	Events
	onChange -- the on change event

	Optional Props
	id -- unique id for this input, will default by using id_{name}
	requiredValidationMessage -- overides the default required validation message
	customInput -- overired the input used and simply outputs what ever is set in here
	*/

	constructor(props) { 
		super(props); 
		this.inputChanged = this.inputChanged.bind(this);
		this.focusDiv = this.focusDiv.bind(this);
	}

	componentDidMount(){
		//console.log(this.props);
		if( this.props.shouldGetFocus ) {
			this.focusDiv(); 
		}
	}

	inputChanged(event){
        let value = event.target.value;
		this.props.onChange(value, event.target.getAttribute('name'), event, this.props.buttonID);
	}

	focusDiv() {
        var _this = this;
        setTimeout(function(){ _this.refs.theInput.focus(); },1000);  
    }

	render(){

        return (
            <div className={`form-field`}>
                <label htmlFor={this.props.buttonID} className={ this.props.customInput }>
					<input 
						type="radio" 
						name={this.props.name} 
						id={this.props.buttonID} 
						onChange={this.inputChanged} 
						value={this.props.value} 
						checked={ this.props.checked }					
						tabIndex={0}
						ref="theInput" 
					/>
					{ this.props.label }
                </label>
            </div>
        )
	}
}
export default RadioBtnField;
