export default function ( state={
    activeCheckboxChanged: null,
    activeCheckboxStatus: null,
    watershedBoundaries: "on",
    floodPlain: "off",
    floodLine: "off",
    damGauges: "on",
    streamGauges: "on",
    rainGauges: "on",
    weatherRadar: "off",
    precipitationBars: "off",
    thirdPartyData: "off"
}, action ) {
    switch( action.type ) {

        case "CHECKBOX_UPDATED":
            switch(action.payload.id) {
                case "watershed-boundaries":
                state.watershedBoundaries = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.watershedBoundaries;
                break;

                case "flood-plain":
                state.floodPlain = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.floodPlain;
                break;

                case "flood-line":
                state.floodLine = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.floodLine;
                break;

                case "dam-gauges":
                state.damGauges = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.damGauges;
                break;

                case "stream-gauges":
                state.streamGauges = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.streamGauges;
                break;

                case "rain-gauges":
                state.rainGauges = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.rainGauges;
                break;

                case "weather-radar":
                state.weatherRadar = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.weatherRadar;
                break;

                case "precipitation-bars":
                state.precipitationBars = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.precipitationBars;
                break;

                case "third-party-data":
                state.thirdPartyData = action.payload.status ? "on" : "off";
                state.activeCheckboxStatus = state.thirdPartyData;
                break;

                default:
                return state;
            }
            state.activeCheckboxChanged = action.payload.id;

        //console.log(state);
        return {...state};


        default:
        return state;
    }
}