import leftPad from "left-pad";
import slugify from "../helpers/slugify";
import {buildSearchString} from "../helpers/reportBuilderHelpers";
import { timeToInt } from 'time-number';


function get_default_state(){
    return {
        dateTime: {
            range: {
                from: null,
                to: null,
                earliest: null,
                latest: null,
            },
            formattedRange: {
                from: null,
                to: null
            }
        },
        dateTimeToPresentChecked: false,
        currentMonthFromDropdown: null,
        startTime: 0,
        endTime: 0,
        formattedStartTime: "00:00",
        formattedEndTime: "00:00",
        searchInput: "",
        toggleSearchList: false,
        reportTemplateData: {
            title: "",
            type: "STREAM"
        },
        stationsAndAssociatedGauges: [],
        masterStationAndGaugeList: [],
        selectedStations: [],
        stationGauges: [],
        currentStationSelected: null,
        validationErrorMsg: "",
        searchInputValidationErrorMsg: "",
        dateTimeValidationErrorMsg: "",
        formValidated: false,
        reportResults: null,
        reportType: "custom",
        selectedStandardReport: "",
        doShowReportBuilder: true,
        plotGraphOnSeperateContainer: false,
        startTimeIsValid: true,
        endTimeIsValid: true,
        chartObjects: {
            report: {},
            timeStamp: null
        },
        showDonRiverAlarms: false,
        generatedReportUrl: null,
        visualizationUrlRequest: {
            link: null,
            plotSeperately: null,
            fromDate: null,
            toDate:null,
        },
    }
}

export default function ( state=get_default_state(), action ) {
    switch( action.type ) {

        case "RESET_DEFAULT_STATE":
            return get_default_state();

        case "DATE_SELECTED":
        //if the to present checkbox is checked set to time to today and disable 	
		if( state.dateTimeToPresentChecked === true ) {
            state.dateTime.range.from = action.payload.from;
            state.dateTime.range.to = new Date();    
        } else {
            state.dateTime.range.from = action.payload.from;
            state.dateTime.range.to = action.payload.to; 
        }

        if( state.dateTime.range.from ){
            var fromDayPicked = leftPad( state.dateTime.range.from.getDate(),2,0 );
    		var fromMonth = leftPad( (state.dateTime.range.from.getMonth()+1),2,0 );
    		var fromYear = state.dateTime.range.from.getFullYear();
            var fromDatePicked = fromYear+"-"+fromMonth+"-"+fromDayPicked;
            state.dateTime.formattedRange.from = fromDatePicked;
        }

        if( state.dateTime.range.to ) {
            var toDayPicked = leftPad( state.dateTime.range.to.getDate(),2,0 );
            var toMonth = leftPad( (state.dateTime.range.to.getMonth()+1),2,0 );
            var toYear = state.dateTime.range.to.getFullYear();
            var toDatePicked = toYear+"-"+toMonth+"-"+toDayPicked;
            state.dateTime.formattedRange.to = toDatePicked;
        }
        
        return {...state};

        case "RESET_DATE":
        state.dateTime.range.from = action.payload.from;
        state.dateTime.range.to = action.payload.to;
        state.dateTime.formattedRange.from = action.payload.from;
        state.dateTime.formattedRange.to = action.payload.to;
        return{ ...state }

        case "TIME_VALUE_CHANGED":
        if(action.payload.id === "start-time") {
            state.startTime = action.payload.value;
            state.formattedStartTime = action.payload.formattedValue;
        } else {
            state.endTime = action.payload.value;
            state.formattedEndTime = action.payload.formattedValue;
        }

        return{...state}

        case "TIME_VALUE_RESET":
        state.startTime = 0;
        state.endTime = 0;
        return{...state}

        case "SEARCH_INPUT_CHANGE":
        state.searchInput = action.payload;
        return{...state}

        case "SEARCH_LIST_TOGGLE_CLICKED":
        state.toggleSearchList ? state.toggleSearchList = false : state.toggleSearchList = true;
        return{...state}

        case "SET_REPORT_TEMPLATE_DATA":
        if( state.reportTemplateData.title !== action.payload.title ) {
            state.selectedStations = [];
            state.stationGauges = [];
        }
        state.reportTemplateData.title = action.payload.title;
        state.reportTemplateData.type = action.payload.type;
        state.reportTemplateData.stations = action.payload.stations;
        return{...state}

        case "ADD_STATION_TO_REPORT": 
        var addStation = true;
        for(var i=0; i< state.selectedStations.length; i++) {
            if( state.selectedStations[i].id === action.payload.id ) {
                addStation = false;
            }
        }

        if( addStation ) {
            let obj = {
                id: action.payload,
                selectedGauges: []
            };

            state.selectedStations.push(obj);
        }
        return{...state}

        case "UPDATE_SELECTED_STATION_GAUGES":        
        var doAddGaugeList = true;
        for( var k=0; k < state.selectedStations.length; k++ ) {
            if( state.selectedStations[k].id === action.payload.id ) {
                doAddGaugeList = false;
            }
        }

        if( doAddGaugeList ) {
            var obj2 = {
                id: action.payload.id,
                result: action.payload.gauges
            };
            
            state.stationGauges.push(obj2);
        }
        
        state.currentStationSelected = action.payload.stationId;
        return{...state}

        case "ADD_ALL_STATIONS_TO_REPORT":
        state.selectedStations = [];
        state.stationGauges = [];

        var selectedGauges = [];
        for( let i = 0; i < action.payload.length; i++ ) {
            selectedGauges = [];   
            for( let j = 0; j < action.payload[i].gauges.length; j++ ) {
                let obj = {
                    id: action.payload[i].gauges[j].id,
                    sensorName: action.payload[i].gauges[j].sensorName
                };
                selectedGauges.push(obj);        
            }

            var id = slugify(action.payload[i].stationName);
            let obj = {
                id: id,
                selectedGauges: selectedGauges,
                title: action.payload[i].stationName
            };

            state.selectedStations.push(obj);
        }
        return{...state}

        case "REMOVE_STATION_FROM_REPORT":
        for( let m=0; m < state.stationGauges.length; m++ ) {
            if( state.stationGauges[m].id === action.payload.id ) {
                state.stationGauges.splice(m, 1);
            }
        }
        for( let j=0; j < state.selectedStations.length; j++  ) {
            if( state.selectedStations[j].id === action.payload.id ) {
                state.selectedStations.splice(j, 1);
            }
        }
        
        return{ ...state }

        case "GAUGE_ADDED_TO_REPORT": 
        var gaugeWasAdded = true;
        for( var n = 0; n < state.selectedStations.length; n++  ) {
            if( state.selectedStations[n].id.toString() === action.payload.stationId ) {
                //console.log("match");
                if( state.selectedStations[n].selectedGauges > 0 ) {
                    //there is a selected gauge
                    for( var o = 0; o < state.selectedStations[n].selectedGauges.length; o++  ) {
                        gaugeWasAdded = state.selectedStations[n].selectedGauges[o].sensorName.includes(action.payload.gauge);
                    }
                } else {
                    let obj = {
                        id: action.payload.gaugeId,
                        selected: true
                    };
                    
                    state.selectedStations[n].selectedGauges.push(obj);
                }
            } 

            if( !gaugeWasAdded ) {
                let obj = {
                    sensorName: action.payload.gauge,
                    id: action.payload.id
                };
                state.selectedStations[n].selectedGauges.push(obj);
                gaugeWasAdded = true;
            }
        }
        
        return{ ...state }

        case "GAUGE_REMOVED_FROM_REPORT":
        switch( action.payload.reportType ) {
            case"standard":
            for( let o = 0; o < state.selectedStations.length; o++ ) {
                if( state.selectedStations[o].id === action.payload.station ) {
                    if( action.payload.station === state.selectedStations[o].id ) {
                        var index = state.selectedStations.findIndex(function(el){
                            return el.id === action.payload.station;
                        })

                        if (index !== -1) {
                            state.selectedStations.splice(index, 1);
                        }
                    }
                }
            }
            break;
            
            default:
            for( let o = 0; o < state.selectedStations.length; o++ ) {
                if( state.selectedStations[o].id.toString() === action.payload.station ) {
                    if( state.selectedStations[o].selectedGauges ) {
                        for( var p = 0; p <  state.selectedStations[o].selectedGauges.length; p++) {
                            if( action.payload.gauge.toString() === state.selectedStations[o].selectedGauges[p].id.toString() ) {
                                let index = state.selectedStations[o].selectedGauges.findIndex(function(el){
                                    return el.id.toString() === action.payload.gauge.toString();
                                })
    
                                if (index !== -1) {
                                    state.selectedStations[o].selectedGauges.splice(index, 1);
                                }
                            }
                        }
                    }
                }
            }
            break;
        }
        return{ ...state }

        case "VALIDATION_ERROR_MSG":
        state.validationErrorMsg = action.payload;
        return{ ...state }

        case "FORM_VALIDATED":
        state.formValidated = action.payload;
        return{ ...state }

        case "SET_REPORT_RESULTS":
        state.doShowReportBuilder = false;
        state.reportResults = action.payload;
        return{ ...state }

        case "SHOW_REPORT_BUILDER_VIEW":
        state.doShowReportBuilder = action.payload;
        return{ ...state } 


        case "PLOT_GRAPH_ON_SEPERATE_CONTAINER":
        state.plotGraphOnSeperateContainer = action.payload;
        return{ ...state }

        case "SETUP_STATIONS_GAUGES":
        state.selectedStations = [];
        state.stationsAndAssociatedGauges = action.payload;

        //console.log(action.payload);

        //setup the searchable string
        for (let i = 0; i < state.stationsAndAssociatedGauges.length; i++) { 
            // Create variable to hold searchable items
            let searchString = state.stationsAndAssociatedGauges[i].stationName.toLowerCase();

            // Curently standard reports only show station names, don't add the gauges
            if( state.stationsAndAssociatedGauges[i].type !== "standard" ){
                searchString = buildSearchString(searchString, state.stationsAndAssociatedGauges[i].gauges, "reports");
                /*for (var b = 0; b < state.stationsAndAssociatedGauges[b].gauges.length; b++) {
                    if( state.stationsAndAssociatedGauges[i].gauges[b] !== undefined ) {
                        searchString += " " + state.stationsAndAssociatedGauges[i].gauges[b].sensorName.toLowerCase();
                        searchString += " " + state.stationsAndAssociatedGauges[i].gauges[b].extraSearchTerms
                        if( state.stationsAndAssociatedGauges[i].id === 1027 ) {
                            console.log( state.stationsAndAssociatedGauges[i] );
                            console.log( state.stationsAndAssociatedGauges[i].gauges[b].id );
                        }
                    }
                }*/
            }
            //console.log(searchString);
            state.stationsAndAssociatedGauges[i].searchString = searchString;
        }

        //console.log(state.stationsAndAssociatedGauges);

        state.reportType = action.payload[0].type;
        state.selectedStandardReport = action.payload[0].gaugeId;

        var today = new Date();
        var h = leftPad( today.getHours(),2,0 );
        var m = leftPad( today.getMinutes(),2,0 );

        var currentTime = h+":"+m;
        state.endTime = timeToInt(currentTime);
        state.formattedEndTime = currentTime;
        return{ ...state }

        case "UPDATE_CHART_OBJECTS": 

        let obj = {
            report: action.payload,
            timeStamp: Date.now(),
        };
        state.chartObjects = obj;
        return{ ...state }

        case "ADD_ALL_GAUGES_FOR_SELECTED_STATION":
        var ary = [];
        for( let i=0; i < state.stationsAndAssociatedGauges.length; i++ ) {
            if( action.payload === state.stationsAndAssociatedGauges[i].stationName ) {
                for( let j=0; j < state.stationsAndAssociatedGauges[i].gauges.length; j++ ) {
                    let obj = {
                        id: state.stationsAndAssociatedGauges[i].gauges[j].id,
                        sensorName: state.stationsAndAssociatedGauges[i].gauges[j].sensorName
                    };

                    ary.push(obj);
                }
            }
        }
        for( let k=0; k < state.selectedStations.length; k++ ) {
            if( action.payload === state.selectedStations[k].title ) {
                state.selectedStations[k].selectedGauges = ary;
            }
        }
        
        return{ ...state }

        case "STORE_MASTER_STATION_LIST":
        //console.log(action.payload);
        state.masterStationAndGaugeList = action.payload;
        return{ ...state }

        case "TOGGLE_ALARMS_FOR_DON":
        state.showDonRiverAlarms = action.payload
        return{ ...state }

        case "SET_TIME_IS_VALID":
        if( action.payload.id === "start-time" ) {
            state.startTimeIsValid = action.payload.isValid;
        } else {
            state.endTimeIsValid = action.payload.isValid;
        }
        return{ ...state }

        case "SET_DATE_RANGE_ERROR":
        state.dateTimeValidationErrorMsg = action.payload;
        return{ ...state }

        case "SET_SEARCH_INPUT_ERROR":
        state.searchInputValidationErrorMsg = action.payload;
        return{ ...state }

        case "CLEAR_ALL_STATIONS_FROM_MENU":
        state.selectedStations = [];
        return{ ...state }

        case "SET_DATETIME_TO_PRESENT":
        state.dateTimeToPresentChecked = action.payload;
        state.dateTime.range.to = new Date();  
        return{...state  }

        case "AUTO_UPDATE_TIME":
        if( state.dateTimeToPresentChecked === true ) {
            let today = new Date();
            let h = leftPad( today.getHours(),2,0 );
            let m = leftPad( today.getMinutes(),2,0 );
            let currentTime = h+":"+m;
            state.endTime = timeToInt(currentTime);
            state.formattedEndTime = currentTime;   
        }    
        return{ ...state }

        case "SET_MONTH_FOR_DATE_PICKER":
        state.currentMonthFromDropdown = action.payload;    
        return{ ...state }

        case "DESELECT_GAUGES":
        // an array of gauge ids to select
        const removeGauges = action.payload.toString();
        for (let i = 0; i < state.stationsAndAssociatedGauges.length; i++) {
            for (let b = 0; b < state.stationsAndAssociatedGauges[i].gauges.length; b++) {
                if( removeGauges.indexOf( state.stationsAndAssociatedGauges[i].gauges[b].id ) !== -1){
                    state.stationsAndAssociatedGauges[i].gauges[b].selected = false;
                }
            }
        }
        return{ ...state };
        
        case "REMOVE_ALL_GAUGES":
        for (let i = 0; i < state.stationsAndAssociatedGauges.length; i++) {
            for (let b = 0; b < state.stationsAndAssociatedGauges[i].gauges.length; b++) {
                state.stationsAndAssociatedGauges[i].gauges[b].selected = false;
            }
        }
        return{ ...state }

        case "SELECT_GAUGES":
            // an array of gauge ids to select
            const selectGauges = action.payload;
            for (let i = 0; i < state.stationsAndAssociatedGauges.length; i++) {
                for (let b = 0; b < state.stationsAndAssociatedGauges[i].gauges.length; b++) {
                    if( selectGauges.indexOf( state.stationsAndAssociatedGauges[i].gauges[b].id ) !== -1){
                        state.stationsAndAssociatedGauges[i].gauges[b].selected = true;
                    }
                }
            }
        return{ ...state };

        case "DATE_RANGE_FOR_DAY_PICKER":
        //console.log( action.payload );
        state.dateTime.range.earliest = action.payload.earliestDate;
        state.dateTime.range.latest = action.payload.latestDate;    
        return { ...state }
        
        case "STORE_GENERATED_REPORT_URL":
        state.generatedReportUrl = action.payload;    
        return { ...state }

        case "DASHBOARD_VISUALIZATION_REQUEST_DATA":
            if(action.payload) {
                state.visualizationUrlRequest.link = action.payload.link;
                state.visualizationUrlRequest.plotSeperately = action.payload.plotSeperately;
                state.visualizationUrlRequest.fromDate = action.payload.fromDate;
                state.visualizationUrlRequest.toDate = action.payload.toDate;
                state.visualizationUrlRequest.gaugeList = action.payload.gaugeList;
            } else {
                state.visualizationUrlRequest.link = null;
                state.visualizationUrlRequest.plotSeperately = null;
                state.visualizationUrlRequest.fromDate = null;
                state.visualizationUrlRequest.toDate = null;
                state.visualizationUrlRequest.gaugeList = null;
            }
        
        return { ...state }

        default:
        return state;  
    }
}