import React, {Component} from "react";

class SafetyCodeIconContainer extends Component {

    render() {

        return(
            <div className="dam-safety-container">
                <div className="safety-code-icon-container">
                    <div className={ "safety-icon "+ this.props.safetyCode  } aria-hidden="true" ></div>
                    <span > { this.props.safetyCondition } </span>
                </div>
            </div>
        );
    }
}

export default SafetyCodeIconContainer;  