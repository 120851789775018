export default function ( state={
    documentData: null,
}, action ) {
    switch( action.type ) {
        case "SET_DOCUMENT_LIBRARY":
            state.documentData = action.payload;
        return {...state};

        default:
        return state;
    }
}