import React, { Component } from "react";
import PlotlyChart from "./PlotlyChart.js";
import SvgIcon from "../SvgIcon";

import { 
    formatGaugeAmount,
    formatMeasurement,
    getNonNullMax,
    getNonNullMin,
    hhmmampm
 } from "../../helpers/gaugeHelpers";


/* 

EXAMPLE chartData prop:

chartData = {
    
    unitOfMeasure: apiData.unit_of_measure,  // the unit of measure from the api
    yAxisName: 'Some Name', //optional not all graphs show a yAxisLabel
    chartData: [
        {
            name: "Some Name", // optional, not all graphs use legends
            timeSeriesData: apiData.time_series_data, 
        },
        {
            name: "Some Name 2", // optional, not all graphs use legends
            timeSeriesData: apiData.time_series_data,
        }
    ],

    // feel like there would be a more flexable way to add lines,
    // for now this will work
    lowLowLine: null, // null is dont show
    lowLine: null, // null is dont show
    normalLine: null, // null is dont show
    highLine: null, // null is dont show
    highHighLine: null // null is dont show
        
}

*/

class TimeSeriesChart extends Component {

    constructor ( props ) {
        super( props );
        this.getDataFromApiData = this.getDataFromApiData.bind(this);
        this.getLayout = this.getLayout.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.addChartLine = this.addChartLine.bind(this);
        this.clearChartLines = this.clearChartLines.bind(this);

        this.getYAxisRange = this.getYAxisRange.bind(this);
        this.getSmallestXaxis = this.getSmallestXaxis.bind(this);
        this.getLargestXAxis = this.getLargestXAxis.bind(this);
        this.setupChartLines = this.setupChartLines.bind(this);
        this.orderDataByMean = this.orderDataByMean.bind(this);
        this.formatDataForDashboardWidget = this.formatDataForDashboardWidget.bind(this);

        //setup the chart line manager
        this.chartLines = {};
        this.clearChartLines();
        
        //console.log( this.props );
    }

    // resets the chart lines to be empty
    clearChartLines(){
        this.chartLines = {
            shapes :[],
            annotations: []
        }
    }


    // gets the smallest date in the provided chart datas
    getSmallestXaxis(){

        let smallestDate = 0;

        if(!this.props.chartData.chartData[0].timeSeriesData.length){
            var targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + 10);
            smallestDate = targetDate.getTime();
        }
        else{
            smallestDate = new Date(this.props.chartData.chartData[0].timeSeriesData[ this.props.chartData.chartData[0].timeSeriesData.length-1].datetime_of_measurement).getTime();
        }


        for (var i = 1; i < this.props.chartData.chartData.length; i++) {
            if( this.props.chartData.chartData[i].timeSeriesData.length ){

                const axisDate =  new Date(this.props.chartData.chartData[i].timeSeriesData[ this.props.chartData.chartData[i].timeSeriesData.length-1].datetime_of_measurement).getTime();
                if( smallestDate > axisDate ){
                    smallestDate = axisDate;
                }
                
            }
        }

        return smallestDate;
    }

    //gets the largest date in the provided chart datas
    getLargestXAxis(){

        let largestDate = 0;
        if(this.props.chartData.chartData[0].timeSeriesData.length){
            largestDate = new Date(this.props.chartData.chartData[0].timeSeriesData[0].datetime_of_measurement).getTime();
        }

        for (var i = 1; i < this.props.chartData.chartData.length; i++) {

            if( this.props.chartData.chartData[i].timeSeriesData.length ){
                const axisDate = new Date(this.props.chartData.chartData[i].timeSeriesData[0].datetime_of_measurement).getTime();
                if( largestDate < axisDate ){
                    largestDate = axisDate;
                }
            }
        }
        return largestDate;
    }

    addChartLine(linePos, lineText, color, xStart, xEnd){


        var shape = {
            type: 'line',
            x0: xStart,
            y0: linePos,
            x1: xEnd,
            y1: linePos,
            line: {
                color: color,
                width: 1,
            }
        };

        var annotation = {
            x: xStart,
            y: linePos,
            text: lineText,
            showarrow: false,
            xanchor: 'left',
            'yanchor' : 'bottom',
            borderpad: 3,
        };

        this.chartLines.shapes.push(shape);
        this.chartLines.annotations.push(annotation);
    }

    /// returns the Y axis range including and takes into concidersation any annotation lines
    getYAxisRange( data ){

         //an array of all the custom lines passed into the system
        //console.log( this.props.chartData );


        var customValues = [];

        //clean the custom valeus for any nulls for undefined
        for (var j = this.chartLines.annotations.length - 1; j >= 0; j--) {
            if( !isNaN(this.chartLines.annotations[j].y) ){
                customValues.push(this.chartLines.annotations[j].y);
            }
        }

        // loop over the plots and find the lowest and highest values for the provided data
        var lowestValue = getNonNullMin.apply(null, data[0].y);
        var highestValue = getNonNullMax.apply(null, data[0].y);
       
        for (var i = 1; i < data.length; i++) {
            let currentLow = getNonNullMin.apply(null, data[i].y);
            if( lowestValue > currentLow ){
                lowestValue = currentLow;
            }

            let currentHigh = getNonNullMax.apply(null, data[i].y);
            if( currentHigh > highestValue ){
                highestValue = currentHigh;
            }
        }

        //calculate the min and max for the y axis
        //get the lowest value shown on the graph and max
        if( customValues.length){
            lowestValue = getNonNullMin(lowestValue, getNonNullMin.apply(null, customValues) );
        }


        //get the highest value for the graph
        if( customValues.length){
            highestValue = getNonNullMax( highestValue, getNonNullMax.apply(null, customValues) );
        }

        var offsetMultiplyer = null;
        switch( this.props.type ) {
            case "report": 
            offsetMultiplyer = 0.005;
            break;

            case "stationDetail":
            offsetMultiplyer = 0.002;
            break;

            case "stationDetailSecondary":
            offsetMultiplyer = 0.05;
            break;

            default:
            offsetMultiplyer = 0.02;
            break;
        }



        const yAxisRange = [Math.floor(lowestValue - (lowestValue * offsetMultiplyer)), Math.ceil(highestValue + (highestValue * offsetMultiplyer)) ];

        return yAxisRange;

    }  

    //converts the
    getDataFromApiData(chartData, unitOfMeasure, color){
        var data = {
            x: [],
            y: [],
            yTickValues: [],
        }

        unitOfMeasure = formatMeasurement( unitOfMeasure );

        var theChartData = chartData.timeSeriesData.slice();
        theChartData.reverse();

        //populate the Y tick values
        for (var i = 0; i < theChartData.length; i++) {

            // setup the values to be used
            var measuredValue = null;
            if( theChartData[i].measured_value !== null){
                if( unitOfMeasure === "mm" ) {
                    measuredValue = Math.round( theChartData[i].measured_value * 100 ) / 100;
                } else {
                    measuredValue = theChartData[i].measured_value.toFixed(3);
                }
                
            }

            // convert the date to a localized time
            var theDate = new Date( theChartData[i].datetime_of_measurement );
            
            //add to the data object the values 
            data.x.push(  theDate );
            data.y.push( measuredValue );
            //console.log(theDate, datetimeOfMeasurement);

            //setup the yTickValues that is used custom text on the hover label
            var previousValue = 0;
            if( i !== 0 ){
                //we can calculate the previous value
                previousValue = theChartData[i].measured_value - theChartData[i-1].measured_value;
                //previousValue = Math.round( previousValue * 100 ) / 100;
                previousValue = previousValue.toFixed(3);
            }
            var prevString = previousValue + unitOfMeasure;

            //console.log(unitOfMeasure);
            if(unitOfMeasure === "mm") {
                data.yTickValues.push(measuredValue + unitOfMeasure + ' ' + hhmmampm( theDate ) );
            } else {
                data.yTickValues.push(measuredValue + unitOfMeasure + ', ' + prevString + ' ' + hhmmampm( theDate ) );
            }
        }

        //Sets up the data in the plotly format
        
        var graphData = 
          {
            x: data.x,
            y: data.y,
            name: chartData.name,
            type: 'scatter',
            fill: 'tonexty',
            text: data.yTickValues,
            hoverinfo: 'text',
            mode: 'lines+markers',
            line: {
                color: color,
                width: 0,
            }
          };

        return graphData;
    }

    setupChartLines(chartData){
        //x should start on the oldest valuethis.props.chartData.timeSeriesData
        var xStart = this.getSmallestXaxis();

        //x end should be the most recent data
        var xEnd = this.getLargestXAxis();

        //get the type of measurement to display on the lines
        var typeString = '';
        if(chartData.unitOfMeasure === '(m^3)/s'){
            typeString = " - Discharge";
        }
        else if(chartData.unitOfMeasure === 'm'){
            typeString = ' - WL';
        }

        // add the low low alarm
        if( chartData.lowLowLine  !== null && chartData.lowLowLine !== undefined ){
            this.addChartLine(
                chartData.lowLowLine,
                '&nbsp;&nbsp;LLA' + typeString + ' ('+ formatGaugeAmount(chartData.lowLowLine, chartData.unitOfMeasure, "WL") +')',
                'rgb(0, 0, 0)',
                xStart,
                xEnd
            );
            
        }

        // add the low alarm
        if( chartData.lowLine  !== null && chartData.lowLine !== undefined ){
            this.addChartLine(
                chartData.lowLine,
                '&nbsp;&nbsp;LA' + typeString + ' ('+ formatGaugeAmount(chartData.lowLine, chartData.unitOfMeasure, "WL") +')',
                'rgb(0, 0, 0)',
                xStart,
                xEnd
            );
            
        }


        //if normal is set add it to the graph
        if( chartData.normalLine !== null && chartData.normalLine !== undefined ){
            this.addChartLine(
                chartData.normalLine,
                '&nbsp;&nbsp;Normal' + typeString + ' ('+ formatGaugeAmount(chartData.normalLine, formatMeasurement(chartData.unitOfMeasure),"WL") +')',
                'rgb(0, 0, 0)',
                xStart,
                xEnd
            );
        }

        //if the high is set add it to the graph
        if( chartData.highLine !== null && chartData.highLine !== undefined ){
            this.addChartLine(
                chartData.highLine,
                '&nbsp;&nbsp;HA' + typeString + ' (' +  formatGaugeAmount(chartData.highLine, chartData.unitOfMeasure, "WL") + ')',
                'rgb(255, 0, 0)',
                xStart,
                xEnd
            );
        }


        //if the high high is set add it to the graph
        if( chartData.highHighLine !== null && chartData.highHighLine !== undefined ){
            this.addChartLine(
                chartData.highHighLine,
                '&nbsp;&nbsp;HHA' + typeString + ' (' +  formatGaugeAmount(chartData.highHighLine, chartData.unitOfMeasure, "WL") + ')',
                'rgb(255, 0, 0)',
                xStart,
                xEnd
            );
        }

        if( chartData.customLines){

            for (var i = 0; i < chartData.customLines.length; i++) {
                this.addChartLine(
                    chartData.customLines[i].lineAt,
                    '&nbsp;&nbsp;' + chartData.customLines[i].lineName,
                    chartData.customLines[i].colour,
                    xStart,
                    xEnd
                );
                
            }
            
        }

        //console.log(this.chartLines);
        //console.log(chartData.customLines);
    }

    getLayout( data, chartData ){
        //sets up the layout variable in the plotly format

        //console.log(data);
        //console.log(chartData);

        var layout = {};
        var range = this.getYAxisRange( data );

        var title = "";
        if( this.props.selectedSensor ) {
            title = this.props.selectedSensor;
        }

        if( this.props.selectedSensor === "Storage" ) {
            title = "Total reservoir storage"
        }
        
        if( chartData.unitOfMeasure === "m^3" ) {
            title += " (m³)"
        } else if( chartData.unitOfMeasure === "m" ) {
            title += " (m)"
        } else if( chartData.unitOfMeasure === "C" ) {
            title += " (°C)"
        } else if( chartData.unitOfMeasure === "(m^3)/s" ) {
            title += " (m³/s)"
        } else {
            title += " ("+chartData.unitOfMeasure+")" ;
        }   

        //console.log(this.props.type);

        switch( this.props.type ) {
            case "report":

                layout = { 
                    showlegend: false,
                    legend: {
                        x: 0.5,
                        y: -.2,
                        orientation: "h",
                        xanchor:"center",
                        yanchor:"top",
                    },
                    font: {
                        family: "Lato",
                        size:16,
                    },
                    xaxis: {
                        showticklabels: true,
                        showgrid: true,
                        fixedrange: (this.props.allowModeBar ? false : true ),
                        showline: false,

                        //using ticks to increase spacing between label and axis
                        ticks: 'outside',
                        tickcolor: 'rgba(0,0,0,0)',
                        ticklen: 10,
                        zeroline: false,
                    },
                    yaxis: {
                        showticklabels: true,
                        showgrid: true,
                        //autoRange: true,
                        range: range,
                        
                        fixedrange: (this.props.allowModeBar ? false : true ),
                        showline: false,

                        //using ticks to increase spacing between label and axis
                        ticks: 'outside',
                        ticklen: 5,
                        tickcolor: 'rgba(0,0,0,0)',
                        zeroline: false,
                        title: chartData.yAxisName,
                    },
                    margin: {
                        l: 100,
                        r: 50,
                        b: 80,
                        t: 40,
                        pad: 0
                    },
                };            

                if( data.length > 1 ){
                    layout.showlegend = true;
                    layout.margin.b = 40;
                }
  

            break;

            

            default:
                layout = {

                    paper_bgcolor:'rgba(0, 0, 0, 0)',
                    plot_bgcolor: 'rgba(0, 0, 0, 0)',

                    xaxis: {
                        showticklabels: false,
                        showgrid: false,
                        fixedrange: (this.props.allowModeBar ? false : true ),
                        zeroline: false,
                    },
                    /*yaxis: {
                        showticklabels: false,
                        showgrid: false,
                        fixedrange: true,
                        zeroline: false,
                    },
                    margin: {
                        l: 0,
                        r: 0,
                        b: 0,
                        t: 0,
                        pad: 0
                    },*/
                    yaxis: {
                        showticklabels: true,
                        showgrid: true,
                        //autoRange: true,
                        range: range,
                        
                        fixedrange: (this.props.allowModeBar ? false : true ),
                        showline: false,
                        //using ticks to increase spacing between label and axis
                        ticks: 'outside',
                        ticklen: 5,
                        tickcolor: 'rgba(0,0,0,0)',
                        zeroline: false,
                        title: chartData.yAxisName,
                        tickfont: {
                            size: 10,
                        }
                    },
                    margin: {
                        l: 40,
                        r: 0,
                        b: 10,
                        t: 20,
                        pad: 0
                    },
                };

                if( this.props.type === 'stationDetail'){
                    //client request to show x axis on details
                    layout.xaxis.showgrid = true;
                    layout.xaxis.showticklabels = true;
                    layout.xaxis.hoverformat = '.2f';
                    layout.yaxis.showgrid = true;
                    layout.yaxis.showticklabels = true;
                    layout.yaxis.title = title;
                    layout.margin =  {
                        l: 80,
                        r: 50,
                        b: 40,
                        t: 20,
                        pad: 10
                    };
                }
            break;
        }



        //always reset the chart lines
        this.clearChartLines();

        //could be conditional based on props
        //console.log(this.props.showAlarms);
        if( this.props.showAlarms === true || this.props.showAlarms === undefined ) {
            this.setupChartLines(chartData);
        }

        // Add the chart lines to the layout
        // Since clear lines is always called these will either be empty or contain lines
        layout.shapes = this.chartLines.shapes;
        layout.annotations = this.chartLines.annotations;

                      
        //if( this.chartLines.shapes.length ){
            //only use the range if we are using annotations
            layout.yaxis.range = this.getYAxisRange( data );
        //}

        //console.log( layout );

        

        return layout;
    }

    getConfig( data ){
        //set the plotconfig settings
        var plotConfig = {
            displayModeBar: (this.props.allowModeBar ? true : false ),
        }

        return plotConfig;
    }


    // orders the data by the mean of the y axis
    // this is to help with colouring, larger values should go in back 
    // this gives best colour blending
    orderDataByMean( data ){

        var means = [];

        for (var i = 0; i < data.length; i++) {

            let total = 0;
            for (var b = 0; b < data[i].y.length; b++) {
                total = total + data[i].y[b];
            }

            means.push( { originalPos: i, mean: total / data[i].y.length });
        }


        //sort the means highest to lowest
        means.sort(function(a, b) {
            return parseFloat(a.mean) - parseFloat(b.mean);
        });

        let newData = [];
        for (var d = 0; d < means.length; d++) {
            newData.push( data[means[d].originalPos] );
        }

        return newData; 

    }

    formatDataForDashboardWidget(e) {
        e.preventDefault();
		e.stopPropagation();
        //console.log(this.props);

        let obj = {
            chartData: this.props.chartData.chartData,
            gaugeType: this.props.gaugeType,
            stationName: this.props.chartData.chartData[0].name,
            gaugeId: this.props.gaugeId,
        };
        
        this.props.saveChartToDashboard( obj );
    }

    render() {
        
        if( this.props.chartData.chartData[0].timeSeriesData.length === 0 ){
            // There is no data to display on this chart, skip adding the line
            return null;
        }

        let data = []; 
        let chartColors = ["rgb(43, 171, 226)", "rgb(253, 185, 19)", "rgb(141, 198, 63)", "rgb(48, 56, 58)"];
        let batteryChartColors = ["rgb(245, 136, 31)", "rgb(253, 185, 19)", "rgb(141, 198, 63)", "rgb(48, 56, 58)"];

        for (var i = 0; i < this.props.chartData.chartData.length; i++) {
            if( this.props.gaugeType === "BATTERY" ) {
                data.push( this.getDataFromApiData( this.props.chartData.chartData[i],  this.props.chartData.unitOfMeasure, batteryChartColors[i] ) );
            } else {
                data.push( this.getDataFromApiData( this.props.chartData.chartData[i],  this.props.chartData.unitOfMeasure, chartColors[i] ) );
            }
            
        }
        data = this.orderDataByMean( data );

        let layout = this.getLayout( data, this.props.chartData );
        let config = this.getConfig( data );

        let height = 150;
        if( this.props.graphHeight ){
            height = this.props.graphHeight;
        }

        //chart height multiplier. each station after the first ten adds another 60px to the charts height.  
        if( this.props.chartData.chartData.length > 10 ) {
            let numOfChartsOverTen = this.props.chartData.chartData.length - 10;
            height = height + (60 * numOfChartsOverTen);
        }

        const graphHeight = {height: height+"px"};
        const chartStyles = { width: "100%", height:height+"px" };

        let saveChartLink = (this.props.type === "report" && this.props.chartData.chartData.length === 1 && this.props.canSaveCharts ) ? 
        <div className="save-chart-btn">
            <button className="btn-link" onClick={this.formatDataForDashboardWidget} aria-label={this.props.saveChartText}><SvgIcon icon="floppy-disk" /> Save to dashboard </button>
        </div> : null; 

        //console.log( this.props );
        
        return(
            <div style={ graphHeight } id={ this.props.uniqueID } >
                <PlotlyChart 
                    style={ chartStyles } 
                    data={ data } 
                    layout={ layout } 
                    config={ config } 
                />
                { saveChartLink } 
            </div>
        );
    }
}

export default TimeSeriesChart;