import React, { Component } from "react";
import SvgIcon from "../components/SvgIcon";
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';

const CameraIcon = ({ count, onMarkerClick }) => <div className="map-camera-icon" onClick={onMarkerClick}><SvgIcon icon="camera" /><span className="count">{count}</span></div>;
const ClusterIcon = ({ count, onMarkerClick }) => <div className="map-cluster-icon" onClick={onMarkerClick}><SvgIcon icon="cluster" /><span className="count">{count}</span></div>;

class CameraMap extends Component {

    constructor ( props ) {

        /*
            Requires Props:

            stations = object -- REQUIRED
            --  A key based object of all stations
            {
                'HY101': {
                    'cameras': [{
                        'title': '',
                        'photo': '',
                        'thumbnail': ''
                    },...] // and array of cameras
                    latitude: 123.2,
                    longitude: -123.2
                }
                ...
            }

            stationClick - callback - REQUIRED
            -- The call back method when a station is clicked on the map
            -- will return the station ID
        */

        super( props ); 

        this.stationMapClick = this.stationMapClick.bind(this);
        this.googleMapChange = this.googleMapChange.bind(this);
        this.clusterMapClick = this.clusterMapClick.bind(this);

        this.state = {
            'zoom': 10,
            'bounds': [],
            'centerLat': 43.6532,
            'centerLng': -79.3832
        }

        this.myRef = React.createRef();

    }

    stationMapClick(station_id){
        // call back to the original station
        this.props.stationClick(station_id);
    }

    googleMapChange( google ){

        // update the internal state from google maps event
        this.setState({
            zoom: google.zoom,
            bounds:[
                google.bounds.nw.lng,
                google.bounds.se.lat,
                google.bounds.se.lng,
                google.bounds.nw.lat
            ],
            centerLat: google.center.lat,
            centerLng: google.center.lng
        });

    }
    clusterMapClick( zoom, centerLat, centerLng){
        // set new center on clustet click
        this.setState({
            'zoom': zoom,
            'centerLat': centerLat,
            'centerLng': centerLng
        });
    }

	render() {

        const stations = this.props.stations;
        const keys = Object.keys(stations);
        const points = [];
        let stationIcons = []; 
        let clusters = [];

        // build the geojson object for supercluster
        //console.log(stations);
        for (let i = 0; i < keys.length; i++) {
            points.push({
                type: "Feature",
                properties: { cluster: false, stationId: keys[i], count: stations[keys[i]].cameras.length },
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(stations[keys[i]].longitude),
                        parseFloat(stations[keys[i]].latitude)
                    ]
                }
            });

        }

        if(points.length){
            
            // create the new cluster
            const index = new Supercluster({
                radius: 40,
                maxZoom: 16
            });

            // load the points
            index.load(points);

            // get the new list of cluster and normal stations on the map
            clusters = index.getClusters(this.state.bounds, this.state.zoom);

            stationIcons = clusters.map( (cluster) => {

                if(cluster.properties.cluster){
                    // this is a cluster of items, create a cluster icon for it
                    
                    // get the zoom level to see items inside this cluster
                    const zoom = index.getClusterExpansionZoom(cluster.id)

                    return (
                        <ClusterIcon
                            lat={cluster.geometry.coordinates[1]}
                            lng={cluster.geometry.coordinates[0]}
                            count={cluster.properties.point_count}
                            onMarkerClick={(e) => this.clusterMapClick(zoom, cluster.geometry.coordinates[1], cluster.geometry.coordinates[0])}
                        />
                    )
                }
                else{
                    // this is a normal camera view
                    return(
                        <CameraIcon
                            lat={cluster.geometry.coordinates[1]}
                            lng={cluster.geometry.coordinates[0]}
                            count={cluster.properties.count}
                            onMarkerClick={(e) => this.stationMapClick(cluster.properties.stationId)}
                        />
                    )
                }

            });
        }




		return (
			<div className="map-container clear" role="region" aria-labelledby="gauge-map-region">
                <h2 className="screen-reader-text" id="gauge-map-region">Map with stations plotted</h2>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCxel8Hdnu9UVjOQf9RVvUKWskuah13Sj4' }}
                    center={{lat: this.state.centerLat, lng: this.state.centerLng }}
                    zoom={this.state.zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onChange={this.googleMapChange}
                >
                    {stationIcons}
                </GoogleMapReact>
            </div> 
		)
    }
}

export default CameraMap