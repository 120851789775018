import React, { Component } from "react";
import { getRoute, apiRequest } from "../../../helpers/Api";
import LoadingSVG from "../../../images/gauge-placeholder.svg";
import LoadingSpinner from "../../../images/loading-spinner.svg";
import GateStatusTable from "../../station-detailed/GateStatusTable";
import EditWidgetMenu from "../EditWidgetMenu";

class DamOpeningWidget extends Component {

    constructor ( props ) {
        super( props );

        //variables
        this.apiTimeout = null;

        //methods
		this.fetchData = this.fetchData.bind(this);
		this.editWidget = this.editWidget.bind(this);
		this.removeWidget = this.removeWidget.bind(this);
        this.cardRefreshLength = 60000;

        //state
        this.state = {
        	isFetching: true,
        	apiData: null
        }
    }



    componentDidMount(){
    	this.fetchData();
    	this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);

	}
	
	shouldComponentUpdate(nextProps) {
		if( this.props.updateRequired === nextProps.updateRequired ) {
			return true;
		} else {
			return false;
		}
	}

    componentWillUnmount(){
		//clear the timeout for fetching data
		clearTimeout( this.apiTimeout );
	}


    componentDidUpdate(prevProps, prevState){
        //check to see if something was changed that makes us need to update
        let requireFetch = false;

        if( this.props.timeFrame !== prevProps.timeFrame){
            requireFetch = true;
        }

        if( this.props.type !== prevProps.type){
            requireFetch = true;
        }

        if( this.props.gaugeId !== prevProps.gaugeId){
            requireFetch = true;
        }


        if( requireFetch === true ){
            clearInterval( this.apiTimeout );
            this.fetchData();
            this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);

        }


    }


    fetchData(){
		
    	this.setState({isFetching: true });
    	apiRequest( getRoute("currentGateOpenings", [], {station_id: this.props.stationId}) ) 
			.then(result => {  
				//console.log( result );
				this.setState({isFetching: false, apiData: result });
			})
			.catch((error) => {
				//console.log('error fetching gauge data');
				this.setState({isFetching: false });
			});
	}
	
	editWidget( widgetId ) {
		this.props.handleEditWidget( this.props, this.state.apiData.sensor_name, widgetId );
	}

	removeWidget( widgetId ) {
		this.props.handleRemoveWidget(widgetId);
	}

    render() {

    	let className = `dashboard-grid-item dashboard-grid-item-dam-opening`;
    	    	let cardContent = null;
    	if( this.state.apiData === null )
    	{
    		cardContent = (
    			<div className="card card-content gauge">
					<h2 className="gauge-name">Loading&hellip;</h2>
					<img src={ LoadingSVG } className="guage-loading-placeholder" alt="" />
    			</div>
    		);

    	}
    	else{
    		//console.log(this.state.apiData);
    		let cardTitle = ( this.state.apiData.results[0] ? this.state.apiData.results[0].dam_location : '' );
    		cardContent = (
    			<div className="card card-content gauge">
    				<h2 className="gauge-name dam-opening-heading">{cardTitle}</h2>
    				<GateStatusTable gateData={this.state.apiData.results} />
    			</div>
    		);
    	}

    	let loadingWheel = null;
    	if( this.state.isFetching ){
    		loadingWheel = <div className="dashboard-grid-item-loading-wheel"><img src={ LoadingSpinner }  alt="Updating Card" /></div>
		}
		
		let editWidgetMenu = this.props.buildModeActive ? 
		<EditWidgetMenu 
			widgetId={ this.props.widgetId } 
			handleEditWidget={ this.editWidget }
			handleRemoveWidget={ this.removeWidget }
		/>
		: null;
    	
    	return (
    		<div className={ className }>
    			{ cardContent }
				{ loadingWheel }
				{ editWidgetMenu }
    		</div>
    	)
    }
}

export default DamOpeningWidget;