import React, { Component } from "react";

class Modal extends Component {
    constructor ( props ) {
        super( props ); 
        this.clickedModalContent = this.clickedModalContent.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

        //console.log(this.props);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event){
        if(event.keyCode === 27) {
            this.props.doCloseModal();
        }
    }

    clickedModalContent(e) {
        e.stopPropagation();
    }

    render() {
        let modalCss = this.props.customClass ? this.props.customClass : "modal-container";
        let showModalCss = this.props.isVisible ?  modalCss+" visible" : modalCss;
        let modalContentCss = (this.props.isFor === "damCalculator") ? "modal-content-container dam-calculator" : "modal-content-container";
          
        return(
            <div className={ showModalCss } onClick={ this.props.doCloseModal }>
                <div className={ modalContentCss } onClick={ this.clickedModalContent }>
                    {this.props.modalContent}
                </div>
            </div>
        );
    }
}

export default Modal;