import React, { Component } from "react";
import SvgIcon from "../SvgIcon";


class SelectedItemPill extends Component {
	constructor ( props ) {
		super( props );

		//methods
		this.showToolTip = this.showToolTip.bind(this);
		this.hideToolTip = this.hideToolTip.bind(this);

		this.state = {
			tooltipShown: false,
		}
	}

	showToolTip(){
		this.setState({
			tooltipShown: true,
		});
	}

	hideToolTip(){
		this.setState({
			tooltipShown: false,
		});
	}


	render(){
		let removeButton = null;
    	if( this.props.removeable ){
    		removeButton = (
    			<button value={this.props.value} className="selected-gauge-list-remove" onClick={ () => this.props.removeItem( this.props.value, this.props.stationId ) }>
					<span className="screen-reader-text">Remove Item</span>
					<SvgIcon icon="menu-close" />
				</button>
    		)
    	}

    	let attrProps = {
    		'className': `selected-guage-list-item ${this.props.className}`,
    		'key' : this.props.key,
    	};

    	let tooltipEl = null;
    	if(this.props.tooltip !== undefined && this.props.tooltip !== null ){
    		attrProps.className = attrProps.className + ' selected-gauge-list-item-has-tooltip';
			attrProps.onMouseOver = this.showToolTip;
			attrProps.onMouseOut = this.hideToolTip;
    		if( this.state.tooltipShown === true){
    			attrProps.className = attrProps.className + ' show-tooltip';
    		}

    		tooltipEl = (
    			<span id={`tooltip-${this.props.key}`} className="selected-gauge-list-item-tooltip">
    				{this.props.tooltip}
    				<SvgIcon icon="triangle-up" />
    			</span>
    		);
    	}

    	return (
    		<li {...attrProps}>
				{ this.props.name }
				{ removeButton }
				{ tooltipEl }			
			</li>
		)
	}

}


class SelectedGaugeList extends Component {

	/*
	EXAMPLE PROPS
	
	// selected stations and options
	selectedStations = {
        id: 123,
        stationName: 'Some Station',
        showThirdPartyFlag: true/false OPTIONAL -- used to show a third party flag
        gauges: [
	        {
		        id: 321,
		        sensorName: 'Some Gauge',
		        removeable: true, //if the item can be removed
		        toolTip: 'Some Text to display' // optional: sets thist o display a tooltip
		        className: 'some' // optional: adds a class to the gauge pill
		    },
		]
    }
	
    stationsOnly = false // sets if the output should only include the station as a pill or show the station as a title and each gauge as a pill
	removeGauges = true // callback for when the x is clicked, returns an array of ids to remove
	noneSelectedMessage // message shown when no gauges selected

	*/

	constructor ( props ) {
		super( props );

		this.getStationOnlyOutput = this.getStationOnlyOutput.bind(this);
		this.getGaugeOutput = this.getGaugeOutput.bind(this);
		this.removeItem = this.removeItem.bind(this);

    }

    removeItem(gauges, stationId){
    	this.props.removeGauges(gauges, stationId)
    }


    getStationOnlyOutput(){
    	const stationInfo = this.props.selectedStations.map( (station, index) => {
			var removeable = null;

			if(station.removeable) {
				removeable = true;
			}

			if( this.props.disableEditing === true ) {
				removeable = false;
			}

    		const gaugesIds = station.gauges.map( (gauge, index) => {
    			return gauge.id;
    		});
			return (
				<div key={`selected-station-${index}`}>
					<SelectedItemPill
						//key={`selected-station-${index}`}
						name={station.stationName}
						value={gaugesIds}
						removeable={removeable}
						removeItem={this.removeItem}
						stationId={station.id}
						disableEditing={ this.props.disableEditing }
					/>
				</div>
			);
		});

		return stationInfo;

    }

	getGaugeOutput(){

		const stationInfo = this.props.selectedStations.map( (station, index) => {

			

			let thirdPartyTag = null;
			if( station.showThirdPartyFlag === true ){
				thirdPartyTag = <span className="third-party-tag">Third Party</span>;
			}

			return (
				<li key={`station-select-${index}`}>
					<span className="selected-gauge-list-heading">{thirdPartyTag} {station.stationName}</span>
					<ul>
						{ 
							station.gauges.map( (gauge, index2) => {
								var removeable = null;


								if(gauge.removeable) {
									removeable = true;
								}

								if( this.props.disableEditing === true ) {
									removeable = false;
								}

								return (
									<div key={`selected-gauge-${index2}`}>
										<SelectedItemPill
											//key={`selected-gauge-${index2}`}
											name={gauge.sensorName}
											value={[gauge.id]}
											removeable={removeable}
											removeItem={this.removeItem}
											stationId={station.id}
											className={gauge.className}
											tooltip={gauge.tooltip}
											disableEditing={ this.props.disableEditing }
										/>
									</div>
								)

							})
						}
					</ul>
				</li>
			)

		});
			
		

		return stationInfo;
	}


	render() {
		//console.log(this.props);

		let selectedGaugeList = null;
		if( !this.props.selectedStations.length ){
			selectedGaugeList = <li>{this.props.noneSelectedMessage}</li>
		}
		else{

			if( this.props.stationsOnly === true ){
				selectedGaugeList = this.getStationOnlyOutput();
			}
			else{
				
				selectedGaugeList = this.getGaugeOutput();
			}
			
		}


		return (
			<ul className="selected-gauge-list">
				{ selectedGaugeList }
			</ul>
		)
	}
}

export default SelectedGaugeList;