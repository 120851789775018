import React, { Component } from "react";
import PlotlyChart from "./PlotlyChart.js";
import { 
	formatGaugeAmount,
	formatMeasurement,
    hhmmampm
 } from "../../helpers/gaugeHelpers";
 import SvgIcon from "../SvgIcon";


class MinimalizedRainChart extends Component {
    constructor ( props ) {
        super( props ); 
        this.parseRainData = this.parseRainData.bind(this);
        this.checkForNullChart = this.checkForNullChart.bind(this);
    }

	parseRainData(rainData){
       var percipAmnountBars = {
            x: [],
            y: [],
            type: 'bar',
            hoverinfo: 'none',
            marker: {color: 'rgb(43, 171, 226)'},
            yaxis: 'y',
        }

        var percipTotalLine = {
            x: [],
            y: [],
            type: 'scatter',
            hoverinfo: 'text',
            mode: 'lines+markers',
            text: [],
            fill: 'tonexty',
            line: {
                color: 'rgb(245, 136, 31)',
                width: 2,
            },
            yaxis: 'y2',
        }

        var percipTotal = 0;
        var highestPrecipAmount = 0;

        var unitOfMeasure = formatMeasurement( rainData.unitOfMeasure );

        var theRainData = null;
        if( rainData.chartData ) {
            theRainData = rainData.chartData[0].timeSeriesData.slice();
        } else {
            theRainData = rainData.timeSeriesData.slice();
        }
        
        theRainData.reverse();


        for (var i = 0; i < theRainData.length; i++) {

            var measuredValue = theRainData[i].measured_value;
            
            var datetimeOfMeasurement = new Date(theRainData[i].datetime_of_measurement);

            //find the highest single precip measurement;
            if( measuredValue > highestPrecipAmount ) {
                highestPrecipAmount = measuredValue;
            }

            // setup data for the bars
            percipAmnountBars.x.push(  datetimeOfMeasurement );
            percipAmnountBars.y.push( measuredValue )

            // setup date for the line chart
            percipTotalLine.x.push(  datetimeOfMeasurement );
            percipTotal = percipTotal + measuredValue;
            percipTotalLine.y.push( percipTotal ); 


            // set the hover text
            percipTotalLine.text.push( formatGaugeAmount(percipTotal,unitOfMeasure)+' total,<br>'+ formatGaugeAmount(measuredValue, unitOfMeasure)+', '+ hhmmampm(datetimeOfMeasurement));

        }

        //find the min and max so both axis can be the same
        //var lowestValue = 0;
        //var highestValue = getNonNullMax.apply(null, percipTotalLine.y);
        //var offsetMultiplyer = 0.1;
        //var yAxisRange = [0, Math.ceil(highestValue + (highestValue * offsetMultiplyer)) ];

        //console.log( highestPrecipAmount );
        //highestPrecipAmount = highestPrecipAmount *2;
        if( highestPrecipAmount === 0 ) {
            highestPrecipAmount = 0.01;
        }


        var layout = {};
        
        if( percipTotal === 0 ) {
            percipTotal = 0.01;
        }

        layout = {
            showlegend: false,
            legend: {
                x: 0.5,
                y: -.2,
                orientation: "h",
                xanchor:"center",
                yanchor:"top",
            },
            font: {
                family: "Lato",
                size:16,
            },
            xaxis: {
                showticklabels: true,
                showgrid: true,
                fixedrange: true,
                showline: false,

                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                tickcolor: 'rgba(0,0,0,0)',
                ticklen: 5,
            },
            yaxis: {
                showticklabels: true,
                showgrid: true,
                //range: data.yAxisRange,
                //fixedrange: true,
                //autorange: true,
                showline: false,
                //range: yAxisRange,
                range: [0, highestPrecipAmount],
                //using ticks to increase spacing between label and axis
                title: 'Precipitation (mm)',
                ticks: 'outside',
                ticklen: 5,
                tickcolor: 'rgba(0,0,0,0)',
                zeroline: false,
            },

            yaxis2: {
                showticklabels: true,
                showgrid: false,
                range: [0, percipTotal],
                //fixedrange: true,
                //autorange: true,
                overlaying: 'y',
                showline: false,
                //range: yAxisRange,
                ticks: 'outside',
                ticklen: 15,
                title: 'Total (mm)',
                tickcolor: 'rgba(0,0,0,0)',
                side: 'right',
                zeroline: false,
            },
            margin: {
                l: 80,
                r: 80,
                b: 60,
                t: 20,
                pad: 0
            },
        };



        //set the plotconfig settings
        var plotConfig = {
            displayModeBar: false,
        }

        return [ [percipTotalLine, percipAmnountBars], layout, plotConfig ];
    }

    checkForNullChart() {
        var hasData = false;

        for( var i = 0; i < this.props.chartData.length; i++ ) {
            for( var j = 0; j < this.props.chartData.timeSeriesData.length; j++ ) {
                if( this.props.chartData[i].timeSeriesData[j].measured_value !== null ) {
                    //console.log(this.props.chartData.timeSeriesData[i].measured_value);
                    hasData = true;
                }
            }
        }

        // eslint-disable-next-line
        for( var i = 0; i < this.props.chartData.timeSeriesData.length; i++ ) {
            if( this.props.chartData.timeSeriesData[i].measured_value !== null ) {
                //console.log(this.props.chartData.timeSeriesData[i].measured_value);
                hasData = true;
            }
        }

        if( hasData ) {
            return "card-missed-data-overlay";
        } else {
            return "card-missed-data-overlay visible";
        }
    }
    render() { 
        //console.log(this.props.chartData.timeSeriesData); 

        if( this.props.chartData.timeSeriesData ) {
            if( this.props.chartData.timeSeriesData.length === 0 ){
                // There is no data to display on this chart, skip adding the line
                return null;
            }
        }
        
        let height = 290;
        if( this.props.graphHeight ){
            height = this.props.graphHeight;
        }

        const graphHeight = {height: height+"px"};
        const chartStyles = { width: "100%", height:height+"px" };
        const chartData = this.parseRainData(this.props.chartData);

        let noDataAvailableCss = "card-missed-data-overlay";

        if( this.props.type === "report" ) {
            noDataAvailableCss = this.checkForNullChart();
        }

        let saveChartLink = (this.props.type === "report" && this.props.canSaveCharts) ? 
        <div className="save-chart-btn">
            <button className="btn-link" onClick={this.formatDataForDashboardWidget} ><SvgIcon icon="floppy-disk" /> Save to dashboard </button>
        </div> : null;

        //console.log(this.props);
        
        return(

                <div className="chart-result" style={ graphHeight } id={ this.props.uniqueID } ref="plot">
                    <PlotlyChart
                        style={ chartStyles } 
                        data={ chartData[0] } 
                        layout={ chartData[1] } 
                        config={ chartData[2] } 
                    />
                    { saveChartLink }
                    <div className={noDataAvailableCss}>
                        No Data Available
                    </div>
                </div>

        );

    }

}

export default MinimalizedRainChart;