import { TOGGLE_NAV_MENU, SET_NAV_ITEMS, SET_ACTIVE_BUILD_MENU_TYPE } from '../actions/navigation';

const defaultState = {
		visible: false,
		navItems: [
		], 
		activeDashboardBuildMenuType: null,
};

export default function( state = defaultState, action){
	switch( action.type ) {
		case TOGGLE_NAV_MENU:
			state.visible = !state.visible;
			return {...state};

		case SET_NAV_ITEMS:
			return {...state, navItems: action.items }

		case SET_ACTIVE_BUILD_MENU_TYPE:
			state.activeDashboardBuildMenuType = action.payload;
			return { ...state }
			
        default:
			return state;
    }
}
