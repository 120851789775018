import React, {Component} from "react";
import DamSafetyLogListDetails from "./DamSafetyLogListDetails"

class DamSafetyLogListExpanding extends Component {

    constructor ( props ) {
        super( props );

        this.toggleExpand = this.toggleExpand.bind(this);

        this.state = {
            currentlyExpanded: false,
        }
    }

    toggleExpand(){
        this.setState({
            currentlyExpanded: !this.state.currentlyExpanded
        })
    }

    render() {

        const maxShown = 5;

        //generate the row for the log
        if( this.props.logs.length === 0 ){
            return <span>No logs avaialble</span>
        }

        let logs = [];

        // filter the logs to only show ones for this dam
        for (let i = 0; i < this.props.logs.length; i++) {
            // stop looping if we are not expanded and past the max shown
            if( this.state.currentlyExpanded === false && i >= maxShown){
                break;
            }
            logs.push( <DamSafetyLogListDetails logDetails={this.props.logs[i]} key={"log-"+this.props.logs[i].id} /> );
        }

        const buttonText = ( this.state.currentlyExpanded ? "Show Less" : "Show More")

        return (
            <div className="dam-safety-code-logs">
                <span>Dam safety code log: </span>
                { logs }
                <div class="dam-safety-code-logs-controls">
                    <button onClick={this.toggleExpand}>{buttonText}</button>
                </div>
            </div>
        );


    }
}

export default DamSafetyLogListExpanding;  