import React from 'react';
import SvgIcon from "../SvgIcon";

const IconTextLink = ({ 
    icon,
    text,
    link,
}) => {

    return( 
        <div className="icon-text-link">
            <a className="" href={ link }><SvgIcon icon={icon} />{ text }</a>
        </div>
    );
}; 

export default IconTextLink; 