import React, { Component } from "react";
import { Link } from 'react-router-dom';
import TimeSeriesChart from "../charts/TimeSeriesChart";
import RainChart from "../charts/RainChart";
import SvgIcon from "../SvgIcon";
import { yyyymmdd, hhmmampm } from "../../helpers/gaugeHelpers";

class CustomReport extends Component {
    constructor ( props ) {
        super( props ); 

        this.setupReportResultsView = this.setupReportResultsView.bind(this);
    }

    shouldComponentUpdate( nextProps, nextState ) {
        if( this.props.chartData.timeStamp !== nextProps.chartData.timeStamp || this.props.showAlarms !== nextProps.chartData.showAlarms ) {
            //console.log("not the same");
            return true;
        }  

        return false
    }

    setupReportResultsView(data) {
        return data.map( (gauge, key) => {

            let chart = null;
            let saveChartText = "Save, "+ gauge.stationName + ": " + gauge.gaugeType + ", to dashboard";
            
            switch( gauge.gaugeType ) {
                case "PRECIP":
                    if( this.props.plotSeperately ) {
                        
                        chart = 
                        <RainChart 
                            uniqueID={`chart-${key}`}  
                            chartData={ gauge.chartData } 
                            storedChartData={ this.props.storedChart }
                            graphHeight={500}  
                            type="report" 
                            canSaveCharts={ this.props.canAcccesStandardReports }
                            saveChartToDashboard={this.props.saveChartToDashboard} 
                            saveChartText={ saveChartText }
                            stationName={this.props.chartData.report.apiData.data[0].station_name} 
                            gaugeId={this.props.chartData.report.apiData.data[0].id} 
                            gaugeType={this.props.chartData.report.apiData.data[0].pretty_sensor_name} 
                            allowModeBar={true}
                        />
                    } else {
                        chart = 
                        <TimeSeriesChart 
                            uniqueID={`chart-${key}`} 
                            chartData={ gauge.chartData } 
                            graphHeight={500} 
                            type="report"  
                            showAlarms={ this.props.showAlarms } 
                            canSaveCharts={ this.props.canAcccesStandardReports }
                            saveChartToDashboard={this.props.saveChartToDashboard} 
                            saveChartText={ saveChartText }
                            gaugeId={this.props.chartData.report.apiData.data[0].id} 
                            gaugeType={this.props.chartData.report.apiData.data[0].pretty_sensor_name} 
                            allowModeBar={true}
                        />     
                    }
                     
                    break;
                default:
                    chart = 
                    <TimeSeriesChart 
                        uniqueID={`chart-${key}`} 
                        chartData={ gauge.chartData } 
                        graphHeight={500}  
                        type="report" 
                        gaugeType={gauge.gaugeType} 
                        showAlarms={ this.props.showAlarms } 
                        canSaveCharts={ this.props.canAcccesStandardReports }
                        saveChartToDashboard={this.props.saveChartToDashboard} 
                        saveChartText={ saveChartText }
                        gaugeId={ gauge.gaugeId } 
                        allowModeBar={true}
                    /> 
                    break;
            }

            //console.log(this.props.dateTimeSelections.dateTime.range);

            return (
                <div key={key} className="report-chart-result">
                    <h2>{gauge.chartTitle} from { this.formatFromToDate(this.props.dateTime.range.from) } to { this.formatFromToDate(this.props.dateTime.range.to)}</h2>
                    {chart}
                </div>
            )
        })
    }
    
    formatFromToDate(date){
        return `${yyyymmdd(date)} at ${hhmmampm(date)}` 
    }

    render() {
        var chart = null;
        if( this.props.chartData.report && this.props.chartData.report.charts ) {
            chart = this.setupReportResultsView(this.props.chartData.report.charts );
        }

        let dashboardLink = (this.props.canAcccesStandardReports) ?
        <a className="report-btn btn-link" onClick={ this.props.saveVisualizationToDashboard } ><SvgIcon icon="floppy-disk" /> Create a dashboard link </a> : null;

        let tabularDataLink = (this.props.canAcccesStandardReports) ?
        <a 
            className="report-btn btn-link"
            href={ this.props.chartData.report.downloadCSV }
        >   
            <SvgIcon icon="download" /> Download Tabular Data
        </a>: null;
        
        return(
            <div className="custom-report container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        { chart }

                        <div className="report-btn-container report-result-buttons">
                          
                            { tabularDataLink }
                            
                            <Link className="report-btn btn-link" to={"/data-explorer"}><SvgIcon icon="download" /> Request Data</Link>

                            {dashboardLink}

                        </div>
                    </div>
                </div>    
            </div>
        );
    }
}

export default CustomReport;