import React, { Component } from "react";
import HeaderMenu from '../components/HeaderMenu';

import logo from '../images/logo.png';
import logo2x from '../images/logo-2x.png';
//import SvgIcon from "../components/SvgIcon";

class HeaderContainer extends Component {

	render() {
		
		return (
			<header className="header" role="banner"> 
				<div className="header-top-wrap clear"> 

					<div className="logo-area">
		                <a href="/" className="logo" aria-label="Link to home"> 
		                    <img src={ logo } srcSet={ logo2x + ' 2x' } className="logo-img" alt="Toronto and Region Conservation (TRCA)" />
		                </a>
		            </div>  

		            <div className="right-container">
		                <HeaderMenu />
		            </div>

		        </div>
			</header>
		)
	}

}

export default HeaderContainer;