import { createStore } from "redux";
import allReducers from './reducers/index';

// updates auth in local storage
function updateAuthStorage(auth){
	if( auth.rememberMe === true){
		//console.log('using local storage');
		localStorage.setItem('auth', JSON.stringify(auth));
		sessionStorage.removeItem('auth');
	}
	else{
		//console.log('using session storage');
		sessionStorage.setItem('auth', JSON.stringify(auth));
		localStorage.removeItem('auth');
	}
}

// gets auth from local storage
function getAuthStorage(){
	var auth = localStorage.getItem('auth');

	if( auth === null ){
		// try getting it from session storage
		auth = sessionStorage.getItem('auth');
	}
	return JSON.parse(auth);
}

let auth = getAuthStorage();

//set the saved state if auth is not null;
let savedState = {};
if( auth !== null){
	savedState = {auth:auth};
}

const store = createStore( allReducers, savedState );


// setup listeners for persisted state
function select(state) {
	return state.auth;
}

//checks to see if anything inside auth has changed
let currentValue = null;
function handleChange() {
	let previousValue = currentValue
	currentValue = select(store.getState())

	const keys = Object.keys(currentValue);
	let diff = false;
	//always update if the first time
	if( previousValue === null){
		diff=true;
	}
	else{
		for (var i = 0; i < keys.length; i++) {
			if( currentValue[keys[i]] !== previousValue[keys[i]]){
				diff = true;
				break;
			}
		}
		
	}
	if (diff) {

		updateAuthStorage(currentValue)
		
	}
}

// eslint-disable-next-line
let unsubscribe = store.subscribe(handleChange)

export default store;