import React from 'react';
//import SvgIcon from "../SvgIcon.js";

const WidgetModalSaveBtn = ({ handleAddWidgetToDashboard }) => {

    return( 
        <div className="widget-modal-save-btn">
            <button onClick={ handleAddWidgetToDashboard } >Save</button>
        </div>
    );
}; 

export default WidgetModalSaveBtn; 