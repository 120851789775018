import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"; 
import { withRouter, Link } from 'react-router-dom'

import Preloader from "../components/Preloader";
import PageWrapper from "../components/PageWrapper";

import { pageTitle } from "../helpers/pageTitle";
import { getRoute, apiRequest } from "../helpers/Api";
import { doAuthCheck } from "../actions/Auth";
import { RequireCapability } from "./RequireCapability";

import DamSafetyLogListDetails from "../components/dam-ops/DamSafetyLogListDetails";

import { 
    togglePreloader,
    showFetchError
} from "../actions/index";


function getInitialState(){
    return {
        fetchingSafteyCodeLogs: false,
        fetchingStation: false,
        safteyCodeLogs: null,
        stationDetails: null,
        morePages: false,
        curPage: 1,
        errorMessage: null
    }
}

class DamSafetyCodeContainer extends Component {
    constructor ( props ) {
        super(props);

        this.getSafetyCodeLogData = this.getSafetyCodeLogData.bind(this);
        this.getStationData = this.getStationData.bind(this);

        this.state = getInitialState();
    }

    componentDidMount(){
        window.scrollTo(0,0);
        this.getStationData();
        this.getSafetyCodeLogData();
    }

    componentDidUpdate(prevProps, prevState){

        let wasPreviousLoading = false;
        let currentlyLoading = false;

        // check if previously was in a loading state
        if( prevState.fetchingSafteyCodeLogs === true ||
            prevState.fetchingStation === true ){
            wasPreviousLoading = true;
        }

        // check if currently in a loading state
        if( this.state.fetchingSafteyCodeLogs === true ||
            this.state.fetchingStation === true ){
            currentlyLoading = true;
        }

        if( currentlyLoading === false && wasPreviousLoading === true ){
            //transitioning from loading
            this.props.togglePreloader(false);
        }
        else if ( currentlyLoading === true && wasPreviousLoading === false ){
            //transitioning to loading
            this.props.togglePreloader(true);
        }

        // when the current page is changed fetch the additional items
        if(prevState.curPage !== this.state.curPage ){
            this.getSafetyCodeLogData();
        }
    }

    addStationsListTogether(currentArray, newArray){

        // we are starting with a new set 
        if(currentArray === null){
            currentArray = [];
        }

        // combine the two arrays without mutating their objects
        const newState = [];

        for (let i = 0; i < currentArray.length; i++) {
            newState.push({...currentArray[i]});
        }

        for (let i = 0; i < newArray.length; i++) {
            newState.push({...newArray[i]});
        }

        return newState;
    }


    getStationData(){

        this.setState({
            fetchingStation: true,
        })
        apiRequest( getRoute("stationDetails", this.props.match.params.stationSlug) )
            .then(result => {
                this.setState({
                    fetchingStation: false,
                    stationDetails: result
                });
            })
            .catch( (error) => {
                // on error restting to the initial state and showing the loading message
                const netState = getInitialState();
                netState.errorMessage = true;
                this.setState(netState);
            })

    }


    getSafetyCodeLogData() {
        this.setState({
            fetchingSafteyCodeLogs: true,
        });

        const fetchArgs = {
            station_slug: this.props.match.params.stationSlug,
            page: this.state.curPage
        }

        apiRequest( getRoute("damSafetyCodeLogs", [], fetchArgs) )
            .then(result => {
                
                this.setState({
                    fetchingSafteyCodeLogs: false,
                    safteyCodeLogs: this.addStationsListTogether(this.state.safteyCodeLogs, result.results),
                    morePages: ( result.next === null ? false : true )
                });
            })
            .catch( (error) => {
                // on error restting to the initial state and showing the loading message
                const netState = getInitialState();
                netState.errorMessage = true;
                this.setState(netState);

            })
    }



	render() {

        let title = "Loading Dam Safety Code Logs";
        let headingTitle = title;
        let content = null;

        if( this.state.errorMessage !== null ){
            // content for when in an error state
            title = "Error loading dam safety code logs"
            content = (
                <div>
                    <h2>Error</h2>
                    <p>An error occured while loading the dam safety code logs.</p>
                </div>
            )
        }
        else if( this.state.fetchingStation && this.state.fetchingSafteyCodeLogs ){
            // content for when on initiail load
            content = (
                <h2>{title}</h2>
            )
        }
        else{
            // content for when we have dam safety codes
            const safetyLogs = [];
            let headingTitle = title;

            // if we have a station set the title
            if( this.state.stationDetails !== null ){
                title = this.state.stationDetails.station_name + "Safety Code Logs";
                headingTitle = this.state.stationDetails.station_name;
            }

            if( this.state.safteyCodeLogs !== null ){

                // filter the logs to only show ones for this dam
                for (let i = 0; i < this.state.safteyCodeLogs.length; i++) {
                    // stop looping if we are not expanded and past the max shown
                    safetyLogs.push( <DamSafetyLogListDetails logDetails={this.state.safteyCodeLogs[i]} key={"log-"+this.state.safteyCodeLogs[i].id} /> );
                }

                if( safetyLogs.length === 0 ){
                    safetyLogs.push(<span key="not-keys-avaialble">No logs available</span>);
                }
            }
            else{
                // we are in a loading state
                safetyLogs.push(<span key="loading-log">Loading Logs</span>);
            }


            let nextPageButton = null;
            if( this.state.morePages && !this.state.fetchingSafteyCodeLogs ){
                nextPageButton = (
                    <div className="dam-ops-load-more">
                        <button className="dam-ops-load-more" onClick={ () => this.setState({'curPage': this.state.curPage + 1})}>Load More</button>
                    </div>
                );
            }

    		content = (
                    <div className="container-fluid" role="region" aria-labelledby="station-region">
                        <h2 id="station-region" className="dam-ops-title-with-back">{ headingTitle }</h2>
                        <Link to={`/dam-ops/`}>Back to Dam Ops</Link>
                        <div className="dam-safety-code-logs">
                            <span>Dam safety code logs: </span>
                            {safetyLogs}
                        </div>
                        {nextPageButton}
                    </div>
    		)


        }

        return (
            <PageWrapper fullWidth={true} >
                <Helmet>
                    <title>{ pageTitle(title) }</title>
                </Helmet>
                <Preloader showPreloader={ this.props.handleTogglePreloader } message="Fetching Dam Safety Codes" />
                {content}
            </PageWrapper>
        )

    }
}


function mapStateToProps(state) {
    return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
        doAuthCheck: doAuthCheck
    }, dispatch)    
}

export default RequireCapability( 
    withRouter( 
        connect( mapStateToProps, matchDispatchToProps )( DamSafetyCodeContainer ) 
    ), 
    'canAccessDamOperationsLog' 
); 