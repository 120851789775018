import React, { Component } from "react";
import { Link } from 'react-router-dom';
import TimeSeriesChart from "../charts/TimeSeriesChart";
import RainChart from "../charts/RainChart";
import DualAxisTimeSeries from "../charts/DualAxisTimeSeries";
import SvgIcon from "../SvgIcon";
import { yyyymmdd, hhmmampm } from "../../helpers/gaugeHelpers";
import DamOperationsTable from "../dam-ops/DamOperationsTable";

class DamsReport extends Component {
    constructor ( props ) {
        super( props ); 

        this.setupReportResultsView = this.setupReportResultsView.bind(this);
        this.getSummaryOutput = this.getSummaryOutput.bind(this);
    }

    shouldComponentUpdate( nextProps, nextState ) {
        if( this.props.chartData.timeStamp !== nextProps.chartData.timeStamp  ) {
            //console.log("not the same");
            return true;
        }
        
        return false
    }

    setupReportResultsView(data) { 
        return data.map( (gauge, key) => {
            let chart = null;
            
            let saveChartText = "Save, "+ gauge.chartData.chartData[0].name + ": " + gauge.gaugeType + ", to dashboard";

            switch( gauge.gaugeType ) {
                case "DualAxis":
                    chart = 
                    <DualAxisTimeSeries  
                        uniqueID={`chart-${key}`} 
                        chartData={ gauge.chartData } 
                        canSaveCharts={ this.props.canAcccesStandardReports }
                        graphHeight={500}  type="report" 
                        allowModeBar={true}
                    />
                    break;

                case "PRECIP":
                    if( gauge.chartData.chartData !== undefined ) {
                        if( gauge.chartData.chartData[0].timeSeriesData.length ) {
                            chart = 
                            <RainChart  
                                uniqueID={`chart-${key}`} 
                                chartData={ gauge.chartData } 
                                graphHeight={500}  
                                type="report" 
                                saveChartToDashboard={this.props.saveChartToDashboard} 
                                stationGaugeList={ this.props.stationGaugeList }
                                canSaveCharts={ this.props.canAcccesStandardReports }
                                saveChartText={ saveChartText }
                                gaugeId={gauge.gaugeId} 
                                gaugeType={ gauge.gaugeType }
                                allowModeBar={true}
                            /> 
                        } else {
                            chart = 
                            <div className="card-missed-data-overlay visible">
                                No Data Available
                            </div>
                        }
                        
                    } else {
                        if( gauge.chartData.timeSeriesData.length ) {
                            chart = 
                            <RainChart  
                                uniqueID={`chart-${key}`} 
                                chartData={ gauge.chartData } 
                                graphHeight={500}  
                                type="report" 
                                saveChartToDashboard={this.props.saveChartToDashboard} 
                                stationGaugeList={ this.props.stationGaugeList }
                                canSaveCharts={ this.props.canAcccesStandardReports }
                                saveChartText={ saveChartText }
                                gaugeId={gauge.gaugeId} 
                                gaugeType={ gauge.gaugeType }
                                allowModeBar={true}
                            />
                        } else {
                            chart = 
                            <div className="card-missed-data-overlay visible">
                                No Data Available
                            </div>
                        }
                    }
                    
                    break;
                default:
                    chart = 
                    <TimeSeriesChart  
                        uniqueID={`chart-${key}`} 
                        chartData={ gauge.chartData } 
                        graphHeight={500}  
                        type="report" 
                        saveChartToDashboard={this.props.saveChartToDashboard} 
                        stationGaugeList={ this.props.stationGaugeList }
                        canSaveCharts={ this.props.canAcccesStandardReports }
                        saveChartText={ saveChartText }
                        gaugeId={gauge.gaugeId}
                        gaugeType={ gauge.gaugeType }
                        allowModeBar={true}
                    /> 
                    break;
            }

            //console.log(this.props.dateTimeSelections.dateTime.range);

            return (
                <div key={key} className="report-chart-result">
                    <h2>{gauge.chartTitle} from { this.formatFromToDate(this.props.dateTime.range.from) } to { this.formatFromToDate(this.props.dateTime.range.to)}</h2>
                    {chart}
                </div>
            )
        })
    }
    
    formatFromToDate(date){
        return `${yyyymmdd(date)} at ${hhmmampm(date)}` 
    }

    getCalculatedTotalsTable( summariezedData ){
        var rows = [];

        for (var i = 0; i < summariezedData.length; i++) {
            for (var b = 0; b < summariezedData[i].length; b++) {
                rows.push( summariezedData[i][b] );
            }
        }


        return (
            <DamOperationsTable logs={rows} />
        );
    }

    getSummaryOutput( rows ) {
        let summaryData = null;
        if( rows.length ) {
            summaryData = 
            rows.map( (data, index ) => {
                //console.log(data);
                return (
                    <tr key={ index }>
                        <td>{data.dam_location}</td>
                        <td>{ yyyymmdd( new Date(data.last_record) )} { hhmmampm( new Date(data.last_record) ) }</td>
                        <td>{data.gate_name}</td>
                        <td>{data.operation_type}</td>
                        <td>{data.operation_amount}</td>
                        <td>{data.current_opening}</td>
                        <td>{data.op_comment}</td>        
                    </tr>
                )
            })
        }
        
        return summaryData;
    }

    render() {

        var chart = null;
        if( this.props.chartData.report && this.props.chartData.report.charts ) {
            chart = this.setupReportResultsView(this.props.chartData.report.charts );
        }

        let dashboardLink = (this.props.canAcccesStandardReports) ?
        <button className="btn-link" onClick={ this.props.saveVisualizationToDashboard } ><SvgIcon icon="floppy-disk" /> Create a link to this report on your dashboard </button> : null;
        
        return(
            <div className="dam-report container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        { chart }

                        { this.getCalculatedTotalsTable( this.props.chartData.report.summariezedData) }

                        <div className="report-btn-container report-result-buttons">
                            <a 
                                className="report-btn btn-link"
                                href={ this.props.chartData.report.downloadCSV }
                            >   
                                <SvgIcon icon="download" /> Download Tabular Data
                            </a>
                            
                            <Link className="report-btn btn-link" to={"/data-explorer"}><SvgIcon icon="download" /> Request Data</Link>

                            {dashboardLink}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DamsReport;