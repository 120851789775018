import leftPad from "left-pad";
import { yyyymmdd } from "../helpers/gaugeHelpers"
import {buildSearchString} from "../helpers/reportBuilderHelpers";
//import { selectAllGauges } from "../actions/DataExplorer";

function convert_station_data_dates(stations){


	for (var i = 0; i < stations.length; i++) {
		let lastUpdated = null;
		if( stations[i].lastUpdated.indexOf('T') >= 0 ){
			// converting the object from json 
			lastUpdated = new Date( stations[i].lastUpdated )
		}
		else{
			//using the standard date format from the API
			lastUpdated = stations[i].lastUpdated.split('-');
			lastUpdated = new Date(lastUpdated[0], lastUpdated[1]-1, lastUpdated[2]);
		}
		stations[i].lastUpdated = lastUpdated;

		for (var n = 0; n < stations[i].gauges.length; n++) {
			let startDate, endDate = null;
			if(  stations[i].gauges[n].startDate.indexOf('T') >= 0){
				// converting the object from json 
				startDate = new Date( stations[i].gauges[n].startDate );
				endDate = new Date( stations[i].gauges[n].endDate );
			}
			else{
				//using the standard date format from the API
				startDate = stations[i].gauges[n].startDate.split('-');
				endDate = stations[i].gauges[n].endDate.split('-');
				startDate = new Date(startDate[0], startDate[1]-1, startDate[2]);
				endDate = new Date(endDate[0], endDate[1]-1, endDate[2]);
			}
			stations[i].gauges[n].startDate = startDate;
			stations[i].gauges[n].endDate = endDate;

		}

	}
}

function isFieldValid(field, value){

	let isValid = '';
	
	switch( field ) {

		case 'data_usage':
			value = value.trim();
			if( value === ''){
				isValid = 'Please let us know how you will be using this data.';
			}
			break;
		case 'first_name':
			value = value.trim();
			if( value === ''){
				isValid = 'Please enter your first name';
			}
			break;
		case 'last_name':
			value = value.trim();
			if( value === ''){
				isValid = 'Please enter your last name';
			}
			break;
		case 'email':
			value = value.trim();
			if( value === ''){
				isValid = 'Please enter your email address';
			}
			else{
				if( !/\S+@\S+\.\S+/.test(value) ){
					isValid = 'Please enter a valid email address';
				}
			}
			break;
		case 'phone':
			value = value.trim();
			if( value === ''){
				isValid = 'Please enter your phone number';
			}
			else{
				let testValue  = value.replace(/[- ().]/g, '');

				/// TODO need to remove and add polite space
				if ( !/^\d+$/.test(testValue) ){
					isValid = 'Please enter a valid 10 digit phone number. eg 555-555-5555';
				}
				if( testValue.length !== 10 ){
					isValid = 'Please enter a valid 10 digit phone number. eg 555-555-5555';
				}
				
			}

			break;
		case 'has_signed_tos':
			if( value === false || value === ''){
				isValid = 'You must accept the TOS ';
			}
			break;

		case 'organization_name':
			/*if( value === "" ) {
				value = value.trim();
				isValid = "Please enter an organization name";
			}*/
		break;
		default:
			break;
	}

	return isValid;
}

function setContactNote(newState, showMessages){

		let noteValid = true;

		//custom validation for note
		let selectedGauges = [];
		for (var i = 0; i < newState.stations.length; i++) {
			for (var b = 0; b < newState.stations[i].gauges.length; b++) {
				if( newState.stations[i].gauges[b].selected === true ){
					selectedGauges.push( newState.stations[i].gauges[b].full_name );
				}
			}
		}

		if( !selectedGauges.length ){
			noteValid = false;
			if( showMessages ){
				newState.contactFields.notes.showMessage.stationSelect = 'Please select a station';
			}
			else{
				newState.contactFields.notes.showMessage.stationSelect = '';
			}
		}
		else{
			newState.contactFields.notes.showMessage.stationSelect = '';
		}

		//get the date
		let selectedDate = [];
		if( !newState.dateTime.range.to || !newState.dateTime.range.to ){
			noteValid = false;
			if( showMessages ){
				newState.contactFields.notes.showMessage.timeSelect = 'Please select a start and end date for your dataset';
			}
			else{
				newState.contactFields.notes.showMessage.timeSelect = '';
			}
		}
		else{
			newState.contactFields.notes.showMessage.timeSelect = '';
			selectedDate.push( yyyymmdd(newState.dateTime.range.from) );
			selectedDate.push( yyyymmdd(newState.dateTime.range.to) );
		}

		/*
		REQUESTED GAUGES:
		HY002 - Albion Hills - CrustCode - Precipitation
		HY002 - Albion Hills - CrustCode - Snow

		REQUESTED DATETIME
		1970-01-01 to 2017-12-31
		*/
		newState.contactFields.notes.value = "REQUESTED GAUGES:\n" + selectedGauges.join('\n') + "\n\nREQUESTED DATETIME:\n" + selectedDate.join(' to ');
		newState.contactFields.notes.isValid = noteValid;
}


function get_default_state(){

    return {
		slideoutOpen: true,
		requestComplete: false,

		// Example Station:
		// {
		// 	id: 13,
		// 	lat: 12.12,
		// 	lng: 12.12,
		// 	stationName: 'Claireville Dam',
		// 	lastUpdated: Date('2009-06-23'),
		// 	gauges: [
		// 		// Example Gauge
		// 		// {
		// 		// 	'id': 123,
		// 		// 	'statDate': Date('2009-06-23'),
		// 		// 	'endDate': Date('2015-12-31'),
		// 		// 	'sensorName': "Wind Dir",
		// 		// 	'selected': true,
		// 		// 	'isValid': true,
		// 		// 	'validationMessage': ''
		// 		// 	'monitoringNetwork' : 'Something',
		// 		// 	'full_name': full_name
		// 		// 	'searchableString': 'something all lowercase',
		// 		// }
		// 	]
		// }
		stations: [],

		// // example Filter
		// {
		// 		displayName: 'Some Name',
		//		id: "something-unique"
		// 		value: 'Something',
		// 		selected: true,
		// }
		filters: [],
		submittingRequest: false,
		submissionSuccess: null,
		contactFields: {
			'notes': {
				value: '',
				showMessage: {		
					timeSelect: '',
					stationSelect: '',
				},
				isValid: false,
			},
			'data_usage': {
				value: '',
				showMessage: '',
				isValid: false,
			},
			'first_name': {
				value: '',
				showMessage: '',
				isValid: false,
			},
			'last_name': {
				value: '',
				showMessage: '',
				isValid: false,
			},
			'email': {
				value: '',
				showMessage: '',
				isValid: false,
			},
			'phone': {
				value: '',
				showMessage: '',
				isValid: false,
			},
			'phone_extension': {
				value: '',
				showMessage: '',
				isValid: true,
			},
			'organization_name': {
				value: '',
				showMessage: '',
				isValid: true,
			},
			'has_signed_tos': {
				value: true,
				showMessage: '',
				isValid: true,
			},
		},
		dateTime: {
			range: {
				from: null,
				to: null
			},
			formattedRange: {
				from: null,
				to: null
			}
		},
		currentMonthFromDropdown: null,
    }
}

export default function ( state=get_default_state(), action ) {

	let newState = JSON.parse(JSON.stringify(state));
	if( newState.dateTime.range.to !== null ){
		newState.dateTime.range.to = new Date( newState.dateTime.range.to );
	}
	if( newState.dateTime.range.from !== null ){
		newState.dateTime.range.from = new Date( newState.dateTime.range.from );
	}
	if( newState.currentMonthFromDropdown !== null ){
		let prevMonth = new Date();
		prevMonth.setDate(1);
		prevMonth.setMonth(prevMonth.getMonth() - 1);
		//newState.currentMonthFromDropdown = new Date( newState.prevMonth );
		newState.currentMonthFromDropdown = newState.prevMonth;
	}
	convert_station_data_dates(newState.stations);

    switch( action.type ) {

    	case "RESET_DATA_EXPLORER_DATA":
    		const resetState =  get_default_state();
    		return resetState;


        case "SET_SLIDEOUT_OPEN":
        	newState.slideoutOpen = action.payload;
        	return newState

        case "UPDATE_ALL_FILTERS":

        	let filters = [];

        	for (var i = 0; i < action.payload.length; i++) {
        		filters.push({
        			displayName: action.payload[i],
					id: "gauge-filter-"+i,
					value: action.payload[i],
					selected: false,
        		});
        	}
        	newState.filters = filters;
        	return newState;


        case "UPDATE_ALL_STATIONS":
			let stations = [];

			//console.log(action.payload);
        	// eslint-disable-next-line
        	for (var i = 0; i < action.payload.length; i++) {
        		const stationData = action.payload[i];

        		let station = {
        			id: stationData.id,
        			stationId: stationData.station_id,
        			stationName: stationData.station_name,
					lat: stationData.latitude,
					lng: stationData.longitude,
					lastUpdated: stationData.last_updated,
					stationType: '',
					gauges: [],
				}
				//console.log(stationData);
        		// eslint-disable-next-line
        		for (var b = 0; b < stationData.trca_gauges.length; b++) {
					const gaugeData = stationData.trca_gauges[b];
					
					//console.log(gaugeData);

					//searchString = buildSearchString(searchString, state.stationsAndAssociatedGauges[i].gauges);

        			let gauge = {
	        			'id': gaugeData.id,
						'startDate': gaugeData.start_date,
						'endDate': gaugeData.end_date,
						'sensorName': gaugeData.sensor_name,
						'selected': false,
						'isValid': true,
						'validationMessage': '',
						'subNetwork' : gaugeData.sub_network,
						'searchableString': buildSearchString(gaugeData.full_name.toLowerCase(), action.payload[i].trca_gauges, "explorer"),
						'full_name': gaugeData.full_name,
        			};

        			station.gauges.push( gauge );
        		}

        		stations.push( station );
        	}

        	convert_station_data_dates( stations );

			//console.log(stations);
        	newState.stations = stations;
      
        	return newState;
   
        case "SET_FILTER":
        	// eslint-disable-next-line
        	for (var i = 0; i < newState.filters.length; i++) {
        		if( newState.filters[i].value === action.filter ){
        			newState.filters[i].selected = action.status;
        			break;
        		}
        	}
        	return newState;

        case "SELECT_GAUGES":

        	
        	// an array of gauge ids to select
			const selectGauges = action.payload;
        	// eslint-disable-next-line
        	for (var i = 0; i < newState.stations.length; i++) {
        		// eslint-disable-next-line
				for (var b = 0; b < newState.stations[i].gauges.length; b++) {
					//console.log( selectGauges.indexOf( newState.stations[i].gauges[b].id ), newState.stations[i].gauges[b].id );
					if( selectGauges.indexOf( newState.stations[i].gauges[b].id ) !== -1){
						//console.log('adding gauge');
						newState.stations[i].gauges[b].selected = true;
					}
				}
			}
			setContactNote(newState, false);
			
			return newState;

		case "DESELECT_GAUGES":
			// an array of gauge ids to select
        	const removeGauges = action.payload;
        		// eslint-disable-next-line
        	for (var i = 0; i < newState.stations.length; i++) {
				// eslint-disable-next-line
				for (var b = 0; b < newState.stations[i].gauges.length; b++) {
					if( removeGauges.indexOf( newState.stations[i].gauges[b].id ) !== -1){
						newState.stations[i].gauges[b].selected = false;
					}
				}
			}
			setContactNote(newState, false);
			return newState;

		case "DESELECT_ALL_GAUGES":
			// eslint-disable-next-line
			for (var i = 0; i < newState.stations.length; i++) {
				// eslint-disable-next-line
				for (var b = 0; b < newState.stations[i].gauges.length; b++) {
					newState.stations[i].gauges[b].selected = false;
				}
			}
			setContactNote(newState, false);
			return newState;

		case "SELECT_ALL_GAUGES":

			// eslint-disable-next-line
			for (var i = 0; i < newState.stations.length; i++) {
				// eslint-disable-next-line
				for (var b = 0; b < newState.stations[i].gauges.length; b++) {
					newState.stations[i].gauges[b].selected = true;
				}
			}
			setContactNote(newState, false);
			return newState;

		case "UPDATE_CONECT_FORM_FIELD":

			newState.contactFields[ action.field ].value = action.value;
			newState.contactFields[ action.field ].isValid = ( isFieldValid( action.field, action.value ) === '' ? true : false ) ;
			newState.contactFields[ action.field ].showMessage = isFieldValid( action.field, action.value );

			return newState;
		case "VALIDATE_ALL_FIELDS":	

			const formFields = Object.keys( newState.contactFields );
			
			for (let i = 0; i < formFields.length; i++) {
				if( formFields[i] === 'notes'){
					continue;
				}
				newState.contactFields[ formFields[i] ].isValid = ( isFieldValid( formFields[i], newState.contactFields[ formFields[i] ].value ) === '' ? true : false ) ;
				newState.contactFields[ formFields[i]  ].showMessage = isFieldValid( formFields[i], newState.contactFields[ formFields[i] ].value );

				
				//console.log( newState.contactFields[ formFields[i] ] );
			}



			setContactNote(newState, true);

			//console.log(newState.contactFields);
			return newState;

		case "SUBMITTING_REQUEST":
			newState.submittingRequest = true;
			newState.submissionSuccess = null;
			return newState;
		case "SUBMIT_SUCCESS":
			let resetSubmitState = get_default_state();
			resetSubmitState.submittingRequest = false;
			resetSubmitState.submissionSuccess = true;
			resetSubmitState.stations = newState.stations;
			resetSubmitState.filters = newState.filters;
			// eslint-disable-next-line
			for (var i = 0; i < resetSubmitState.stations.length; i++) {
				// eslint-disable-next-line
				for (var b = 0; b < resetSubmitState.stations[i].gauges.length; b++) {
					resetSubmitState.stations[i].gauges[b].selected = false;
				}
			}
			setContactNote(resetSubmitState, false);
			return resetSubmitState;
		case "SUBMIT_FAIL":
			newState.submittingRequest = false;
			newState.submissionSuccess = false;
			return newState;

		case "SET_TIME_RANGE_SELECTION":
			newState.dateTime.range.from = action.payload.from;
			newState.dateTime.range.to = action.payload.to;
			
			if( action.payload.from ){
				
				var fromDayPicked = leftPad( action.payload.from.getDate(),2,0 );
				var fromMonth = leftPad( (action.payload.from.getMonth()+1),2,0 );
				var fromYear = action.payload.from.getFullYear();
				var fromDatePicked = fromYear+"-"+fromMonth+"-"+fromDayPicked;
				newState.dateTime.formattedRange.from = fromDatePicked;
				setContactNote(newState, false);
			}

			if( action.payload.to ) {
				var toDayPicked = leftPad( action.payload.to.getDate(),2,0 );
				var toMonth = leftPad( (action.payload.to.getMonth()+1),2,0 );
				var toYear = action.payload.to.getFullYear();
				var toDatePicked = toYear+"-"+toMonth+"-"+toDayPicked;
				newState.dateTime.formattedRange.to = toDatePicked;
				setContactNote(newState, false);
			}
			return newState;

		case "SET_MONTH_FOR_DATE_PICKER":
			newState.currentMonthFromDropdown = action.payload;    
			return newState;	

        default:
        	return state;
    }

 }