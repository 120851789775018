import React, { Component } from "react";
	
class TimeSeriesLength extends Component {

	render(){
		return (			
			<ul className="gauge-time-series-links piped-nav">
				  	{
                        this.props.timeSeriesOptions.map((object, i) =>
                        	<li key={i} className="piped-nav-item time-series-toggle-option">
                        		<a  
	                        		onClick={ () => this.props.clickCallback( object.hour ) } 
	                        		className={`${( object.selected ? 'active' : 'not-active' )}`}>
	                        		{object.prettyName}
                        		</a>    
                        	</li>
						)                            
                    }
				
			</ul>
		)
	}
}

export default TimeSeriesLength;
