import React, { Component } from "react"; 
import CameraImage from "../CameraImage";
import CameraModal from "../CameraModal";

class StationImages extends Component {

	constructor(props) {
        	
        // required props
        // images - array  - an array of images with the properties:
        // 		'id': - a unique id for the image
		// 		'thumbnail' - a url to the thumbnail
		// 		'image' - a url to a larger version of the image
		// 		'title' - a title for the image, can be empty but property must exist
		// modalTitle - string, title for top of modal
        super(props);

 		this.gotoImage = this.gotoImage.bind(this);
 		this.closeCameraModal = this.closeCameraModal.bind(this);
 		this.cameraClick = this.cameraClick.bind(this);

        this.state = {
        	curShown: 0,
        	modalIsVisible: false
        }
 	}

 	gotoImage(index){
 		this.setState({'curShown': index});
 	}

 	closeCameraModal(){
 		this.setState({modalIsVisible: false});
 	}

 	cameraClick(id){
 		console.log(id);
 		this.setState({modalIsVisible: true});
 	}

 	render(){

 		// if no images, do not render anything
 		if( !this.props.images || this.props.images.length == 0 ){
 			return null;
 		}

 		// build the view data
 		const images = this.props.images;
 		const imageCollection = [];
		const cameraImages = [];


		for (var i = 0; i < images.length; i++) {

			cameraImages.push({
				imageName: images[i].title,
				imageUrl: images[i].image
			});


			const displayed = (this.state.curShown == i ? true : false );

			if( !displayed ){
				continue;
			}

			imageCollection.push(
				<div className="station-image" key={`station-image-${images[i].id}`}>			
					<CameraImage image={images[i].thumbnail } cameraClick={()=> this.cameraClick(i)} />
				</div> 

			);
		}

		let cameraModal = null;
		if( this.state.modalIsVisible ){
			cameraModal = <CameraModal isVisible={this.state.modalIsVisible} closeModal={this.closeCameraModal} modalTitle={this.props.modalTitle} cameraImages={cameraImages} initialStart={ this.state.curShown } />;
		}


		
		// build the constrols 		
		const controls = [];

		for (let i = 0; i < this.props.images.length; i++) {
			const disabled = (this.state.curShown == i ? true : false );
			controls.push(<button disabled={disabled} onClick={(e) => this.gotoImage(i) } ><span className="screen-reader-text">Show Image {i+1}</span></button>);
		}


		return (
			<div className="station-image-selector">
				{imageCollection}
				<div className="station-image-controls">
					{controls}
				</div>
				{ cameraModal }
			</div>
		);
 	
 	}
}

export default StationImages;