import React, { Component } from "react";
import PlotlyChart from "./PlotlyChart.js";
//import SvgIcon from "../SvgIcon";

import { 
    //formatGaugeAmount,
    formatMeasurement,
    getNonNullMax,
    getNonNullMin,
    hhmmampm
 } from "../../helpers/gaugeHelpers";


/* 

EXAMPLE chartData prop:

chartData = {
    
    unitOfMeasure: apiData.unit_of_measure,  // the unit of measure from the api
    yAxisName: 'Some Name', //optional not all graphs show a yAxisLabel
    chartData: [
        {
            name: "Some Name", // optional, not all graphs use legends
            timeSeriesData: apiData.time_series_data, 
        },
        {
            name: "Some Name 2", // optional, not all graphs use legends
            timeSeriesData: apiData.time_series_data,
        }
    ],

    // feel like there would be a more flexable way to add lines,
    // for now this will work
    lowLowLine: null, // null is dont show
    lowLine: null, // null is dont show
    normalLine: null, // null is dont show
    highLine: null, // null is dont show
    highHighLine: null // null is dont show
        
}

*/

class MinimalizedTimeSeriesChart extends Component {

    constructor ( props ) {
        super( props );
        this.getDataFromApiData = this.getDataFromApiData.bind(this);
        this.getLayout = this.getLayout.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.getYAxisRange = this.getYAxisRange.bind(this);
        this.getSmallestXaxis = this.getSmallestXaxis.bind(this);
        this.getLargestXAxis = this.getLargestXAxis.bind(this);
        this.orderDataByMean = this.orderDataByMean.bind(this);
    }    

    // gets the smallest date in the provided chart datas
    getSmallestXaxis(){

        let smallestDate = 0;

        if(!this.props.chartData.chartData[0].timeSeriesData.length){
            var targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + 10);
            smallestDate = targetDate.getTime();
        }
        else{
            smallestDate = new Date(this.props.chartData.chartData[0].timeSeriesData[ this.props.chartData.chartData[0].timeSeriesData.length-1].datetime_of_measurement).getTime();
        }


        for (var i = 1; i < this.props.chartData.chartData.length; i++) {
            if( this.props.chartData.chartData[i].timeSeriesData.length ){

                const axisDate =  new Date(this.props.chartData.chartData[i].timeSeriesData[ this.props.chartData.chartData[i].timeSeriesData.length-1].datetime_of_measurement).getTime();
                if( smallestDate > axisDate ){
                    smallestDate = axisDate;
                }
                
            }
        }

        return smallestDate;
    }

    //gets the largest date in the provided chart datas
    getLargestXAxis(){

        let largestDate = 0;
        if(this.props.chartData.chartData[0].timeSeriesData.length){
            largestDate = new Date(this.props.chartData.chartData[0].timeSeriesData[0].datetime_of_measurement).getTime();
        }

        for (var i = 1; i < this.props.chartData.chartData.length; i++) {

            if( this.props.chartData.chartData[i].timeSeriesData.length ){
                const axisDate = new Date(this.props.chartData.chartData[i].timeSeriesData[0].datetime_of_measurement).getTime();
                if( largestDate < axisDate ){
                    largestDate = axisDate;
                }
            }
        }
        return largestDate;
    }

    /// returns the Y axis range including and takes into concidersation any annotation lines
    getYAxisRange( data ){

         //an array of all the custom lines passed into the system
        //console.log( this.props.chartData );


        var customValues = [];
        // loop over the plots and find the lowest and highest values for the provided data
        var lowestValue = getNonNullMin.apply(null, data[0].y);
        var highestValue = getNonNullMax.apply(null, data[0].y);
       
        for (var i = 1; i < data.length; i++) {
            let currentLow = getNonNullMin.apply(null, data[i].y);
            if( lowestValue > currentLow ){
                lowestValue = currentLow;
            }

            let currentHigh = getNonNullMax.apply(null, data[i].y);
            if( currentHigh > highestValue ){
                highestValue = currentHigh;
            }
        }

        //calculate the min and max for the y axis
        //get the lowest value shown on the graph and max
        if( customValues.length){
            lowestValue = getNonNullMin(lowestValue, getNonNullMin.apply(null, customValues) );
        }


        //get the highest value for the graph
        if( customValues.length){
            highestValue = getNonNullMax( highestValue, getNonNullMax.apply(null, customValues) );
        }

        var offsetMultiplyer = null;
        switch( this.props.type ) {
            case "report": 
            offsetMultiplyer = 0.005;
            break;

            case "stationDetail":
            offsetMultiplyer = 0.002;
            break;

            default:
            offsetMultiplyer = 0.05;
            break;
        }

        const yAxisRange = [Math.floor(lowestValue - (lowestValue * offsetMultiplyer)), Math.ceil(highestValue + (highestValue * offsetMultiplyer)) ];

        return yAxisRange;

    }  

    //converts the
    getDataFromApiData(chartData, unitOfMeasure, color){
        var data = {
            x: [],
            y: [],
            yTickValues: [],
        }

        unitOfMeasure = formatMeasurement( unitOfMeasure );

        var theChartData = chartData.timeSeriesData.slice();
        theChartData.reverse();

        //populate the Y tick values
        for (var i = 0; i < theChartData.length; i++) {

            // setup the values to be used
            var measuredValue = null;
            if( theChartData[i].measured_value !== null){
                measuredValue = Math.round( theChartData[i].measured_value * 100 ) / 100;
            }

            // convert the date to a localized time
            var theDate = new Date( theChartData[i].datetime_of_measurement );
            
            //add to the data object the values 
            data.x.push(  theDate );
            data.y.push( measuredValue );
            //console.log(theDate, datetimeOfMeasurement);



            //setup the yTickValues that is used custom text on the hover label
            var previousValue = 0;
            if( i !== 0 ){
                //we can calculate the previous value
                previousValue = theChartData[i].measured_value - theChartData[i-1].measured_value;
                previousValue = Math.round( previousValue * 100 ) / 100;

            }
            var prevString = previousValue + unitOfMeasure;

            data.yTickValues.push(measuredValue + unitOfMeasure + ', ' + prevString + ' ' + hhmmampm( theDate ) );
        }

        //Sets up the data in the plotly format
        
        var graphData = 
          {
            x: data.x,
            y: data.y,
            name: chartData.name,
            type: 'scatter',
            fill: 'tonexty',
            text: data.yTickValues,
            hoverinfo: 'text',
            mode: 'lines+markers',
            line: {
                color: color,
                width: 1,
            }
          };
        



        return graphData;
    }

    getLayout( data, chartData ){
        //sets up the layout variable in the plotly format

        //console.log( data );
        //console.log( chartData );

        var layout = {};
        //var range = this.getYAxisRange( data );

        var title = "";
        if( chartData.sensorName ) {
            title = chartData.sensorName;
        }

        if( chartData.sensorName === "Storage" ) {
            title = "Total reservoir storage"
        }
        
        if( chartData.unitOfMeasure === "m^3" ) {
            title += " (m³)"
        } else if( chartData.unitOfMeasure === "m" ) {
            title += " (m)"
        } else if( chartData.unitOfMeasure === "C" ) {
            title += " (°C)"
        } else if( chartData.unitOfMeasure === "(m^3)/s" ) {
            title += " (m³/s)"
        } else {
            title += " ("+chartData.unitOfMeasure+")" ;
        }

        layout = {

            paper_bgcolor:'rgba(0, 0, 0, 0)',
            plot_bgcolor: 'rgba(0, 0, 0, 0)',

            showlegend: false,
            font: {
                family: "Lato",
                size:15,
            },
            xaxis: {
                showticklabels: true,
                showgrid: true,
                fixedrange: true,
                showline: false,

                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                tickcolor: 'rgba(0,0,0,0)',
                ticklen: 20,
                zeroline: false,
            },
            yaxis: {
                showticklabels: true,
                showgrid: true,
                fixedrange: true,
                showline: false,

                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                ticklen: 15,
                tickcolor: 'rgba(0,0,0,0)',
                title: title ,
                zeroline: false,
            
            },
            margin: {
                l: 70,
                r: 50,
                b: 80,
                t: 20,
                pad: 0
            },
        };

        layout.yaxis.range = this.getYAxisRange( data );

        return layout;
    }

    getConfig( data ){
        //set the plotconfig settings
        var plotConfig = {
            displayModeBar: false,
        }

        return plotConfig;
    }


    // orders the data by the mean of the y axis
    // this is to help with colouring, larger values should go in back 
    // this gives best colour blending
    orderDataByMean( data ){

        var means = [];

        for (var i = 0; i < data.length; i++) {

            let total = 0;
            for (var b = 0; b < data[i].y.length; b++) {
                total = total + data[i].y[b];
            }

            means.push( { originalPos: i, mean: total / data[i].y.length });
        }


        //sort the means highest to lowest
        means.sort(function(a, b) {
            return parseFloat(a.mean) - parseFloat(b.mean);
        });

        let newData = [];
        for (var d = 0; d < means.length; d++) {
            newData.push( data[means[d].originalPos] );
        }

        return newData; 

    }

    render() {
        
        if( this.props.chartData.chartData[0].timeSeriesData.length === 0 ){
            // There is no data to display on this chart, skip adding the line
            return null;
        }

        let data = []; 
        let chartColors = ["rgb(43, 171, 226)", "rgb(253, 185, 19)", "rgb(141, 198, 63)", "rgb(48, 56, 58)"];
        let batteryChartColors = ["rgb(245, 136, 31)", "rgb(253, 185, 19)", "rgb(141, 198, 63)", "rgb(48, 56, 58)"];

        for (var i = 0; i < this.props.chartData.chartData.length; i++) {
            if( this.props.gaugeType === "BATTERY" || this.props.chartData.sensorName === "Battery" ) {
                data.push( this.getDataFromApiData( this.props.chartData.chartData[i],  this.props.chartData.unitOfMeasure, batteryChartColors[i] ) );
            } else {
                data.push( this.getDataFromApiData( this.props.chartData.chartData[i],  this.props.chartData.unitOfMeasure, chartColors[i] ) );
            }
            
        }
        data = this.orderDataByMean( data );

        //console.log(this.props);

        let layout = this.getLayout( data, this.props.chartData );
        let config = this.getConfig( data );

        let height = 290;
        if( this.props.graphHeight ){
            height = this.props.graphHeight;
        }

        //chart height multiplier. each station after the first ten adds another 60px to the charts height.  
        if( this.props.chartData.chartData.length > 10 ) {
            let numOfChartsOverTen = this.props.chartData.chartData.length - 10;
            height = height + (60 * numOfChartsOverTen);
        }

        const graphHeight = {height: height+"px"};
        const chartStyles = { width: "100%", height:height+"px" };

        //console.log( data );
        
        return(
            <div style={ graphHeight } id={ this.props.uniqueID } >
                <PlotlyChart 
                    style={ chartStyles } 
                    data={ data } 
                    layout={ layout } 
                    config={ config } 
                />
            </div>
        );
    }
}

export default MinimalizedTimeSeriesChart;