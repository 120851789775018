/*
 * action types
 */

export const TOGGLE_NAV_MENU = 'TOGGLE_NAV_MENU';
export const SET_NAV_ITEMS = 'SET_NAV_ITEMS';
export const SET_ACTIVE_BUILD_MENU_TYPE = 'SET_ACTIVE_BUILD_MENU_TYPE';

export function toggleNavMenu(){
	return { type: TOGGLE_NAV_MENU }
}

export function setNavItems(items){
	return { type: SET_NAV_ITEMS, items: items}
}

export const setActiveBuildMenuType = ( menuItem ) => {
	return {
		type: SET_ACTIVE_BUILD_MENU_TYPE,
		payload: menuItem
	}
}