export function getTrendIcon(stationData){
    
    if( stationData.trend === 'up' || stationData.trend === 'increasing'){
        return 'trend-up';
    }
    else if( stationData.trend === 'down' || stationData.trend === 'decreasing' ){
        return 'trend-down';
    }
    else if( stationData.trend === 'flat' || stationData.trend === 'stable'  ){
        return 'trend-flat';
    }
    else if( stationData.trend === 'none' || stationData.trend === null || stationData.trend === 'null' ){
        return 'cross';
    }
    else if( stationData.trend === 'raining'){
        return 'rain-gauge';
    } 
    else if( stationData.trend === 'not raining' ) {
        return "sunny";
    }

   throw new Error(`getTrendIcon was passed an unknown trend value: ${stationData.trend} ID: ${stationData.station_name}` ); 

}

export function getRainIcon(cardData){

    if( cardData.trend === 'raining'){
        return 'rain-gauge' 
    }
    else if( cardData.trend === null){
        return 'cross';
    }

    return 'sunny';
}

export function formatMeasurement(measurement) {
    //formats meters squared
    var measurementSubRegex = /(\^(\d*))/;
    var sups = measurementSubRegex.exec(measurement);
    if( sups !== null ){
        measurement = measurement.replace(sups[1], '<sup>' + sups[2] + '</sup>' );
        measurement = measurement.replace('(', '');
        measurement = measurement.replace(')', '');
        return measurement;
    }

    //formats Inches
    if(measurement === "Inches"){ 
        return "\"";
    }

    if(measurement === "C") {
        measurement = '°' + measurement;
    }

    //always return the original
    return measurement;
}

export function formatGaugeAmount(ammount, unitOfMeasure=false, gaugeType=null){

    if( ammount !== null){
        ammount = Number(ammount);

        //console.log( "gaugeType: "+ gaugeType );

        if( gaugeType === "WL" || unitOfMeasure === "m" ) {
            ammount = ammount.toFixed(3);
        } else {
            ammount = ammount.toFixed(2);
        }

        if( unitOfMeasure !== false){
            ammount += unitOfMeasure;
        }
        return ammount;
    }
    else{
        return 'N/A';
    }
}

export function yyyymmdd(d) {
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function hhmmampm(d){

    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
        min = "0" + min;
    }
    if( hr > 12 ) {
        hr -= 12;
    }
    if( hr === 0 ){
        hr = 12;
    }

    var ampm = "am";
    if( d.getHours() > 11){
        ampm = "pm";
    }

    var timeString =  `${hr}:${min} ${ampm}`;
    return timeString.toUpperCase();

}

export function formatTime(timeInt) {
    timeInt = timeInt + 18000; //adjust for timezone;
    var sec_num = parseInt(timeInt, 10); 
    var hours   = Math.floor((sec_num / 3600 ));
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if( hours >= 24 ) {
        hours = (hours - 24);
    }

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    
    return hours+':'+minutes+':'+seconds;
}

function removeNulls(array){
    let numbers = [];

    for (var i = 0; i < array.length; i++) {
        if( array[i] !== null){
            numbers.push(array[i]);
        }
    }
    return numbers;
}

// Functions the same as Math.max however removes nulls from the arguments
// Math.max(0.2,null,0.5) == 0;
// getNonNullMax(-0.2,null,-0.5) == -0.2
export function getNonNullMax(){
    let numbers = removeNulls(arguments);
    return Math.max.apply(null, numbers);
}


// Functions the same as Math.max however removes nulls from the arguments
// Math.min(12,null,5) == 0;
// getNonNullMax(12,null,5) == 5
export function getNonNullMin() {
    let numbers = removeNulls(arguments);
    return Math.min.apply(null, numbers);
}

// checks if date is within range
// 1 = both start and end are within range
// 2 = date is not completely within range
// 0 = date not within range
export function withinDateRange(startDate, endDate, selectedStart, selectedEnd){
    //console.log(startDate, endDate, selectedStart, selectedEnd);
    //find if the chosen start date is within the range

    if( (selectedStart >= startDate && selectedStart <= endDate) &&  (selectedEnd >= startDate && selectedEnd <= endDate) ){
        //both dates are within the range
        //console.log('both dates are within the range');
        return 1;
    }

    if( selectedStart < startDate && selectedEnd > endDate ){
        // range extends available date on both ends
        //console.log('range extends available date on both ends');
        return 1;
    }

    if( selectedStart < startDate && (selectedEnd >= startDate && selectedEnd <= endDate) ){
        //range extends the begining of the data set
        //console.log( 'range extends the begining of the data set' );
        return 2;
    }

    if( (selectedStart >= startDate && selectedStart <= endDate ) && selectedEnd > endDate  ){
        //date range of the selected dates extend the end of gauge
        //console.log( 'date range of the selected dates extend the end of gauge')
        return 3;
    }

    //not within range
    //console.log('not within range');
    return 0;

    
}

export function parseAccumulatedPrecipTotals( theRainData ) {
    let theRainDataTotals = {};
    let theData = [];
    let timeData = [];
    let totals = 0;
    let measuredValues = [];
    
    for (var i = 0; i < theRainData.length; i++) {

        theRainDataTotals.name = theRainData[i].name;
        theRainDataTotals.timeSeriesData = [];
        totals = 0;
        measuredValues = [];

        for (var j = 0; j < theRainData[i].timeSeriesData.length; j++) {

            if( theRainData[i].timeSeriesData[j].measured_value > 0 ) {
                totals = totals + theRainData[i].timeSeriesData[j].measured_value;
            }

            measuredValues.push(totals);
            timeData.push( theRainData[i].timeSeriesData[j].datetime_of_measurement );
        }
        
        measuredValues.reverse();

        for( var k = 0; k < measuredValues.length; k++ ) {
            var obj = {
                datetime_of_measurement: timeData[k],
                measured_value: measuredValues[k]    
            };

            theRainDataTotals.timeSeriesData.push( obj );
        }

        theData.push(theRainDataTotals); 
        theRainDataTotals = {};
    }

    return theData;
}

