import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"; 
import DashboardBuildMenuItem from "./DashboardBuildMenuItem";

import { 
    setModalContentOptions,
} from "../../actions/Dashboard";


class DashboardBuildMenu extends Component {
    constructor ( props ) {
        super( props ); 

        this.state = {
            widgetSizeSelected: null,
            widgets: [],
        };

        this.handleMenuItemSelected = this.handleMenuItemSelected.bind(this);

    }

    handleMenuItemSelected( e ) {
        this.props.setModalContentOptions( { type: 'add', options: {widgetType: e.currentTarget.dataset.itemtype } })
    }

    render() {

        return(
            <div className="dashboard-build-menu menu-buttons" role="navigation" aria-labelledby="dashboard-build-menu-nav">
                <h2 className="screen-reader-text" id="dashboard-build-menu-nav">Menu for building navigation items</h2>
                <DashboardBuildMenuItem  
                    icon="dashboard-dam-gauge" 
                    itemType="dam" 
                    label="DAM GAUGE" 
                    buildMenuItemSelected={ this.handleMenuItemSelected } 
                />

                <DashboardBuildMenuItem  
                    icon="dashboard-stream-gauge" 
                    itemType="stream" 
                    label="STREAM GAUGE" 
                    buildMenuItemSelected={ this.handleMenuItemSelected } 
                />

                <DashboardBuildMenuItem  
                    icon="dashboard-precip-gauge" 
                    itemType="precip" 
                    label="PRECIP GAUGE" 
                    buildMenuItemSelected={ this.handleMenuItemSelected } 
                />

                <DashboardBuildMenuItem  
                    icon="menu-station-map" 
                    itemType="map" 
                    label="STATION MAP" 
                    buildMenuItemSelected={ this.handleMenuItemSelected } 
                />

                <DashboardBuildMenuItem  
                    icon="dashboard-link" 
                    itemType="link" 
                    label="LINK" 
                    buildMenuItemSelected={ this.handleMenuItemSelected } 
                />

                <DashboardBuildMenuItem  
                    icon="dashboard-misc" 
                    itemType="misc" 
                    label="MISC" 
                    buildMenuItemSelected={ this.handleMenuItemSelected } 
                />
                
            </div>
        );
    }
}



function mapStateToProps(state) {
    return { 
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        setModalContentOptions: setModalContentOptions,
    }, dispatch)    
}

export default connect( mapStateToProps, matchDispatchToProps )( DashboardBuildMenu ); 