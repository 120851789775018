import getAvailableXandYPositionsFrom from "../helpers/libs/findNextAvailablePositionIn2DArray"
import shortid from "shortid";

/*
current dashboard id when set 
//get rid of currentLayour var and current dashboard var 
and replace with a rerference to the available dashboards'[i]'.layout widgets

*/

function get_default_state(){

    return  {
        //state
        buildModeActive: false,
        dashboardVisable: false,
        currentDashboardId: null,
        availableDashboards: [],
        requireDashboardSave: null,
        dashboardModalVisable: false,
        dashboardModalOptions: null,
        gridItemDraggable: false,
    }
}

// clone the availableDashboards state
function cloneAvailableDashboards( availableDashboards ){
    availableDashboards = JSON.stringify( availableDashboards );
    return JSON.parse( availableDashboards );
}


function calculateNextPos(currentDashboard, newWidget, cols, isDraggable=true ){

    let newGridSettings = {
        ...determineNextWidgetSize( currentDashboard, newWidget, isDraggable )
    }

    newGridSettings.w = Math.min(cols, newGridSettings.w);
    
    let newPosition = getAvailableXandYPositionsFrom( currentDashboard, newGridSettings, cols );
    //console.log('new position', newPosition);
    // calculate the position
    // for now just going to put it at the bottom left, more magical logic needs to go here
    newGridSettings.x = newPosition.x;
    newGridSettings.y = newPosition.y;

    return newGridSettings;

}

function determineNextWidgetSize( currentDashboard, newWidget, isDraggable=true ){
        let newGridSettings = {
            isDraggable: true,
            isResizable: false,
            i: newWidget.key,
        };

        // calculate the size
        switch( newWidget.type ){
            case "time-series":
            case "dam-gauge":
            case "precip-gauge":
                if( newWidget.options.size === 'small'){
                    newGridSettings.w = 1;
                    newGridSettings.h = 4;
                }   
                else if ( newWidget.options.size === 'large' || newWidget.options.size === 'medium'){
                    newGridSettings.w = 2;
                    newGridSettings.h = 4;
                }
                else {
                    throw new Error(`Unknown size was passed to determineNextWidgetPos for ${newWidget.type}: ${ newWidget.options.size}. Supported sizes are 'small' and 'large'`);
                }  
                break;
            case "dam-gauge-opening":
                newGridSettings.w = 1;
                newGridSettings.h = 4;
                break;
            case "add-link":
                newGridSettings.w = 1;
                newGridSettings.h = 1;
                break;

            case "station-map":
                if( newWidget.options.size === 'small'){
                    newGridSettings.w = 2;
                    newGridSettings.h = 4;
                }   
                else if ( newWidget.options.size === 'medium' ) {
                    newGridSettings.w = 2;
                    newGridSettings.h = 4;
                    
                }
                else if ( newWidget.options.size === 'large' ){
                    newGridSettings.w = 4;
                    newGridSettings.h = 8;
                }
                else{
                    throw new Error(`Unknown size was passed to determineNextWidgetPos for ${newWidget.type}: ${ newWidget.options.size}. Supported sizes are 'small', 'medium', and 'large'`);
                }
                break;

            case "public-alerts":
                newGridSettings.w = 1;
                newGridSettings.h = 4;
                break;

            case "weather-forecast":
                newGridSettings.w = 1;
                newGridSettings.h = 32;
                break;
            
            case "battery":
                newGridSettings.w = 1;
                newGridSettings.h = 4;
                break;

            default:
                throw new Error(`An unknown widget type was passed to determineNextWidgetPos: ${newWidget.type}`);
        }   
        
        //console.log(newGridSettings);

        return newGridSettings;
}

export default function ( state=get_default_state(), action ) {
    // eslint-disable-next-line
    let layoutKeys = [];

    let newAvailableDashboards = cloneAvailableDashboards( state.availableDashboards );

    switch( action.type ) {

        case "SET_DASHBOARD_VISABLE":
            return { ...state, dashboardVisable: action.payload, availableDashboards:newAvailableDashboards };

        case "SET_BUILD_MODE":
            let gridItemDraggable = state.gridItemDraggable;
            gridItemDraggable = action.payload;
            return { ...state, buildModeActive: action.payload, gridItemDraggable: gridItemDraggable, availableDashboards:newAvailableDashboards   };

        case "UPDATE_CURRENT_DASHBOARD_LAYOUT":
            for( var i=0; i < newAvailableDashboards.length; i++ ) {
                if( newAvailableDashboards[i].id === state.currentDashboardId ) {
                    newAvailableDashboards[i].layout = { ...action.payload };
                }
            }
            return { ...state, availableDashboards:newAvailableDashboards, requireDashboardSave: new Date().getTime() };

        case "EDIT_WIDGET":
        let oldWidget =  action.payload;
        oldWidget.key = shortid.generate();

        for( let i=0; i < newAvailableDashboards.length; i++ ) {
            if( newAvailableDashboards[i].id === state.currentDashboardId ) {
                for( var j=0; j < newAvailableDashboards[i].widgets.length; j++ ) {
                    if( newAvailableDashboards[i].widgets[j].key === action.payload.widgetToUpdate ) {    
                        if( newAvailableDashboards[i].widgets[j].options.size === oldWidget.options.size ) {    
                            newAvailableDashboards[i].widgets.splice( j, 1 ); 
                            oldWidget.key = action.payload.widgetToUpdate;  
                        } else {
                            //the size has updated so replace the layout as well
                            newAvailableDashboards[i].widgets.splice( j, 1 );
                            let layoutKeys = Object.keys( newAvailableDashboards[i].layout );
                            for (var k = 0; k < layoutKeys.length; k++) {
                                let layoutItem = newAvailableDashboards[i].layout[ layoutKeys[k] ];
                                for (var b = 0; b < layoutItem.length; b++) {
                                if( layoutItem[b].k === action.payload ) {
                                        layoutItem.splice( b, 1 );
                                    }
                                }
                            }
                        }
                    }
                }
    
                newAvailableDashboards[i].widgets.push( oldWidget );
                newAvailableDashboards[i].layout.lg.push( calculateNextPos( newAvailableDashboards[i].layout.lg, oldWidget, 4, state.buildModeActive ) );
                newAvailableDashboards[i].layout.md.push( calculateNextPos( newAvailableDashboards[i].layout.md, oldWidget, 3, state.buildModeActive ) );
                newAvailableDashboards[i].layout.sm.push( calculateNextPos( newAvailableDashboards[i].layout.sm, oldWidget, 2, state.buildModeActive ) );
                newAvailableDashboards[i].layout.xs.push( calculateNextPos( newAvailableDashboards[i].layout.xs, oldWidget, 1, state.buildModeActive ) );
            }
        }

        return { ...state, availableDashboards:newAvailableDashboards, requireDashboardSave: new Date().getTime()  };

        case "ADD_WIDGET":
            let newWidget =  action.payload;
            newWidget.key = shortid.generate();

            //console.log(newWidget);

            if( action.payload.options.dashboardId ) {
                state.currentDashboardId = action.payload.options.dashboardId;
            }

            for( let i = 0; i < newAvailableDashboards.length; i++ ) {
                if( newAvailableDashboards[i].id === state.currentDashboardId ) {

                    newAvailableDashboards[i].widgets.push( newWidget );
                    newAvailableDashboards[i].layout.lg.push( calculateNextPos( newAvailableDashboards[i].layout.lg, newWidget, 4, state.buildModeActive ) );
                    newAvailableDashboards[i].layout.md.push( calculateNextPos( newAvailableDashboards[i].layout.md, newWidget, 3, state.buildModeActive ) );
                    newAvailableDashboards[i].layout.sm.push( calculateNextPos( newAvailableDashboards[i].layout.sm, newWidget, 2, state.buildModeActive ) );
                    newAvailableDashboards[i].layout.xs.push( calculateNextPos( newAvailableDashboards[i].layout.xs, newWidget, 1, state.buildModeActive ) );
                }
            }

            return { ...state, availableDashboards:newAvailableDashboards, requireDashboardSave: new Date().getTime()   };

        case "REMOVE_WIDGET_FROM_DASHBOARD":

            for( let i = 0; i < newAvailableDashboards.length; i++ ) {
                if( newAvailableDashboards[i].id === state.currentDashboardId ) { //get the correct dashboard
                    for( let j=0; j < newAvailableDashboards[i].widgets.length; j++ ) {
                        if( newAvailableDashboards[i].widgets[j].key === action.payload ) { 
                            //get the widget to remove
                            //console.log("found the widget to remove ");
                            newAvailableDashboards[i].widgets.splice( j, 1 );
                        }
                    }

                    let layoutKeys = Object.keys( newAvailableDashboards[i].layout );
                    for (let k = 0; k < layoutKeys.length; k++) {
                        let layoutItem = newAvailableDashboards[i].layout[ layoutKeys[k] ];
                        //console.log("layoutItem: "+ layoutItem);

                        for (let b = 0; b < layoutItem.length; b++) {
                            if( layoutItem[b].i === action.payload ) {
                                layoutItem.splice( b, 1 );
                            }
                        }
                    }
                }
            }

            document.body.classList.toggle('disable-scroll', false);
                    
            return { ...state, dashboardModalVisable: false, availableDashboards:newAvailableDashboards, requireDashboardSave: new Date().getTime()   };

        case "SET_AVAILABLE_DASHBOARDS":
            return { ...state, availableDashboards:action.payload   };
            
        case "LOGOUT":
            return get_default_state();

        case "DELETE_DASHBOARD":
            
            let foundDashboardToDelete = false;
            let dashboardID;
            for (let i = 0; i < newAvailableDashboards.length; i++) {
                if(action.payload === newAvailableDashboards[i].id ){
                    foundDashboardToDelete = newAvailableDashboards[i].id;
                    newAvailableDashboards.splice( i, 1 );
                    break;
                }
            }

            if( foundDashboardToDelete === state.currentDashboardId ){
                let foundNewDefault = false;
                for (let i = 0; i < newAvailableDashboards.length; i++) {
                    if( newAvailableDashboards[i].is_default ){
                    //console.log('found default');
                        foundNewDefault = true;
                        dashboardID =  newAvailableDashboards[i].id;
                        break;
                    }
                }

                if( foundNewDefault === false ){
                    //console.log('did not find default');
                    dashboardID =  newAvailableDashboards[0].id;
                }
            }
            
            return { ...state, availableDashboards:newAvailableDashboards, currentDashboardId: dashboardID  };



        case "SET_CURRENT_DASHBOARD":

            //find the selected dashboard
            let selectedDashboardFound = false;
            var selectedDashboardId = null;
            for (let i = 0; i < state.availableDashboards.length; i++) {

                if( action.payload === state.availableDashboards[i].id ){
                    selectedDashboardFound = true;
                    selectedDashboardId = state.availableDashboards[i].id;
                    break;
                }
            }

            if( selectedDashboardFound === false ){
                throw new Error('Could not find the selected dashboard id');
            }

            return  { ...state, currentDashboardId: selectedDashboardId, availableDashboards:newAvailableDashboards, requireDashboardSave: new Date().getTime()   };

        case "UPDATE_SPECIFIC_DASHBOARD":
            for (let i = 0; i < newAvailableDashboards.length; i++) {
                if(action.payload.id === newAvailableDashboards[i].id ){
                    newAvailableDashboards[i] = action.payload;
                    break;
                }
            }

            return { ...state, availableDashboards:newAvailableDashboards };


        case "ADD_NEW_DASHBOARD":
            newAvailableDashboards.push(action.payload);
            return { ...state, currentDashboardId: action.payload.id, availableDashboards:newAvailableDashboards };

        case "SET_DEFAULT_DASHBOARD":
            //console.log('set default');
            for (let i = 0; i < newAvailableDashboards.length; i++) {
                if(action.payload === newAvailableDashboards[i].id ){
                    newAvailableDashboards[i].is_default = true;
                    break;
                }
            }

            return { ...state, availableDashboards:newAvailableDashboards };

        case "SET_MODAL_CONTENT_OPTIONS":
            //console.log('set modal content options', action.payload);
            return  { ...state, dashboardModalOptions: action.payload, availableDashboards:newAvailableDashboards };


        case "SHOW_DASHBOARD_MODAL":
            document.body.classList.toggle('disable-scroll', true);
            return  { ...state, dashboardModalVisable: true, availableDashboards:newAvailableDashboards };


        case "CLOSE_DASHBOARD_MODAL":
            document.body.classList.toggle('disable-scroll', false);
            return  { ...state, dashboardModalVisable: false, dashboardModalOptions: null, availableDashboards:newAvailableDashboards };
        
        default:
        	return state;
    }

}