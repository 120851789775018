export default function ( state={
    stationObjs: [],
    stationObjsHistory: []
}, action ) {

    switch( action.type ) {
        case "UPDATE_GAUGE_MAP_API_DATA":
        let results = action.payload;
        //let newState = [];
        //let oldState = state;
        let newStationObj = {};
        let timeSeriesData = [];

        for( var i = 0; i < results.length; i++ ) {
            newStationObj = {};
            newStationObj.stationId = results[i].station_id + "-" + results[i].station_name + "-" + results[i].sensor_name;

            for( var j = 0; j < results[i].time_series_data.length; j++ ) {
                timeSeriesData.push( results[i].time_series_data[j].measured_value );
            }

            newStationObj.timeSeriesData = timeSeriesData;
            state.apiData = action.payload;
            state.stationObjs.push(newStationObj);
            timeSeriesData = [];
        }

        state.stationObjsHistory.push(state.stationObjs);

        var changes = compareValues(state.stationObjs, state.stationObjsHistory[state.stationObjsHistory.length - 1]);

        state.cardsToUpdate = changes;

        return {...state} ;

        default:
        return state;
    }
}

function compareValues(newVals, oldVals) {
    //console.log(newVals);
    //console.log(oldVals);
    var cardsToUpdate = [];

    for( var i = 0; i < newVals.length; i++ ) {
        for( var j=0; j < oldVals.length; j++ ) {
            if( newVals[i].stationId === oldVals[j].stationId ) {
                //console.log(newVals[i].stationId);
                //console.log(oldVals[j].stationId);
                for( var k=0; k < newVals[i].timeSeriesData.length; k++ ) {
                    if( newVals[i].timeSeriesData[k] !== oldVals[j].timeSeriesData[k]  ){
                        /*console.log("difference found");
                        console.log("newval: ");
                        console.log(newVals[i].timeSeriesData[k]);
                        console.log("oldval: ");
                        console.log(oldVals[j].timeSeriesData[k]);*/

                        cardsToUpdate.push(newVals[i]);
                    }
                }
            }
        }
    }

    return cardsToUpdate;
}