import React, { Component } from "react";
//import IconTextLink from "./station-detailed/IconTextLink";
import StationTitle from "./StationTitle";
//import StationReadings from "./StationReadings";
//import GaugeDamChart from "./charts/GaugeDamChart";
//import { getRoute, apiRequest } from "../helpers/Api";
import MinimalizedTimeSeriesChart from "./charts/MinimalizedTimeSeriesChart.js";
import MinimalizedRainChart from "./charts/MinimalizedRainChart.js";
import { Link } from 'react-router-dom';
import slugify from "../helpers/slugify";
/*import { 
	//yyyymmdd, 
	//formatGaugeAmount, 
	getRainIcon,
	getTrendIcon,
 } from "../helpers/gaugeHelpers";*/

class MinimalizedCard extends Component {
    constructor ( props ) {
        super( props );

		this.parseStreamData = this.parseStreamData.bind(this);
        this.parseRainData = this.parseRainData.bind(this);
    }

    shouldComponentUpdate(nextProps) {

        //if the last update value has not changed there is no need to re render
        if( this.props.data.last_updated !== nextProps.data.last_updated || this.props.doUpdate) {
            //console.log( this.props.data.last_updated, nextProps.data.last_updated, "Card updating"  );
            return true;
        }
        else{
            //console.log( this.props.data.last_updated, nextProps.data.last_updated, "Card not updating"  );
            return false;
        }

    }

    // Takes the API data and converts into the format RainChart uses
	parseRainData(apiData){
		var rainData = {
            unitOfMeasure: apiData.unit_of_measure,
            timeSeriesData: apiData.time_series_data, 
        }

        return rainData;
	}

    // Takes the API data and converts it into a format the WaterLevel graph component can use
	parseStreamData(apiData){
        
        var streamData = {
            unitOfMeasure: apiData.unit_of_measure,
            chartData: [ { timeSeriesData: apiData.time_series_data } ],
            normalLine: null,
            highHighLine: null,
            sensorName: apiData.pretty_sensor_name
        }

        //if normal is set add it to the graph
        if( apiData.normal_for_this_month !== null){
            streamData.normalLine = apiData.normal_for_this_month;
        }
        //if the high high is set add it to the graph
        if( apiData.high_high_alarm === true ){
            streamData.highHighLine = apiData.high_high_limit
        }

        return streamData;
    }

    render() { 

        var cardData = this.props.data;

        var id = this.props.id;

        var viewData = {
            "gauge_id": cardData.station_id,
            "gauge_name": cardData.station_name,
            "unique_id": id,
            //"gauge_date": yyyymmdd( new Date(cardData.last_updated) ),
        };

        //console.log( viewData );

        if( cardData.status === "Non-responsive"){
            viewData.missedData = true;
        } else {
            viewData.missedData = false;
        }

        var graphData = null;

        switch(this.props.cardType) {
            case 'rain-gauge':
                //display a rain widget
                graphData = this.parseRainData(cardData);
                //viewData.icon_name = getRainIcon(cardData);
                viewData.icon_name = "menu-reports";
                break;
            case 'time-series':
                //display a stream widget
                graphData = this.parseStreamData(cardData);
                //viewData.icon_name = getTrendIcon(cardData);
                viewData.icon_name = "menu-reports";
                break;
            default:
                console.warn('A gauge with an unknown type was passed to: ' + id );
            break;
        }

        //console.log(graphData);

       
        let iconName = viewData.icon_name,
            gaugeName = viewData.gauge_name,
            gaugeDate = viewData.gauge_date,
            gaugeId = viewData.gauge_id,
            slug = cardData.slug,
            sensorName = this.props.data.sensor_name,
            missedDataCss = "";
            
        ( viewData.missedData ) ? missedDataCss = "card-missed-data-overlay visible" : missedDataCss = "card-missed-data-overlay";

        const stationSlug = slugify(gaugeName + "-" + sensorName);
        if( this.props.cardType === 'time-series' || this.props.cardType === 'rain-gauge' ) {

            let graphComponent = null;
            if(  this.props.cardType === 'time-series'){
                graphComponent = <MinimalizedTimeSeriesChart uniqueID={viewData.unique_id} chartData={ graphData }  widgetType={ this.props.widgetType }/>
            }
            else {
                graphComponent = <MinimalizedRainChart uniqueID={viewData.unique_id} chartData={ graphData } />
            }
          
            return(
                <div className="map-gauge-wrap" >
                    <div className="card gauge card-gauge minimalized">
                        <div className="card-gauge-content">
                            <StationTitle 
                                icon={ iconName }
                                gaugeName={ gaugeName }
                                gaugeDate={ gaugeDate }
                                gaugeId={ gaugeId }
                                sensorName={ sensorName }
                                slug={slug}
                            />
                            
                        </div>
                        <div className="card-gauge-graph">
                            { graphComponent }
                        </div>
                        <div className={ missedDataCss }>
                            <span><Link to={ "/gauge/"+stationSlug }>No Data Available</Link></span>
                        </div>
                    </div>
                </div>    
            );
        }

        // Error will be thrown if a unknonwn card type is passed
        throw new Error(`Unknown cardType prop passed to Card: ${this.props.cardType}`);

    }
}

export default MinimalizedCard;