import React, {Component} from "react";
import DamSafetyLogListDetails from "./DamSafetyLogListDetails";
import leftPad from "left-pad";

class DamOperationsTable extends Component {
    // Expected Props:
    // Expects data serailzied using the DamSafetyCodeLogSerializer
    constructor ( props ) {
        super( props );
    }

    render() {

        const rows = []

        for (let i = 0; i < this.props.logs.length; i++) {
            const log = this.props.logs[i];

            let dateTime = new Date(log.timestamp);
            let year = dateTime.getFullYear();
            let month = leftPad( dateTime.getMonth() + 1,2,0 );
            let day = leftPad( dateTime.getDate(),2,0 );
            let hours = dateTime.getHours();
            //let minutes = dateTime.getMinutes();
            let minutes = leftPad( dateTime.getMinutes(),2,0 );
            let dateTimeString =  year+"-"+month+"-"+day+" "+hours+":"+minutes;


            let amount = Math.abs(log.operation_amount);
            let operationType = null;

            if( log.emergency_operation === true ){
                amount = '';
                operationType = (log.operation_amount > 0 ? "Closing" : "Opening")
            }
            else{
                amount = amount+'"';
                operationType = (log.operation_amount > 0 ? "Opening" : "Closing")
            }


            rows.push(
                <tr>
                    { this.props.showStationName && <td>{log.station_name}</td> }
                    <td>{operationType}</td>
                    <td>{amount}</td>
                    <td>{ dateTimeString }</td>
                    <td>{log.comment}</td>
                </tr>
            )
        }


        let noDataMsg = null
        if(rows.length === 0) {
            noDataMsg = <span className="no-summary-results">No operations</span>
        }


        return (
            <div className="summariezed-data">
                <div className="responsive-table-wrap">
                    <table className="table-lined">
                        <thead>
                            <tr>
                                { this.props.showStationName && <th>Station</th> }
                                <th>Operation Type</th>
                                <th>Total Amount</th>
                                <th>Timestamp</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                            { rows }
                        </tbody>
                    </table>
                </div>
                { noDataMsg }
            </div>
        );


    }
}

export default DamOperationsTable;  