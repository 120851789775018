import React, { Component } from "react";
import IconTextLink from "./IconTextLink";
import TimeSeriesLength from "./TimeSeriesLength"; 

import BatteryLevelChart from "../charts/BatteryLevelChart";


import { 
	yyyymmdd, 
 } from "../../helpers/gaugeHelpers";
	
class StationBattery extends Component {

	constructor(props) {
        super(props);
        this.handleSpecificTimeSeriesRequest = this.handleSpecificTimeSeriesRequest.bind(this);
 	}

	parseBatteryData(apiData){
		//console.log(apiData);
		var batteryData = {
			unitOfMeasure: apiData.unit_of_measure,
			timeSeriesData: apiData.time_series_data,
		}

		return batteryData;
	}

 	formatViewData(stationData){

		let cardData = stationData.data;
				
		let viewData = {
			"gauge_id": cardData.station_id,
			"gauge_slug": cardData.slug,
			"gauge_name": cardData.pretty_station_name,
			"unique_id": cardData.station_name+" "+ cardData.sensor_name,
			"type_of": cardData.type_of,
			"gauge_date": yyyymmdd( new Date(cardData.last_updated) ),
			"icon_name" : "plus",
			"gauge_readings" : [],
			"download_link": "#",
			"download_text": "Download Tabular Data (last 72 hours)",

		};

		//console.log(cardData);
		viewData.graphData = this.parseBatteryData( cardData );


		// get the battery level
		if( stationData.currentBatteryLevel === null){
			viewData.currentBatteryLevel = "Current Battery Level: No readings in the last 72 hours.";
		}
		else{
			viewData.currentBatteryLevel = `Current Battery Level: ${stationData.currentBatteryLevel}`; 
		}

		return viewData;

 	}


	handleSpecificTimeSeriesRequest(hour) {
		this.props.handleSpecificTimeSeriesRequest(hour);
	}
	

	render(){
		//console.log('StationBatteryRender', this.props.stationData);
		
		if( !this.props.stationData.data ){
			//need a loading screen
			return null;
		}
		const viewData = this.formatViewData( this.props.stationData );
	

		const chartId = `${viewData.gauge_slug}-chart`;

		let tabularDataLink = this.props.isLoggedIn ? 
		<IconTextLink 
			icon={ "floppy-disk" }
			text={ viewData.download_text }
			link={ viewData.download_link }
		/> : null;
		
		return (
			<div className="gauge battery-chart-details ">

				<div className="station-details-info">

			        <h2 className="gauge-name">{ viewData.gauge_name } Battery Level</h2>

					<div className="gauge-download-links gauge-section">
						{ tabularDataLink }
			        </div>

					<div className="gauge-time-series-links gauge-section">
						<TimeSeriesLength timeSeriesOptions={ this.props.stationData.timeSeriesOptions } clickCallback={ this.handleSpecificTimeSeriesRequest } /> 
					</div>
				</div>

				<div className="battery-graph-container">
					<BatteryLevelChart uniqueID={chartId} chartData={ viewData.graphData } graphHeight="280" />
				</div>

			</div>
		)
	}
}

export default StationBattery;