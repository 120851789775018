import React, { Component } from "react";
import GoogleMap from "../../GoogleMap";
import { getRoute, apiRequest } from "../../../helpers/Api";
import EditWidgetMenu from "../EditWidgetMenu";
import PrecipBarLegend from "../../PrecipBarLegend"; 

class GoogleMapWidget extends Component {

    constructor ( props ) {
        super( props );

        //variables
        this.apiTimeout = null;

        //methods
        this.fetchData = this.fetchData.bind(this);
        this.editWidget = this.editWidget.bind(this);
        this.removeWidget = this.removeWidget.bind(this);
        this.setSelectedPrecipBarTimeframe = this.setSelectedPrecipBarTimeframe.bind(this);
        this.cardRefreshLength = 60000;

        //state
        this.state = {
        	isFetching: true,
            apiData: null,
            precipBarSelectedTime: null,
        }
    }

    componentDidMount(){
    	this.fetchData();
    	this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);

    }

    shouldComponentUpdate(nextProps) {
		if( this.props.updateRequired === nextProps.updateRequired ) {
			return true;
		} else {
			return false;
		}
	}

    componentWillUnmount(){
		//clear the timeout for fetching data
		clearInterval( this.apiTimeout );
	}

    fetchData(){

    	this.setState({isFetching: true });

    	apiRequest( getRoute("gaugeListing" ) )
			.then(result => {  
				//console.log( result );
				this.setState({isFetching: false, apiData: result });
			})
			.catch((error) => {
				//console.log('error fetching gauge data');
				this.setState({isFetching: false });
			});
    }

    editWidget( widgetId ) {
		this.props.handleEditWidget( this.props, this.state.apiData.sensor_name, widgetId );
    }
    
    removeWidget( widgetId ) {
		this.props.handleRemoveWidget(widgetId);
    }
    
    setSelectedPrecipBarTimeframe( value ) {
        //console.log("value : "+value);
        this.setState({ precipBarSelectedTime: value });
    }


    render() {
        let showPrecip = 'off';

        if( this.props.size !== 'small' && this.props.size !== 'medium' && this.props.size !== 'large'){
            throw new Error(`Unknown prop size was passed to GoogleMapWidget: ${ this.props.size}. Supported sizes are 'small', 'medium', and 'large'`);
        }
        let className = `dashboard-grid-item dashboard-grid-item-map ${this.props.size}`;
        
        let editWidgetMenu = this.props.buildModeActive ? 
		<EditWidgetMenu 
			widgetId={ this.props.widgetId } 
			handleEditWidget={ this.editWidget }
			handleRemoveWidget={ this.removeWidget }
		/>
		: null;

        let googleMap = null;
        let precipBarLegend = null;

        if( this.state.apiData !== null ){

            let dataToUse =  this.state.apiData;
            if( this.props.mapType === "precip" ) {
                showPrecip = 'on';
                dataToUse = [];
                for (var i = 0; i < this.state.apiData.length; i++) {
                    if( this.state.apiData[i].sensor_name === 'PRECIP' ){
                        dataToUse.push(this.state.apiData[i]);
                    }
                }
                
                precipBarLegend = 
                <PrecipBarLegend selectedPrecipBarTimeframe = { this.setSelectedPrecipBarTimeframe } />
            }

            googleMap = 
                <GoogleMap 
                    apiData = { dataToUse } 
                    checkboxUpdated = { false }
                    panelOut={ false }
                    watershedBoundariesIsOn={ "on" }
                    weatherRadarIsOn={ false }
                    floodPlainIsOn= { false }
                    precipitationBarsIsOn={ showPrecip }
                    precipitationBarsSelectedTimeframe={ this.state.precipBarSelectedTime }
                    mapId={this.props.mapId}
                />
        }

    	return (
            <div>
                { precipBarLegend }
                <div className={ className }>
                    { googleMap }
                    { editWidgetMenu }
                </div>
            </div>
    	)
    }
}

export default GoogleMapWidget;