/*
	Throttles evenlistener until the function is completed
	
	Since resize events can fire at a high rate, the event handler shouldn't execute 
	computationally expensive operations such as DOM modifications.

	Modified to take a callback function from: 
	https://developer.mozilla.org/en-US/docs/Web/Events/resize#requestAnimationFrame_customEvent

	Use:
		// Add an event listener
		resizeEvent = new EventThrottle("resize", this.windowRezieEvent);

		// Remove the event
		resizeEvent.removeEvent();
*/

class EventThrottle {

	constructor ( type, listener, obj=window ) {
		this._type = type;
		this._listener = listener;
		this._obj = obj;
		this._running = false;

		this.removeEvent = this.removeEvent.bind(this);
		this.monitorFunction = this.monitorFunction.bind(this);

		this.addEvent();
	}

	monitorFunction(){
		if (this._running) { return; }
        this._running = true;
         requestAnimationFrame(function() {
            this._listener();
            this._running = false;
        }.bind(this));
	}

	addEvent() {
	    this._obj.addEventListener(this._type, this.monitorFunction);
	};

	removeEvent(){
		this._obj.removeEventListener(this._type, this.monitorFunction);
	}
}


export default EventThrottle;