import React, { Component } from "react";
import EventThrottle from "../helpers/EventThrottle";
import SvgIcon from "./SvgIcon"

class BackToTop extends Component {


    constructor(props) {
        super(props);
        this.resizeEvent = null;
        this.windowRezieEvent = this.windowRezieEvent.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);

        this.state = {
            showBackTop: false,
        }
    }

    componentDidMount(){
        this.resizeEvent = new EventThrottle("scroll", this.windowRezieEvent);
    }

    componentWillUnmount(){
        this.resizeEvent.removeEvent();
    }

    scrollToTop(){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    windowRezieEvent(event){
        if( this.state.showBackTop === false && window.pageYOffset > 500 ){
            this.setState({showBackTop: true});
        }
        else if( this.state.showBackTop === true && window.pageYOffset < 500 ){
            this.setState({showBackTop: false});
        }
    }
    
    
    render() {
        return(
            <span role="button" aria-label="Back to top" className={`omni-back-top ${(this.state.showBackTop ? ' present' : '')}`} onClick={this.scrollToTop}>
                <SvgIcon icon="chev-up" />
                <span className="screen-reader-text">Go back to top</span>
            </span>
        )
    }
};

export default BackToTop;