import React, { Component } from "react";
import Field from "../forms/Field";
//import SvgIcon from "./SvgIcon";

class CheckboxList extends Component {

    // sample props:
    // this.props.title -- the title of the checkbox list
    // this.props.handleInputChange -- the call back
    // this.props.groupName -- the name for the collection of checkboxes
    // this.props.checkboxList = [{id:'some-unique-name', displayName: 'Some Friendly Name' value: 'the-value', selected: true/false}]


    constructor ( props ) {
        super( props ); 
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(value, input, event) {
        //console.log(event);
        this.props.handleInputChange(event);
    }

    render() {
        return(
            <div>
                <fieldset>
                    <legend className="slideout-heading" >{ this.props.title }</legend>
                    { 
                        this.props.checkboxList.map(( item, index ) => {
                            return (
                                <Field
                                    key={index}
                                    id={item.id}
                                    type="checkbox" 
                                    name={this.props.groupName}
                                    label={ item.displayName } 
                                    value={ item.value} 
                                    defaultChecked={item.selected}
                                    required={false}
                                    onChange={ this.handleInputChange }

                                    />
                            )
                            
                        })
                    }

                </fieldset>
            </div>
        );
    }
}

export default CheckboxList;