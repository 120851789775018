
export function convertWidgetModalTime(oldTimeFormat) {
    let time = null;
    switch(oldTimeFormat) {
        case "15m":
        time = 0.25;
        break;
        case "30m":
        time = 0.5;
        break;
        case "60m":
        time = 1;
        break;
        case "2h":
        time = 2;
        break;
        case "6h":
        time = 6;
        break;
        case "12h":
        time = 12;
        break;
        case "24h":
        time = 24;
        break;
        case "48h":
        time = 48;
        break;
        case "72h":
        time = 72;
        break;

        default: 
        let startTime = new Date( oldTimeFormat.from );
        let endTime = new Date( oldTimeFormat.to );
        let seconds = (endTime.getTime() - startTime.getTime()) / 1000;
        let hours = Math.ceil(seconds / 3600);
        time = hours;
        break;
    }

	return time
}