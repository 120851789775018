import React, { Component } from "react";
import { getRoute, apiRequest } from "../../../helpers/Api";
import BatteryLevelChart from "../../charts/BatteryLevelChart";
import { convertWidgetModalTime } from "../../../helpers/DashboardHelpers";
import EditWidgetMenu from "../EditWidgetMenu";

class BatteryWidget extends Component {

    constructor ( props ) {
        super( props );

        //variables
        this.apiTimeout = null;

        //methods
        this.fetchData = this.fetchData.bind(this);
        this.parseBatteryData = this.parseBatteryData.bind(this);
        this.editWidget = this.editWidget.bind(this);
		this.removeWidget = this.removeWidget.bind(this);
        this.cardRefreshLength = 60000;

        //state
        this.state = {
        	isFetching: true,
        	apiData: null,
        }
    }

    shouldComponentUpdate(nextProps) {
		if( this.props.updateRequired === nextProps.updateRequired ) {
			return true;
		} else {
			return false;
		}
	}


    componentDidUpdate(prevProps, prevState){
        //check to see if something was changed that makes us need to update
        let requireFetch = false;

        if( this.props.timeFrame !== prevProps.timeFrame){
            requireFetch = true;
        }

        if( this.props.type !== prevProps.type){
            requireFetch = true;
        }

        if( this.props.gaugeId !== prevProps.gaugeId){
            requireFetch = true;
        }


        if( requireFetch === true ){
            clearInterval( this.apiTimeout );
            this.fetchData();
            this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);

        }


    }

    componentDidMount(){
    	this.fetchData(); 
    	this.apiTimeout = setInterval(this.fetchData, this.cardRefreshLength);

    }

    componentWillUnmount(){ 
		//clear the timeout for fetching data
		clearTimeout( this.apiTimeout );
    }


    fetchData(){
    	this.setState({isFetching: true });
        let routeDetails = ["gaugeDetails", this.props.gaugeId.toString()];
		let time = convertWidgetModalTime(this.props.timeFrame);
        routeDetails.push({override_hours_ago:time})
		apiRequest( getRoute.apply(null, routeDetails))
		.then(result => {  
			this.setState({isFetching: false, apiData: result });
		})
		.catch((error) => {
			//console.log('error fetching gauge data');
			this.setState({isFetching: false });
		});;
    }


    parseBatteryData(apiData){
        //console.log(apiData);
        var batteryData = {
            unitOfMeasure: apiData.unit_of_measure,
            timeSeriesData: apiData.time_series_data,
        }

        return batteryData;
    }

    editWidget( widgetId ) {
		this.props.handleEditWidget( this.props, this.state.apiData.sensor_name, widgetId );
	}

	removeWidget( widgetId ) {
		this.props.handleRemoveWidget(widgetId);
	}

    render() {
         

    	let className = `dashboard-grid-item dashboard-grid-item-battery ${this.props.size}`;

        let batteryInfo = null;
        if( this.state.apiData !== null ){

            let battery = this.state.apiData;
            const chartId = `${battery.slug}-chart`;

            batteryInfo = (
                <div className="card gauge card-gauge card-battery">
                    <div className="card-gauge-content">
                        <h2 className="gauge-name">{ battery.pretty_station_name } { battery.pretty_sensor_name }</h2>
                        <BatteryLevelChart uniqueID={chartId} chartData={ this.parseBatteryData( battery ) } graphHeight="280" />
                    </div>
                </div>
            )   
        }

        let editWidgetMenu = this.props.buildModeActive ? 
		<EditWidgetMenu 
			widgetId={ this.props.widgetId } 
			handleEditWidget={ this.editWidget }
			handleRemoveWidget={ this.removeWidget }
		/>
		: null;
       
    	return (
    		<div className={ className }>
                { batteryInfo }
                { editWidgetMenu }
    		</div>
    	)
    }
}

export default BatteryWidget;