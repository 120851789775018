import React, { Component } from "react";
import RadioButtonSeries from "./dashboard/RadioButtonSeries";

class PrecipBarLegend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addPrecipBarLegend: false,
            checkedId: null,
        };
        
        this.handleRadioInputChange = this.handleRadioInputChange.bind( this );
    }

    componentDidMount() { 
        
    }

    handleRadioInputChange( value, name, event, btnID ) {
        this.setState({ checkedId: btnID });
        this.props.selectedPrecipBarTimeframe(value);
    }
    
    render() {
        let timeframes = [
            {label: "1h", value: 1, id: "1h", checked: false},
            {label: "2h", value: 2, id: "2h", checked: false},
            {label: "6h", value: 6, id: "6h", checked: true},
            {label: "12h", value: 12, id: "12h", checked: false},
            {label: "18h", value: 18, id: "18h", checked: false},
            {label: "24h", value: 24, id: "24h", checked: false},
            {label: "48h", value: 48, id: "48h", checked: false},
            {label: "72h", value: 72, id: "72h", checked: false},
        ];

        if( this.state.checkedId ) {
            for( var i=0; i < timeframes.length; i++ ) {
                if( timeframes[i].id === this.state.checkedId ) {
                    timeframes[i].checked = true;
                } else {
                    timeframes[i].checked = false;
                }
            }
        }

        return (
            <div className="precip-bar-legend-container" tabIndex="0">
                <RadioButtonSeries 
                    title="Precipitation timeframe:"
                    groupName="precip-timeframe"
                    options={timeframes}
                    handleRadioInputChange={this.handleRadioInputChange}
                    required={false}
                    isValid={true}
                    requiredValidationMessage=""
                />
            </div>
        );
    }
};

//http://138.197.174.91/api/web/26,23,158/override_hours_ago=18&format=json

    export default PrecipBarLegend;

 