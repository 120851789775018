import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { login, logout } from "../../actions/Auth";
import { setAvailableDashboards } from "../../actions/Dashboard";
import { getRoute, apiRequest } from "../../helpers/Api";
import Field from "../forms/Field";
import parseLoginApiData from "../../helpers/Auth";


// A nav item component
class Login extends Component {

	constructor(props) {
		super(props);
		this.inputChanged = this.inputChanged.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.logout = this.logout.bind(this);
		this.state = {
			email: '',
			password: '',
			rememberMe: true,
			loginError: '',
			fetchingLogin: false,
		}
	}

	handleSubmit(event){
		event.preventDefault();
		this.setState({fetchingLogin: true});
		const loginDetails = {
			email: this.state.email,
			password: this.state.password
		};

		apiRequest(getRoute('login'), {
		      method: 'POST',
		      body: JSON.stringify(loginDetails)
		    })
		    .then(theResult => {
		    	const result = theResult.json;
		    	//console.log( this.props );
			    //console.log('login success ', result );
			    let loginDetails = parseLoginApiData( result );
			    loginDetails.rememberMe = this.state.rememberMe;
			    //console.log(loginDetails);
			    this.props.login( loginDetails );
			    //console.log( result );
			    this.props.setAvailableDashboards( result.dashboards );
			    this.setState({
			    	fetchingLogin: false,
			    	loginError: '',
			    	email: '',
			    	password: ''
			    });

		    })
		    .catch(error => {
		    	let loginMessage = '';
		    	//bad result
		    	if(error.badRequest){
		    		// the server returned an error
		    		//console.log('login bad request');
		    		loginMessage = error.badRequest.non_field_errors;
		    	}
		    	else{
		    		//network error or something else
		    		//console.log('login network error', error);
		    		loginMessage = 'We were unable to log you in at this time. Please try again later.';
		    	}
		    	this.setState({fetchingLogin: false, loginError: loginMessage});
		    });
	}

	inputChanged(value, name, event){
		//console.log('input changed', value, name, event);
		let newState = {};
		newState[name] = value;
		//console.log(newState);
		this.setState(newState)

	}

	logout(){
		this.props.logout();
	}

	render() {


		return (
			<section>
				<h1 id="main-heading" className="modal-title">Login</h1>
				{ this.props.auth.isLoggedIn ? 
				(
					<p>You are already logged in. Would you like to <button className="btn-link" onClick={this.logout}>Logout?</button></p>
				) : (

					<form onSubmit={this.handleSubmit}>
						{ this.props.loginMessage ? ( <div className="form-validation-summary">{this.props.loginMessage}</div>) : null }
						{ this.state.loginError ? ( <div role="alert" className="form-validation-summary">{this.state.loginError}</div>) : null }
						 <Field name="email" label="Email" type="email" isValid={true} required={true} onChange={ this.inputChanged } value={this.state.email} maxLength={60} />
						 <Field name="password" label="Password" type="password" isValid={true} required={true} onChange={ this.inputChanged } value={this.state.password} />
						 <Field name="rememberMe" label="Remember Me" type="checkbox" isValid={true} required={false} onChange={ this.inputChanged } value={this.state.rememberMe} />
						 <input type="submit" value="Login" disabled={this.state.fetchingLogin} />
					</form>
				)}
			</section>
		)
	}
}


function mapStateToProps(state) {
	return { 
        auth: state.auth
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
		login: login,
		logout: logout,
		setAvailableDashboards: setAvailableDashboards
    }, dispatch)    
}


export default connect( mapStateToProps, matchDispatchToProps )( Login ); 
