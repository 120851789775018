import React, { Component } from "react";

import SvgIcon from "./SvgIcon";
import Modal from "./Modal";
import CameraImage from "./CameraImage";


class CameraModal extends Component {

	/*
		Requires Props:

		isVisible = True ? False -- REQUIRED
		-- 	Whether if the modal should be shown

		closeModal = callback method -- REQUIRED
		-- 	Parent compnnets should handling changing isVisiable, 
			this call back tells the parent the close button was clicked

		modalTitle = string -- REQUIRED
		-- 	The title to display at the top of the modal

		cameraImages = [ -- REQUIRED
			{
				imageName: 'Title of the image',
				imageUrl: 'http://urlForTheImage',
			}
			...
		] 
		-- 	A collection of images to display in the modal.
			If more than one is passed user will be able to navigate through gallery

		// the current image position
		initialStart = int -- OPTIONAL


	*/

	constructor(props) {
		super(props);
		this.closeModal = this.closeModal.bind(this);
		this.nextImage = this.nextImage.bind(this);
		this.prevImage = this.prevImage.bind(this);

		this.state ={
			currentImage: ( this.props.initialStart ? this.props.initialStart : 0 )
		}
	}

	closeModal(){
		this.props.closeModal();
	}

	nextImage(){

		let nextImage = this.state.currentImage + 1;
		if( nextImage == this.props.cameraImages.length ){
			nextImage = 0;
		}

		this.setState({currentImage: nextImage});
	}

	prevImage(){
		let prevImage = this.state.currentImage - 1;
		if( prevImage == -1){
			prevImage = this.props.cameraImages.length -1;
		}
		this.setState({currentImage:prevImage});
	}

	render() {

		const curImage = {...this.props.cameraImages[this.state.currentImage]};

		let cameraControls = null;
		let cameraTitle = (
			<div className="modal-camera-image-title">
				{ curImage.imageName } 
				<span> ({this.state.currentImage+1}/{this.props.cameraImages.length})</span>
			</div>
		);

		if( this.props.cameraImages.length > 1 ){
			cameraControls = (
				<div className="modal-camera-controls">
					<div className="modal-camera-left">
						<button
							className="prev-image"
							onClick={ this.nextImage }
						>
							<SvgIcon icon="chev-left" />
						</button>
					</div>

					{ cameraTitle }

					<div className="modal-camera-right">
						<button
							className="next-image"
							onClick={ this.prevImage }
						>
							<SvgIcon icon="chev-right" />
						</button>
					</div>
				</div>
			)
		}
		else{
			cameraControls = <div className="modal-camera-title-only">
				{ cameraTitle}
			</div>
		}


		return (
			<Modal 
				isVisible={ this.props.isVisible }
				contentType={null}
				doCloseModal={ this.closeModal }
				selectedStations={ null }
				modalContent={ 
					<div className="modal-camera">
						<button 
							className="closeModalBtn" 
							onClick={ this.closeModal }
							autoFocus
						>
							<SvgIcon icon="menu-close" />
						</button>

						<h1 id="main-heading" className="modal-title">{this.props.modalTitle}</h1>

						<div className="">
							<div className="modal-camera-image">
								<CameraImage key={this.state.currentImage} image={curImage.imageUrl} />
							</div>
							{cameraControls}
						</div>
			
					</div>
				}
			/>
		)
	}
}



export default CameraModal; 
