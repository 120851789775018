function get_default_state(){

    return {

        rateOfChange: null,
        rateOfChangeLastInput: null,
        currentLLOpening: null,
        currentRadialOpening: null,
        availableStorage: null,
        percentAvailable: null,
        threshholdReached: "",
        expectedLLOutflow: null,
        expectedRadialOutflow: null,
        totalOutflow: null,
        wlValidationError: "",
        llValidationError: "",
        radialValidationError: "",

    }
}

export default function ( 
    state=get_default_state(), action ) {
    switch( action.type ) {

        case "SET_DAM_OPS_CALCULATOR_DATA":
            //console.log(action.payload);    
            state.currentLLOpening = action.payload.currentLLOpening;
            state.currentRadialOpening = action.payload.currentRadialOpening;
            state.rateOfChange = action.payload.rateOfChange;
            state.rateOfChangeLastInput = action.payload.rateOfChangeLastUpdate;
            state.currentWaterLevel = action.payload.currentWaterLevel;
        return{ ...state }

        case "SET_WATER_ELEVATION_DATA":
            //console.log(action.payload);
            state.availableStorage = action.payload.availableVolume; 
            state.percentAvailable = action.payload.percentage + " %"; 
            state.selectedWLInput = action.payload.enteredWaterLevel;
        return{ ...state }

        case "CALCULATE_EXPECTED_OUTFLOW":
            //console.log(action.payload);
            state.expectedLLOutflow = action.payload.expected_outflow.ll_gates.value;
            state.expectedRadialOutflow = action.payload.expected_outflow.radial_gates.value;
            state.totalOutflow = action.payload.total_expected_outflow.value;
        return{ ...state }

        case "DETERMINE_THRESHOLD_REACHED":
            //console.log(action.payload); 
            state.threshholdReached = action.payload.reached_threshold.toString();
        return{ ...state }

        case "DAM_CALCULATOR_WL_VALIDATION_ERROR":
            state.wlValidationError = action.payload;
        return{ ...state }

        case "DAM_CALCULATOR_LL_VALIDATION_ERROR":
            state.llValidationError = action.payload;
        return{ ...state }

        case "DAM_CALCULATOR_RADIAL_VALIDATION_ERROR":
            state.radialValidationError = action.payload;
        return{ ...state }

        default:
            return state;
    }
}