import React from 'react';
import SvgIcon from "../SvgIcon.js";

const DashboardBuildMenuItem = ({ icon, itemType, label, buildMenuItemSelected }) => {
    return( 
        <div className="dashboard-build-menu-item menu-item-wrap menu-item-has-icon">
            <button className="menu-item" onClick={ buildMenuItemSelected } data-itemtype={ itemType }  >
                <SvgIcon icon={ icon } />
                { label }
            </button>    
        </div>
    );
}; 

export default DashboardBuildMenuItem; 