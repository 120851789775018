import React, {Component} from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux";
import { Dropdown } from 'semantic-ui-react';

import Field from "../forms/Field";    
import SimpleDropdown from "../forms/SimpleDropdown";
import RadioButtonSeries from "../dashboard/RadioButtonSeries"
import { getRoute, apiRequest, mapDjangoErrorsToFieldState } from "../../helpers/Api";
import { 
    togglePreloader
} from "../../actions/index";

// Keep in mind that these are the styles from flatpickr package
// See troubleshooting section in case you have problems importing the styles

import "flatpickr/dist/themes/material_blue.css";

import Flatpickr from "react-flatpickr";



function cloneStepState(oldState){

    const newState = [];

    for (var i = 0; i < oldState.length; i++) {
        newState.push({...oldState[i]});
    }

    return newState;

}

function getInitialStepState(){
    return {
        dam_gate: {
            value: null,
            validationMessage: '',
            isValid: true,
        },
        type: {
            value: 'closing',
            validationMessage: '',
            isValid: true,
        },
        datetime: {
            value: new Date(),
            validationMessage: '',
            isValid: true,
        },
        amount: {
            value: '',
            validationMessage: '',
            isValid: true,
        },
        measurement_type: {
            value: 'inches',
            validationMessage: '',
            isValid: true,
        },

    }
}

function getInitiailFormState(){
    return {
        comment: {
            value: '',
            validationMessage: '',
            isValid: true,
        },


        downstream_spillway_inspected:{
            value: false,
            validationMessage: '',
            isValid: true,
        },
        forest_valley_school_notified:{
            value: false,
            validationMessage: '',
            isValid: true,
        }
    }
}

class GateOperationForm extends Component {
    constructor ( props ) {
        super( props ); 

        this.onUnload = this.onUnload.bind(this);
        this.validateAllFields = this.validateAllFields.bind(this);
        this.buildFormData = this.buildFormData.bind(this);
        this.getValidationState = this.getValidationState.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.emergencyGateChanged = this.emergencyGateChanged.bind(this);
        this.stepInputChanged = this.stepInputChanged.bind(this);
        this.removeStep = this.removeStep.bind(this);
        this.addStep = this.addStep.bind(this);
        this.validateAllFields = this.validateAllFields.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.renderStepFieldset = this.renderStepFieldset.bind(this);

        this.state = {
            'formChanged': false,
            'emergencyGateOperation' : false,
            'formValidationError': false,
            'savingForm': false,
            'savingFormError': false,
            'genericFormError': '',
            'formFields': getInitiailFormState(),
            'operationSteps': [
                getInitialStepState()
            ]

        };
    }


    componentDidUpdate(prevProps, prevState){

        let wasPreviousLoading = false;
        let currentlyLoading = false;

        // check if previously was in a loading state
        if( prevState.savingForm === true ){
            wasPreviousLoading = true;
        }

        // check if currently in a loading state
        if( this.state.savingForm === true ){
            currentlyLoading = true;
        }


        if( currentlyLoading === false && wasPreviousLoading === true ){
            //transitioning from loading
            this.props.togglePreloader(false);
        }
        else if ( currentlyLoading === true && wasPreviousLoading === false ){
            //transitioning to loading
            this.props.togglePreloader(true);
        }


    }


    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload)
    }

    onUnload( e ) {
        e.returnValue = "This will discard any changes";
    }



    validateAllFields(){
        let allFieldsValid = true;
        let newState = {};

        const stateKeys = Object.keys(this.state.formFields);

        //build a new state
        for (var i = 0; i < stateKeys.length; i++) {
            let inputState = {...this.state.formFields[ stateKeys[i] ] };
            inputState = this.getValidationState( inputState, stateKeys[i] );
            if( inputState.isValid === false){
                allFieldsValid = false;
            }
            newState[ stateKeys[i] ] = inputState;
        }


        if( allFieldsValid !== true){
            this.setState({ formFields: newState, formValidationError: true, scrollToError: true });
        }
        else{
            this.setState({ formValidationError: false });
        }

        return allFieldsValid;

    }


    buildFormData(){

        var formData = {}

        const stateKeys = Object.keys(this.state.formFields);  
                    
        for (let i = 0; i < stateKeys.length; i++) {
            formData[stateKeys[i]] =  this.state.formFields[ stateKeys[i] ].value;
        }

        const steps = [];

        for (let i = 0; i < this.state.operationSteps.length; i++) {
            const stepKeys = Object.keys(this.state.operationSteps[i]);
            const step = {};
            for (let b = 0; b < stepKeys.length; b++) {
                step[stepKeys[b]] = this.state.operationSteps[i][stepKeys[b]].value;
            }

            if(step.measurement_type === 'feet'){
                step.amount = step.amount * 12;
            }

            if(step.type === 'closing'){
                step.amount = step.amount * -1;
            }

            if(Array.isArray(step.datetime)){
                step.datetime = step.datetime[0];
            }

            if( this.state.emergencyGateOperation && step.type === 'closing'){
                step.amount = -1;
            }
            else if( this.state.emergencyGateOperation && step.type === 'opening'){
                step.amount = 1;
            }

            steps.push(step);
        }

        formData['damgateoperation_set'] = steps;

        formData['station'] = this.props.stationId;

        return formData;

    }




    getValidationState(inputState, name){

    
        switch(name){
            case 'comment':
                if( inputState.value.trim() === ''){
                    inputState.validationMessage = "Please enter a comment";
                    inputState.isValid = false;
                }
                else{
                    inputState.validationMessage = '';
                    inputState.isValid = true;
                }
                break;

            case 'downstream_spillway_inspected':
                inputState.validationMessage = '';
                inputState.isValid = true;
                
                break;

            case 'forest_valley_school_notified':
                inputState.validationMessage = '';
                inputState.isValid = true;
                
                break;

            case 'dam_gate':
                inputState.value = parseInt(inputState.value)
                if( inputState.value === null){
                    inputState.validationMessage = "Please select a gate.";
                    inputState.isValid = false;
                }
                else{
                    inputState.validationMessage = '';
                    inputState.isValid = true;
                }
                break;

            case 'type':
                // type only have valid options
                inputState.validationMessage = '';
                inputState.isValid = true;
                break;
            case 'datetime':
                if( inputState.value === ''){
                    inputState.validationMessage = "Please select an operation time.";
                    inputState.isValid = false;
                }
                else{
                    inputState.validationMessage = '';
                    inputState.isValid = true;
                }
                break;

            case 'amount':

                const parsedValue = parseInt( inputState.value );
                if( this.state.emergencyGateOperation ){
                    inputState.value = '';
                    inputState.validationMessage = '';
                    inputState.isValid = true;
                }
                else if( Number.isNaN(parsedValue) ){
                    inputState.validationMessage = "Please enter whole number.";
                    inputState.isValid = false;
                }
                else if( parsedValue <= 0 ){
                    inputState.validationMessage = "Please enter a number greater than 0.";
                    inputState.isValid = false;
                }
                else{
                    inputState.value = parsedValue;
                    inputState.validationMessage = '';
                    inputState.isValid = true;
                }
                break;

            case 'measurement_type':
                if( inputState.value === null){
                    inputState.validationMessage = "Please select a measurement amount.";
                    inputState.isValid = false;
                }
                else{
                    inputState.validationMessage = '';
                    inputState.isValid = true;
                }
                break;

            default:
                throw new Error(`getValidationState called with an unknown filed name: ${name}`);
                break;
        }
        return inputState;
    }


    inputChanged(value, name, event){ 

        let newState = {};
        const stateKeys = Object.keys(this.state.formFields);

        if( stateKeys.indexOf(name) === -1){
            throw new Error(`inputChanged called with a input name that is not in the state: ${name}`);
        }

        //build a new state
        for (var i = 0; i < stateKeys.length; i++) {
            let inputState;
            if( name === stateKeys[i] ){
                inputState = {...this.state.formFields[ stateKeys[i] ], value: value};
                inputState = this.getValidationState( inputState, name );
            }
            else{
                inputState = {...this.state.formFields[ stateKeys[i] ] };
            }

            newState[ stateKeys[i] ] = inputState;
            
        }

        //console.log('input changed', value, title, event)      
        this.setState({formFields: newState, formChanged: true});
        //window.addEventListener("beforeunload", this.onUnload);
    }

    stepInputChanged(value, name, event){

        // name of inputs must start with their index in the array followed a _ 
        const stepIndex = name.substr(0, name.indexOf('_'))

        // get the name of the input everything after the _
        const inputName = name.substr(name.indexOf('_')+1)
        // get a clone of the current state
        const newState = cloneStepState(this.state.operationSteps);

        // update the new state object with the new value
        newState[stepIndex][inputName].value = value;

        // get the validation for this input
        newState[stepIndex][inputName] = this.getValidationState( newState[stepIndex][inputName], inputName );

        // update the state
        this.setState({operationSteps: newState, formChanged: true});

    }

    emergencyGateChanged(value, name, event){
        this.setState({emergencyGateOperation: value, operationSteps: [getInitialStepState()]});
    }

    removeStep(step){ 
       const newState = cloneStepState(this.state.operationSteps);
        newState.splice(step, 1);
        this.setState({operationSteps: newState});
    }
    
    addStep(){
        const newState = cloneStepState(this.state.operationSteps);
        newState.push( getInitialStepState() );
        this.setState({operationSteps: newState});
    }
 


    validateAllFields(){
        let allFieldsValid = true;
        let newState = {};

        const stateKeys = Object.keys(this.state.formFields);

        //build a new state for generic form keys
        for (let i = 0; i < stateKeys.length; i++) {
            let inputState = {...this.state.formFields[ stateKeys[i] ] };
            inputState = this.getValidationState( inputState, stateKeys[i] );
            if( inputState.isValid === false){
                //console.log('field not valid', stateKeys[i]);
                allFieldsValid = false;
            }
            newState[ stateKeys[i] ] = inputState;
        }


        // build new state for step forms
        // get a clone of the current state
        const newStepState = cloneStepState(this.state.operationSteps);


        for (let i = 0; i < newStepState.length; i++) {
            const stepStateKeys = Object.keys(newStepState[i]);

            for (let b = 0; b < stepStateKeys.length; b++) {
                newStepState[i][stepStateKeys[b]] = this.getValidationState( newStepState[i][stepStateKeys[b]], stepStateKeys[b] );

                if( newStepState[i][stepStateKeys[b]].isValid === false){
                    //console.log('field not valid', stepStateKeys[b]);
                    allFieldsValid = false;
                }
            }
        }

        if( allFieldsValid !== true){
            this.setState({ 
                formFields: newState, 
                operationSteps: newStepState, 
                formValidationError: true
            });
        }
        else{
            this.setState({ 
                formFields: newState, 
                operationSteps: newStepState, 
                formValidationError: false 
            });
        }

        return allFieldsValid;

    }

    submitForm(){

        // bail if the form is not valid
        if( this.validateAllFields() !==  true ){
            //console.log('form not valid');
            return;
        }

        const formData = this.buildFormData(this);

        this.setState({savingForm: true, savingFormError: false});

        apiRequest( getRoute("damOpsLogs"), { 
                method: 'POST',
                body: JSON.stringify(formData)
            })
            .then(result => {
                this.setState({savingForm: false, savingFormError: false});
                this.props.operationLogCreated();
            })
            .catch( (error) => {

                if( error.status === 403 ){
                    this.props.doAuthCheck();
                    return error;
                }

                if( !error.badRequest ){
                    //console.log('generic error');

                    this.setState({
                        savingForm: false,
                        savingFormError: true
                    });
                    return error;
                }

                let errorState = mapDjangoErrorsToFieldState(this.state.formFields, error);
                let newStepState = cloneStepState(this.state.operationSteps);

     
                if(error.badRequest.damgateoperation_set){

                    for (let i = 0; i < newStepState.length; i++) {
                        newStepState[i] = mapDjangoErrorsToFieldState(newStepState[i], {badRequest: error.badRequest.damgateoperation_set[i]}).fields;
                    }
                }
        
                //console.log('error state', errorState, newStepState);


                this.setState({
                    savingForm: false,
                    savingFormError: true,
                    genericFormError: errorState.generalErrorMessages,
                    formFields: errorState.fields,
                    operationSteps: newStepState
                });
                

            })

    }


    renderStepFieldset(){

        const steps = [];

        for (let i = 0; i < this.state.operationSteps.length; i++) {
           // this.state.operationSteps[i];

           // build the gate options
            const gates = [];

            for (let b = 0; b < this.props.gates.length; b++) {

                //console.log(this.state.emergencyGateOperation , this.props.gates[b].emergencyGate);
                const damOption = {
                    id: `${i}_gate_${this.props.gates[b].gateId}`,
                    label: this.props.gates[b].gateName, 
                    value: this.props.gates[b].gateId, 
                    checked: ( this.state.operationSteps[i].dam_gate.value === this.props.gates[b].gateId ? true : false ) 
                };
                if( this.state.emergencyGateOperation === true && this.props.gates[b].emergencyGate === true ){
                    //console.log('adding emergency gate');
                    gates.push(damOption);
                
                }
                else if(this.state.emergencyGateOperation === false && this.props.gates[b].emergencyGate === false){
                    //console.log('addingn non emergency gate');
                    gates.push(damOption);
                }
            }

            //console.log(gates);

            const gatesRadio = (      
                <div className="gates-operated">              
                    <RadioButtonSeries 
                        title="Gates Operated:"
                        groupName={`${i}_dam_gate`}
                        iconLabel={false}
                        options={gates}
                        handleRadioInputChange={this.stepInputChanged}
                        required={true}
                        isValid={this.state.operationSteps[i].dam_gate.isValid}
                        requiredValidationMessage={this.state.operationSteps[i].dam_gate.validationMessage}
                    />
                </div>
            )


            // build the type dropdown
            // Note: labels are reversed here from request from client
            // "Closed/Opening Type is the opposite of what is expected for 
            // Emergency Gate Operation - Closing = In Use and Opening = Not in Use DEC: 
            // Keep the words opening a closing in the dropdowns but they need to be reversed."

            let typeLabels = [ 
                {
                    label: 'Opening',
                    value: 'opening'
                },
                {
                    label: 'Closing',
                    value: 'closing'
                }
            ];

            if( this.state.emergencyGateOperation ){
                typeLabels = [ 
                    {
                        label: 'Closing',
                        value: 'opening'
                    },
                    {
                        label: 'Opening',
                        value: 'closing'
                    }
                ];

            }


            const typeDropdown = (
                <SimpleDropdown
                    label="Type"
                    name={`${i}_type`}
                    required={true}
                    onChange={ this.stepInputChanged }
                    value={this.state.operationSteps[i].type.value}
                    options={typeLabels}
                    isValid={this.state.operationSteps[i].type.isValid}
                    validationMessage={this.state.operationSteps[i].type.validationMessage}

                />
            );

            // add the button controls


            let addButton = null;
            let removeButton = null;
            let amountFields = null;

            if( !this.state.emergencyGateOperation ){

                // there is more than one step, allow for removing steps
                if( this.state.operationSteps.length > 1){
                    removeButton = <button className="btn-link" onClick={()=>this.removeStep(i)}>Remove this step</button>
                }

                // we are on the last step, show the add more steps button
                if( i == this.state.operationSteps.length-1 ){
                    addButton = <button className="btn-link" onClick={()=>this.addStep()}>+ Add another step</button>
                }

                amountFields = (
                    <div className="amount-wrap">
                        <Field 
                            type="number"
                            label="Amount"
                            name={`${i}_amount`}
                            required={true}
                            onChange={ this.stepInputChanged }
                            isValid={this.state.operationSteps[i].amount.isValid}
                            validationMessage={this.state.operationSteps[i].amount.validationMessage}
                            value={this.state.operationSteps[i].amount.value}
                        />
                        <RadioButtonSeries 
                            title="Amount Measurement Type:"
                            groupName={`${i}_measurement_type`}
                            iconLabel={false}
                            options={[
                                {
                                    id: `${i}_amount_feet`,
                                    label: "Feet", 
                                    value: "feet",
                                    checked:( this.state.operationSteps[i].measurement_type.value === 'feet' ? true : false )  
                                },
                                {
                                    id: `${i}_amount_inches`,
                                    label: "Inches", 
                                    value: "inches", 
                                    checked:( this.state.operationSteps[i].measurement_type.value === 'inches' ? true : false ) 
                                }
                            ]}
                            handleRadioInputChange={this.stepInputChanged}
                            required={true}
                            isValid={this.state.operationSteps[i].measurement_type.isValid}
                            requiredValidationMessage={this.state.operationSteps[i].measurement_type.validationMessage}
                        />
                    </div>
                )

            }

       
            // displayTypes = [
            //     {label: "Precipitation Map", value: "precip", id: "precip-map"},
            //     {label: "Standard Station Map", value: "standard", id: "standard-map"},
            // ];

            // for( var j = 0; j < displayTypes.length; j++ ) {
            //     if( displayTypes[j].value === this.state.selectedMapType ) {
            //         displayTypes[j].checked = true;
            //     } else {
            //         displayTypes[j].checked = false;
            //     }
            // }


            steps.push(
                <div className="operation-step-fieldset" key={`step-${i}`}>
                    <fieldset >
                        <legend className="operation-step-legend">Step {i+1} Details:</legend>

                        { gatesRadio }

                        <div className="type-time-wrap">
                            { typeDropdown }
                            <Field 
                                customInput={
                                    <Flatpickr
                                        id={`id_${i}_datetime`}
                                        data-enable-time
                                        value={this.state.operationSteps[i].datetime.value}
                                        onChange={date => {
                                            this.stepInputChanged(date, `${i}_datetime`, null)
                                        }}
                                      />
                                }
                                label="Date/Time"
                                name={`${i}_datetime`}
                                required={true}
                                onChange={ this.stepInputChanged }
                                isValid={this.state.operationSteps[i].datetime.isValid}
                                validationMessage={this.state.operationSteps[i].datetime.validationMessage}
                                value={this.state.operationSteps[i].datetime.value}
                            />
                            
              
                        </div>

                        {amountFields}



                        <div className="step-controls">
                            { addButton }
                            { removeButton }
                        </div>
                    </fieldset>
                </div>
            );
        }

        return steps;
    }




    render() {
        // some additional fields dependant on the dam
        const damMeta = [];

        damMeta.push(
            <Field
                key="downstream_spillway_inspected"
                type="checkbox"
                label="Downstream Spillway Inspected"
                name="downstream_spillway_inspected"
                required={false}
                onChange={ this.inputChanged }
                isValid={this.state.formFields.downstream_spillway_inspected.isValid}
                validationMessage={this.state.formFields.downstream_spillway_inspected.validationMessage}
                value={this.state.formFields.downstream_spillway_inspected.value}
            />
        )

        if( this.props.includeForestValley ){

            damMeta.push(
                <Field 
                    key="forest_valley_school_notified"
                    type="checkbox"
                    label="Forest Valley School Notified"
                    name="forest_valley_school_notified"
                    required={false}
                    onChange={ this.inputChanged }
                    isValid={this.state.formFields.forest_valley_school_notified.isValid}
                    validationMessage={this.state.formFields.forest_valley_school_notified.validationMessage}
                    value={this.state.formFields.forest_valley_school_notified.value}
                />
            );
        }


        

        const stepFieldset = this.renderStepFieldset();      

        let emergencyGateToggle = null;
        if( this.props.canUseEmergencyGates ){
            emergencyGateToggle = (
                <Field 
                    type="checkbox"
                    label="Emergency Gate Operation"
                    name="emergency_gate_operation"
                    required={false}
                    onChange={ this.emergencyGateChanged }
                    isValid={ true }
                    validationMessage={this.state.formFields.comment.validationMessage}
                    value={ this.state.emergencyGateOperation }
                />
            )

        }

        const generalErorr = ( this.state.genericFormError ? <div className="form-field form-field-validation-error"><div className="validation-message">{this.state.genericFormError}</div></div> : null );

        const form = (
            <div className="gate-operation-form">

                {generalErorr}

                {emergencyGateToggle }

                <div className="step-fieldsets">
                    { stepFieldset }
                </div>

                <p>Include comments such as Dam Operator, expected outflows, abnormal conditions at the dam, etc…</p>
                <Field 
                    type="textarea"
                    label="Comment"
                    name="comment"
                    required={true}
                    onChange={ this.inputChanged }
                    isValid={this.state.formFields.comment.isValid}
                    validationMessage={this.state.formFields.comment.validationMessage}
                    value={this.state.formFields.comment.value}
                />


                { damMeta }

                {generalErorr}


                <button className="gate-operation-form-submit" onClick={this.submitForm}>Submit Operation</button>

            </div>

        );

        

        return form;

    }
}


function mapStateToProps(state) {
    return { 
        handleTogglePreloader: state.togglePreloader,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        togglePreloader: togglePreloader,
    }, dispatch)    
}

export default connect( mapStateToProps, matchDispatchToProps )( GateOperationForm ) 
