import React, { Component } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import RadioButtonSeries from "./dashboard/RadioButtonSeries";
import Field from "./forms/Field";
import { getRoute, apiRequest } from "../helpers/Api";

class FeedbackWidget extends Component {


    constructor(props) {
        super(props);

        this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
        this.handleFeedbackSubmit = this.handleFeedbackSubmit.bind(this);
        this.onTextAreaChange = this.onTextAreaChange.bind(this);
        this.onRecaptchaChange = this.onRecaptchaChange.bind(this);

        this.state = {
            radioValid: true,
            textFieldValid: true,
            radioInput: null,
            textInput: null,
            formValid: null,
            captchaResponse: null,
            captchaValid: true,
            FeedbackSentSucessfully: false,
        }
    }

    componentDidMount(){

    }

    handleRadioInputChange( value, name, event ) {
        this.setState({radioInput: value, radioValid:true});
    }

    handleFeedbackSubmit(event) {
        event.preventDefault();
        if( this.state.radioInput ) {
            this.setState({ radioValid: true });
        } else {
            this.setState({ radioValid: false });
        }

        if( this.state.textInput && this.state.textInput !== "" ) {
            this.setState({ textFieldValid: true });
        } else {
            this.setState({ textFieldValid: false });
        }

        if( this.state.captchaResponse ) {
            this.setState({ captchaValid: true });
        }

        if( this.state.radioInput && this.state.textInput ) {
            //console.log("form is valid");
            var currentPath = this.props.location;
            //console.log("current path: "+ this.props.location);

            let feedback = {
                was_helpful: true,
                comment: this.state.textInput,
                recaptcha_response: this.state.catchaResponse,
                path: currentPath
            };

            let json = JSON.stringify(feedback);

            //console.log( json );

            apiRequest(getRoute('feedback'), {
                method: 'POST',
                body: json
              })
              .then(theResult => {
                   //console.log('feedback!', theResult);
                   this.setState({ FeedbackSentSucessfully: true });
              })
              .catch(error => {
                  //console.log( 'feedback Error', error );
            });

        }
    }

    onTextAreaChange( value ) {
        //console.log( value );
        if( value === "" ) {
            this.setState({textInput: value, textFieldValid:false});
        } else {
            this.setState({textInput: value, textFieldValid:true});
        }
    }

    onRecaptchaChange(response) {
        //console.log(response);
        this.setState({ catchaResponse: response, captchaValid: true });
    }

    render() {

        let displayTypes = [
            {label: "Yes", value: "true", id: "yes-btn"},
            {label: "No", value: "false", id: "no-btn"},
        ];

        let feedbackContent = null;

        if( this.state.FeedbackSentSucessfully ) {
            feedbackContent = 
            <div>
                <h3>Thank you!</h3>
                <p>We appreciate your feedback.</p>
            </div>
        } else {
            feedbackContent = 
            <div>
                <h3>Provide Feedback</h3>
                <form onSubmit={this.handleFeedbackSubmit} >
                    <RadioButtonSeries 
                        title="Was this page helpful?"
                        groupName="display-type"
                        options={displayTypes}
                        handleRadioInputChange={this.handleRadioInputChange}
                        required={true}
                        isValid={this.state.radioValid}
                        requiredValidationMessage="Please make a selection"
                    />

                    <fieldset>
                        <Field   
                            type="textarea"
                            label="Why"  
                            name="label"
                            required={true}
                            onChange={ this.onTextAreaChange }
                            isValid={this.state.textFieldValid}
                            value={ this.state.textInput }
                            validationMessage="Please add a response"
                        />
                    </fieldset>

                    <ReCAPTCHA
                        ref="recaptcha"
                        sitekey="6LceLTIUAAAAAAl1g5kwfX2GEd-a6tyB5TrjEUhz"
                        onChange={this.onRecaptchaChange}
                    />
                    <span style={{color: "#db0303", display: (this.state.captchaValid)? "none" : "block"}}>Please verify you are a human</span>
                    
                    <input className="report-btn" type="submit" name="Submit" value="Submit" />
                </form>
            </div>
        }

        return(
            <div className="feedback-widget-container">
                { feedbackContent }
            </div>
        )
    }
};

export default FeedbackWidget;