import React, { Component } from "react";

class SlideoutPanel extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            scrolling: false,
            yPos: null,
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        if( this.props.pageRef !== "gaugeMap" ) {
            window.addEventListener('scroll', this.handleScroll);
            this.setState({ yPos: 300 });
        }
    }

    componentWillUnmount() {
        if( this.props.pageRef !== "gaugeMap" ) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll(event) {
        if( window.scrollY >= 200 && window.scrollY <=  document.body.scrollHeight - 1300 ) {
            this.setState({yPos: window.scrollY + 180});
        }
    }
    
    render() {
        //console.log(this.props);
        let panelButtonSlideoutCss = this.props.doSlideoutPanel ? 
        "slideout-button slideout-open" : "slideout-button slideout-closed";
    
        let panelSlideoutCss = this.props.doSlideoutPanel ? 
        "slideout-panel slideout-open" : "slideout-panel slideout-closed";
        
        return (
            <div className= { panelSlideoutCss }>
                <div className="slideout-content">
                    { this.props.menuData }        
                </div>      
                <div className="slideout-controls">
                    <button 
                        className= { panelButtonSlideoutCss } 
                        onClick={ this.props.onSlideoutClick }
                        
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-chev-down slideout-closed-el">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chev-down"></use>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-chev-up slideout-opened-el">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chev-up"></use>
                        </svg>
                        <svg style={{position:"absolute", top:this.state.yPos, right: "10px"}} xmlns="http://www.w3.org/2000/svg" className="icon icon-chev-right slideout-opened-el slideout-desktop-el">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chev-right"></use>
                        </svg>
                        <svg style={{position:"absolute", top:this.state.yPos, right: "10px"}} id="icon-chev" xmlns="http://www.w3.org/2000/svg" className="icon icon-chev-left slideout-closed-el slideout-desktop-el">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-chev-left"></use>
                        </svg>
                        <span className="screen-reader-text slideout-closed-text">Open Map Controls</span>
                        <span className="screen-reader-text slideout-open-text">Close Map Controls</span>
                    </button>                    
                </div>
            </div>
        );
    }
};

    export default SlideoutPanel;

 