import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';
import RadioButtonSeries from "./RadioButtonSeries";
import Field from "../forms/Field";
import { getRoute, apiRequest } from "../../helpers/Api";
import WidgetSizeLabel from "./WidgetSizeLabel";
import WidgetModalSaveBtn from "./WidgetModalSaveBtn";

class DashboardStreamModalContent extends Component {
    constructor ( props ) {
        super( props );  

        this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
        this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);
        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);
        this.getStreamContent = this.getStreamContent.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.fetchStreamData = this.fetchStreamData.bind(this);
        this.updateModalOptions = this.updateModalOptions.bind(this);

        if( props.editModeData ){
            this.state = {
                station: props.editModeData.station,
                timeframe: props.editModeData.options.timeFrame,
                widgetSize: props.editModeData.options.size,
                stationValid: true,
                timeframeValid: true,
                sizeValid: true,
                stationData: null,
                defaultDropdownSelection: null,
                defaultDisplayType: null,
                displayTypeSelected: null,
                dischargeSelected: props.editModeData.dischargeSelected,
                dischargeID: null,
                wlID: props.editModeData.options.gaugeId,
                selectedGaugeID: null,
            };
        } else {
            this.state = {
                station: null,
                timeframe: null,
                widgetSize: null,
                dischargeSelected: false,
                stationValid: true,
                timeframeValid: true,
                sizeValid: true,
                stationData: null,
                dischargeID: null,
                wlID: null,
                selectedGaugeID: null,
            };
        }
    }

    componentDidMount() { this.fetchStreamData() }


    updateModalOptions( data ) {
        let dischargeID = null;
        let wlID = null;

        for( var i = 0; i < this.state.stationData.length; i++ ) {
            if( data.value === this.state.stationData[i].station_name) {
                for( var j = 0; j < this.state.stationData[i].gauge.length; j++ ) {
                    switch( this.state.stationData[i].gauge[j].sensor_name ) {
                        case "DISCHARGE":
                        dischargeID = this.state.stationData[i].gauge[j].id
                        break;
                        case "WL":
                        wlID = this.state.stationData[i].gauge[j].id
                        break;
                        default:
                        console.log( "An unknown sensor name was used" );
                        break;
                    }
                }
            }
        }

        let dischargeSelected = false;
        if( dischargeID !== null && this.state.dischargeSelected ) {
            dischargeSelected = true;
        }

        this.setState({ 
            station: data.value,
            stationValid: true,
            dischargeID: dischargeID,
            wlID: wlID,
            dischargeSelected: dischargeSelected,
        });
    }

    handleRadioInputChange( value, name, event ) {
        switch( name ) {
            case "timeframe":
            this.setState({timeframe: value, timeframeValid: true });
            break;

            case "widget-size":
            this.setState({widgetSize: value, sizeValid: true});
            break;

            default:
            console.log( "An unknown widget size was selected" );
            break;
        }
    }

    handleCheckboxInputChange(value, name, event) {
        this.setState({ dischargeSelected: value });
    }

    dropdownSelectionChanged(e, data) {
        this.updateModalOptions(data);
    }

    addWidgetToDashboard() {
        let isFormValid = this.validateForm();
        let widgetSize = null;

        switch( this.state.widgetSize ) {
            case "large-widget":
            widgetSize = "large";
            break;
            case "medium-widget":
            widgetSize = "medium";
            break;
            case "small-widget":
            widgetSize = "small";
            break;
            case "small":
            widgetSize = "small";
            break;
            case "medium":
            widgetSize = "medium";
            break;
            case "large":
            widgetSize = "large";
            break;

            default:
            console.log( "An unknown widget size was selected" );
            break;
        }

        if( isFormValid ) {
            let widgetToUpdate = null;
            let selectedGaugeId = this.state.wlID;

            if( this.props.editModeData  ) {
                if( this.props.editModeData.id ) {
                    widgetToUpdate = this.props.editModeData.id;
                }
            }

            let dischargeSelected = false;

            if( this.state.dischargeSelected ) {
                selectedGaugeId = this.state.dischargeID;
                dischargeSelected = true
            }

            let widgetObj = {
                type: "time-series",
                order: "TODO",
                dischargeSelected: dischargeSelected,
                widgetToUpdate: widgetToUpdate,
                widgetType:"stream",
                station: this.state.station,
                options: {
                    size: widgetSize,
                    timeFrame: this.state.timeframe,
                    gaugeId: Number(selectedGaugeId)
                }
            };
            this.props.addWidgetToScreen( widgetObj );

        } else {
            //console.log("sorry, try again");
        }
    }

    validateForm() {
        let formValid = true;
        if(!this.state.station) {
            formValid = false;
            this.setState({ stationValid: false });
        } else {
            this.setState({ stationValid: true });
        }

        if(!this.state.timeframe) {
            formValid = false;
            this.setState({ timeframeValid: false });
        } else {
            this.setState({ timeframeValid: true });
        }

        if(!this.state.widgetSize) {
            formValid = false;
            this.setState({ sizeValid: false });
        } else {
            this.setState({ sizeValid: true });
        }

        return formValid;
    }

    getStreamContent() {
        let data = this.state.stationData;

        //setup stream widget components options for modal
        let timeframes = [
            {label: "15m", value: "15m", id: "15m"},
            {label: "30m", value: "30m", id: "30m"},
            {label: "60m", value: "60m", id: "60m"},
            {label: "2h", value: "2h", id: "2h"},
            {label: "6h", value: "6h", id: "6h"},
            {label: "12h", value: "12h", id: "12h"},
            {label: "24h", value: "24h", id: "24h"},
            {label: "48h", value: "48h", id: "48h"},
            {label: "72h", value: "72h", id: "72h"}
        ];

        for( var i = 0; i < timeframes.length; i++ ) {
            if( timeframes[i].id === this.state.timeframe ) {
                timeframes[i].checked = true;
            } else {
                timeframes[i].checked = false;
            }
        }

        let displaySizes = [
            {label: <WidgetSizeLabel size="small" label="Small"/>, value: "small", id: "small"},
            {label: <WidgetSizeLabel size="medium" label="Medium"/>, value: "medium", id: "medium"},
        ];

        for( var j = 0; j < displaySizes.length; j++ ) {
            if( displaySizes[j].id === this.state.widgetSize ) {
                displaySizes[j].checked = true;
            } else {
                displaySizes[j].checked = false;
            }
        }

        let stationList= [];
        let dischargeFieldset = "";

        //build search dropdown list 
        for( let i = 0; i < data.length; i++ ) {
            var obj = {
                key: "station-"+i,
                value: data[i].station_name,
                text: data[i].station_name.toLowerCase(),
            }
            stationList.push( obj );

            if(this.state.station === data[i].station_name) {
                for( let j = 0; j < data[i].gauge.length; j++ ) {
                    if( data[i].gauge[j].sensor_name === "DISCHARGE" ) {
                        dischargeFieldset = 
                        <fieldset className="show-discharge-fieldset">
                            <Field 
                                type="checkbox"
                                label="Show  discharge"
                                name="show-discharge"
                                required={false}
                                defaultChecked={ this.state.dischargeSelected }
                                onChange={ this.handleCheckboxInputChange }
                            />
                        </fieldset>
                    } 
                }
            }
        }

        let dropdownValidationClass =  this.state.stationValid ? "" : "dropdown-validation-error";

        //add stream widget components to modal
        let streamContent = 
        <div >
            <h2>{ ( this.props.editModeData ? `EDIT` : `ADD A` ) } STREAM GAUGE</h2> 
            <div className={"station-dropdown-container "+ dropdownValidationClass}>
                <Dropdown 
                    id="station-dropdown"
                    className={"all-stations " + dropdownValidationClass} 
                    placeholder='Select Station' 
                    fluid 
                    search 
                    selection 
                    options={stationList} 
                    onChange={ this.dropdownSelectionChanged }
                    defaultValue={ this.state.station }
                    searchInput={{ autoFocus: true }}
                />
                <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a station</span>
            </div>   

            <RadioButtonSeries 
                title="Select a timeframe:" 
                groupName="timeframe"
                options={ timeframes }
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.timeframeValid}
                requiredValidationMessage="Please select a timeframe"
            />
            
            { dischargeFieldset }

            <RadioButtonSeries 
                title="Select a size:"
                groupName="widget-size"
                iconLabel={true}
                options={displaySizes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.sizeValid}
                requiredValidationMessage="Please select a display size"
            />
        </div>

        return streamContent;
    }

    fetchStreamData() {
        let url = getRoute("stationAndGaugeNames", [], { type: "STREAM" });
        
        this.props.handleTogglePreloader(true);
        apiRequest(url)
		.then(result => {
            this.props.handleTogglePreloader(false);
            this.props.handleOpenModal();
            this.setState({ stationData: result });

            if( this.state.defaultDropdownSelection ) {
                //console.log(this.state.defaultDropdownSelection);
                var data = { value: this.state.defaultDropdownSelection };
                this.updateModalOptions( data );
            } 
        })
        .catch( (error) => {
            //console.log( 'Error', error );
            this.props.togglePreloader(false); 
            
            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
    }

    render() {
        let streamContent = null;

        if( this.state.stationData ) {
            streamContent = this.getStreamContent();
        }

        return(
            <div className="dashboard-add-widget-modal-content">
                { streamContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addWidgetToDashboard } />
            </div>
        );
    }
}

export default DashboardStreamModalContent;