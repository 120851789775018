import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';
import WidgetModalSaveBtn from "./dashboard/WidgetModalSaveBtn";
import WidgetSizeLabel from "./dashboard/WidgetSizeLabel";
import RadioButtonSeries from "./dashboard/RadioButtonSeries";

class SaveChartToDashboardModal extends Component {
    constructor ( props ) {
        super( props ); 

        this.addChartToDashboard = this.addChartToDashboard.bind(this);
        this.getNameContent = this.getNameContent.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);
        this.focusDiv = this.focusDiv.bind(this); 

        this.state = {
            size: null,
            sizeValid: true,
            dashboard: '',
            dashboardValid: true,
        };
    } 

    componentDidMount() {
        this.focusDiv(); 
    }

    focusDiv() {
        var _this = this;
        setTimeout(function(){ _this.refs.theDiv.focus();  },1000);  
    }
    
    addChartToDashboard() {
        let isFormValid = this.validateForm(); 

        if( isFormValid ) {
            //console.log( this.props );
            //console.log( this.state );
            let currentDashboardId = null;

            for( var i=0; i < this.props.dashboards.length; i++ ) {
                if( this.state.dashboard === this.props.dashboards[i].name ) {
                    currentDashboardId = this.props.dashboards[i].id;
                }
            }
            
            let widgetObj = {
                type: "time-series",
                order: "TODO",
                reportVisualzation: true,
                dischargeSelected: false,
                widgetType:this.props.chartData.gaugeType.toLowerCase(),
                station: this.props.chartData.stationName,
                options: {
                    size: this.state.size,
                    timeFrame: {
                        from: this.props.dateRange.from,
                        to: this.props.dateRange.to,
                    },
                    gaugeId: Number(this.props.chartData.gaugeId),
                    isReportVisualization: true,
                    dashboardToAdd: this.state.dashboard,
                    dashboardId: currentDashboardId, 
                    showAccumulatedTotal: this.props.chartData.showAccumulated, 
                }
            };

            this.props.addChartToDashboard( widgetObj );

        } else {
            //console.log("sorry, try again");
        }
    }

    validateForm() {
        let formValid = true;
        
        if(!this.state.size) {
            formValid = false;
            this.setState({ sizeValid: false });
        } else {
            this.setState({ sizeValid: true });
        }

        if( !this.state.dashboard.length > 0 ) {
            this.setState({ dashboardValid: false });
        } else {
            this.setState({ dashboardValid: true });
        }

        return formValid;
    }

    getNameContent() {

        let dashboards = [];

        for( var i = 0; i < this.props.dashboards.length; i++ ) {
            var obj = {
                key: "dashboard-"+i,
                value: this.props.dashboards[i].name,
                text: this.props.dashboards[i].name,
            }

            dashboards.push( obj );
        }

        let displaySizes = [
            {label: <WidgetSizeLabel size="small" label="Small"/>, value: "small", id: "small"},
            {label: <WidgetSizeLabel size="medium" label="Medium"/>, value: "medium", id: "medium"},
        ];

        let displaySizesRadioButtons = 
            <RadioButtonSeries 
                title="Select a size:" 
                groupName="widget-size"
                iconLabel={true}
                options={displaySizes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.sizeValid}
                requiredValidationMessage="Please select a display size"
            />

        //console.log(this.props);

        let dropdownValidationClass =  this.state.dashboardValid ? "" : "dropdown-validation-error";

        let LinkContent = 
        <div ref="theDiv" tabIndex={0}>
            <h2>Saving:</h2>
            <p>{ this.props.chartData.gaugeType + " for "+this.props.chartData.stationName }</p>
            <p>{ "FROM " + this.props.dateRange.from + " TO " + this.props.dateRange.to }</p>
            
            <fieldset>
                <div className={"available-dashboards-container "+ dropdownValidationClass }>
                    <label>SELECT DASHBOARD</label>
                    <Dropdown 
                        id="available-dashboards"
                        className={"dashboard-list-dropdown "+ dropdownValidationClass} 
                        placeholder='Dashboard' 
                        fluid 
                        search 
                        selection 
                        options={dashboards} 
                        onChange={ this.dropdownSelectionChanged }
                        defaultValue={ this.state.station }
                        //searchInput={{ autoFocus: true }}
                    />
                    <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a station</span>
                </div>    
                <div className={"available-dashboards-container "+ dropdownValidationClass }>
                    { displaySizesRadioButtons }
                </div>
            </fieldset>
        </div>

        return LinkContent;
    }

    handleInputChange(value, name, event) {
        if(value.length > 0) {
            this.setState({ name: value, nameValid: true });
        } else {
            this.setState({ name: value, nameValid: false });
        } 
    }

    handleRadioInputChange( value, name, event, btnID ) {
        this.setState({size: value, sizeValid: true});
    }

    dropdownSelectionChanged(e, data) {
        this.setState({ 
            dashboard: data.value,
            dashboardValid: true,
        });
    }

    render() {
        let nameContent = this.getNameContent();

        return(
            <div className="save-visualization-content">
                { nameContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addChartToDashboard } />
            </div>
        );
    }
}

export default SaveChartToDashboardModal; 