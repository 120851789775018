import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getRoute, apiRequest } from "../helpers/Api";

import { 
    setDamOpsCalculatorData,
    setWaterElevationData,
    calculateExpectedOutflow,
    determineReachedThreshold,
    setDamCalculatorWaterLevelValidationError,
    setDamCalculatorDesiredLLValidationError,
    setDamCalculatorDesiredRadialValidationError
} from "../actions/index";


class DamCalculator extends Component {
    constructor ( props ) {
        super( props ); 

        this.waterElevationInputValue = null;
        this.llGateInput = "";
        this.radialGateInput = "";

        this.state = {
            outflowErrors: [],
        };

        this.handleWaterElevationValueChanged = this.handleWaterElevationValueChanged.bind(this);
        this.calculateWaterElevation = this.calculateWaterElevation.bind(this);
        this.calculateWaterElevationFetch = this.calculateWaterElevationFetch.bind(this);
        this.handleLLGateInputChanged = this.handleLLGateInputChanged.bind(this);
        this.handleRadialGateInputChanged = this.handleRadialGateInputChanged.bind(this);
        this.calculateExpectedOutflow = this.calculateExpectedOutflow.bind(this);
        this.calculateExpectedOutflowFetch = this.calculateExpectedOutflowFetch.bind(this)
        this.determineReachedThreshold = this.determineReachedThreshold.bind(this);
        this.loadDamCalculatorData = this.loadDamCalculatorData.bind(this);
        this.numberWithCommas = this.numberWithCommas.bind(this);
    }

    componentDidMount() {
        this.loadDamCalculatorData();

        this.elevationLevel.focus(); 
    }

    handleWaterElevationValueChanged(e) {
        this.waterElevationInputValue = e.target.value;
    }

    calculateWaterElevation() {
        if( this.waterElevationInputValue >= 166.2 && this.waterElevationInputValue <= 183.5 ) {
            this.calculateWaterElevationFetch( this.waterElevationInputValue );    
            this.props.setDamCalculatorWaterLevelValidationError("");

            let obj = {
                operations_level: "",
                reached_threshold: ""
            };

            this.props.determineReachedThreshold(obj); 
        } else {
            this.props.setDamCalculatorWaterLevelValidationError("Water elevation must be between 166.2 and 183.5 meters");

            if( isNaN(this.waterElevationInputValue ) ) {
                this.props.setDamCalculatorWaterLevelValidationError("Input must be a number");           
            }
        }
    }

    handleLLGateInputChanged(e) {
        this.llGateInput = e.target.value;
    }

    handleRadialGateInputChanged(e) {
        this.radialGateInput = e.target.value;
    }

    calculateExpectedOutflow() {
        let validInputs = true;

        if( this.llGateInput === "" || this.llGateInput > 78  ) {
            this.props.setDamCalculatorDesiredLLValidationError("Gate opening cannot exceed 78in.");           
            validInputs = false;
        } else {
            this.props.setDamCalculatorDesiredLLValidationError("");           
        }

        if( isNaN( this.llGateInput ) ) {
            this.props.setDamCalculatorDesiredLLValidationError("input must be a number");               
            validInputs = false;
        }

        if( this.radialGateInput === "" || this.radialGateInput > 18 ) {
            this.props.setDamCalculatorDesiredRadialValidationError("Gate opening cannot exceed 18ft.");           
            validInputs = false;
        } else {
            this.props.setDamCalculatorDesiredRadialValidationError("");           
        }

        if( isNaN( this.radialGateInput ) ) {
            this.props.setDamCalculatorDesiredRadialValidationError("input must be a number");               
            validInputs = false;
        }

        if( validInputs ) {
            this.props.setDamCalculatorDesiredLLValidationError("");           
            this.props.setDamCalculatorDesiredRadialValidationError("");           

            var obj = {
                water_level_in_metres: this.props.damCalculatorData.selectedWLInput,
                current_ll_gate_opening_in_inches: this.props.damCalculatorData.currentLLOpening,
                current_radial_gate_opening_in_feet: this.props.damCalculatorData.currentRadialOpening,
                desired_ll_gate_opening_in_inches: this.llGateInput,
                desired_radial_gates_in_feet: this.radialGateInput
            };
    
            this.calculateExpectedOutflowFetch( obj );
        }
    }



    loadDamCalculatorData() {

        apiRequest(getRoute( "damOpsStatusInfo" ))
            .then(result => {
                let changeRate = new Date(result.rate_of_change_last_updated_at);
                 var obj = {
                    rateOfChange: result.rate_of_change,
                    rateOfChangeLastUpdate: changeRate.toString(),
                    currentWaterLevel: result.water_level, 
                    currentLLOpening: result.current_ll_gates_opening,
                    currentRadialOpening: result.current_radial_gates_opening
                };

                this.props.setDamOpsCalculatorData( obj );
            });

    }


    calculateExpectedOutflowFetch(obj) {

        //console.log(obj);

        apiRequest(getRoute( "damOpsOutflowDischarge" ),{
                method: 'POST',
                body: JSON.stringify(obj)
            })
            .then(results => {
                //console.log(results);
                this.setState({ outflowErrors: results.errors });
                this.props.calculateExpectedOutflow( results );
            });

    }


    calculateWaterElevationFetch(input) {
        var data = {"water_level_in_metres":input};

        apiRequest(getRoute( "damOpsAvailableStorage" ),{
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(result => {

                var obj = {
                    availableVolume: result.available_volume,
                    percentage: result.percentage,
                    volume: result.volume,
                    enteredWaterLevel: input,
                };

                this.props.setWaterElevationData( obj );
                
                if( this.props.damCalculatorData.expectedLLOutflow !== null ) {
                    setTimeout(this.calculateExpectedOutflow(), 2000);    
                }
            });
    }



    determineReachedThreshold( obj ) {
        apiRequest(getRoute( "damOpsOperationLevel" ),{
                method: 'POST',
                body: JSON.stringify(obj)
            })
            .then(results => {
                this.props.determineReachedThreshold(results); 
            });

    }

    numberWithCommas(num) {
        let newNum = Math.round(num * 100) / 100;
        let newNewNum = newNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return newNewNum;
    }

    render() {
        
        if( this.waterElevationInputValue && this.props.damCalculatorData.rateOfChange !== null && this.props.damCalculatorData.threshholdReached === "" ) {
            var obj ={
                water_level_in_metres: this.waterElevationInputValue,
                rate_of_change: this.props.damCalculatorData.rateOfChange
            };

            this.determineReachedThreshold(obj);
        }

        let expectedOutflowBtnCss = "calculate-expected-outflow-btn disabled";
        let expectedOutflowEvent = null;
        let availableStorage = null;
        if( this.props.damCalculatorData.availableStorage) {

            expectedOutflowBtnCss = (this.props.damCalculatorData.availableStorage) ? "calculate-expected-outflow-btn" : "calculate-expected-outflow-btn disabled"; 
            expectedOutflowEvent = (this.props.damCalculatorData.availableStorage) ? this.calculateExpectedOutflow : null;
            //console.log(expectedOutflowEvent);
            availableStorage = this.numberWithCommas( this.props.damCalculatorData.availableStorage );
        }

        let waterElevationInputCss = "water-elevation-input";        
        if( this.props.damCalculatorData.wlValidationError !== "" ) {
            waterElevationInputCss = "water-elevation-input invalid"
        }

        let llOpeningInputCss = "desired-ll-gate-opening-input";        
        if( this.props.damCalculatorData.llValidationError !== "" ) {
            llOpeningInputCss = "desired-ll-gate-opening-input invalid";
        }

        let radialOpeningInputCss = "desired-radial-gate-opening-input";        
        if( this.props.damCalculatorData.radialValidationError !== "" ) {
            radialOpeningInputCss = "desired-radial-gate-opening-input invalid";
        }
        
        let rateOfChange = "";
        
        if( this.props.damCalculatorData.rateOfChange !== null ) {
            rateOfChange = (this.props.damCalculatorData.rateOfChange !== null) ? this.props.damCalculatorData.rateOfChange + " as of" : "";
        }

        let reachedThreshold = null;
        if( this.props.damCalculatorData.selectedWLInput && this.props.damCalculatorData.currentWaterLevel ) {
            if( this.props.damCalculatorData.selectedWLInput.toString() === this.props.damCalculatorData.currentWaterLevel.toString() ) {
                reachedThreshold = this.props.damCalculatorData.threshholdReached;
            } else {
                reachedThreshold = <span className="reached-threshold-warning"> Available only if current water elevation is calculated</span>
            }
        } else {
            reachedThreshold = reachedThreshold = <span className="reached-threshold-warning"> Available only if current water elevation is calculated</span>;
        }

        let outflowErrors = null;
        let errors = null;
        if( this.state.outflowErrors.length ) {
            errors = this.state.outflowErrors.map( (error,index) =>{
                return <li key={index}>{error}</li>
            });

            outflowErrors = <ul>{errors}</ul>
        }

        return(
            <div className="dam-calculator">
                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor="water-elevation-input">
                            <strong>Enter water elevation (m):</strong>
                            <input 
                                className={ waterElevationInputCss }
                                id="water-elevation-input"
                                type="input" 
                                name="water-elevation-input" 
                                placeholder="must be between 166.2m & 183.5m"
                                onChange={ this.handleWaterElevationValueChanged }
                                ref={(input) => { this.elevationLevel = input; }} 
                            />           
                        </label>
                        
                        <button
                            className="calculate-water-elevation-btn"
                            onClick={ this.calculateWaterElevation }
                        >
                            Calculate
                        </button>
                        <span className="error-msg elevation-input">{ this.props.damCalculatorData.wlValidationError }</span> 
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12" >
                        <div className="rate-of-change-box">
                        <span className="return-data-label"><strong>Current water elevation: </strong>{ this.props.damCalculatorData.currentWaterLevel }</span>
                            <span className="return-data-label gap-top"><strong>Current rate of change: </strong>{ rateOfChange } { this.props.damCalculatorData.rateOfChangeLastInput }</span>
                            <span className="return-data-label gap-top"><strong>Reached threshold: </strong> { reachedThreshold }</span>
                        </div>
                        <div>
                            <span className="return-data-label inline"><strong>Available Storage: </strong>{ availableStorage } m<sup>3</sup></span>
                            <span className="return-data-label inline"><strong>% available: </strong> { this.props.damCalculatorData.percentAvailable }</span>
                        </div>
                    </div>

                    <div className="gate-opening-col" >
                        <span className="return-data-label"><strong>Current LL gate opening: </strong>{ this.props.damCalculatorData.currentLLOpening } in.</span>
                    </div>
                    <div className="gate-opening-col" >
                        <span className="return-data-label"><strong>Current radial gate opening: </strong>{ this.props.damCalculatorData.currentRadialOpening } ft.</span>
                    </div>

                    <div className="gate-opening-col" >
                        <label className="gate-opening-label" htmlFor="desired-ll-gate-opening-input">
                            <strong>Enter desired LL gate opening (in.):</strong>
                            <input 
                                className={ llOpeningInputCss }
                                id="desired-ll-gate-opening-input"
                                type="input" 
                                name="desired-ll-gate-opening-input" 
                                onChange={ this.handleLLGateInputChanged }
                                placeholder="must not exceed 78 inches"
                            />                
                        </label>

                        <span className="error-msg elevation-input">{ this.props.damCalculatorData.llValidationError }</span> 
                    </div>
                    
                    <div className="gate-opening-col" >
                        
                        <label className="gate-opening-label" htmlFor="desired-radial-gate-opening-input">
                            <strong>Enter desired radial gate opening (ft.):</strong>
                            <input 
                                className={ radialOpeningInputCss }
                                id="desired-radial-gate-opening-input"
                                type="input" 
                                name="desired-radial-gate-opening-input" 
                                onChange={ this.handleRadialGateInputChanged }
                                placeholder="must not exceed 18 feet"
                            />                
                        </label>
                        <button
                            className={expectedOutflowBtnCss}
                            onClick={ expectedOutflowEvent }
                        >
                            update
                        </button>
                        <span className="error-msg elevation-input">{ this.props.damCalculatorData.radialValidationError }</span>
                    </div>
                </div>
                <div className="error-msg elevation-input" style={{display:"block", width: "100%"}}>{ outflowErrors }</div>
                <div className="row">
                    <div className="gate-opening-col" >
                        <span className="return-data-label"><strong>Expected outflow LL gate: </strong>{ this.props.damCalculatorData.expectedLLOutflow } m<sup>3</sup>/s</span>
                    </div>
                    <div className="gate-opening-col">
                        <span className="return-data-label"><strong>Expected outflow radial: </strong>{ this.props.damCalculatorData.expectedRadialOutflow } m<sup>3</sup>/s</span>
                    </div>
                    <div className="gate-opening-col" >
                        <span className="return-data-label gap-top"><strong>Total expected outflow: </strong>{ this.props.damCalculatorData.totalOutflow } m<sup>3</sup>/s</span>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    //console.log(state);
    return { 
        damCalculatorData: state.damCalculator
    }
}
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        setDamOpsCalculatorData: setDamOpsCalculatorData,
        setWaterElevationData: setWaterElevationData,
        calculateExpectedOutflow: calculateExpectedOutflow,
        determineReachedThreshold: determineReachedThreshold,
        setDamCalculatorWaterLevelValidationError: setDamCalculatorWaterLevelValidationError,
        setDamCalculatorDesiredLLValidationError: setDamCalculatorDesiredLLValidationError,
        setDamCalculatorDesiredRadialValidationError: setDamCalculatorDesiredRadialValidationError
    }, dispatch)    
}
export default connect( mapStateToProps, matchDispatchToProps )( DamCalculator ); 
