import React from 'react';
//import SvgIcon from "../SvgIcon.js";
import RadioBtnField from "../forms/RadioBtnField.js";

const RadioButtonSeries = ({ 
    title, 
    options, 
    iconLabel, 
    groupName, 
    handleRadioInputChange, 
    required, 
    requiredValidationMessage,
    shouldGetFocus,
    isValid }) => {
    
    let labelClass = iconLabel ? "radio-btn large-gap-top" : "radio-btn"; 

    const radioButtons = options.map( (btn, index) => {
        //console.log(btn.label.props.size);
        let btnValue = null;
        let checked = btn.checked;

        if( typeof btn.label === "string" ) {
            btnValue = btn.label;
        } else {
            btnValue = btn.label;
        }
        
        //let value = btn.value;
        //let id = btn.id;

        return(
            <RadioBtnField 
                key={groupName+"-"+index}
                name={ groupName }
                label={ btnValue }
                type="radio"
                checked={ checked }
                value={ btn.value }
                customInput={ labelClass }
                required={ true }
                onChange={ handleRadioInputChange } 
                buttonID={ btn.id }
                shouldGetFocus={ shouldGetFocus }                
            />
        )
    });

    const requiredString = ( required ? <span className="form-field-required">*</span> : null );
    const isValidClass = ( (isValid === undefined || isValid === true) ? '' : 'form-field-validation-error' );
    const validationMsg = ((isValid === undefined || isValid === true) ? '' : requiredValidationMessage);

    return( 
        <div className={ "radio-button-series form-field " + isValidClass }>
            <fieldset>
                <legend>{title}  {requiredString}</legend>
                {radioButtons}
            </fieldset>
            <span className="validation-message">{validationMsg}</span>
        </div>
    );
}; 

export default RadioButtonSeries;  