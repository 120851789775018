import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { login, logout } from "../../actions/Auth";
import { getRoute, apiRequest } from "../../helpers/Api";
import { Field } from "../forms/Field";
import { validatePhone, validateEmail } from "../forms/Validation";


function getDefaultState(){
	return {
		email: '',
		emailIsValid: false,
		emailValidationMessage: '',
		//phone: '',
		//phoneIsValid: false,
		//phoneValidationMessage: '',
		forgotPasswordError: '',
		fetchingForgotPassword: false,
		passwordEmailSent: false,
	}
}


// A nav item component
class ForgotPassword extends Component {

	constructor(props) {
		super(props);
		this.inputChanged = this.inputChanged.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getValidationState = this.getValidationState.bind(this);
		this.logout = this.logout.bind(this);
		this.state = getDefaultState();
	}

	handleSubmit(event){
		event.preventDefault();

		if( this.state.emailIsValid !== true/* || this.state.phoneIsValid !== true*/ ){
			const validationState = {...this.getValidationState(this.state.email, 'email')/*, ...this.getValidationState(this.state.phone, 'phone')*/ };
			this.setState(validationState);
			return;
		}

		this.setState({fetchingForgotPassword: true, forgotPasswordError: ''});
		const loginDetails = {
			email: this.state.email
			/*phone: this.state.phone.replace(/-/g, '')*/
		};

		//console.log(loginDetails);

		apiRequest(getRoute('resetPasswprd'), {
		      method: 'POST',
		      body: JSON.stringify(loginDetails)
		    })
		    .then(result => {
			    //console.log('results', result );
			    //console.log('reset password success');
			    this.setState({passwordEmailSent: true});
		    })
		    .catch(error => {

		    	let newState = {fetchingForgotPassword: false};
		    	//bad result
		    	if(error.badRequest){
		    		// the server returned an error
		    		//console.log('reset password bad request');
		    		const errorKeys = Object.keys(error.badRequest);

		    		for (var i = 0; i < errorKeys.length; i++) {
		    			const theError = error.badRequest[ errorKeys[i] ];
		    			const key = errorKeys[i];
		    			//console.log(key);
		    			switch(key){
		    				case 'email':
		    					newState.emailIsValid = false;
								newState.emailValidationMessage = theError;
		    					break;
		    				case 'phone':
		    					newState.phoneIsValid = false;
								newState.phoneValidationMessage = theError;
		    					break;
		    				case 'non_field_errors':
		    					newState.forgotPasswordError = theError;
		    					break;
		    				default:
		    					throw new Error('An unknown error type was returned from the API');
		    			}

		    		}

		    	}
		    	else{
		    		//network error or something else
		    		//console.log('reset password network error')
		    		newState.forgotPasswordError  = 'We were unable to reset your password at this time. Please try again later.';
		    	}
		    	this.setState(newState);
		    });
	}

	getValidationState(value, name){

		let newState = {};
		newState[name] = value;


		switch(name){
			case 'email':
				if( value === ''){
					newState['emailValidationMessage'] = "Please enter your email address";
					newState['emailIsValid'] = false;
				}
				else if( !validateEmail( value ) ){
					newState['emailValidationMessage'] = "Please enter a valid email address";
					newState['emailIsValid'] = false;
				}
				else {
					newState['emailValidationMessage'] = "";
					newState['emailIsValid'] = true;
				}
				break;

			case 'phone':
				if( value === '' ){
					newState['phoneValidationMessage'] = "Please enter your phone number";
					newState['phoneIsValid'] = false;
				}
				else if( !validatePhone( value ) ){
					newState['phoneValidationMessage'] = 'Please enter a valid 10 digit phone number. eg 555-555-5555';
					newState['phoneIsValid'] = false;
				}
				else{
					newState['phoneValidationMessage'] = "";
					newState['phoneIsValid'] = true;
				}
				break;
			default:
				throw new Error('InputChanged called with an unknown filed name');
		}

		return newState;
	}


	inputChanged(value, name, event){
		//console.log('input changed', value, name, event)		
		this.setState(this.getValidationState(value, name));

	}

	logout(){
		this.props.logout();
	}

	render() {
		//console.log(this.props.auth);

		return (
			<section>
				<h1 id="main-heading" className="modal-title">Forgot Password</h1>

				{ this.state.passwordEmailSent ? 
					(
						<p>Success! We have sent {this.state.email} an email with instructions to reset your password.</p>
					) 
					: 
					(

						<form onSubmit={this.handleSubmit}>
							<p>Enter your email below and we will email you a link to reset your password.</p>
							{ this.state.forgotPasswordError ? ( <div role="alert" className="form-validation-summary">{this.state.forgotPasswordError}</div>) : null }
							 <Field 
							 	name="email" 
							 	label="Email" 
							 	type="email" 
							 	isValid={( this.state.emailValidationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.emailValidationMessage}  
							 	required={true} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.email}
							 	maxLength={60}
							 	 />
							 
							 <input type="submit" value="Reset Password" disabled={this.state.fetchingForgotPassword} />

						</form>
						
					)
				}

				
			</section>
		)
	}
}


function mapStateToProps(state) {
	return { 
        auth: state.auth
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
		login: login,
		logout: logout
    }, dispatch)    
}


export default connect( mapStateToProps, matchDispatchToProps )( ForgotPassword ); 
