import React, { Component } from "react";
import SvgIcon from "../components/SvgIcon";

class ErrorMsg extends Component {
    constructor ( props ) {
        super( props ); 
        this.clickedModalContent = this.clickedModalContent.bind(this);
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event){}

    clickedModalContent(e) {
        e.stopPropagation();
    }

    render() {
        let showModalCss = this.props.showErrorMsg ? "modal-container visible" : "modal-container";
          
        return(
            <div className={ showModalCss } onClick={ this.props.dismissErrorMessage }>
                <div className="modal-content-container fetch-error-msg" onClick={ this.clickedModalContent }>
                    <button 
                        className="closeModalBtn" 
                        onClick={ this.props.dismissErrorMessage }
                    >
                        <SvgIcon icon="menu-close" />
                    </button>

                    <SvgIcon icon="dissapointed-face" />    
                    {this.props.message}
                </div>
            </div>
        );
    }
}

export default ErrorMsg;