import React from 'react';
import SvgIcon from "./SvgIcon.js";

const Preloader = ({ showPreloader, message }) => {
    const css = showPreloader ? "preloader active" : "preloader";
    return( 
        <div className={ css }>
            <div className="preloader-content-container">
                <span>{ message }</span>
                <SvgIcon icon={ "fountain-preloader" } />
            </div>
        </div>
    );
}; 

export default Preloader; 