import React, { Component } from "react";
import WidgetModalSaveBtn from "./WidgetModalSaveBtn";
import Field from "../forms/Field";

//import 'semantic-ui-css/components/dropdown.css';

class DashboardLinkModalContent extends Component {
    constructor ( props ) {
        super( props ); 

        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.getLinkContent = this.getLinkContent.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        if( props.editModeData ){
            this.state = {
                url: props.editModeData.options.url,
                urlValid: true,
                label: props.editModeData.options.name,
                labelValid: true,
            };
        }
        else{
            this.state = {
                url: '',
                urlValid: true,
                label: '',
                labelValid: true,
            };
        }
    }

    componentDidMount() { 
        this.props.handleOpenModal();  
    } 
    
    addWidgetToDashboard() {
        let isFormValid = this.validateForm();
        let widgetObj = {};

        if( isFormValid ) {
            let widgetToUpdate = null;

            if( this.props.editModeData  ) {
                if( this.props.editModeData.id ) {
                    widgetToUpdate = this.props.editModeData.id;
                }
            }
            
            widgetObj = {
                type: "add-link",
                order: "TODO",
                widgetToUpdate: widgetToUpdate,
                options: {
                    url: this.state.url,
                    name: this.state.label,
                }
            }; 

            this.props.addWidgetToScreen( widgetObj );

        } else {
            console.log("sorry, try again");
        }
    }

    validateForm() {
        let formValid = true;
        if(!this.state.url) {
            formValid = false;
            this.setState({ urlValid: false });
        } else {
            if((/^http:\/\//.test(this.state.url)) || (/^https:\/\//.test(this.state.url))) {
                this.setState({ urlValid: true });
            } else {
                formValid = false;
                this.setState({ urlValid: false });
            }
        }

        if(!this.state.label || this.state.labelValid.length > 0) {
            formValid = false;
            this.setState({ labelValid: false });
        } else {
            this.setState({ labelValid: true });
        }

        return formValid;
    }

    getLinkContent() {
        //add link widget components to modal

        let LinkContent = 
        <div >
            <h2>{ ( this.props.editModeData ? `EDIT` : `ADD A` ) } LINK</h2> 
            <fieldset >
                <Field 
                    type="input"
                    label="URL"
                    name="url"
                    required={true}
                    onChange={ this.handleInputChange }
                    isValid={this.state.urlValid}
                    vashouldGetFocuslue={ this.state.url }
                    shouldGetFocus={ true }
                    validationMessage="Please enter a valid url (must start with http:// or https://)"
                />
            </fieldset>
            <fieldset>
                <Field 
                    type="input"
                    label="Name"
                    name="label"
                    required={true}
                    onChange={ this.handleInputChange }
                    isValid={this.state.labelValid}
                    value={ this.state.label }
                    validationMessage="Please name your link"
                />
            </fieldset>
        </div>

        return LinkContent;
    }

    handleInputChange(value, name, event) {
        if(name === "url") {

            if(value.length > 0) {
                if((/^http:\/\//.test(value)) || (/^https:\/\//.test(value))) {
                    this.setState({ url: value, urlValid: true });
                }
                else{
                    this.setState({ url: value, urlValid: false });
                }
            } else {
                this.setState({ url: value, urlValid: false });
            }
        } else {
            if(value.length > 0) {
                this.setState({ label: value, labelValid: true });    
            } else {
                this.setState({ label: value, labelValid: false });    
            }
        }
    }

    render() {
        let linkContent = this.getLinkContent();

        return(
            <div className="dashboard-add-widget-modal-content">
                { linkContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addWidgetToDashboard } />
            </div>
        );
    }
}

export default DashboardLinkModalContent; 