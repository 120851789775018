/**
 * Class is used to input `react-grid-layout` JavaScript dictionary and future
 * `item` you would like to fit and this class fill find where you can place
 * your `item`.
 *
 * @class
 */
var FindNextAvailablePositionIn2DArray = (function() {

    ///////////////////////////////
    // Private member variables. //
    ///////////////////////////////
    // Variable used to hold the array of items inside the layout using the
    // formatting specific to the 'react-grid-layout' library. 
    // eslint-disable-next-line
    var reactGridLayoutArray = {}

    // Variable use to hold the interpretation of the 'react-grid-layout' in
    // 'X' and 'O' values.
    var dataArray = [];

    // Variable used to keep track largest `height` we have in all the inputted
    // data.
    var largestH = 0;

    // Variable used to keep track the largest `width` we have in all the
    // inputted data.
    var largestW = 0;

    ////////////////////////////
    // Private class methods. //
    ////////////////////////////

    /**
     * Source: http://stackoverflow.com/a/10270781
     * @param {Object} obj The object that will be cloned.
     */
    var cloneDict = function(obj) {
        if (null === obj || "object" !== typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = cloneDict(obj[attr]);
        }
        return copy;
    }

    /**
     * Create a 2-dimensional array and fill it with a empty placeholder
     * character so we will know it's empty space.
     *
     * @param {Integer} cols
     * @param {Integer} rows
     */
     function create2DArray(cols, rows) {
         var arr = [];
         for (var row = 0; row < rows; row++) {
             arr[row] = [];
             for (var col = 0; col < cols; col++) {
                 arr[row][col] = 0;
             }
         }
         return arr;
    }

    /**
     *  Function will take the `itemDict` parameter and reserve the cells inside
     * our `2D array`.
     *
     * @param {Array} arr A two dimensional array.
     * @param {Integer} x0
     * @param {Integer} y0
     * @param {Integer} x1
     * @param {Integer} y1
     */
    var reserveInside2DArray = function(arr, x0, y0, x1, y1) {
        // Iterate through the 2D array by X and Y's.
        for (var x = x0; x < x1; x++) {
            for (var y = y0; y < y1; y++) {
                arr[y][x] = 1  // Reserve a cell.
            }
        }
        return arr
    }

    /**
     *  Function will take the `react-grid-layour` object and convert it into
     *  a `2D array` filled with ones and zeros to indicate either reservation
     *  of a position or an availability of a position.
     *
     * @param {Dictionary} obj
     * @param {Integer} numberOfColumns
     */
    var getDataArrayByTranscodingReactGridLayoutArray = function(obj, numberOfColumns) {
        var arr = [];

        // Find the totals.
        var maxX = 0;
        var maxY = 0;

        // Iterate through all the objects we have in the layout...
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                // Get our layout object coords.
                var itemDict = obj[prop];

                // Calculate (x0,y0) and (x1, y1) points.
                // eslint-disable-next-line
                var x0 = itemDict['x'];
                // eslint-disable-next-line
                var y0 = itemDict['y'];
                // eslint-disable-next-line
                var x1 = itemDict['x'] + itemDict['w'];
                // eslint-disable-next-line
                var y1 = itemDict['y'] + itemDict['h'];

                // Find the largest `X` position.
                if (x1 > maxX) {
                    maxX = x1;
                }

                // Find the largest `Y` position.
                if (y1 > maxY) {
                    maxY = y1;
                }

                // Keep track of the largest `Y` position in our class which
                // will be used later...
                if (maxY > largestH) {
                    largestH = maxY;
                }

            } // end if
        } // end forloop

        // We will generate our `2D array` all filled out with zero values
        // which represent non-reserved cells.
        arr = create2DArray(numberOfColumns, maxY);

        // Iterate through all the objects we have in the layout...
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                // Get our layout object coords.
                let itemDict = obj[prop];

                // Calculate (x0,y0) and (x1, y1) points.
                let x0 = itemDict['x'];
                let y0 = itemDict['y'];
                let x1 = itemDict['x'] + itemDict['w'];
                let y1 = itemDict['y'] + itemDict['h'];

                // We are going to take the two points from the item and
                // reserve their spaces inside the `2D array`. Reservation
                // means assigning a one integer. In summary, zero integer means
                // available position and one integer means a reserved position.
                arr = reserveInside2DArray(arr, x0, y0, x1, y1);
            }
        }

        return arr;
    }

    var getAvailableCoordinatesFromDataArray = function(dataArray) {
        var availableArr = [];
        for (var y in dataArray) {
            for (var x in dataArray[y]) {
                if (dataArray[y][x] === 0) {
                    availableArr.push({
                        // eslint-disable-next-line
                       'x': parseInt(x),
                       // eslint-disable-next-line
                       'y': parseInt(y)
                    })
                }
            }
        }
        return availableArr
    }

    //////////////////////////
    // Constructor methods. //
    //////////////////////////

    /**
     * Set all member variables to default starting values.
     *
     * @constructor
     */
    var initialize = function() {
        reactGridLayoutArray = [];
        dataArray = [];
        largestH = 0;
        largestW = 0;
    }

    /**
     * Initializes the class from the javascript array.
     *
     * @param {Object} JSONData
     * @constructor
     */
    var initializeFromArray = function(initialReactGridLayoutArray, numberOfColumns) {
        initialize();
        largestW = numberOfColumns
        reactGridLayoutArray = initialReactGridLayoutArray;
        dataArray = getDataArrayByTranscodingReactGridLayoutArray(initialReactGridLayoutArray, numberOfColumns);
    }

    //////////////////////////////
    // Class mutator functions. //
    //////////////////////////////

    /////////////////////////////
    // Class getter functions. //
    /////////////////////////////

    var getDataArray = function() {
        return dataArray;
    }

    ///////////////////////////////////////
    // Public class functions. (Generic) //
    ///////////////////////////////////////

    /**
     *  Function will return either a `true` or `false` value depending on
     *  whether the cells can be reservered for the inputted `itemDict`
     *  parameter for the member `dataArray`.
     */
    var canReservePositionAt = function(x0, y0, itemDict) {
        // Variable used to keep track of how many reserved cells we have
        // counted when we look at the surrounding cells for the given `X` and
        // `Y` coordinate.
        // eslint-disable-next-line
        var reservedCellsCount = 0;

        // Get our new coordinates.
        // eslint-disable-next-line
        var x1 = parseInt(x0) + parseInt(itemDict['w']);
        // eslint-disable-next-line
        var y1 = parseInt(y0) + parseInt(itemDict['h']);

        // Iterate through the 2D array by X and Y's.
        for (var x = x0; x < x1; x++) {
            for (var y = y0; y < y1; y++) {
                var cellValue = dataArray[y][x];
                if (cellValue === 1) {
                    return false
                }
            }
        }

        // // FOR DEBUGGING PURPOSES ONLY...
        // console.log("x0:", x0, " y0:", y0, " x1:", x1, " y1:", y1);
        // console.log(x1, " <= ", largestW, x1 <= largestW);
        // console.log();

        // DEVELOPERS NOTE:
        // - We must now check to confirm that our `item` is able to fit into
        //   the provided space given the maximum size of the array.
        // - If it can fit then we can officially reserve and if not then
        //   we cannot reserve the `item`.
        return x1 <= largestW
    }

    /**
     *  Function iterates through all the available cells and returns the
     *  `X` and `Y` coordinates of the available starting cell to fill for
     *  the inputted `itemDict` parameter.
     */
    var findXandYAvailableReservationPositionFromItemDict = function(itemDict) {
        // Iterate through our entire `2D array` and find all the `x & y`
        // points which are cells that have not been reserved yet.
        var availablePositionArr = getAvailableCoordinatesFromDataArray(dataArray)

        try {
            // Iterate through all the available positions and attempt to find a
            // spot available for reservation and if possile return the new
            // position coordinates to do so.
            for (var prop in availablePositionArr) {
                if (availablePositionArr.hasOwnProperty(prop)) {
                    var positionDict = availablePositionArr[prop];
                    if (canReservePositionAt(positionDict['x'], positionDict['y'], itemDict)) {
                        return positionDict
                    }
                }
            }
        }
        catch (error) {
            // If we cannot reserve any cell(s) in the available positions
            // we currently have then return the very bottom. Also if any errors
            // occur then simply just add the `item` to the very bottom.
            // (SKIP)
        }

        // If we cannot find any available position then simply add the `item`
        // to the very bottom.
        return {
            'x': 0,
            'y': largestH
        }
    }


    ///////////////////////////////////
    // Class publize functions list. //
    ///////////////////////////////////

    return {
        // Constructor.
        initializeFromArray: initializeFromArray,

        // Mutators.
        // (None)

        // Getters.
        getDataArray: getDataArray,

        // Public class functions.
        findXandYAvailableReservationPositionFromItemDict: findXandYAvailableReservationPositionFromItemDict
    }

})();


/**
 * Function will find the available X and Y cooridinates of where to
 * place the `itemDict` object inside the array of `itemDict` objects inside
 * the `arr` parameter for the given `numberOfColumns` count.
 */
export default function getAvailableXandYPositionsFrom(searchArr, itemDict, numberOfColumns) {
    FindNextAvailablePositionIn2DArray.initializeFromArray(searchArr, numberOfColumns);
    var resultDict = FindNextAvailablePositionIn2DArray.findXandYAvailableReservationPositionFromItemDict(itemDict);
    return resultDict;
}

// DEVELOPERS NOTE:
// - REMOVE THE "export" FUNCTION WHEN YOU ARE USING LOCAL BUILD FOR THE
//   JAVASCRIPT BECAUSE YOU WANT TO RUN THE JQUERY LOCAL UNIT TESTS.
//