import React, {Component} from "react";
import SvgIcon from "../SvgIcon.js";

class EditWidgetMenu extends Component {
    constructor(props) {
        super(props);

        this.editBtnClicked = this.editBtnClicked.bind(this);
        this.removeBtnClicked = this.removeBtnClicked.bind(this);

    }

    editBtnClicked() {
        this.props.handleEditWidget( this.props.widgetId );
    }

    removeBtnClicked() {
        this.props.handleRemoveWidget( this.props.widgetId );
    }

    render() {
        
        let widgetClass = "edit-widget-overlay";
        if( this.props.linkWidget ) {
            widgetClass = "edit-widget-overlay link-widget"
        }

        if( this.props.weatherForecastWidget ) {
            widgetClass = "edit-widget-overlay weather-forecast-widget"
        }

        let menuContent = null;

        if( this.props.savedReport ) {
            menuContent =
            <div className="edit-widget-side-menu">
                <div className="edit-wiget-menu-btn">
                    <button onClick={ this.removeBtnClicked }>
                        <SvgIcon icon="menu-close" />
                    </button>
                </div>
            </div>
        } else {
            menuContent =
            <div className="edit-widget-side-menu">
                <div className="edit-wiget-menu-btn">
                    <button onClick={ this.editBtnClicked }>
                        <SvgIcon icon="pencil" />
                    </button>
                </div>
                <div className="edit-wiget-menu-btn">
                    <button onClick={ this.removeBtnClicked }>
                        <SvgIcon icon="menu-close" />
                    </button>
                </div>
            </div>
        }

        return(
            <div className={ widgetClass }>
                { menuContent }
            </div>
        )
    }
}

export default EditWidgetMenu;  