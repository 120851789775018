import React, { Component } from "react"; 
import { Chart } from 'react-google-charts';

class StationDial extends Component {

	render() {
		let options = {                
			redFrom: 76.6, redTo: 77,
			yellowFrom: 76.4, yellowTo: 76.6,
			greenFrom: 76.0, greenTo: 76.4,
			minorTicks: 10,
			min: 74.5,
			max: 76.7
		};

		return(
			<div>
				<Chart
					chartType="Gauge"
					data={[
						['Label', 'Value'],
						['DUNDAS', this.props.currentValue]
					]}
					options={ options }
					graph_id="gauge"
					width="200px"
				/>
			</div>
		)
	}
}

export default StationDial;