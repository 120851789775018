function get_default_state(){

    return {
        is404: false,
        primaryStation: {
            data: null,
            currentTimeSeriesLength: null,
            timeSeriesOptions: [],
            stationSensors: [],  
        },
        previousMonthlyTotals: null,
        neighbouringStations: [],
        batteryLevelStation: {
            data: null,
            timeSeriesLength: null,
            timeSeriesOptions: [], 
        },
    }
}

export default function ( 
    state=get_default_state(), action ) {
    switch( action.type ) {
        case "RESET_STATION_DETAIL_DATA":
            return get_default_state();

        case "GAUGE_NOT_FOUND":
            return {...get_default_state(), is404: true };

        case "UPDATE_STATION_DETAIL_DATA":

            //console.log('default_state', state);

            state.primaryStation.data = action.payload;

            //change this to be set from the API
            state.primaryStation.currentTimeSeriesLength = action.payload.time_series_data_hours_ago

            //setup state for the time series data
            state.primaryStation.timeSeriesOptions = [];

            var i = null;

            // eslint-disable-next-line
            for (i = 0; i < action.payload.past_time_series_data.length; i++) {
                //setup all avaialble options
                const selected = ( action.payload.past_time_series_data[i].hour === state.primaryStation.currentTimeSeriesLength ? true : false );
                state.primaryStation.timeSeriesOptions.push({
                    'prettyName' : action.payload.past_time_series_data[i].pretty_name,
                    'hour': action.payload.past_time_series_data[i].hour,
                    'detailUrl': action.payload.past_time_series_data[i].detail_url,
                    'selected': selected
                });
            }

            //setup state for the availalbe gauges
            state.primaryStation.stationSensors = [];

            // eslint-disable-next-line
            for (var i = 0; i < action.payload.station_gauges.length; i++) {
                const selected = ( action.payload.station_gauges[i].sensor_name === action.payload.sensor_name ? true : false );
                state.primaryStation.stationSensors.push({
                    'prettyName' : action.payload.station_gauges[i].pretty_sensor_name,
                    'sensorName' : action.payload.station_gauges[i].sensor_name,
                    'typeOf': action.payload.station_gauges[i].type_of,
                    'detailUrl': action.payload.station_gauges[i].detail_url,
                    'selected': selected
                });

            }


            // eslint-disable-next-line
            if( state.neighbouringStations.length === 0 ){
                //only add the gauges if they are currently not in state
                // eslint-disable-next-line
                for (var i = 0; i < action.payload.neighbouring_gauges.length; i++) {
                    state.neighbouringStations.push({
                        data: null,
                        nearbyName: action.payload.neighbouring_gauges[i].nearby_name,
                        detailUrl: action.payload.neighbouring_gauges[i].detail_url,
                        currentTimeSeriesLength: null,
                        timeSeriesOptions: [],
                        stationSensors: [],  
                    });
                }
            }

            const curDate = new Date();
            //months in the API are 1-12
            const currentMonth = curDate.getMonth() + 1;
            const currentMonthString = curDate.toLocaleString("en-us", { month: "short" });

            //Setup data for previous monthly totals
            if( action.payload.monthly_totals.length ){
                
                state.previousMonthlyTotals = {
                    stationName: action.payload.station_name,
                    totals: []
                }

                // eslint-disable-next-line
                for (var i = 0; i < action.payload.monthly_totals.length; i++) {

                    if( action.payload.monthly_totals[i].month === currentMonth ){
                        state.previousMonthlyTotals.totals.push({
                            'year': currentMonthString + ' ' + action.payload.monthly_totals[i].year,
                            'value': action.payload.monthly_totals[i].value
                        });
                    }


                }


            }

            
            return {...state};

        

        case "UPDATE_SPECIFIC_NEIGHBOURING_GAUGE_DATA":
            // state.neighbouringStations[ action.arrayPos ].data = action.payload;
            // state.neighbouringStations[ action.arrayPos ].currentTimeSeriesLength = action.payload.time_series_data_hours_ago;

            // //update the active setting
            // // eslint-disable-next-line
            // for (var i =  state.neighbouringStations[ action.arrayPos ].timeSeriesOptions.length - 1; i >= 0; i--) {
            //     const selected = ( state.neighbouringStations[ action.arrayPos ].timeSeriesOptions[i].hour === action.payload.time_series_data_hours_ago ? true : false );
            //     state.neighbouringStations[ action.arrayPos ].timeSeriesOptions[i].selected = selected;
            // }

            //console.log( state.primaryStation.data, action.arrayPos );

            var gauge = {...state.neighbouringStations[action.arrayPos],
                data: action.payload,
                currentTimeSeriesLength: null,
                timeSeriesOptions: [],
                stationSensors: [],  
            };

             //change this to be set from the API
            gauge.currentTimeSeriesLength = action.payload.time_series_data_hours_ago

            //setup state for the time series data
            gauge.timeSeriesOptions = [];

            // eslint-disable-next-line
            for (var i = 0; i < action.payload.past_time_series_data.length; i++) {
                //setup all avaialble options
                const selected = ( action.payload.past_time_series_data[i].hour === gauge.currentTimeSeriesLength ? true : false );
                gauge.timeSeriesOptions.push({
                    'prettyName' : action.payload.past_time_series_data[i].pretty_name,
                    'hour': action.payload.past_time_series_data[i].hour,
                    'detailUrl': action.payload.past_time_series_data[i].detail_url,
                    'selected': selected
                });
            }

            //setup state for the availalbe gauges
            gauge.stationSensors = [];

            // eslint-disable-next-line
            for (var i = 0; i < action.payload.station_gauges.length; i++) {
                const selected = ( action.payload.station_gauges[i].sensor_name === action.payload.sensor_name ? true : false );
                gauge.stationSensors.push({
                    'prettyName' : action.payload.station_gauges[i].pretty_sensor_name,
                    'sensorName' : action.payload.station_gauges[i].sensor_name,
                    'typeOf': action.payload.station_gauges[i].type_of,
                    'detailUrl': action.payload.station_gauges[i].detail_url,
                    'selected': selected
                });

            }

            state.neighbouringStations[ action.arrayPos ] = gauge;

            return {...state}


        case "UPDATE_BATTERY_LEVEL_CHART":
            //console.log(action.payload);
            state.batteryLevelStation.data = action.payload;
            state.batteryLevelStation.currentTimeSeriesLength = action.payload.time_series_data_hours_ago

                        //setup state for the time series data
            state.batteryLevelStation.timeSeriesOptions = [];

            // eslint-disable-next-line
            for (var i = 0; i < action.payload.past_time_series_data.length; i++) {
                //setup all avaialble options
                const selected = ( action.payload.past_time_series_data[i].hour === state.batteryLevelStation.currentTimeSeriesLength ? true : false );
                state.batteryLevelStation.timeSeriesOptions.push({
                    'prettyName' : action.payload.past_time_series_data[i].pretty_name,
                    'hour': action.payload.past_time_series_data[i].hour,
                    'detailUrl': action.payload.past_time_series_data[i].detail_url,
                    'selected': selected
                });
            }
            
            return {...state};

        default:
            return state;
    }
}