import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';
import WidgetModalSaveBtn from "./dashboard/WidgetModalSaveBtn";
import Field from "./forms/Field";

class SaveVisualizationModal extends Component {
    constructor ( props ) {
        super( props ); 

        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.getNameContent = this.getNameContent.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);

        this.state = {
            name: '',
            nameValid: true,
            dashboard: '',
            dashboardValid: true,
            dashboardId: null,
        };
    }

    componentDidMount() {  } 
    
    addWidgetToDashboard() {
        let isFormValid = this.validateForm();

        if( isFormValid ) {
            let widgetObj = {
                type: "add-link",
                order: "TODO",
                reportVisualzation: true,
                options: {
                    url: this.props.reportUrl,
                    name: this.state.name,
                    plotSeperately: this.props.plotSeperately,
                    isReportVisualization: true,
                    dashboardToAdd: this.state.dashboard,
                    fromDate: this.props.fromDate,
                    toDate: this.props.toDate,
                    gaugeList: this.props.gaugeList,
                    dashboardId: this.state.dashboardId,
                }
            }; 

            this.props.addWidgetToDashboard( widgetObj );

        } else {
            //console.log("sorry, try again");
        }
    }

    validateForm() {
        let formValid = true;
        if(!this.state.name.length > 0) {
            formValid = false;
            this.setState({ nameValid: false });
        } else {
            this.setState({ nameValid: true });
        }

        if( !this.state.dashboard.length > 0 ) {
            this.setState({ dashboardValid: false });
        } else {
            this.setState({ dashboardValid: true });
        }

        return formValid;
    }

    getNameContent() {
        //add link widget components to modal
        //console.log( this.props.dashboards );

        let reportSummary = null;
        if( this.props.selectedCharts ) {
            reportSummary = this.props.selectedCharts.map(( chart,index ) => {
                var title = chart.chartTitle.replace("Showing ","");
                return (
                    <li key={index}>{title}</li>
                )
            });
        }

        let dashboards = [];

        for( var i = 0; i < this.props.dashboards.length; i++ ) {
            var obj = {
                key: "dashboard-"+i,
                value: this.props.dashboards[i].name,
                text: this.props.dashboards[i].name,
                id: this.props.dashboards[i].id,
            }

            dashboards.push( obj );
        }

        let timeRange = null;
        if(this.props.dateRange) {
            timeRange = <p>{"FROM "+ this.props.dateRange.from + " TO "+ this.props.dateRange.to}</p>;
        }

        
        let dropdownValidationClass =  this.state.dashboardValid ? "" : "dropdown-validation-error";

        let LinkContent = 
        <div >
            <h2>Saving:</h2>
            <ul>{ reportSummary }</ul>
            { timeRange } 
            <fieldset>
                <div className={"available-dashboards-container "+ dropdownValidationClass }>
                    <label>SELECT DASHBOARD</label>
                    <Dropdown 
                        id="available-dashboards"
                        className={"dashboard-list-dropdown "+ dropdownValidationClass} 
                        placeholder='Dashboard' 
                        fluid 
                        search 
                        selection 
                        options={dashboards} 
                        onChange={ this.dropdownSelectionChanged }
                        defaultValue={ this.state.station }
                        searchInput={{ autoFocus: true }}
                    />
                    <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a station</span>
                </div>

                <Field 
                    type="input"
                    label="NAME YOUR REPORT"
                    name="label"
                    required={true}
                    onChange={ this.handleInputChange }
                    isValid={this.state.nameValid}
                    value={ this.state.name }
                    validationMessage="Please name your visualization"
                />
            </fieldset>
        </div>

        return LinkContent;
    }

    handleInputChange(value, name, event) {
        if(value.length > 0) {
            this.setState({ name: value, nameValid: true });
        } else {
            this.setState({ name: value, nameValid: false });
        } 
    }

    dropdownSelectionChanged(e, data) {
        
        for( var i=0; i < data.options.length; i++ ) {
            if( data.options[i].value === data.value ) {
                this.setState({ dashboardId: data.options[i].id });
            }
        }

        this.setState({ 
            dashboard: data.value,
            dashboardValid: true,
        });
    }

    render() {
        let nameContent = this.getNameContent();

        return(
            <div className="save-visualization-content">
                { nameContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addWidgetToDashboard } />
            </div>
        );
    }
}

export default SaveVisualizationModal; 