import React, { Component } from "react";
import SvgIcon from "./SvgIcon.js";



const brokenImage = <svg className="broken-svg" aria-label="loading" height='300px' width='300px'  fill="#000000" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve"><g transform="translate(-570 -80)"><g xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><circle cx="579" cy="92" r="2"></circle><polygon points="590.908,86 590.315,88 595,88 595,103 585.871,103 585.278,105 597,105 597,86   "></polygon><path d="M586.167,102H588h6v-2.857c-1.997-2.776-2.954-6.657-4.883-7.098L586.167,102z"></path><path d="M588.041,81.716L586.771,86H573v19h8.143l-1.102,3.716l1.918,0.568l8-27L588.041,81.716z M583.31,97.682    c-0.668-0.861-1.34-1.396-2.06-1.396c-1.955,0-2.674,4.157-5.25,4.999V102h2.25h3.781l-0.296,1H575V88h11.18L583.31,97.682z"></path></g></g></svg>;


class CameraImage extends Component {

    constructor ( props ) {

    	/*
            Requires Props:

            image = URL -- REQUIRED
            --  The image URL to be displayed

            cameraClick - callback - OPTIONAL
            -- The call back method when the image is clicked
        */
        super( props );
 
        this.imageError = this.imageError.bind(this);
        this.imageLoad = this.imageLoad.bind(this);
        this.reloadImage = this.reloadImage.bind(this);
        this.ourClick = this.ourClick.bind(this);

        this.timeout = null;

        this.state = {
        	imageError: false,
        	imageLoading: true,
        	imageFirstLoad: true,
        	loadTimeStamp: Date.now(),
        }
    }
    
    reloadImage() {
    	this.setState({
    		imageLoading: true,
    		loadTimeStamp: Date.now(),
    	});
    }

    componentDidMount() {
    	this.timeout = setInterval( this.reloadImage, 30 * 1000);
    }

    componentWillUnmount() {
    	clearInterval(this.timeout);
    }

    imageLoad() {
    	this.setState({
    		imageFirstLoad: false,
    		imageLoading: false,
    		imageError: false,
    	});
    }

    imageError() {
    	this.setState({
    		imageError: true,
    		imageLoading: false,
    		imageFirstLoad: false,
    	});
    }

    ourClick(){
    	if( this.props.cameraClick ){
    		this.props.cameraClick();
    	}
    }

    render() { 

    	let image = <img 
    					key={this.state.loadTimeStamp} 
    					className="camera-image-img" 
    					src={this.props.image} 
    					onError={ this.imageError } 
    					onLoad={this.imageLoad } 
    					alt=""
    					onClick={ this.ourClick }
    				/>;

    	if( this.state.imageError ){
    		image = <div className="camera-image-placeholder camera-image-error">{brokenImage}</div>
    	}

    	let className = 'camera-image-camera-view';
    	let loadingSVG = null;
    	if( this.state.imageLoading ){
    		className += ' card-image-loading';
    		loadingSVG = <SvgIcon icon={ "fountain-preloader" } />;
    	}
    	//console.log(this.state);
    	return (
    		<div className={className}>
    			{ image }
    			{ loadingSVG }
    		</div>
    	)
    }
}

export default CameraImage;