import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";

import Preloader from "../components/Preloader";
import { getRoute, apiRequest } from "../helpers/Api";
import ErrorMsg from "../components/ErrorMsg";
import Modal from "../components/Modal";
import SvgIcon from "../components/SvgIcon.js"; 
import DashboardBuildMenu from "../components/dashboard/DashboardBuildMenu";
import DashboardMultiMenu from "../components/dashboard/DashboardMultiMenu"
import DashboardDamModalContent from "../components/dashboard/DashboardDamModalContent"; 
import DashboardStreamModalContent from "../components/dashboard/DashboardStreamModalContent"; 
import DashboardPrecipModalContent from "../components/dashboard/DashboardPrecipModalContent"; 
import DashboardMapModalContent from "../components/dashboard/DashboardMapModalContent"; 
import DashboardLinkModalContent from "../components/dashboard/DashboardLinkModalContent"; 
import DashboardMiscModalContent from "../components/dashboard/DashboardMiscModalContent";
import DashboardNewModalContent from "../components/dashboard/DashboardNewModalContent";
//import DataExplorerMap from "../components/data-explorer/DataExplorerMap";
import GaugeCardWidget from "../components/dashboard/widgets/GaugeCardWidget";
import DamOpeningWidget from "../components/dashboard/widgets/DamOpeningWidget";
import LinkWidget  from "../components/dashboard/widgets/LinkWidget";
import GoogleMapWidget from "../components/dashboard/widgets/GoogleMapWidget";
import WeatherAlertWidget from "../components/dashboard/widgets/WeatherAlertWidget";
import WeatherForecastWidget from "../components/dashboard/widgets/WeatherForecastWidget";
import BatteryWidget from "../components/dashboard/widgets/BatteryWidget";
import FeedbackWidget from "../components/FeedbackWidget";
//import Card from "../components/Card";
//import slugify from "../helpers/slugify";
import { connect } from "react-redux"; 
import ConfirmationModalContent from "../components/ConfirmationModalContent";

import { 
    togglePreloader,
    showFetchError,
    toggleModal,
    setupStationsAndGauges,
    setDashboardVisualizationRequest,
} from "../actions/index";

import { 
    setActiveBuildMenuType,
} from "../actions/navigation";

import { 
    addWidget, 
    editWidget,
    updateCurrentDashboardLayout, 
    setBuildMode, 
    setDashboardVisable ,
    removeWidgetFromDashboard,
    setAvailableDashboards,
    setCurrentDashboard,
    updateSpecificDashboard,
    setModalContentOptions,
    showDashboardModal,
    closeDashboardModal,
    setDefaultDashboard,
    deleteDashboard 
} from "../actions/Dashboard";

import { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashboardContainer extends Component {
    constructor ( props ) {
        super( props ); 

        this.dismissErrorMessage = this.dismissErrorMessage.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.addWidgetToScreen = this.addWidgetToScreen.bind(this);
        this.handleRadioInputChange = this.handleRadioInputChange(this);
        this.togglePreloader = this.togglePreloader.bind(this);
        this.doOpenModal = this.doOpenModal.bind(this);
        this.getDashboardWidget = this.getDashboardWidget.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.editWidget = this.editWidget.bind(this);
        this.removeWidget = this.removeWidget.bind(this);
        this.widgetRemovalConfirmed = this.widgetRemovalConfirmed.bind(this);
        this.getDashboardWidgetModal = this.getDashboardWidgetModal.bind(this);
        this.getDamSafetyCodeData = this.getDamSafetyCodeData.bind(this);
        this.removeCurrentDashboard = this.removeCurrentDashboard.bind(this);
        this.handleReportRequestFromVisualization = this.handleReportRequestFromVisualization.bind(this);
        this.getPrecipTotals = this.getPrecipTotals.bind(this);

        this.state = { 
            widgetModalContent: null,
            widgetId: null,
            widgetType: null,
            dashboardSaving: false,
            initialFetch: true,
            modalIsVisible: false,
            damOpsStationData: null,
            dashboardDeletedConfirmationMessage: '',
            precipitationTotals: null,
        }

        this.apiData = null;
    }


    dismissErrorMessage() {
		var obj = {
			isVisible: false, 
			message: "",
		};

		this.props.showFetchError( obj );
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        apiRequest( getRoute('getDashboards') )
            .then( theResult => {
                //this.setState({isFetching: false, accountActivated: true})                  
                this.props.setAvailableDashboards( theResult );

                let theDefault = null;
                for (var i = 0; i < theResult.length; i++) {
                    if( theResult[i].is_default === true ){
                        theDefault = theResult[i].id
                        break;
                    }
                }

                if( !this.props.dashboard.currentDashboardId ) {
                    if( theDefault === null ){
                        theDefault = theResult[0].id;
                    }

                    this.props.setCurrentDashboard(theDefault);
                }
            })
            /*.catch( theError => {
                console.log('dashboardFetch fail', theError);

                //TODO: Error for dashboard
            })*/

        
        this.props.setDashboardVisable( true );
        this.getDamSafetyCodeData();
        this.getPrecipTotals();
    }

    componentWillUnmount(){
        this.props.setDashboardVisable( false );
        this.props.setBuildMode( false );
    }

    componentDidUpdate(prevProps, prevState){
        //check to see if we need to update the dashboard

        if( prevProps.dashboard.currentDashboardId !== this.props.dashboard.currentDashboardId ) {
            window.scrollTo(0, 0);
        }

        if(this.props.dashboard.currentDashboardId !== null && ( this.props.dashboard.requireDashboardSave !== prevProps.dashboard.requireDashboardSave ) ){
            //console.log('dashboard save required',this.props.dashboard );
            //this.setState({dashboardSaving: true});

            //let theTitle = null;
            // eslint-disable-next-line
            let is_default = null;
            for (var i = 0; i < this.props.dashboard.availableDashboards.length; i++) {
                if(this.props.dashboard.availableDashboards[i].id === this.props.dashboard.currentDashboardId ){
                    //theTitle = this.props.dashboard.availableDashboards[i].name;
                    //is_default = this.props.dashboard.availableDashboards[i].is_default;
                    //break;

                    let dashboard = {
                        name: this.props.dashboard.availableDashboards[i].name,
                        widgets: this.props.dashboard.availableDashboards[i].widgets,
                        layout: this.props.dashboard.availableDashboards[i].layout,
                        is_default: this.props.dashboard.availableDashboards[i].is_default,
                    }

                    apiRequest(getRoute('getDashboard', [this.props.dashboard.availableDashboards[i].id]), {
                        method: 'PUT',
                        body: JSON.stringify(dashboard)
                      })
                      .then(theResult => {
                           //console.log(theResult);
                           //this.setState({dashboardSaving: false});
                           this.props.updateSpecificDashboard( theResult );
                      })
                      .catch(error => {
                          //console.log( 'savingDashboard Error', error );
                          this.setState({dashboardSaving: false});
                      });
                }
            }
        }
    }

    getDashboardWidgetModal( widgetType, editData ){
        //let url = null;
        //let stationData = null;
        //let title = [];
        let type  = "";
        //let gaugeId = "";
        //let id = 2100;
        let content = null;

        switch( widgetType ) {            
            case "dam":
            type = widgetType.toUpperCase();
            content = 
            <DashboardDamModalContent 
                contentType={ type } 
                handleOpenModal={ this.doOpenModal }
                handleTogglePreloader={ this.togglePreloader }
                addWidgetToScreen={ this.addWidgetToScreen }
                editModeData={ editData }
            />
            break;

            case "stream":
            type = widgetType.toUpperCase();
            content = 
            <DashboardStreamModalContent 
                contentType={ type }  
                handleOpenModal={ this.doOpenModal }
                handleTogglePreloader={ this.togglePreloader }
                addWidgetToScreen={ this.addWidgetToScreen } 
                editModeData={ editData }
            />
            break;

            case "precip":
            type = widgetType.toUpperCase();
            content = 
            <DashboardPrecipModalContent 
                contentType={ type } 
                handleOpenModal={ this.doOpenModal }
                handleTogglePreloader={ this.togglePreloader }
                addWidgetToScreen={ this.addWidgetToScreen }
                editModeData={ editData }
            />
            break;

            case "map":
            type = widgetType.toUpperCase();
            content = 
            <DashboardMapModalContent 
                contentType={ type } 
                handleOpenModal={ this.doOpenModal }
                handleTogglePreloader={ this.togglePreloader }
                addWidgetToScreen={ this.addWidgetToScreen }
                editModeData={ editData }
            />
            break;

            case "link":
            type = widgetType.toUpperCase();
            content = 
            <DashboardLinkModalContent 
                contentType={ type } 
                handleOpenModal={ this.doOpenModal }
                handleTogglePreloader={ this.togglePreloader }
                addWidgetToScreen={ this.addWidgetToScreen }
                editModeData={ editData }
            />
            break;

            case "misc":
            type = widgetType.toUpperCase();
            content = 
            <DashboardMiscModalContent 
                contentType={ type } 
                handleOpenModal={ this.doOpenModal }
                handleTogglePreloader={ this.togglePreloader }
                addWidgetToScreen={ this.addWidgetToScreen }
                editModeData={ editData }
            />
            break;

            default: 
            break;
        }

        return content;
    }

    addWidgetToScreen( options ){
        if( options.widgetToUpdate ) {
            //this.props.removeWidgetFromDashboard( options.widgetToUpdate );
            this.props.editWidget( options );
        } else {
            this.props.addWidget( options );
        }

        this.handleCloseModal(); 

    }

    handleCloseModal(  ) {
        this.props.closeDashboardModal();
    }

    doOpenModal() {
        this.props.showDashboardModal();
    }

    togglePreloader( doToggle ) {
        this.props.togglePreloader( doToggle );
    }
    
    handleRadioInputChange(value, name, event) { }

    getDashboardWidget(widget, index){ 
        let theWidget = null;
        switch( widget.type ){
            case "time-series":
            case "dam-gauge":
            case "precip-gauge":
                theWidget = 
                    <div key={widget.key}>
                        <GaugeCardWidget 
                            type={widget.type} 
                            widgetType={ widget.widgetType }
                            gaugeId={widget.options.gaugeId} 
                            gaugeType={widget.options.gaugeType}
                            timeFrame={widget.options.timeFrame} 
                            size={widget.options.size} 
                            //showAccumulated={widget.options.showAccumulatedTotal}
                            showAccumulated={widget.options.showAccumulated}
                            buildModeActive={ this.props.dashboard.buildModeActive }
                            handleEditWidget={ this.editWidget }
                            handleRemoveWidget={ this.removeWidget }
                            widgetId = { widget.key }
                            reportVisualization={ widget.options.isReportVisualization }
                            damSafetyCodeData={ this.state.damOpsStationData }
                            precipTotals={ this.state.precipitationTotals }
                        />
                    </div>
                break;
            case "dam-gauge-opening":
                theWidget = 
                    <div key={widget.key}>
                        <DamOpeningWidget 
                            stationId={widget.options.stationId}
                            buildModeActive={ this.props.dashboard.buildModeActive }
                            handleEditWidget={ this.editWidget }
                            handleRemoveWidget={ this.removeWidget }
                            widgetId = { widget.key }
                        />
                    </div>            
                break;
            case "add-link":
                theWidget = 
                    <div key={widget.key}>
                        <LinkWidget 
                            link={widget.options.url} 
                            name={widget.options.name}
                            buildModeActive={ this.props.dashboard.buildModeActive }
                            handleEditWidget={ this.editWidget }
                            handleRemoveWidget={ this.removeWidget }
                            widgetId= { widget.key }
                            reportVisualization={ widget.options.isReportVisualization }
                            handleReportRequestFromVisualization={ this.handleReportRequestFromVisualization }
                            reportFromDate={ widget.options.fromDate }
                            reportToDate={ widget.options.toDate }
                            gaugeList={ widget.options.gaugeList }
                        />
                    </div>
                break; 

            case "station-map":
                // google maps going to be problematic due to duplicate loading of scripts
                //console.log( widget );
                theWidget = 
                    <div key={widget.key}>
                        <GoogleMapWidget 
                            size={widget.options.size} 
                            type={widget.options.type} 
                            mapId={`map-${index}`}  
                            buildModeActive={ this.props.dashboard.buildModeActive }
                            handleEditWidget={ this.editWidget }
                            handleRemoveWidget={ this.removeWidget }
                            widgetId = { widget.key }
                            mapType={ widget.selectedMapType }
                        />
                    </div>
                break;

            case "public-alerts":
                theWidget = 
                    <div key={widget.key}>                
                        <WeatherAlertWidget 
                            region={widget.options.region} 
                            buildModeActive={ this.props.dashboard.buildModeActive }
                            handleEditWidget={ this.editWidget }
                            handleRemoveWidget={ this.removeWidget }
                            widgetId = { widget.key }
                        />
                    </div>
                break;

            case "weather-forecast":
                theWidget = 
                    <div key={widget.key}>
                        <WeatherForecastWidget 
                            city={widget.options.city} 
                            // eslint-disable-next-line
                            widgetId = { widget.key }
                            buildModeActive={ this.props.dashboard.buildModeActive }
                            handleEditWidget={ this.editWidget }
                            handleRemoveWidget={ this.removeWidget }
                        />
                    </div> 
                break;
            
            case "battery":
                theWidget = 
                    <div key={widget.key} >
                        <BatteryWidget 
                            gaugeId={widget.options.gaugeId} 
                            timeFrame={widget.options.timeFrame} 
                            widgetId = { widget.key }
                            buildModeActive={ this.props.dashboard.buildModeActive }
                            handleEditWidget={ this.editWidget }
                            handleRemoveWidget={ this.removeWidget }
                        />
                    </div>
                break;

            default:
                throw new Error(`An unknown widget type was passed to the dashboard: ${widget.type}`);
        }

        return theWidget;
    }

    onLayoutChange(layout, bpLayouts){
        this.props.updateCurrentDashboardLayout(bpLayouts);
    }

    removeWidget( widgetId ) {
        //this.setState({ modalIsVisible: true });
        this.props.setModalContentOptions({ type: 'removeWidget', options: { widgetId: widgetId } });
        this.props.showDashboardModal();
    }


    widgetRemovalConfirmed( widgetToRemove ) {
        //this.setState({ modalIsVisible: false });
        
        let _this = this;
        this.props.togglePreloader(true);
        this.props.closeDashboardModal();

        setTimeout(function(){ 
            _this.props.removeWidgetFromDashboard( widgetToRemove ); 
            _this.props.togglePreloader(false);
        },2000);
        
    }

    editWidget( cardData, widgetType, widgetId ) {
        let currentWidgetSettings = {};
        let currentWidgetType = null;

        for (var i = 0; i < this.props.dashboard.availableDashboards.length; i++) {
            if(this.props.dashboard.availableDashboards[i].id === this.props.dashboard.currentDashboardId ){
                let currentDashboard = this.props.dashboard.availableDashboards[i];
                //console.log(currentDashboard); 

                for( var j = 0; j < currentDashboard.widgets.length; j++ ) {
                    if( currentDashboard.widgets[j].key === widgetId ) {
                        switch( currentDashboard.widgets[j].type ) {
                            case "precip-gauge":
                            //console.log(currentDashboard);
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                selectedStation: currentDashboard.widgets[j].selectedStation,
                                options: {
                                    //gaugeType: currentDashboard.options.gaugeType,
                                    gaugeId: currentDashboard.widgets[j].options.gaugeId,
                                    size: currentDashboard.widgets[j].options.size,
                                    timeFrame: currentDashboard.widgets[j].options.timeFrame,
                                    showAccumulated: currentDashboard.widgets[j].options.showAccumulated,
                                }
                            }
                            currentWidgetType = currentDashboard.widgets[j].widgetType;  
                            break;
                
                            case "dam-gauge-opening":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                station: currentDashboard.widgets[j].station,
                                options: {
                                    stationId: currentDashboard.widgets[j].options.stationId,
                                    type: currentDashboard.widgets[j].options.type
                                }
                            }
                            currentWidgetType = "dam";
                            break;
                
                            case "time-series":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                station: currentDashboard.widgets[j].station,
                                dischargeSelected: currentDashboard.widgets[j].dischargeSelected,
                                options: {
                                    gaugeType: currentDashboard.widgets[j].options.gaugeType,
                                    gaugeId: currentDashboard.widgets[j].options.gaugeId,
                                    size: currentDashboard.widgets[j].options.size,
                                    timeFrame: currentDashboard.widgets[j].options.timeFrame
                                }
                            }
                            currentWidgetType = currentDashboard.widgets[j].widgetType;    
                            break;
                
                            case "dam-gauge":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                station: currentDashboard.widgets[j].station,
                                options: {
                                    gaugeType: currentDashboard.widgets[j].options.gaugeType,
                                    gaugeId: currentDashboard.widgets[j].options.gaugeId,
                                    size: currentDashboard.widgets[j].options.size,
                                    timeFrame: currentDashboard.widgets[j].options.timeFrame
                                }
                            }
                            currentWidgetType = "dam";
                            break;
                
                            case "station-map":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                selectedMapType: currentDashboard.widgets[j].selectedMapType,
                                options: {
                                    gaugeType: currentDashboard.widgets[j].options.gaugeType,
                                    size: currentDashboard.widgets[j].options.size,
                                }
                            }
                            currentWidgetType = "map";
                            break;
                
                            case "add-link":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                options: {
                                    url: currentDashboard.widgets[j].options.url,
                                    name: currentDashboard.widgets[j].options.name,
                                }
                            }
                            currentWidgetType = "link";
                            break;
                
                            case "public-alerts":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                widgetType: currentDashboard.widgets[j].widgetType,
                                data: currentDashboard.widgets[j].data,
                                selectedStation: currentDashboard.widgets[j].selectedStation,
                                options: {
                                    region: currentDashboard.widgets[j].options.region,
                                }
                            }
                            currentWidgetType = "misc";
                            break;
        
                            case "battery":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                selectedStation: currentDashboard.widgets[j].selectedStation,
                                widgetType: currentDashboard.widgets[j].widgetType,
                                data: currentDashboard.widgets[j].data,
                                options: {
                                    gaugeId: currentDashboard.widgets[j].options.gaugeId,
                                    timeframe: currentDashboard.widgets[j].options.timeFrame,
                                }
                            }
                            currentWidgetType = "misc";
                            break;
        
                            case "weather-forecast":
                            currentWidgetSettings = {
                                id: currentDashboard.widgets[j].key,
                                type: currentDashboard.widgets[j].type,
                                widgetType: currentDashboard.widgets[j].widgetType,
                                selectedStation: currentDashboard.widgets[j].selectedStation,
                                data: currentDashboard.widgets[j].data,
                                options: {
                                    city: currentDashboard.widgets[j].options.city,
                                }
                            }
                            currentWidgetType = "misc";
                            break;

                            default:
                            break;
                        }
                    }
                }
            }
        }

        this.props.setModalContentOptions({ type: 'edit', options: { widgetType: currentWidgetType, editData: currentWidgetSettings } });
    }

    getDamSafetyCodeData() {
		apiRequest( getRoute('damOpsStations', [], { is_dam: true, page: 1 })) 
		.then(result => {
            //console.log(result);
            this.props.togglePreloader(false);
            this.setState({damOpsStationData: result}); 

        })
        .catch( (error) => {
            //console.log(error.status);
        })
    }
    
    removeCurrentDashboard(){
        // eslint-disable-next-line
        let theDashboard = null;
        let _this = this;
        for (var i = 0; i < this.props.dashboard.availableDashboards.length; i++) {
            if( this.props.dashboard.availableDashboards[i].id === this.props.dashboard.currentDashboardId ){
                theDashboard = this.props.dashboard.availableDashboards[i];
            }
        }
        this.props.closeDashboardModal();
        this.props.togglePreloader(true);

      //this.props.deleteDashboard(this.props.dashboard.currentDashboardId);
        //console.log(this.props.dashboard);
        apiRequest( getRoute('getDashboard',[this.props.dashboard.currentDashboardId]), {
              method: 'DELETE',
            })
             .then( theResult => {
                //console.log('successfully deleted', theResult);
                this.setState({ dashboardDeletedConfirmationMessage: "Dashboard successfully deleted!" });
                setTimeout(function(){
                    _this.props.deleteDashboard( _this.props.dashboard.currentDashboardId );
                    _this.setState({ dashboardDeletedConfirmationMessage: "" });
                },3000);
                
                this.props.togglePreloader(false);
            })  
            .catch( theError => {

                //console.log('delete fail', theError);
                //TODO: Error for dashboard
            })

    }

    handleReportRequestFromVisualization(obj) {
        //console.log(obj);
        this.props.setDashboardVisualizationRequest(obj);
        this.props.history.push('/reports/results');
    }

    getPrecipTotals() {
		apiRequest( getRoute("precipTotals"))
		.then(result => {
			//console.log(result);
			this.setState({ precipitationTotals: result.results });

		})
		.catch( (error) => {
			//console.log( 'Error', error );
		})
    }

	render() {
        /*console.log("************************************");
        console.log(JSON.stringify(this.props.dashboard.currentDashboard));
        console.log(JSON.stringify(this.props.dashboard.currentGridLayout));
        console.log("************************************");*/

        //console.log(this.props);

        let widgets = null;
        let currentLayout = {};
        for (var i = 0; i < this.props.dashboard.availableDashboards.length; i++) {
            if(this.props.dashboard.availableDashboards[i].id === this.props.dashboard.currentDashboardId ){

                if( this.props.dashboard.availableDashboards[i].layout !== null ){
                    currentLayout = this.props.dashboard.availableDashboards[i].layout;
                }

                if( this.props.dashboard.availableDashboards[i].widgets !== null ){                
                    widgets = this.props.dashboard.availableDashboards[i].widgets.map((widget, index) => {
                        return this.getDashboardWidget(widget, index)
                    });
                }
            }
        }

        /*let widgets = this.props.dashboard.currentDashboard.map((widget, index) => {
            return this.getDashboardWidget(widget, index)
        });*/

        //console.log(currentLayout);

        let buildModeMenu = null;
        let dashboardMultiMenu = null;
        //let dashboardControls = null;
        let dashboardControlsCss = "dashboard-controls"
        if( this.props.dashboard.buildModeActive === true ){
            buildModeMenu = <DashboardBuildMenu />
            dashboardMultiMenu = <DashboardMultiMenu />;
            dashboardControlsCss = "dashboard-controls open";
            /*dashboardControls =  
            <div className="dashboard-controls">
                { buildModeMenu }
                { dashboardMultiMenu }
            </div>; */
        }

        let theTitle = null;
        // eslint-disable-next-line
        for (var i = 0; i < this.props.dashboard.availableDashboards.length; i++) {
            if(this.props.dashboard.availableDashboards[i].id === this.props.dashboard.currentDashboardId ){
                theTitle = this.props.dashboard.availableDashboards[i].name;
                break;
            }
        }


        // get the content for the modal 
        let modalContent = null;
        if( this.props.dashboard.dashboardModalOptions !== null){
            //net to determine what modal content to show!
            //console.log('dashboard should be shown!', this.props.dashboard.dashboardModalOptions );

            switch(this.props.dashboard.dashboardModalOptions.type){
                case 'add':
                case 'edit':
                    modalContent = this.getDashboardWidgetModal(this.props.dashboard.dashboardModalOptions.options.widgetType, this.props.dashboard.dashboardModalOptions.options.editData);
                    break;
                case 'new':
                    modalContent = <DashboardNewModalContent 
                                        handleOpenModal={ this.doOpenModal } 
                                        />
                    break;
                case 'removeWidget':
                    modalContent = 
                    <ConfirmationModalContent  
                            userConfirmationGiven={ this.widgetRemovalConfirmed }
                            confirmationMessage={
                                <div>
                                    <span>Are you sure you want to remove this widget?</span>
                                    <p>This action can not be undone.</p>
                                </div>
                            }
                            confirmationBtnLabel="yes"
                            denyBtnLabel="No"
                            handleCloseModal={ this.handleCloseModal }
                            widgetId={ this.props.dashboard.dashboardModalOptions.options.widgetId }
                        />
                    break;
                case 'removeDashboard':
                    modalContent = 
                    <ConfirmationModalContent  
                            userConfirmationGiven={ this.removeCurrentDashboard }
                            confirmationMessage={
                                <div>
                                    <span>Are you sure you want to remove this dashboard?</span>
                                    <p>This action can not be undone.</p>
                                </div>
                            }
                            confirmationBtnLabel="yes"
                            denyBtnLabel="No"
                            handleCloseModal={ this.handleCloseModal }
                            widgetId={ true }
                        />
                    break;
                default:
                throw new Error(`Unknown modal type was passed tp dashboardModalOptions! ${this.props.dashboard.dashboardModalOptions.type}`);
            }

        }

        let draggableHandleCss = this.props.dashboard.gridItemDraggable ? "" : ".doesnt-exist";

        let dashboardGrid = ( this.state.dashboardDeletedConfirmationMessage !== "" ) ? 
        <div className="deleted-dashboard-confirmation-message"><p>{ this.state.dashboardDeletedConfirmationMessage }</p></div> :
        <div className="dashboard-grid-wrap">
            {/* Margin is the same as the margin in the dashboard margin */}
            <h1 id="main-heading" className="page-title" style={{paddingLeft: 16}}> { theTitle }</h1>
            <ResponsiveReactGridLayout  
                className="layout" 
                rowHeight={75} 
                margin={[16,16]}
                cols={{ lg: 4, md: 3, sm: 2, xs: 1 }}
                breakpoints={{lg: 1700, md: 1250, sm: 800, xs: 0 }}
                layouts={ currentLayout }
                draggableHandle={ draggableHandleCss }
                isDraggable={ true }
                onLayoutChange={this.onLayoutChange} >

                {widgets}
            </ResponsiveReactGridLayout>
        </div>

        //console.log('render layout', this.props.dashboard.currentGridLayout);
		return (
			<div className="dashboard-container" role="main">
                <Helmet>
                    <title>{ pageTitle('My Dashboard') }</title>
                </Helmet>
               
                { /*dashboardControls*/ }
                <div className={ dashboardControlsCss }>
                    { buildModeMenu }
                    { dashboardMultiMenu }
                </div>
                
                <ErrorMsg 
                    //showErrorMsg={ this.props.fetchErrorMsg.isVisible } 
                    //message={ this.props.fetchErrorMsg.errorMessage } 
                    //dismissErrorMessage={ this.dismissErrorMessage }	
                />

                { dashboardGrid }                

                { /*dashboardControls*/ }

                <div className="dashboard-feedback-container">
                    <FeedbackWidget  location={ this.props.location.pathname } />
                </div>

                <div className={ dashboardControlsCss }>
                    { buildModeMenu }
                    { dashboardMultiMenu }
                </div>

                <Modal 
                    isVisible={ this.props.dashboard.dashboardModalVisable }
                    customClass="modal-container dashboard-widget-builder"
					doCloseModal={ this.handleCloseModal }
					modalContent={ 
						<div className="station-search-container">
							<button 
                                className="closeModalBtn" 
                                id="closeModal"
                                onClick={ this.handleCloseModal }
							>
								<SvgIcon icon="menu-close" />
							</button>
							{ modalContent }
						</div>
					}
				/>

                <Preloader showPreloader={ this.props.handleTogglePreloader } />
            </div>
		)
    }
}

function mapStateToProps(state) {
	return { 
        handleTogglePreloader: state.togglePreloader,
        fetchErrorMsg: state.fetchErrorMsg,
        doToggleModal: state.toggleModal,
        dateTimeSelections: state.handleDateTimeInteraction,
        dashboard: state.dashboard,
        mainNavigation: state.mainNavigation,
	};
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators({ 
        togglePreloader: togglePreloader,
        showFetchError: showFetchError,
        toggleModal: toggleModal,
        setupStationsAndGauges: setupStationsAndGauges,
        addWidget: addWidget,
        editWidget: editWidget,
        updateCurrentDashboardLayout: updateCurrentDashboardLayout,
        removeWidgetFromDashboard: removeWidgetFromDashboard,
        setBuildMode: setBuildMode,
        setDashboardVisable: setDashboardVisable,
        setActiveBuildMenuType: setActiveBuildMenuType,
        setAvailableDashboards: setAvailableDashboards,
        setCurrentDashboard: setCurrentDashboard,
        updateSpecificDashboard: updateSpecificDashboard,
        setModalContentOptions: setModalContentOptions,
        showDashboardModal: showDashboardModal,
        closeDashboardModal: closeDashboardModal,
        setDefaultDashboard: setDefaultDashboard,
        deleteDashboard: deleteDashboard,
        setDashboardVisualizationRequest: setDashboardVisualizationRequest,
	}, dispatch)	
}

export default connect( mapStateToProps, matchDispatchToProps )( DashboardContainer ); 