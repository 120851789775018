import React, { Component } from "react";
import SvgIcon from "./SvgIcon";

class FloodStatusBar extends Component {
    constructor(props) {
        super(props);

        // notice object:
        // {
        //     type: ''  // type text string for the type of notification
        //     level: '' // level text string for the level of the notification
        //     text: ''  // text to be displayed in the banner
        //     bgColour: '' // bg-color 
        //     link: '' 
        // }

        this.state = {
            hasError: false,
            notices: [
                {
                    'type': "default",
                    'level': 'updating',
                    'text': 'Retrieving flood notice status…',
                    'link': null
                }
            ],
        };

        this.getFloodStatus = this.getFloodStatus.bind(this);
        this.getNormalBannerState = this.getNormalBannerState.bind(this);
        this.getErrorBannerState = this.getErrorBannerState.bind(this);
    }

    getNormalBannerState(){
        return {
            'type': "default",
            'level': 'normal',
            'text': 'Normal water levels in rivers and streams',
            'link': null, // link returned from api
        }
    }


    getErrorBannerState(){
        return {
            'type': "default",
            'level': 'error',
            'text': 'Flood alert status currently unavailable.',
            'link': null, // link returned from api
        }
    }

    componentDidMount() {
        let _this = this;
        this.floodStatusInterval = setInterval(function(){ _this.getFloodStatus() },30000);
        this.getFloodStatus();
    }

    componentWillUnmount() {
        clearInterval(this.floodStatusInterval); 
    }

    getFloodStatus() {

        //fetch("http://192.168.1.148:8080/trca/wp-json/trca/v1/flood-messaging") 
        //fetch("https://staging.trca.ca/wp-json/trca/v1/flood-messaging") 
        fetch("https://trca.ca/wp-json/trca/v1/flood-messaging") 
            .then(response => response.json())
            .then(data => {

                let newBannerState = [];
                if( data.notices.length){
                    newBannerState = data.notices.map( notice => {
                        return {
                            type: notice.type,
                            level: notice.level,
                            text: notice.banner_message,
                            link: notice.notice_details_url
                        }
                    }) 
                }
                else{
                    let defaultStatus = this.getNormalBannerState();
                    defaultStatus.link = data.general_info_url;
                    newBannerState.push(defaultStatus);
                }

                this.setState({'notices': newBannerState});

            })
            .catch( (error) => {
                //console.log( 'Error', error );
                this.setState({ notices: [this.getErrorBannerState()] });
            })
    }
    
    render() {


        let noticeBanners = this.state.notices.map( notice => {

            let svgIcon = null;

            if( notice.type === 'default' ){
                svgIcon = (
                    <div className="flood-notice-banner-svg" aria-hidden="true">
                        <SvgIcon icon="raindrop" />
                    </div>
                );
            }
            else if( notice.type === 'riverine' ){
                svgIcon = (
                    <div className="flood-notice-banner-svg" aria-hidden="true">
                        <SvgIcon icon="floodnotice-riverine" />
                    </div>
                );
            }
            else if( notice.type === 'shoreline' ){
                svgIcon = (
                    <div className="flood-notice-banner-svg" aria-hidden="true">
                        <SvgIcon icon="floodnotice-shoreline" />
                    </div>
                );
            }


            let typeClass = `${notice.type}-${notice.level}`;

            let bannerContent = [];
            bannerContent.push(notice.text + ' ');
            if( notice.link ){
                bannerContent.push( <a href={notice.link} key={`${notice.type}-${notice.level}-link`}>Learn&nbsp;more</a>)
            }

            return (
                <div className={`flood-notice-banner ${typeClass}`} key={typeClass}>
                    {svgIcon}
                    <p className="flood-notice-banner-text">{ bannerContent }</p>
                </div>
            )
        })

        const notificationCountClass = `notification-banners-${this.state.notices.length}`;

        return (
            <div className={`notification-banners ${notificationCountClass}`}> 
                {noticeBanners}
            </div>
        );
    }
};

    export default FloodStatusBar;