import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';
import { getRoute, apiRequest } from "../../helpers/Api";
import RadioButtonSeries from "./RadioButtonSeries";
//import CheckboxButtonSeries from "./CheckboxButtonSeries";
//import WidgetSizeLabel from "./WidgetSizeLabel";
import WidgetModalSaveBtn from "./WidgetModalSaveBtn";

class DashboardMiscModalContent extends Component {
    constructor ( props ) {
        super( props ); 

        this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.fetchMiscData = this.fetchMiscData.bind(this);
        this.getMiscContent = this.getMiscContent.bind(this); 

        if( props.editModeData) {
            let batteryData = null;
            let weatherData = null;
            let publicAlertData = null;

            switch( props.editModeData.widgetType ) {
                case "battery-level":
                batteryData = props.editModeData.data;
                break;

                case "weather-forecast":
                weatherData = props.editModeData.data;
                break;

                case "public-alerts":
                publicAlertData = props.editModeData.data;
                break;

                default:
                console.log( "An unknown widget type was selected." );
                break;
            }


            this.state = {
                station: props.editModeData.selectedStation,
                timeframe: props.editModeData.options.timeframe,
                widgetDisplayType: props.editModeData.widgetType,
                stationValid: true,
                timeframeValid: true,
                displayTypeValid: true,
                batteryGaugeData: batteryData,
                publicAlertData: publicAlertData,
                weatherData: weatherData,
                publicAlertRegion: null,
                weatherForcastCity: null,
                gaugeID: props.editModeData.options.gaugeId,
            };

            //console.log( props );
        } else {
            this.state = {
                station: null,
                timeframe: null, 
                widgetDisplayType: null,
                stationValid: true,
                timeframeValid: true,
                displayTypeValid: true,
                batteryGaugeData: null,
                publicAlertData: null,
                weatherData: null,
                publicAlertRegion: null,
                weatherForcastCity: null,
                gaugeID: null,
            };
        }
    }

    componentDidMount() { 
        this.props.handleOpenModal();
        this.getMiscContent();
    }

    handleRadioInputChange( value, name, event ) {
        switch( name ) {
            case "timeframe":
            this.setState({timeframe: value, timeframeValid: true });
            break;

            case "display-type":
            this.fetchMiscData( value );
            break;

            default:
            console.log( "An unknown radio button type was selected " );
            break;
        }

        //console.log(this.state);
    }

    dropdownSelectionChanged(e, data) {
        let gaugeID = null;

        switch( this.state.widgetDisplayType ) {
            case "battery-level":
            for( var i = 0; i < this.state.batteryGaugeData.length; i++ ) {
               if( data.value === this.state.batteryGaugeData[i].station_name) {
                   for( var j = 0; j < this.state.batteryGaugeData[i].gauge.length; j++ ) {
                        if( this.state.batteryGaugeData[i].gauge[j].sensor_name === "BATTERY" ) {
                           gaugeID = this.state.batteryGaugeData[i].gauge[j].id
                       }
                   }
               }
            }
            break;

            case "weather-forecast":
            this.setState({ weatherForcastCity: data.value });
            break;

            case "public-alerts":
            this.setState({ publicAlertRegion: data.value });
            break;

            default:
            console.log( "An unknown widget type was selected" );
            break;
        }
        
        this.setState({ 
            station: data.value,
            stationValid: true,
            gaugeID: gaugeID,
        });
    }

    addWidgetToDashboard() {
        let isFormValid = this.validateForm();
        let widgetObj = {};

        //console.log(this.state);

        if( isFormValid ) {
            let widgetToUpdate = null;

            if( this.props.editModeData  ) {
                if( this.props.editModeData.id ) {
                    widgetToUpdate = this.props.editModeData.id;
                }
            }

            switch( this.state.widgetDisplayType ) {
                case "battery-level":
                widgetObj = {
                    type: "battery",
                    order: "TODO",
                    selectedStation: this.state.station,
                    data: this.state.batteryGaugeData, 
                    widgetType: "battery-level",
                    widgetToUpdate: widgetToUpdate,
                    options: {
                        gaugeId: this.state.gaugeID,
                        timeFrame: this.state.timeframe,
                    }
                };    
                
                break;

                case "weather-forecast":
                widgetObj = {
                    type: "weather-forecast",
                    order: "TODO",
                    widgetType: "weather-forecast",
                    data: this.state.weatherData,
                    selectedStation: this.state.station,
                    widgetToUpdate: widgetToUpdate,
                    options: {
                        city: this.state.weatherForcastCity,
                    }
                };    
                break;

                case "public-alerts":
                widgetObj = {
                    type: "public-alerts",
                    order: "TODO",
                    widgetType: "public-alerts",
                    selectedStation: this.state.station,
                    data: this.state.publicAlertData,
                    widgetToUpdate: widgetToUpdate,
                    options: {
                        region: this.state.publicAlertRegion,
                    }
                };    
                break;

                default:
                console.log( "An unknown widget type was selected" );
                break;
            }

            this.props.addWidgetToScreen( widgetObj );
        } else {
            //console.log("sorry, try again");
        }
    }

    validateForm() {
        let formValid = true;
        if(!this.state.widgetDisplayType) {
            formValid = false;
            this.setState({ displayTypeValid: false });
        } else {
            this.setState({ displayTypeValid: true });
        }

        if( this.state.widgetDisplayType === "public-alerts" && !this.state.publicAlertRegion ) {
            formValid = false;
            this.setState({ stationValid: false });
        }

        //console.log( this.state );

        if( this.state.widgetDisplayType === "weather-forecast" && !this.state.weatherForcastCity ) {
            formValid = false;
            this.setState({ stationValid: false });
        }

        if( this.state.widgetDisplayType === "battery-level" ) {
            if(!this.state.timeframe) {
                formValid = false;
                this.setState({ timeframeValid: false });
            } else {
                this.setState({ timeframeValid: true });
            }
            
            if(!this.state.station) {
                formValid = false;
                this.setState({ stationValid: false });
            } else {
                this.setState({ stationValid: true }); 
            }
        }

        return formValid;
    }

    getMiscContent() {
        var data = null;

        //setup dam widget components options for modal
        let timeframes = [
            {label: "15m", value: "15m", id: "15m"},
            {label: "30m", value: "30m", id: "30m"},
            {label: "60m", value: "60m", id: "60m"},
            {label: "2h", value: "2h", id: "2h"},
            {label: "6h", value: "6h", id: "6h"},
            {label: "12h", value: "12h", id: "12h"},
            {label: "24h", value: "24h", id: "24h"},
            {label: "48h", value: "48h", id: "48h"},
            {label: "72h", value: "72h", id: "72h"}
        ];

        for( var i = 0; i < timeframes.length; i++ ) {
            if( timeframes[i].id === this.state.timeframe ) {
                timeframes[i].checked = true;
            } else {
                timeframes[i].checked = false;
            }
        }

        let displayTypes = [
            {label: "Weather Forecast", value: "weather-forecast", id: "weather-forecast"},
            {label: "Public Weather Alerts", value: "public-alerts", id: "public-alerts"},
            {label: "Battery Level", value: "battery-level", id: "battery-level"}
        ];

        for( var j = 0; j < displayTypes.length; j++ ) {
            if( displayTypes[j].id === this.state.widgetDisplayType ) {
                displayTypes[j].checked = true;
            } else {
                displayTypes[j].checked = false;
            }
        }

        let stationDropdownWidgetContent = null;
        let timeframeWidgetContent = null;
        let dropdownValidationClass =  this.state.stationValid ? "" : "dropdown-validation-error";

        switch( this.state.widgetDisplayType ) {
            case "battery-level":
            let stationList= [];
            data = this.state.batteryGaugeData

            //build search dropdown list 
            if(data) {
                // eslint-disable-next-line
                for( var i = 0; i < data.length; i++ ) {
                    var obj = {
                        key: "station-"+i,
                        value: data[i].station_name,
                        text: data[i].station_name.toLowerCase(),
                    }
                    stationList.push( obj );
                }
            }

            stationDropdownWidgetContent = 
            <div className={"station-dropdown-container "+ dropdownValidationClass + " misc-widget-station-container"}>
                <span className={"station-title " + dropdownValidationClass} >Select a station <span className="form-field-required">*</span></span>
                <Dropdown 
                    id="station-dropdown"
                    className={"all-stations " + dropdownValidationClass} 
                    placeholder='Select Station' 
                    fluid 
                    search 
                    selection 
                    options={stationList} 
                    onChange={ this.dropdownSelectionChanged }
                    defaultValue={ this.state.station }
                />
                <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a station</span>
            </div> 

            timeframeWidgetContent = 
            <RadioButtonSeries 
                title="Select a timeframe:" 
                groupName="timeframe"
                options={ timeframes }
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.timeframeValid}
                requiredValidationMessage="Please select a timeframe"
            />
            break;

            case "weather-forecast":
            let cityList= [];
            data = this.state.weatherData;
            
            //build search dropdown list 
            // eslint-disable-next-line
            for( let i = 0; i < data.results.length; i++ ) {
                let obj = {
                    key: "city-"+i,
                    value: data.results[i].city,
                    text: data.results[i].city,
                }
                cityList.push( obj );
            }

            stationDropdownWidgetContent = 
            <div className={"station-dropdown-container "+ dropdownValidationClass + " misc-widget-station-container"}>
                <span className={"station-title " + dropdownValidationClass} >Select a city <span className="form-field-required">*</span></span>
                <Dropdown 
                    id="city-dropdown"
                    className={"all-stations " + dropdownValidationClass} 
                    placeholder='Select City' 
                    fluid 
                    search 
                    selection 
                    options={cityList} 
                    onChange={ this.dropdownSelectionChanged }
                    defaultValue={ this.state.station }
                />
                <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a city</span>
            </div> 
            break;

            case "public-alerts":
            let regionList= [];
            data = this.state.publicAlertData;
            
            //build search dropdown list 
            for( let i = 0; i < data.results.length; i++ ) {
                let obj = {
                    key: "region-"+i,
                    value: data.results[i].region,
                    text: data.results[i].region,
                }
                regionList.push( obj );
            }

            stationDropdownWidgetContent = 
            <div className={"station-dropdown-container "+ dropdownValidationClass + " misc-widget-station-container"}>
                <span className={"station-title " + dropdownValidationClass} >Select a region <span className="form-field-required">*</span></span>
                <Dropdown 
                    id="region-dropdown"
                    className={"all-stations " + dropdownValidationClass} 
                    placeholder='Select Region' 
                    fluid 
                    search 
                    selection 
                    options={regionList} 
                    onChange={ this.dropdownSelectionChanged }
                    defaultValue={ this.state.station }
                />
                <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a region</span>
            </div> 

            break;

            default:
            break;
        }

        //add dam widget components to modal
        let miscContent = 
        <div >
            <h2>{ ( this.props.editModeData ? `EDIT` : `ADD A` ) } MISC WIDGET</h2>

            <RadioButtonSeries 
                title="Select type of display:"
                groupName="display-type"
                options={displayTypes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.displayTypeValid}
                requiredValidationMessage="Please select a display type"
                shouldGetFocus={ true }
            />

            { stationDropdownWidgetContent } 
            { timeframeWidgetContent }

        </div>

        return miscContent;
    }

    fetchMiscData( type ) {
        let url = null;

        //console.log(type);

        switch( type ) {
            case "battery-level":
            url = getRoute("stationAndGaugeNames", [], { type: "BATTERY" });
            break;

            case "weather-forecast":
            url = getRoute("weatherForecast");
            break;

            case "public-alerts":
            url = getRoute("weatherAlerts");
            break;

            default:
            console.log( "An unknown widget type was selected" );
            break;
        }

        this.props.handleTogglePreloader(true);
        apiRequest(url)
		.then(result => {
            this.props.handleTogglePreloader(false);
            this.props.handleOpenModal();

            //console.log( type );

            switch(type) {
                case "battery-level":
                this.setState({
                    widgetDisplayType: type, 
                    displayTypeValid: true,
                    station: null,
                    batteryGaugeData: result,
                });
                break;

                case "weather-forecast":
                this.setState({
                    widgetDisplayType: type, 
                    displayTypeValid: true,
                    station: null,
                    weatherData: result
                });
                break;

                case "public-alerts":
                this.setState({
                    widgetDisplayType: type, 
                    displayTypeValid: true,
                    station: null,
                    publicAlertData: result,
                });
                break;

                default:
                console.log( "An unknown widget type was selected" );
                break;
            }

            this.getMiscContent();

        })
        .catch( (error) => {
            //console.log( 'Error', error );
            this.props.togglePreloader(false); 
            
            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            this.props.handleTogglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };*/

            //this.props.showFetchError( obj );
        })
    }

    render() {
        let miscContent = null;
        miscContent = this.getMiscContent();

        return(
            <div className="dashboard-add-widget-modal-content" >
                { miscContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addWidgetToDashboard } />
            </div>
        );
    }
}

export default DashboardMiscModalContent;