import React, { Component } from "react";
import loadScript from '../../helpers/loadScript';
import mapStyles from "../../json/google-map-styles.json";
import $ from 'jquery';
import { yyyymmdd } from '../../helpers/gaugeHelpers';


class DataExplorerMap extends Component {

	constructor ( props ) {
		super( props ); 
        
        this.initialCenter = {
            lat: 43.6532,
            lng: -79.3832
        };


        this.state = {
            openStation: null,
        }

        //internal vars
        this.map = null;
        this.updateWaiting = false;
        this.markers = {};
        this.shouldUseBounds = true;

        this.floodPlainLayers = [];
        this.watershedLayers = [];

        //methods
        this.googleMapsReady = this.googleMapsReady.bind(this);
        this.getPopoutHTML = this.getPopoutHTML.bind(this);
        this.addGauge = this.addGauge.bind(this);
        this.removeGauge = this.removeGauge.bind(this);
        this.updateMarkers = this.updateMarkers.bind(this);
        this.getMarkerHtml = this.getMarkerHtml.bind(this);
        this.getGaugeIcon = this.getGaugeIcon.bind(this);
        this.setupFloodPlainViews = this.setupFloodPlainViews.bind(this);
        this.setupWatershedBoundies = this.setupWatershedBoundies.bind(this);
    }



     componentDidMount(){
        // Google maps 3rd party library for weather will not 
        // load inside webpack due to requiring google maps to be loaded
        // when the component mounts add the script to the the footer

        //if( window.google && window.google.maps){
            // the maps API is already loaded in the app
            this.googleMapsReady();
        // }
        // else{
        //     //google maps is not loaded
        //     const options = {
        //         id:'google-maps-script', 
        //         callBack: this.googleMapsReady.bind(this)
        //     };

        //     loadScript.addScript( 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxel8Hdnu9UVjOQf9RVvUKWskuah13Sj4', options );
        // }

    }


    googleMapsReady(){
        this.map = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: new window.google.maps.LatLng(this.initialCenter.lat, this.initialCenter.lng),
            //styles: mapStyles,
            mapTypeId: 'terrain'
        });

        if( this.updateWaiting ){
            this.updateWaiting = false;
            this.updateMarkers( this.props.stations );
        }

        //this.setupFloodPlainViews();
        this.setupWatershedBoundies();

    }


    componentDidUpdate(){
        //console.log('component did update', this.props.stations);
        if(this.map){
            this.updateMarkers( this.props.stations );
        }
        else{
            this.updateWaiting = true;
        }

    }

    shouldComponentUpdate(nextProps, nextState) {

        //console.log('shouldComponentUpdate', this.props.stations, nextProps.stations  );

        //if the last update value has not changed there is no need to re render
        //console.log( this.props.stations.length, nextProps.stations.length)
        if( this.props.stations.length !== nextProps.stations.length){
            this.shouldUseBounds = true;
            return true;
        }

        

        //check to see if the select state has changed
        for (var i = 0; i < nextProps.stations.length; i++) {

            //check to see if the amount of gauges has changed
            if( nextProps.stations[i].gauges.length !== this.props.stations[i].gauges.length ){
                return true;
            }

            //check to see if the selected property of the guages changed
            for (var b = 0; b < nextProps.stations[i].gauges.length; b++) {
                if( nextProps.stations[i].gauges[b].selected !== this.props.stations[i].gauges[b].selected){
                    return true;
                }
            }
        }

        if( this.state.openStation !== nextState.openStation){
            return true;
        }

        return false;
    }   


    componentWillUnmount(){
        // when the component unmounts remove it from the document
        // will need to further test this once a router is added and this component is destroyed
        //delete window.google;

        if( document.getElementById('google-maps-script') !== null){
            //google maps is loaded in window after being loaded once if the script is stil on the page remove it
            loadScript.removeScript('google-maps-script');
        }

    }

    setupFloodPlainViews(){

        const floodPlainFolder = 'http://trcag.dev.simalam.ca/map/fp/';
		//setup Flood Plain
		const floodPlainFiles = [
			// floodPlainFolder + 'Carruthers.kmz', 
			// floodPlainFolder + 'Don.kmz',
			// floodPlainFolder + 'Duffins.kmz',
			//floodPlainFolder + 'Etobicoke.kmz',
			// floodPlainFolder + 'Frenchmans.kmz',
			// floodPlainFolder + 'Highland.kmz',
			floodPlainFolder + 'humber11.kmz',
			//floodPlainFolder + 'ClusterMgmtZone.kmz',
			// floodPlainFolder + 'Mimico.kmz',
			// floodPlainFolder + 'Petticoat.kmz',
			// floodPlainFolder + 'Rouge.kmz'
		]; 

		for (var i = 0; i < floodPlainFiles.length; i++) {
			this.floodPlainLayers.push(
				new window.google.maps.KmlLayer({
		            url: floodPlainFiles[i],
		            preserveViewport: true            
		        })
			) 
        }

        //console.log( this.floodPlainLayers );

        for (var j = 0; j < this.floodPlainLayers.length; j++) {
        	this.floodPlainLayers[j].setMap(this.map);
        }

    }

    setupWatershedBoundies(){

    	const watershedFolder = 'http://trcag.dev.simalam.ca/map/';

    	const watershedFiles = [
    		watershedFolder + 'waterCourseKML.kml', 
			watershedFolder + 'watershedKML.kml',
    	]

    	for (var i = 0; i < watershedFiles.length; i++) {
    		this.watershedLayers.push(
    			new window.google.maps.KmlLayer({
		            url: watershedFiles[i],
		            preserveViewport: true            
		        })
    		);
        }
        
        for (var j = 0; j < this.watershedLayers.length; j++) {
        	this.watershedLayers[j].setMap(this.map);
        }

    }

    getPopoutHTML(station){

        //console.log(station);
        let gaugeButtons = '';
        var lastUpdated = new Date(0);
        let gaugeIds = [];
        let outsideRange = false;
        for (var i = 0; i < station.gauges.length; i++) {
            gaugeIds.push(station.gauges[i].id);

            let selectedClass = '';
            if( station.gauges[i].selected ){
                selectedClass = 'gauge-selected';
            }

            if( station.gauges[i].selected && station.gauges[i].withinRange !== 1 && station.gauges[i].withinRange !== undefined ){
                selectedClass += ' gauge-out-of-range';
            }

            let fromClass = '';
            let toClass = '';


            if( station.gauges[i].withinRange === 0 ){
                //both are outside range
                fromClass = fromClass + ' outside-range';
                toClass = toClass + ' outside-range';
            }
            else if( station.gauges[i].withinRange === 2){
                //range extends the begining of the data set
                fromClass = fromClass + ' outside-range';
            }
            else if( station.gauges[i].withinRange === 3){
                //date range of the selected dates extend the end of gauge
                toClass = toClass + ' outside-range';
            }
            //console.log( station.gauges[i].withinRange );
            if( station.gauges[i].withinRange !== undefined && station.gauges[i].withinRange !== 1 ){
                // runs whenever the gauge is not in range
                outsideRange = true;
            }
            


            gaugeButtons += 
                    `<li>
                        <button class="gauge-btn ${selectedClass}" data-add-gauge value="${station.gauges[i].id}">${station.gauges[i].sensorName}</button>
                        <span class="gauge-time"><span class="${fromClass}">${yyyymmdd(station.gauges[i].startDate)}</span> to <span class="${toClass}">${yyyymmdd(station.gauges[i].endDate)}</span></span>
                    </li>`;
            if( station.gauges[i].endDate > lastUpdated){
                lastUpdated = station.gauges[i].endDate;
            }
        }
        let useRows = '';
        if( station.gauges.length > 15){
            useRows = 'show-rows-4';
        }
        else if( station.gauges.length > 10 ){
            useRows = 'show-rows-3';
        }
        else if( station.gauges.length > 5){
            useRows = 'show-rows-2';
        }
        //console.log(this.state, station.id, this.state.openStation === station.id);
        //console.log(lastUpdated);
        let gaugeValidationMessage = ''; 
        if(outsideRange === true){
            gaugeValidationMessage = '<p class="validation-message">Available data is outside of the selected date range.</p>'
        }

        let html = 
            `<div class="station-popout ${useRows}" ${(this.state.openStation === station.id ? 'style="display:block"' : '')}>
                <h3 class="station-popout-station-name">${station.stationName}</h3>
                    ${gaugeValidationMessage}
                <h4 class="station-popout-sensor-heading">Select sensors:</h4>
                <ul class="station-popout-sensor-list clear">
                    ${gaugeButtons}
                </ul>
                <button data-add-all-gauges class="btn-link station-poupout-add-all" value="${gaugeIds.join(',')}">Add All</button>
                <span class="station-popout-last-update">Last Updated ${ yyyymmdd(lastUpdated) }</span>
                <button class="btn-link" data-gauge-close>
                    <span class="screen-reader-text">Close</span>
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="icon icon-cross">
                        <use xlink:Href="#icon-cross"/>
                    </svg>
                </button>
                <div class="arrow-left"></div>
            </div>`;

        return html;
    }

    addGauge(gauges){
        //console.log(gauges);
        this.props.addGauges(gauges);
    }

    removeGauge( gauges ){
        //console.log(gauges);
        this.props.removeGauges( gauges );
    }

    getGaugeIcon( station ){


        let gaugeIconGroup = '';

        // determine if this is a dam
        // dam is a reserved word if it is in the name, it is a dam
        let stationNameParts = station.stationName.toLowerCase();
        stationNameParts.split(' ');

        if(stationNameParts.indexOf('dam') !== -1 ){
            // use dam icon
            gaugeIconGroup = 
                `<g data-name="dam">
                    <path d="M10.3,4.2H23.5L32,29.9H.1Z" transform="translate(-0.1 -1.2)" />
                    <rect x="11.51" y="24.6" width="9" height="9" transform="translate(-15.98 18.64) rotate(-45)" />
                  </g>`
        }
        else{

            //check the subnetworks for a match 

            let subNetworks = station.gauges.map((gauge, index) => {
                return gauge.subNetwork.toLowerCase();
            });

            let uniqueSubNetworks = [];

            for (var i = 0; i < subNetworks.length; i++) {
                if( uniqueSubNetworks.indexOf( subNetworks[i] ) === -1 ){
                    uniqueSubNetworks.push( subNetworks[i] );
                }
            }

            //multi is the default
            gaugeIconGroup = 
                `<g id="c94a8506-701c-47d9-b97a-b5be45a5618c" data-name="multi">
                    <path d="M15.5,1.2,31.7,31.1s2.8-1-31.6-1Z" transform="translate(-0.1 -1.2)" />
                    <rect x="10.71" y="23.18" width="10.2" height="10.2" transform="translate(-15.46 18.26) rotate(-45)" />
                </g>`; 


         
            if( uniqueSubNetworks.length === 1){
                if(uniqueSubNetworks[0] === 'precip'){
                    gaugeIconGroup = 
                        `<g data-name="rain">
                        <rect x="12.61" y="27.5" width="6.6" height="6.6" transform="translate(-17.22 19.07) rotate(-45)" />
                        <circle cx="15.8" cy="15.9" r="15.1" />
                      </g>`

                }
                else if(uniqueSubNetworks[0] === 'stream'){
                    gaugeIconGroup = 
                        `<g data-name="stream">
                        <rect x="11.51" y="25.1" width="9" height="9" transform="translate(-16.34 18.79) rotate(-45)" />
                        <rect x="1.8" y="2.6" width="28.3" height="26.6" />
                      </g>`
                }

            }

        }

        //check to see if a gauge is outside the range of the selected date
        let withinRange = true;

        for (let i = 0; i < station.gauges.length; i++) {
            if( station.gauges[i].selected && station.gauges[i].withinRange !== 1 && station.gauges[i].withinRange !== undefined ){
                withinRange = false;
                break;
            }
        }


        // default is the multi icon
        const gaugeIcon = 
            `<svg data-click-marker class="station-svg ${(withinRange ? '' : 'station-svg-out-of-range')}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.9 34.76">
                ${gaugeIconGroup}
            </svg>`;

        return gaugeIcon;
    }

    getMarkerHtml( station ){

        const css = `marker`;
        const markerHtml = 
            `<div class="${css}" style="position:absolute;" title="${station.stationName}">
                ${this.getPopoutHTML(station)}
                ${this.getGaugeIcon( station )}
            </div>`;

        return markerHtml;
    }

    getMarker( station ){
        let marker = {};

     //set a google latlng for this marker
        var latLng = new window.google.maps.LatLng(station.lat,  station.lng);

        //this.markers.push(new window.google.maps.Marker(markerAttr));
        marker = new window.google.maps.OverlayView();
        marker.htmlString = this.getMarkerHtml(station);
        marker.station = station;
        marker.latlng_ = latLng;
        marker.div_ = null;
        marker.reactComponent = this;
        marker.setMap(this.map);
        marker.openState = (this.state.openStation === station.id ? true : false);

        // var debuggingMarker = new window.google.maps.Marker({
        //   position: latLng,
        //   map: this.map
        // });

        marker.togglePopup = function(event){
            event.preventDefault();
            event.stopImmediatePropagation();
            
            const popout = $(this.div_).find('.station-popout');

            if( popout.is(':visible') ){
                this.reactComponent.setState({openStation:null});
                //popout.hide();
            }
            else{
                //console.log(this.station.id);
                this.reactComponent.setState({openStation:this.station.id});
                //popout.show();
            }
            
        }

        marker.clickGauge = function(event){
            event.preventDefault();
            event.stopImmediatePropagation();

            //console.log(event);
            let value = $(event.target).val();

            const $button = $(event.target);
            if( $button.hasClass('gauge-selected') ){
                //$button.removeClass('gauge-selected')
                this.reactComponent.removeGauge([value]);
            }
            else{
                //$button.addClass('gauge-selected')
                this.reactComponent.addGauge([value]);
            }
            
            
        }

        marker.addAllGauges = function(event){
            event.preventDefault();
            event.stopImmediatePropagation();
            //console.log(event);
            let value = $(event.target).val();
            this.reactComponent.addGauge(value.split(','));
            
        }

        marker.updateStation = function(station){
            //this.htmlString = this.reactComponent.getMarkerHtml(station);
            //this.station = station;
            this.openState = (this.reactComponent.state.openStation === station.id ? true : false);
            const parsedChildDiv = $.parseHTML( this.reactComponent.getMarkerHtml(station) );
            this.station = station;
            
            //console.log(this.div_.parentNode);
            var parentNode = this.div_.parentNode;
            parentNode.replaceChild(parsedChildDiv[0], this.div_);
            this.div_ = parsedChildDiv[0];
            this.draw();
        }
     
        marker.onAdd = function(){
            var markerDiv = this.htmlString;
            this.parsedDiv = $.parseHTML(  markerDiv );
            this.div_ = this.parsedDiv[0];
            var panes = this.getPanes();
            panes.overlayImage.appendChild( this.parsedDiv[0] );
        }

        marker.draw = function() {
            // We use the point of the latlong
            // coordinates of the overlay to peg it to the correct position and size.
            // To do this, we need to retrieve the projection from the overlay.
            var overlayProjection = this.getProjection();
            
            // Returns the x and y of the lat long from the top of the screen
            var point = overlayProjection.fromLatLngToDivPixel(this.latlng_);

            //will position the div asbolutely so 0,0 so the top left
        
            var div = this.div_;
           
            var divWidth = div.offsetWidth;
            var divHeight = div.offsetHeight + 5;
        
            div.style.left = point.x - ( divWidth / 2 ) + 'px';
            div.style.top = point.y - divHeight + 'px'; //adding 5 for the triangle

            const popout = $(div).find('.station-popout');
            if( popout.is(':visible') ){

                //center the popout to the dot
                const popupHeight = popout.height();
                popout.css('top', (popupHeight / 2 * -1 ) );
                
                /* 
                **** This entire section below being removed due to google maps calling the draw method every time
                **** the map is moved, calling panBy was rerendering this icon and causing it to constantly move
                **** something must have changed with google maps since the time we launched
                **** uncommenting the block below will cause the map to become unresponsive when large popup open
                
                // center the map to the pop out
                //get the pix point from the container
                let markerPoint = overlayProjection.fromLatLngToContainerPixel(this.latlng_);
                
                //adjust the markerpoints for the size of the div
                const markerPointY = markerPoint.y - divHeight; //adjusting for the size of the marker
                const markerPointX = markerPoint.x + ( divWidth / 2 ); //adjusting for the size of the marker
                
                // get the map size constants
                const map = $('#map');
                const mapWidth = map.width();
                const mapHeight= map.height();
                const popupWidth = popout.width();

                //specify how much to move the map
                let panY = 0;
                let panX = 0;

                let popUpFromTop = (markerPointY - ( popupHeight / 2 ));
                //console.log(popUpFromTop);
                if(  popUpFromTop < 0 ){
                    //console.log('popout extends the top of the map, move the map down!');
                    // need to adjust it a little to make up for the height of the marker
                    panY = popUpFromTop - 45;
                }

                let popUpFromRight = (markerPointX + popupWidth) - mapWidth;
                //console.log( popUpFromRight + popupWidth, popUpFromRight, markerPointX, mapWidth );
                if( popUpFromRight >  0){
                    //console.log('popup extends the right of the map, move the map to the left!');
                    // need to adjust it slight to include the width of the marker plus the spacing to the left of the popup
                    panX = popUpFromRight + 100;
                }

                //if we have not adjusted for Y check if it off the bottom
                if( panY === 0 ){
                    //console.log('popup extends the bottom of the map and did not initially extend the bottom')
                    let popupFromBottom = (markerPointY + ( popupHeight / 2 )) - mapHeight;
                    //we are not adjusting for the top check to see if we need to adjust for the bottom
                    if( popupFromBottom > 0 && popUpFromTop > popupFromBottom){
                        panY = popupFromBottom + 45;
                    }
                }  

                //console.log(markerPointX, markerPointY )
                // move the map
                marker.getMap().panBy(panX, panY); 


                */
            }

            //when a gauge icon is clicked
            $(div).find('[data-click-marker]').on('click.marker', this.togglePopup.bind(this));
            $(div).find('[data-gauge-close]').on('click.close-gauge', this.togglePopup.bind(this));
            $(div).find('[data-add-gauge]').on('click.add-gauge', this.clickGauge.bind(this));
            $(div).find('[data-add-all-gauges]').on('click.add-gauge', this.addAllGauges.bind(this));
        }
        
        marker.onRemove = function() {
            this.div_.parentNode.removeChild(this.div_);
            this.div_ = null;
        };

        return marker;
    }

    updateMarkers(data) {
        var  googleBounds = new window.google.maps.LatLngBounds(); 

        let newData = {};
        for (var nd = 0; nd < data.length; nd++) {
            newData[data[nd].stationId] = data[nd];
        }
        //console.log(newData);

        const currentKeys = Object.keys(this.markers);
        const newKeys = Object.keys(newData);

        //remove any items that don't exist
        for (var i = 0; i < currentKeys.length; i++) {
            if( newKeys.indexOf( currentKeys[i] ) === -1){
                //console.log('removing ' + currentKeys[i]);
                this.markers[currentKeys[i]].setMap(null);
                delete this.markers[currentKeys[i]];
            }
        }

        //update or add new markers
        // eslint-disable-next-line   
        for (var i = 0; i < newKeys.length; i++) {
            if( this.markers[ newKeys[i] ] === undefined ){
                // we need to add it
                //console.log(' adding' + newKeys[i]);
                const marker = this.getMarker( newData[ newKeys[i] ] );
                this.markers[ newKeys[i] ] = marker;
                googleBounds.extend(marker.latlng_);
            }
            else{
                //we need to update it
                let update = false;
                // need some logic to determine if we should update
                if( this.markers[newKeys[i]].station.gauges.length !==  newData[ newKeys[i] ].gauges.length ){
                    update = true;
                }
                else if( this.markers[ newKeys[i] ].openState === true && this.state.openStation !==  this.markers[ newKeys[i] ].openState ){
                    update = true;
                }
                else if( newData[ newKeys[i] ].id === this.state.openStation ){
                    update = true;
                }
                else{
                    // need to check the gauges to see if the selected date range updated
                    let oldGauges = this.markers[newKeys[i]].station.gauges;
                    let newGauges = newData[ newKeys[i] ].gauges;
                    for (var g = 0; g < oldGauges.length; g++) {
                        if( oldGauges[g].withinRange !== newGauges[g].withinRange ){
                            update = true;
                            break;
                        }
                    }
                }



                //console.log(update);
                if( update ){                
                    //console.log(' updating ' + newKeys[i] );
                    this.markers[newKeys[i]].updateStation( newData[ newKeys[i] ] );
                }
                googleBounds.extend(this.markers[newKeys[i]].latlng_);
            }

        }

        // for (var i = 0; i < data.length; i++) {
        //     const marker = this.getMarker( data[i] );
        //     this.markers.push( marker );
        //     //googleBounds.extend(marker.latlng_);
        // }

        
        if( newKeys.length &&  this.shouldUseBounds === true ){
            this.shouldUseBounds = false;
            this.map.fitBounds(googleBounds);
        }
    }

	render() {
		return <div id="map" className="data-explorer-map" role="region" aria-labelledby="data-explorer-map"><h2 className="screen-reader-text" id="data-explorer-map">Data explorer map</h2></div>
	}
}

export default DataExplorerMap;