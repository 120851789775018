import React, { Component } from "react"; 
import SvgIcon from "../SvgIcon";
import IconTextLink from "./IconTextLink"; 
import StationReadings from "../StationReadings";
import GateStatusTable from "./GateStatusTable";
import StationDial from "./StationDial";
import StationImages from "./StationImages";
import TimeSeriesLength from "./TimeSeriesLength";
import DamSafetyCode from "./DamSafetyCode";
import RainChart from "../charts/RainChart";
import TimeSeriesChart from "../charts/TimeSeriesChart";
//import CameraModal from "../components/CameraModal";

import slugify from "../../helpers/slugify";

import { 
	yyyymmdd, 
	getRainIcon,
	getTrendIcon, 
	//getNonNullMax,
    //getNonNullMin
 } from "../../helpers/gaugeHelpers";



class StationPrimary extends Component {

	constructor(props) {
        super(props);
        this.handleSpecificTimeSeriesRequest = this.handleSpecificTimeSeriesRequest.bind(this);
 	}

 	formatViewData(stationData){ 

		let cardData = stationData.data;
				
		let viewData = {
			"gauge_id": cardData.station_id,
			"gauge_slug": cardData.slug,
			"gauge_name": cardData.pretty_station_name,
			"unique_id": cardData.station_name+" "+ cardData.sensor_name,
			"type_of": cardData.type_of,
			"graphData": null,
			"stationImages" : [],
			"gauge_date": yyyymmdd( new Date(cardData.last_updated) ),
			"icon_name" : "plus",
			"gauge_readings" : [],
			"download_link": cardData.last_72hrs_csv_data_url,
			"download_text": "Download Tabular Data (last 72 hours)",
		};

		this.currentGaugeType = cardData.type_of;

		//console.log(cardData);

		switch(cardData.type_of) {
			case 'Rain Gauge':
				//display a rain widget 
				viewData.graphData = this.parseRainData(cardData);
				viewData.iconName = getRainIcon(cardData);
				//console.log('Rain Gauge Data', viewData.graphData);
				break;

			default:
				viewData.graphData = this.parseTimeSeriesData(cardData);
				viewData.iconName = getTrendIcon(cardData);
			break;
		}


		// get the battery level
		if( cardData.lowest_24h_battery_measured_value === null){
			viewData.lowed24battery = "No battery reading in the last 72 hours.";
		}
		else{
			viewData.lowed24battery = `Lowest Battery Level (24h): ${cardData.lowest_24h_battery_measured_value}v`; 
		}

		// build the image array
		if( cardData.thumbnail_photo_url || cardData.photo_url ){
			viewData.stationImages.push({
				'id': 1,
				'thumbnail': cardData.thumbnail_photo_url ? cardData.thumbnail_photo_url : cardData.photo_url,
				'image': cardData.photo_url ? cardData.photo_url : cardData.thumbnail_photo_url ,
				'title': cardData.photo_title
			});
		}

		// build the image array
		if( cardData.thumbnail_photo_url_2 || cardData.photo_url_2 ){
			viewData.stationImages.push({
				'id': 2,
				'thumbnail': cardData.thumbnail_photo_url_2 ? cardData.thumbnail_photo_url_2 : cardData.photo_url_2,
				'image': cardData.photo_url_2 ? cardData.photo_url_2 : cardData.thumbnail_photo_url_2 ,
				'title': cardData.photo_title_2
			});
		}

		// build the image array
		if( cardData.thumbnail_photo_url_3 || cardData.photo_url_3 ){
			viewData.stationImages.push({
				'id': 3,
				'thumbnail': cardData.thumbnail_photo_url_3 ? cardData.thumbnail_photo_url_3 : cardData.photo_url_3,
				'image': cardData.photo_url_3 ? cardData.photo_url_3 : cardData.thumbnail_photo_url_3,
				'title': cardData.photo_title_3
			});
		}

		return viewData;

 	}



	// Takes the API data and converts into the format RainChart uses
	parseRainData(apiData){
		var rainData = {
			unitOfMeasure: apiData.unit_of_measure,
			timeSeriesData: apiData.time_series_data,
		}

		return rainData;
	}


	// Takes the API data and converts it into a format the WaterLevel graph component can use
	parseTimeSeriesData(apiData){
		var streamData = {
			unitOfMeasure: apiData.unit_of_measure,
			chartData: [{timeSeriesData: apiData.time_series_data}],
			lowLowLine: null,
			lowLine: null,
			normalLine: null,
			highLine: null,
			highHighLine: null
		}

		// set the low low alarm
		if( apiData.low_low_alarm === true){
			streamData.lowLowLine = apiData.low_low_limit;
		}

		// set the low alarm
		if( apiData.low_alarm === true){
			streamData.lowLine = apiData.low_limit;
		}

		//if normal is set add it to the graph
		if( apiData.normal_for_this_month !== null){
			streamData.normalLine = apiData.normal_for_this_month;
		}

		// set the high alarm
		if( apiData.high_alarm === true){
			streamData.highLine = apiData.high_limit;
		}

		//if the high high is set add it to the graph
		if( apiData.high_high_alarm === true ){
			streamData.highHighLine = apiData.high_high_limit
		}

		return streamData;
	}

	handleSpecificTimeSeriesRequest(hour) {
		this.props.handleSpecificTimeSeriesRequest(hour);
	}

	render(){

		if( !this.props.stationData.data ){
			//need a loading screen
			return null;
		}

		//console.log('before render', this.props.stationData.data);
		const viewData = this.formatViewData( this.props.stationData ); 

		//console.log(viewData);
	

		let graphComponent = null;
		const chartId = `${viewData.gauge_slug}-chart`;

		// loop over the graph data and see if there is any rain
		let showGraph = true;

		let checkChartData = viewData.graphData;
		if( checkChartData.chartData ){
			checkChartData = viewData.graphData.chartData[0];
		}

		//disabled this functionality as per client request
		/*for (var i = 0; i < checkChartData.timeSeriesData.length; i++) {

			if( checkChartData.timeSeriesData[i].measured_value !== null && checkChartData.timeSeriesData[i].measured_value !== 0 ){
				//we have found a value
				showGraph = true;
				break; //stop the loop
			}
		}*/

		let selectedSensor = null;
		for( var i = 0; i < this.props.stationData.stationSensors.length; i++ ) {
			if( this.props.stationData.stationSensors[i].selected ) {
				selectedSensor = this.props.stationData.stationSensors[i].prettyName;
			}
		}

		if( showGraph ){

			switch( this.props.stationData.data.type_of ) {
				case 'Rain Gauge':
					graphComponent = 
					<RainChart 
						uniqueID={chartId} 
						chartData={ viewData.graphData } 
						graphHeight="380" 
						type="station-detail"
						allowModeBar={true}
					/>;
					break;
				default:
					graphComponent = <div className="time-series-adjustment"><TimeSeriesChart uniqueID={chartId} chartData={ viewData.graphData } graphHeight="380" selectedSensor={ selectedSensor } type="stationDetail" allowModeBar={true} /></div>;
					break;
			}

		}

		let damCalculatorLink = null;
		if( this.props.doShowDamCalculator ) {
			//station is G Ross so show dam calculator link
			damCalculatorLink = 
			<div className="dam-calculator-btn-container">
				<button
					className=""
					onClick={ this.props.showDamCalculator }
				>
					Dam Calculator
				</button>
			</div>	
		}
		
		//create html tag for station image
		// CAMERA HERE
		let stationImages = null;

		if( viewData.stationImages.length ){
			stationImages = <div className="gauge-photo-column col-sm-12 col-md-6 col-lg-4"><StationImages images={viewData.stationImages} modalTitle={viewData.gauge_name} /></div>
		}



		//console.log(this.props.stationData.data.last_measured_value);

		// get the Dial if we are at Dundas
		let stationDial = null; 
		if( viewData.gauge_id === "HY079"){
			stationDial = 
			<div className="gauge-station-dial-column col-sm-12 col-md-6 col-lg-3">
				<StationDial 
					name="DUNDAS" 
					startValue="74.5" 
					endValue="76.7" 
					currentValue={ this.props.stationData.data.last_measured_value }
				/>
			</div>;
		}
		
		// Setup the dam info    
		let damGates = null;
		if( this.props.stationData.data.dam_gates.length){
			damGates = 
				<div className="gauge-dam-status-column col-sm-12 col-md-6 col-lg-3">
					{ damCalculatorLink }
					<GateStatusTable gateData={this.props.stationData.data.dam_gates} />
				</div>;
		}

		let damSafetyCode = null;
		if( this.props.stationData.data.dam_safety_code_colour &&  this.props.stationData.data.dam_safety_code_descriptor){
			if( viewData.gauge_id !== "HY006"){ ////they don't want Bolton Mcfall to show up  
				damSafetyCode = <DamSafetyCode colour={this.props.stationData.data.dam_safety_code_colour} description={ this.props.stationData.data.dam_safety_code_descriptor } />
			}
		}

		//setup the links to the other station guages
		let stationGauges = null;
		if( this.props.stationData.data.station_gauges.length ){
			stationGauges = this.props.stationData.data.station_gauges.map((object, index) => {
				if( object.sensor_name === this.props.stationData.data.sensor_name ){
					return <li key={index} className="piped-nav-item"><span className="active">{object.pretty_sensor_name}</span></li>
				}
				else{
					return <li key={index} className="piped-nav-item"><a href={ "/gauge/"+object.slug }>{object.pretty_sensor_name}</a></li>
					// router not working needs some aditional logic in the update
					//return <li className="piped-nav-item"><Link to={"/gauge/"+stationSlug}>{object.pretty_sensor_name}</Link></li>
				}
			});

			stationGauges = <ul className="gauge-station-links gauge-section piped-nav">{stationGauges}</ul>;
		};

		let tabularDataLink = this.props.isLoggedIn ? 
		<IconTextLink 
			icon={ "floppy-disk" }
			text={ viewData.download_text }
			link={ viewData.download_link }
		/> : null;

		return (
			<div className="station-detail-primary gauge">
				<div className="container-fluid">
					<div className="row station-top-row">
						<div className="station-details-info col-sm-12 col-md-6 col-lg-5">

							<div className="gauge-icon-section">
					            <SvgIcon icon={viewData.iconName} />
					            <h1 id="main-heading" className="gauge-name">{ viewData.gauge_name }</h1>
					        </div>

					        <div className="gauge-identification gauge-section">
								<span className="gauge-date">{ viewData.gauge_date }</span>
								<span className="gauge-id">ID# { viewData.gauge_id }</span>
							</div>

							{stationGauges}

							<div className="gauge-download-links gauge-section">
								{ tabularDataLink }
							</div>

							<StationReadings 
								readings={ this.props.stationData.data.time_series_data } 
								isMissedData={viewData.missedData} 
								unitOfMeasure={this.props.stationData.data.unit_of_measure} 
								gaugeType={ viewData.type_of }
								gaugeId={ viewData.gauge_id }
								gaugeSlug={ viewData.unique_id }
								precipTotals={ this.props.precipTotals }
							/>

							{ damSafetyCode }

							<div className="gauge-section gauge-battery-level">{ viewData.lowed24battery}</div>

							<TimeSeriesLength timeSeriesOptions={ this.props.stationData.timeSeriesOptions } clickCallback={ this.handleSpecificTimeSeriesRequest } />

						</div>

						
							{ damGates }
			
							{ stationImages }

							{ stationDial }

			
					</div>
				</div>

				<div className="station-details-chart">
					<div className="primary-graph-container">
						{ graphComponent }
					</div>
				</div>
				
			</div>
		)
	}
}

export default StationPrimary;