import React from 'react';
//import SvgIcon from "../Svg

const SimpleDropdown = ({ 
		id,
		label,
		name,
		required,
		onChange,
		isValid,
		validationMessage,
		options,
		value,
	}) => {

	const selectOptions = options.map( option => {
		return <option key={option.value} value={option.value} selected={option.selected}>{option.label}</option>
	})

	let the_id = (id ? id : `id_${name}`);
	const requiredString = ( required ? <span className="form-field-required">*</span> : null );
	const isValidClass = ( (isValid === undefined || isValid === true) ? '' :   'form-field-validation-error' );
	console.log('isValid', isValid);
	const changeFunction = function(e){
		onChange(e.target.value, name, e);
	};

	return(
		<div className={`form-field ${isValidClass}`}>
			<label htmlFor={the_id}>{label} {requiredString}</label>
            <select 
            	id={the_id} 
            	name={name}
            	onChange={changeFunction}
            	value={value}
            	>
            		{selectOptions}
            </select>
            <span className="validation-message">{validationMessage}</span>
        </div>
	)
}; 

export default SimpleDropdown;  