import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../helpers/pageTitle";

class FourOhFour extends Component {

	componentDidMount () {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className="site-content-wrap four-oh-four clear">
				<Helmet>
                    <title>{ pageTitle('Page Not Found') }</title>
                </Helmet>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xs-12">
							<h1 id="main-heading" className="four-oh-four-title">Page Not Found</h1>
							<p>This page was not found</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default FourOhFour;