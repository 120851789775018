export function validatePhone(email){
	let testValue  = email.replace(/[- ().]/g, '');


	/// TODO need to remove and add polite space
	if ( !/^\d+$/.test(testValue) ){
		return false;
	}
	if( testValue.length !== 10 ){
		return false;
	}

	return true;

}

export function validateEmail(email){
	if( /\S+@\S+\.\S+/.test(email) ){
		return true;
	}
	else{
		return false;
	}
}