import { combineReducers } from "redux";

import togglePreloader from "./togglePreloaderReducer";
import toggleSlideoutPanel from "./toggleSlideoutPanelReducer";
import handleCheckboxUpdate from "./handleCheckboxUpdateReducer";
import updateActiveCards from "./activeCardsReducer";
import gaugeMapApiData from "./gaugeMapApiDataReducer";
import mainNavigation from "./mainNavigationReducer";
import updateSlideoutMenu from "./slideoutMenuReducer"; 
import updateStationDetailData from "./updateStationDetailDataReducer";
import toggleModal from "./modalReducer";
import handleDateTimeInteraction from "./ReportBuilderReducer";
import dataExplorerReducer from "./DataExplorerReducer";
import DocumentLibraryReducer from "./DocumentLibraryReducer";
import AuthReducer from "./AuthReducer";
import DamCalculator from "./DamCalculator";
import ErrorMsgReducer from "./ErrorMsgReducer";
import DashboardReducer from "./DashboardReducer";

const allReducers = combineReducers({ 
    togglePreloader: togglePreloader,
    toggleSlideoutPanel: toggleSlideoutPanel,
    handleCheckboxUpdate: handleCheckboxUpdate,
    updateActiveCards: updateActiveCards,
    gaugeMapApiData: gaugeMapApiData,
    mainNavigation: mainNavigation,
    updateStationDetailData: updateStationDetailData,
    updateSlideoutMenu: updateSlideoutMenu,
    toggleModal: toggleModal,
    handleDateTimeInteraction: handleDateTimeInteraction,
    dataExplorer: dataExplorerReducer,
    documentLibrary: DocumentLibraryReducer,
    auth: AuthReducer,
    damCalculator: DamCalculator,
    fetchErrorMsg: ErrorMsgReducer, 
    dashboard: DashboardReducer,
});

export default allReducers;