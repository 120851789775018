import React, {Component} from "react";
import { connect } from "react-redux"; 
import { bindActionCreators } from "redux";
import { Dropdown } from 'semantic-ui-react';
import Field from "../forms/Field";    
import SvgIcon from "../SvgIcon.js";
import ConfirmationModalContent from "../ConfirmationModalContent"
import greenIcon from '../../images/safety-codes/green.jpg';
import yellowIcon from '../../images/safety-codes/yellow.jpg';
import blueIcon from '../../images/safety-codes/blue.jpg';
import orangeIcon from '../../images/safety-codes/orange.jpg';
import redIcon from '../../images/safety-codes/red.jpg';
import { getRoute, apiRequest } from "../../helpers/Api";
import { 
    togglePreloader
} from "../../actions/index";

class DamSafetyCodeSelector extends Component {
    constructor ( props ) {
        super( props ); 

        this.state = {
            safetyIconList: null,
            reasonValid: true,
            reasonForChange: "",
            statusCodeSelected: null,
            statusCodeValid: true,
            showConfirmation: false,
            updateModalStatusClass: "dam-safety-code-selector-modal",
            currentlySaving: false,
            genericError: false
        };

        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.getSafetyDropDownSelectionItems = this.getSafetyDropDownSelectionItems.bind(this);
        this.confirmationCancel = this.confirmationCancel.bind(this);
        this.doUpdateDamSafetyCode = this.doUpdateDamSafetyCode.bind(this);
    }


    doUpdateDamSafetyCode() {
        
        this.setState({currentlySaving: true});

        var data = {
            dam_safety_code: this.state.statusCodeSelected,
            station: this.props.selectedDamId,
            reason: this.state.reasonForChange
        };

        apiRequest( getRoute("setDamSafetyCode"), { 
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(result => {
                this.setState({currentlySaving: false});
                this.props.safetyCodeUpdated();
            })
            .catch( (error) => {

                if( error.status === 403 ){
                    this.props.doAuthCheck();
                    return error;
                }

                this.setState({
                    currentlySaving: false,
                    genericError: true,
                    showConfirmation: false
                });
                

            })

    }

    componentDidUpdate(prevProps, prevState){
        if( prevState.currentlySaving === false && this.state.currentlySaving === true ){
            // transitioning into save state
            this.props.togglePreloader(true, 'Saving Dam Sefety Log'); 
        }
        else if(prevState.currentlySaving === true && this.state.currentlySaving === false ){
            //transitioning into normal state
            this.props.togglePreloader(false); 
        }
    }

    getSafetyDropDownSelectionItems(){
        let safetyCodes= [];
        let data = this.props.safetyCodeData;

        //build search dropdown list 
        for( var i = 0; i < data.length; i++ ) {
            let statusIcon = null;
            switch( data[i].colour ) {
                case "green":
                statusIcon = greenIcon;
                break;
                case "blue":
                statusIcon = blueIcon;
                break;
                case "yellow":
                statusIcon = yellowIcon;
                break;
                case "orange":
                statusIcon = orangeIcon;
                break;
                case "red":
                statusIcon = redIcon;
                break;

                default:
                console.log( "The dam status icon color is missing or is of an unknown type" );
                break;    
            }

            var obj = {
                key: "codes-"+i,
                value: data[i].id,
                image: { avatar: false, src: statusIcon },
                text: data[i].category,
            }

            safetyCodes.push( obj );
        }

        return safetyCodes;

    }

    dropdownSelectionChanged(e, data) {
        this.setState({
            statusCodeSelected: data.value,
            statusCodeValid: true,
        });
    }

    handleInputChange(value, name, event) {
        if( value.length > 0 ) {
            this.setState({reasonValid: true, reasonForChange: value});
        } else {
            this.setState({reasonValid: false, reasonForChange: value});
        }
    }

    handleUpdateStatus() {
        let isValid = this.validateForm();
        if( isValid ) {
            this.setState({showConfirmation: true});
            //this.props.confirmUserSelection( this.state.statusCodeSelected, this.state.reasonForChange ); 
        }
    }

    confirmationCancel() {
        this.props.safetyCodeCancelled();
    }

    validateForm() {
        let formValid = true;
        if(this.state.reasonForChange.length === 0) {
            formValid = false;
            this.setState({ reasonValid: false });
        }

        if( !this.state.statusCodeSelected ) {
            formValid = false;
            this.setState({ statusCodeValid: false });
        }

        return formValid;
    }

    render() {
        let dropdownValidationClass =  this.state.statusCodeValid ? "" : "dropdown-validation-error";
        let modalContent = null;

        if( this.state.showConfirmation === true ) {
            modalContent = (
                <ConfirmationModalContent  
                    userConfirmationGiven={ this.doUpdateDamSafetyCode }
                    confirmationMessage="Please confirm your selection"
                    confirmationBtnLabel="Confirm"
                    denyBtnLabel="Cancel"
                    handleCloseModal={ this.confirmationCancel }
                    handleUpdateCurrentSafetyCode={ this.doUpdateDamSafetyCode }
                    disableConfirm={this.state.currentlySaving}
                    disableDeny={this.state.currentlySaving}
                />
            );
        }
        else{
            const dropDownSelectionItems = this.getSafetyDropDownSelectionItems();

            let errorMessage = null;
            if( this.state.genericError ){
                errorMessage = <div className="form-field form-field-validation-error"><div className="validation-message">There was an error submitting the safety code change. Please try again later.</div></div>
            }
            modalContent = (
                <div className="dam-safety-code-selector">
                    <div className={this.state.updateModalStatusClass}>
                        <button 
                            className="closeModalBtn" 
                            onClick={ () => this.props.safetyCodeCancelled() }
                        >
                            <SvgIcon icon="menu-close" />
                        </button>
                        <div className={"station-dropdown-container "+ dropdownValidationClass}>
                            <h2>Update status for <span className="selected-dam">{ this.props.selectedDamName.toLowerCase() }</span></h2>
                            {errorMessage}
                            <Dropdown 
                                id="station-dropdown"
                                className={"all-stations " + dropdownValidationClass} 
                                placeholder='Select Dam Status' 
                                fluid 
                                search 
                                selection 
                                options={ dropDownSelectionItems } 
                                onChange={ this.dropdownSelectionChanged }
                                searchInput={{ autoFocus: this.state.statusCodeSelected === null }}
                                value={this.state.statusCodeSelected}
                            />
                            <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a Safety Code</span>
                        </div>
                        <fieldset >
                            <Field 
                                type="textarea"
                                label="Reason for change"
                                name="reason"
                                required={true}
                                onChange={ this.handleInputChange }
                                isValid={this.state.reasonValid}
                                validationMessage="Please enter a reason for the change"
                                value={this.state.reasonForChange}
                            />
                        </fieldset>
                        <button disabled={this.state.currentlySaving === true} className="update-status-btn" onClick={ this.handleUpdateStatus }>Update Status</button>
                    </div>
                </div>

            );

        }

        return modalContent;

    }
}


function mapStateToProps(state) {
    return { 
        handleTogglePreloader: state.togglePreloader,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        togglePreloader: togglePreloader,
    }, dispatch)    
}

export default connect( mapStateToProps, matchDispatchToProps )( DamSafetyCodeSelector ) 
