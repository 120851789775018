			



import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { login, logout } from "../../actions/Auth";
import { getRoute, apiRequest } from "../../helpers/Api";
import { Field, PhoneField } from "../forms/Field";
import { validateEmail } from "../forms/Validation";


function getDefaultState(){
	return {
		fetchingRegiste: false,
		registerError: false,
		submissionSuccess: false,
		triggerSubmitError: false,
		registerFields : {
			'first_name': {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			last_name: {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			email: {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			phone: {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			phone_extension: {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			organization_name: {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			password: {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			password_repeat: {
				value: '',
				validationMessage: '',
				isValid: false,
			},
			'has_signed_tos': {
				value: 'true',
				validationMessage: '',
				isValid: true,
			},
		}
	}
}


// A nav item component
class Register extends Component {

	constructor(props) {
		super(props);
		this.inputChanged = this.inputChanged.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getValidationState = this.getValidationState.bind(this);
		this.validateAllFields = this.validateAllFields.bind(this);
		this.logout = this.logout.bind(this);
		this.state = getDefaultState();
	}

	componentDidUpdate(){
		if( this.state.triggerSubmitError === true){
			if( this.props.onSubmitError !== undefined ){
				this.props.onSubmitError();
			}
			this.setState({triggerSubmitError: false});
		}
	}

	handleSubmit(event){
		event.preventDefault();

		if( this.validateAllFields() === false){
			this.setState({triggerSubmitError: true});
			return;
		}

		this.setState({fetchingRegister: true, registerError: ''});
		let registerDetails = {}
		const stateKeys = Object.keys(this.state.registerFields);
		for (var i = 0; i < stateKeys.length; i++) {
			registerDetails[ stateKeys[i] ] = this.state.registerFields[ stateKeys[i] ].value;
		}
		registerDetails.phone = registerDetails.phone.replace(/-/g, '');

		console.log( registerDetails );

		apiRequest(getRoute('register'), {
		      method: 'POST',
		      body: JSON.stringify(registerDetails)
		    })
		    .then(result => {
			    //console.log('results', result );
			    //console.log('register success');
				this.setState({ submissionSuccess: true });
		    })
		    .catch(error => {

		    	let newState = {fetchingRegister: false, triggerSubmitError: true};
		    	//bad result
		    	if(error.badRequest){
		    		// the server returned an error
		    		console.log('register bad request');

		    		let newFields = {};
		    		const stateKeys = Object.keys(this.state.registerFields);
					//build a new state
					for (var i = 0; i < stateKeys.length; i++) {
						let inputState = {...this.state.registerFields[ stateKeys[i] ] };

						if( error.badRequest[  stateKeys[i] ] !== undefined ){
							let serverMessage = error.badRequest[ stateKeys[i] ];

							console.log(serverMessage);

							inputState.isValid = false;
							if( Array.isArray( serverMessage) ){
								inputState.validationMessage = serverMessage.map( (item) => { return <span className="validation-message">{item}</span> } );
							}
							else{
								inputState.validationMessage = serverMessage;
							}
						}
						newFields[ stateKeys[i] ] = inputState;
					}
					newState.registerFields = newFields;

					newState.registerError = '';
					if( error.badRequest.non_field_errors ){
						newState.registerError =  error.badRequest.non_field_errors;
					}


		    	}
		    	else{
		    		//network error or something else
		    		console.log('register network error')
		    		newState.registerError  = 'We were unable to create an account at this time. Please try again later.';
		    	}
		    	console.log(newState);
		    	this.setState(newState);
		    });
	}

	getValidationState(inputState, name){
		//trim it first

		switch(name){
			case 'email':
				if( inputState.value.trim() === ''){
					inputState.validationMessage = "Please enter your email address";
					inputState.isValid = false;
				}
				else if( !validateEmail( inputState.value ) ){
					inputState.validationMessage = "Please enter a valid email address";
					inputState.isValid = false;
				}
				else {
					inputState.validationMessage = "";
					inputState.isValid = true;
				}
				break;

			case 'phone':
				inputState.validationMessage = '';
				inputState.isValid = true;
				break;

			case 'phone_extension':
				inputState.validationMessage = '';
				inputState.isValid = true;
				break;	

			case 'first_name':
				if( inputState.value.trim() === ''){
					inputState.validationMessage = "Please enter your first name"
					inputState.isValid = false;
				}
				else{
					inputState.validationMessage = '';
					inputState.isValid = true;
				}
				break;
			
			case 'organization_name':
				if( inputState.value.trim() === '' ) {
					inputState.validationMessage = "Please enter your organization name or private citizen if applicable"
					inputState.isValid = false;
				} else {
					inputState.validationMessage = '';
					inputState.isValid = true;
				}
				
				break;

			case 'last_name':
				if( inputState.value.trim() === ''){
					inputState.validationMessage = "Please enter your last name"
					inputState.isValid = false;
				}
				else{
					inputState.validationMessage = '';
					inputState.isValid = true;
				}
				break;
			case 'password':
				if( inputState.value.trim() === ''){
					inputState.validationMessage = "Please enter a password"
					inputState.isValid = false;
				}
				else{
					inputState.validationMessage = '';
					inputState.isValid = true;
				}
				break;
			case 'password_repeat':
				if( inputState.value !== this.state.registerFields.password.value){
					inputState.validationMessage = "Please confirm your password"
					inputState.isValid = false;
				}
				else{
					inputState.validationMessage = '';
					inputState.isValid = true;
				}
				break;
			case 'has_signed_tos':
				if(inputState.value === ''){
					inputState.validationMessage = "You must agree to the TOS";
					inputState.isValid = true;
				}
				else{
					inputState.validationMessage = '';
					inputState.isValid = true;
				}
				break;


			default:
				throw new Error(`InputChanged called with an unknown filed name: ${name}`);
		}

		return inputState;
	}

	validateAllFields(){
		let allFieldsValid = true;
		let newState = {};

		const stateKeys = Object.keys(this.state.registerFields);

		//build a new state
		for (var i = 0; i < stateKeys.length; i++) {
			let inputState = {...this.state.registerFields[ stateKeys[i] ] };
			inputState = this.getValidationState( inputState, stateKeys[i] );
			if( inputState.isValid === false){
				allFieldsValid = false;
			}
			newState[ stateKeys[i] ] = inputState;
		}

		if( allFieldsValid !== true){
			this.setState({registerFields: newState});
		}

		return allFieldsValid;

	}


	inputChanged(value, name, event){

		let newState = {};
		const stateKeys = Object.keys(this.state.registerFields);

		if( stateKeys.indexOf(name) === -1){
			throw new Error(`inputChanged called with a input name that is not in the state: ${name}`);
		}

		//build a new state
		for (var i = 0; i < stateKeys.length; i++) {
			let inputState;
			if( name === stateKeys[i] ){
				inputState = {...this.state.registerFields[ stateKeys[i] ], value: value};
				//console.log(inputState);
				inputState = this.getValidationState( inputState, name );
			}
			else{
				inputState = {...this.state.registerFields[ stateKeys[i] ] };
			}

			newState[ stateKeys[i] ] = inputState;
			
		}

		//console.log('input changed', value, name, event)		
		this.setState({registerFields: newState});

	}

	logout(){
		this.props.logout();
	}

	render() {
		//console.log(this.props.auth);
		/*
			<Field 
				//name="has_signed_tos" 
				//label="I agree to the TOS" 
				//type="checkbox" 
				//required={true} 
				//isValid={( this.state.registerFields.has_signed_tos.validationMessage !== '' ? false : true )} 
				//onChange={ this.inputChanged } 
				//value={this.state.registerFields.has_signed_tos.value}
				//defaultChecked={this.state.registerFields.has_signed_tos.value}
				//validationMessage={this.state.registerFields.has_signed_tos.validationMessage} 
			/>
		*/
		return (
			<section>
				{ this.state.submissionSuccess ? 
					(
						<p>Success! We have sent {this.state.email} an email with instructions for activating your account.</p>
					) 
					: 
					(

						<form onSubmit={this.handleSubmit}>
							{ this.state.registerError ? ( <div role="alert" className="form-validation-summary">{this.state.registerError}</div>) : null }
							 <Field 
							 	name="first_name" 
							 	label="First Name" 
							 	type="text" 
							 	isValid={( this.state.registerFields.first_name.validationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.registerFields.first_name.validationMessage}  
							 	required={true} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.registerFields.first_name.value}
							 	 />
							
							<Field 
							 	name="last_name" 
							 	label="Last Name" 
							 	type="text" 
							 	isValid={( this.state.registerFields.last_name.validationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.registerFields.last_name.validationMessage}  
							 	required={true} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.registerFields.last_name.value}
							 	 />

							<Field 
							 	name="email" 
							 	label="Email" 
							 	type="email" 
							 	isValid={( this.state.registerFields.email.validationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.registerFields.email.validationMessage}  
							 	required={true} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.registerFields.email.value}
							 	maxLength={60}
							 	 />
							 	
							
							<PhoneField 
						 		name="phone" 
						 		label="Phone Number" 
						 		isValid={( this.state.registerFields.phone.validationMessage !== '' ? false : true )} 
						 		onChange={ this.inputChanged } 
						 		value={this.state.registerFields.phone.value}
						 		required={false}
								validationMessage={this.state.registerFields.phone.validationMessage}  
								mask="999-999-9999"
		                		defaultValue="(000) 000-0000"  />

							<Field 
							 	name="phone_extension" 
							 	label="Phone Extension" 
							 	type="text" 
							 	isValid={( this.state.registerFields.phone_extension.validationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.registerFields.phone_extension.validationMessage}  
							 	required={false} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.registerFields.phone_extension.value}
							/>	

							<Field 
							 	name="organization_name" 
							 	label="Organization Name" 
							 	type="text" 
							 	isValid={( this.state.registerFields.organization_name.validationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.registerFields.organization_name.validationMessage}  
							 	required={true} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.registerFields.organization_name.value}
							 	 />

							<Field 
							 	name="password" 
							 	label="Password" 
							 	type="password" 
							 	isValid={( this.state.registerFields.password.validationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.registerFields.password.validationMessage}  
							 	required={true} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.registerFields.password.value}
							 	 />

							<Field 
							 	name="password_repeat" 
							 	label="Confirm Password" 
							 	type="password" 
							 	isValid={( this.state.registerFields.password_repeat.validationMessage !== '' ? false : true )} 
							 	validationMessage={this.state.registerFields.password_repeat.validationMessage}  
							 	required={true} 
							 	onChange={ this.inputChanged } 
							 	value={this.state.registerFields.password_repeat.value}
							 	 />
										
							

							<p>Data accessible through this website are made available under the<br/><a href="https://trca.ca/about/open-data-licence/" target="_blank" rel="noopener noreferrer">TRCA Open Data License.</a></p>	
										 
							 <input type="submit" value="Create Account" disabled={this.state.fetchingRegister} />

						</form>
						
					)
				}

				
			</section>
		)
	}
}


function mapStateToProps(state) {
	return { 
        auth: state.auth
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
		login: login,
		logout: logout
    }, dispatch)    
}


export default connect( mapStateToProps, matchDispatchToProps )( Register ); 
