import React, { Component } from "react";
import PageWrapper from "../components/PageWrapper";
import { bindActionCreators } from "redux";
import { showLoginModal } from "../actions/Auth";
import { connect } from "react-redux";

export function RequireCapability(WrappdComponent, thePermission ){

	// Wraps the component and prevents rendering if not logged in
	class BaseRequireCapability extends Component {

		render(){

			//console.log('base', this.props.isLoggedIn, this.props.requiredPerm);

			if( this.props.requiredPerm === true ){
				// return the original component
				return <WrappdComponent />	
			}
			else if( this.props.isLoggedIn === true ){
				// logged in but do not have the permission
				return (
					<PageWrapper>
						<h1>Unauthorized</h1>
						<p>You do not have the required permissions to access this resource</p>
					</PageWrapper>
				)
			}
			else{
				// not logged in, show the message with the option to log in
				return (
					<PageWrapper>
						<h1>Unauthorized</h1>
						<p>Only authotirzed users can access this resource. Would you like to <button className="btn-link" onClick={this.props.showLoginModal}>login</button>?</p>
					</PageWrapper>
				)
			}

		}

	}
 

	function mapStateToProps(state) {
		return {
			isLoggedIn: state.auth.isLoggedIn,
	        requiredPerm: state.auth[thePermission]
	    };
	}

	function matchDispatchToProps(dispatch) {
	    return bindActionCreators({ 
			showLoginModal : showLoginModal
	    }, dispatch)    
	}

	return connect(mapStateToProps, matchDispatchToProps)(BaseRequireCapability);
}

