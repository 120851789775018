import React, { Component } from "react";
import { Link } from 'react-router-dom';
import TimeSeriesChart from "../charts/TimeSeriesChart";
import RainChart from "../charts/RainChart";
import SvgIcon from "../SvgIcon";
import $ from 'jquery';

import { yyyymmdd, hhmmampm, formatGaugeAmount, parseAccumulatedPrecipTotals } from "../../helpers/gaugeHelpers";
import loadScript from '../../helpers/loadScript';
import mapStyles from "../../json/google-map-styles.json";

class PrecipReport extends Component {
    constructor ( props ) {
        super( props ); 

        this.initialCenter = {
            lat: 43.6532,
            lng: -79.3832
        };

        this.map = null;
        this.markers = [];


        this.setupReportResultsView = this.setupReportResultsView.bind(this);
        this.getCalculatedTotalsTable = this.getCalculatedTotalsTable.bind(this);
        this.getPrecipitiationBarHTML = this.getPrecipitiationBarHTML.bind(this);
        this.getMarker = this.getMarker.bind(this);
        this.updateMarkers = this.updateMarkers.bind(this);
    }

    componentDidMount(){
        // Google maps 3rd party library for weather will not 
        // load inside webpack due to requiring google maps to be loaded
        // when the component mounts add the script to the the footer

        //if( window.google && window.google.maps){
            // the maps API is already loaded in the app
            this.googleMapsReady();
        // }
        // else{
        //     //google maps is not loaded
        //     const options = {
        //         id:'google-maps-script', 
        //         callBack: this.googleMapsReady.bind(this)
        //     };

        //     loadScript.addScript( 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxel8Hdnu9UVjOQf9RVvUKWskuah13Sj4', options );
        // }

    }

    componentDidUpdate(){
        this.updateMarkers( this.props.chartData.report.apiData.data );
    }


    componentWillUnmount(){
        // when the component unmounts remove it from the document
        // will need to further test this once a router is added and this component is destroyed
        //delete window.google;

        if( document.getElementById('google-maps-script') !== null){
            //google maps is loaded in window after being loaded once if the script is stil on the page remove it
            loadScript.removeScript('google-maps-script');
        }

    }

    shouldComponentUpdate( nextProps, nextState ) {
        if( this.props.chartData.timeStamp !== nextProps.chartData.timeStamp  ) {
            //console.log("not the same");
            return true;
        }  

        
        return false
    }

    googleMapsReady(){
        this.map = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: new window.google.maps.LatLng(this.initialCenter.lat, this.initialCenter.lng),
            //styles: mapStyles,
            mapTypeId: 'terrain'
        });


        this.updateMarkers( this.props.chartData.report.apiData.data );

    }

    getPrecipitiationBarHTML( gaugeApiData ){

        let totals = {};

        let min = null;
        let max = 0;
        const summariezedData = this.props.chartData.report.apiData.summariezed_data;
        for (var b = 0; b < summariezedData.length; b++) {
            const total = summariezedData[b].total;
            totals[summariezedData[b].station_id] = total;
            if( total !== null){
                if( min === null ){
                    min = total;
                }
                min = Math.min(total, min);
                max = Math.max(total, max);
            }

        }


        const gt100 = ( min > 100 && max > 100 ? true : false );

        let rainTotal = totals[gaugeApiData.station_id];
       
        if( rainTotal === null ){
            return;
        }
        //get the max rain amount

        //round to 1 decimal place
        rainTotal = rainTotal * 10;
        rainTotal = Math.round(rainTotal);
        rainTotal = rainTotal / 10;
        
        const barHeightEm = 8; // ems

        const defaultThresholds = [100, 50, 25, 10, 2, -1];


        const barHeight = [
            {
                bgColor: '#004492',
                color: 'white',
                heightPercent: 100,

            },
            {
                bgColor: '#0077ff',
                color: 'white',
                heightPercent: 83.33,
            },
            {
                bgColor: '#59a6ff',
                color: 'black',
                heightPercent: 66.66,
            },
            {
                bgColor: '#a1cdff',
                heightPercent: 50,
            },
            {
                bgColor: '#d2e7ff',
                color: 'black',
                heightPercent: 33.33,
            },
            {

                bgColor: '#f0f7ff',
                color: 'black',
                heightPercent: 16.66,
            },
        ];

        let customThresholds = [];
        for (var i = 0; i < barHeight.length; i++) {
            customThresholds.push( max * ( barHeight[i].heightPercent / 100 ) );
        }


        //find where this bar sits on the scale
        let barPos = 0;
        // eslint-disable-next-line   
        for (var b = 0; b < barHeight.length; b++) {
            let threshold = defaultThresholds[b];
            if( gt100 === true ){
                threshold = customThresholds[b];
            }
            if( rainTotal >= threshold){
                barPos = b;
                break;
            }
        }

        const barHeightStyle =  barHeightEm * (barHeight[barPos].heightPercent / 100);

        if( isNaN(rainTotal) ) {
            rainTotal = 0;
        }

        const html = 
                `<div class="precip-bar" style="height:${barHeightStyle}em;background-color:${barHeight[barPos].bgColor};color:${barHeight[barPos].color}">
                    <span class="precip-bar-value">${rainTotal}</span>
                </div>`;
        return html;

    }


    getMarker( gauge ){
        const gaugeIcon = 
                `<svg data-click-gauge="Rain Gauge" class="gauge-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 35.45">
                    <title>rain-gauge</title>
                    <g data-name="Group 2">
                        <rect data-gauge-fill data-name="background-triangle" x="12.45" y="27" width="7" height="7" transform="translate(-16.89 20.21) rotate(-45)" fill="#515151" />
                        <circle data-gauge-fill data-name="background-fill" cx="16" cy="16" r="16" fill="#515151" />
                    </g>
                    <g data-name="Rainy">
                        <path data-name="Path 21" d="M10.72,19.9v5.78a.42.42,0,0,0,.44.4.43.43,0,0,0,.39-.4V19.9a.42.42,0,0,0-.43-.4A.41.41,0,0,0,10.72,19.9Z" fill="#fff" />
                        <path data-name="Path 22" d="M13.41,19.9v4.55a.42.42,0,0,0,.83,0V19.9a.42.42,0,0,0-.44-.4A.41.41,0,0,0,13.41,19.9Z" fill="#fff" />
                        <path data-name="Path 23" d="M16.1,19.9v3.31a.42.42,0,0,0,.43.39.41.41,0,0,0,.4-.39V19.9a.42.42,0,0,0-.44-.4A.41.41,0,0,0,16.1,19.9Z" fill="#fff" />
                        <path data-name="Path 24" d="M18.79,19.9V22a.42.42,0,0,0,.39.44.43.43,0,0,0,.44-.4V19.9a.43.43,0,0,0-.44-.4A.41.41,0,0,0,18.79,19.9Z" fill="#fff" />
                        <path data-name="Path 20" d="M7,15.22a3.43,3.43,0,0,0,3.43,3.43H20.94a3.43,3.43,0,0,0,0-6.86H19.57a3.87,3.87,0,0,0-7.74,0h-1.4A3.43,3.43,0,0,0,7,15.22Z" fill="none" stroke="#fff" stroke-width="2.3" />
                    </g>
                    <path data-name="x" d="M23.63,20.71h0L18.91,16l4.72-4.71h0a.58.58,0,0,0,.11-.18.49.49,0,0,0-.11-.51L21.4,8.37a.49.49,0,0,0-.51-.11.58.58,0,0,0-.18.11h0L16,13.09,11.29,8.37h0a.58.58,0,0,0-.18-.11.49.49,0,0,0-.51.11L8.37,10.6a.49.49,0,0,0-.11.51.58.58,0,0,0,.11.18h0L13.09,16,8.37,20.71h0a.58.58,0,0,0-.11.18.49.49,0,0,0,.11.51l2.23,2.23a.49.49,0,0,0,.51.11.58.58,0,0,0,.18-.11h0L16,18.91l4.71,4.72h0a.58.58,0,0,0,.18.11.49.49,0,0,0,.51-.11l2.23-2.23a.49.49,0,0,0,.11-.51A.58.58,0,0,0,23.63,20.71Z" fill="#fff"/>
                </svg>`;

        const css = `marker trca-map-gauge rain gauge-normal`;
        const markerHtml = 
            `<div class="${css}" style="position:absolute;" title="${gauge.station_name}">
                ${ this.getPrecipitiationBarHTML( gauge )}
                ${gaugeIcon}
            </div>`;



        let marker = {};

     //set a google latlng for this marker
        var latLng = new window.google.maps.LatLng(gauge.latitude,  gauge.longitude);

        //this.markers.push(new window.google.maps.Marker(markerAttr));
        marker = new window.google.maps.OverlayView();
        marker.htmlString = markerHtml;
        marker.latlng_ = latLng;
        marker.div_ = null;
        marker.setMap(this.map);

     
        marker.onAdd = function(){
                  
            var markerDiv = this.htmlString;

            this.parsedDiv = $.parseHTML( markerDiv );
            this.div_ = this.parsedDiv[0];
            var panes = this.getPanes();
            panes.overlayImage.appendChild( this.parsedDiv[0] );

        }

        marker.draw = function() {
            // We use the point of the latlong
            // coordinates of the overlay to peg it to the correct position and size.
            // To do this, we need to retrieve the projection from the overlay.
            var overlayProjection = this.getProjection();
            
            // Returns the x and y of the lat long from the top of the screen
            var point = overlayProjection.fromLatLngToDivPixel(this.latlng_);

            //will position the div asbolutely so 0,0 so the top left
        
            var div = this.div_;
           
            var divWidth = div.offsetWidth;
            var divHeight = div.offsetHeight;
        
            div.style.left = point.x - ( divWidth / 2 ) + 'px';
            div.style.top = point.y - divHeight + 5 + 'px'; //adding 5 for the triangle


        }
        
        marker.onRemove = function() {
            this.div_.parentNode.removeChild(this.div_);
            this.div_ = null;
        };

        return marker;
    }

    updateMarkers(data) {
        var  googleBounds = new window.google.maps.LatLngBounds();
        
        if( this.markers.length > 0 ) {
            for (var j = 0; j < this.markers.length; j++) {
                this.markers[j].setMap(null);
            }
        }

        this.markers = [];


        for (var i = 0; i < data.length; i++) {
            const marker = this.getMarker( data[i] );
            this.markers.push( marker );
            googleBounds.extend(marker.latlng_);
        }

        
        if( this.markers.length){
            this.map.fitBounds(googleBounds);
        }
    }


    setupReportResultsView(data) {
        return data.map( (gauge, key) => {
            let chart = null;
            let saveChartText = "Save, "+ gauge.stationName + ": " + gauge.gaugeType + ", to dashboard";
            switch( gauge.gaugeType ) {
                case "PRECIP":
                    if( this.props.plotGraphsSeperately ) {
                        //console.log(gauge.chartData);
                        if( gauge.chartData.timeSeriesData.length ) {
                            chart = 
                            <RainChart 
                                uniqueID={`chart-${key}`} 
                                chartData={ gauge.chartData } 
                                graphHeight={500}  
                                type="report" 
                                gaugeType={ gauge.gaugeType }
                                gaugeId={gauge.gaugeId}
                                stationName={gauge.stationName}
                                saveChartText={ saveChartText }
                                canSaveCharts={ this.props.canAcccesStandardReports }
                                saveChartToDashboard={this.props.saveChartToDashboard} 
                                allowModeBar={true}
                            />
                        } else {
                            chart = 
                            <div className="card-missed-data-overlay visible">
                                No Data Available
                            </div>
                        }
                         
                    } else {
                        let precipData = parseAccumulatedPrecipTotals( gauge.chartData.chartData );
                        gauge.chartData.chartData = precipData;
                        chart = 
                        <TimeSeriesChart 
                            uniqueID={`chart-${key}`} 
                            chartData={ gauge.chartData } 
                            graphHeight={500}  type="report" 
                            gaugeType="PRECIP"
                            gaugeId={gauge.gaugeId}
                            stationName={gauge.stationName}
                            saveChartText={ saveChartText }
                            canSaveCharts={ this.props.canAcccesStandardReports }
                            saveChartToDashboard={this.props.saveChartToDashboard} 
                            allowModeBar={true}
                        />                                 
                    }
                    
                    break;
                default:
                    chart = 
                    <TimeSeriesChart 
                        uniqueID={`chart-${key}`} 
                        chartData={ gauge.chartData } 
                        graphHeight={500}  
                        type="report" 
                        gaugeId={gauge.gaugeId}
                        saveChartText={ saveChartText }
                        canSaveCharts={ this.props.canAcccesStandardReports }
                        saveChartToDashboard={this.props.saveChartToDashboard} 
                        allowModeBar={true}
                    /> 
                    break;
            }

            return (
                <div key={key} className="report-chart-result">
                    <h2>{gauge.chartTitle} from { this.formatFromToDate(this.props.dateTime.range.from) } to { this.formatFromToDate(this.props.dateTime.range.to)}</h2>
                    {chart}
                </div>
            )
        })
    }
    
    formatFromToDate(date){
        return `${yyyymmdd(date)} at ${hhmmampm(date)}` 
    }


    getCalculatedTotalsTable( summariezedData ){

        return (
            <div className="summariezed-data">
                <div className="responsive-table-wrap">
                    <table className="table-lined">
                        <thead>
                            <tr>
                                <td>Station ID</td>
                                <td>Station Name</td>
                                <td>Total (mm)</td>
                                <td>Max Intensity (mm/5min)</td>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                summariezedData.map( (data, index ) => {
                                    return (
                                        <tr key={ index }>
                                            <td>{ data.station_id }</td>
                                            <td>{ data.station_name }</td>
                                            <td>{ formatGaugeAmount(data.total) }</td>
                                            <td>{ formatGaugeAmount(data.max_intensity) }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    render() {
        var chart = null;
        if( this.props.chartData.report && this.props.chartData.report.charts ) {
            chart = this.setupReportResultsView(this.props.chartData.report.charts );
        }

        let dashboardLink = (this.props.canAcccesStandardReports) ?
        <a className="report-btn btn-link" onClick={ this.props.saveVisualizationToDashboard } ><SvgIcon icon="floppy-disk" /> Create a link to this report on your dashboard </a> : null;

        return(
            <div className="precip-report">
                
                <div id="map" className="precip-report-map"></div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">

                            { chart }

                            { this.getCalculatedTotalsTable( this.props.chartData.report.summariezedData) }

                            <div className="report-btn-container report-result-buttons">
                                <a 
                                    className="report-btn btn-link"
                                    href={ this.props.chartData.report.downloadCSV }
                                >   
                                    <SvgIcon icon="download" /> Download Tabular Data
                                </a>

                                <Link className="report-btn btn-link" to={"/data-explorer"}><SvgIcon icon="download" /> Request Data</Link>

                                {dashboardLink}

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default PrecipReport;