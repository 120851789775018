
export const addWidget = ( options ) => {
    
	return {
        type: "ADD_WIDGET",
        payload: options
    };
}

export const addSavedVisualizationWidgetToDashboard = ( options ) => {
    
	return {
        type: "ADD_SAVED_VISUALIZATION_WIDGET",
        payload: options
    };
}



export const updateCurrentDashboardLayout = ( layout ) =>{
	return {
		type: "UPDATE_CURRENT_DASHBOARD_LAYOUT",
		payload: layout
	}
}


export const setBuildMode = ( buildModeActive ) => {
	return {
        type: "SET_BUILD_MODE",
        payload: buildModeActive
    };
}

export const setDashboardVisable = ( dashboardVisable ) => {
	return {
        type: "SET_DASHBOARD_VISABLE",
        payload: dashboardVisable
    };
}

export const removeWidgetFromDashboard = ( widgetId ) => {
	return {
        type: "REMOVE_WIDGET_FROM_DASHBOARD",
        payload: widgetId
    };
}

export const editWidget = ( widget ) => {
	return {
        type: "EDIT_WIDGET",
        payload: widget
    };
}



export const setAvailableDashboards = ( dashboards ) => {
    return {
        type: "SET_AVAILABLE_DASHBOARDS",
        payload: dashboards
    };
}


export const setCurrentDashboard = ( dashboardId ) => {
    return {
        type: "SET_CURRENT_DASHBOARD",
        payload: dashboardId
    };
}


export const deleteDashboard = ( dashboardId ) => {
    return {
        type: "DELETE_DASHBOARD",
        payload: dashboardId
    }
}


export const setDefaultDashboard = ( dashboardId ) => {
    return {
        type: "SET_DEFAULT_DASHBOARD",
        payload: dashboardId
    }
}



export const addNewDashboard = ( blankDashboard ) => {
    return {
        type: "ADD_NEW_DASHBOARD",
        payload: blankDashboard
    }
}




export const updateSpecificDashboard = ( dashboard ) => {
    return {
        type: "UPDATE_SPECIFIC_DASHBOARD",
        payload: dashboard
    }
}


export const setModalContentOptions = ( dashboardModalOptions ) => {
    return {
        type: "SET_MODAL_CONTENT_OPTIONS",
        payload: dashboardModalOptions
    }
}

export const showDashboardModal = ( ) => {
    return {
        type: "SHOW_DASHBOARD_MODAL",
    }
}

export const closeDashboardModal = ( ) => {
    return {
        type: "CLOSE_DASHBOARD_MODAL",
    }
}