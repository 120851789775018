
// Not sure if I enjoy splitting them apart like this
// It may make most sense to have them in for re usability across containers?

export const setSlideoutOpen = ( slideOutOpen ) => {
	return {
        type: "SET_SLIDEOUT_OPEN",
        payload: slideOutOpen
    };
}

export const updateAllStations = ( stationData ) => {
	return {
		type: "UPDATE_ALL_STATIONS",
		payload: stationData
	}
}

export const updateAllFilters = ( filters ) => {
	return {
		type: "UPDATE_ALL_FILTERS",
		payload: filters
	}
}

export const setFilter = ( filter, status ) => {
	return {
		type: "SET_FILTER",
		filter: filter,
		status: status
	}
}

export const selectGauges = ( array ) => {

	// allows simply passing an id for single gauge selection
	if( Number.isInteger(array) ){
		array = [array];
	}

    array = array.map((item, index) => { return parseInt(item, 10) });

	return {
		type: "SELECT_GAUGES",
		payload: array
	}

}


export const deselectGauges = ( array ) => {

	// allows simply passing an id for single gauge selection
	if( Number.isInteger(array) ){
		array = [array];
	}

    array = array.map((item, index) => { return parseInt(item, 10) });

	return {
		type: "DESELECT_GAUGES",
		payload: array
	}

}

export const deselectAllGauges = () => {
    return {
        type: "DESELECT_ALL_GAUGES",
    };
};

export const selectAllGauges = () => {
    return {
        type: "SELECT_ALL_GAUGES",
    };
};

export const updateContactFormField = (field, value) => {
    return {
        type: "UPDATE_CONECT_FORM_FIELD",
        field: field,
        value: value
    };
};

export const validateAllFields = () => {
    return {
        type: "VALIDATE_ALL_FIELDS",
    };
};

export const submittingRequest = () => {
    return {
        type: "SUBMITTING_REQUEST",
    };
};

export const submitSuccess = () => {
    return {
        type: "SUBMIT_SUCCESS",
    };
};

export const submitFail = () => {
    return {
        type: "SUBMIT_FAIL",
    };
};

export const handleDataExplorerTimeRangeSelection = ( obj ) => {
    return {
        type: "SET_TIME_RANGE_SELECTION",
        payload: obj,
    };
};


export const resetDataExplorerData = () => {
    return {
        type: "RESET_DATA_EXPLORER_DATA",
    };
};

export const setMonthForDatepickerDropdown = ( date ) => {
    return {
        type: "SET_MONTH_FOR_DATE_PICKER",
        payload: date,
    };
};






