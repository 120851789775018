import React, { Component } from "react";
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { timeToInt, timeFromInt } from 'time-number';

const propTypes = {
    end:          PropTypes.string,
    format:       PropTypes.number,
    initialValue: PropTypes.any,
    onChange:     PropTypes.func,
    start:        PropTypes.string,
    step:         PropTypes.number,
    value:        PropTypes.any,
};

//var validInputCss = "";

class TimePicker extends Component {
    constructor(props) {
        super(props);

        this.end = '23:59';
        this.format = 12;
        this.initialValue = '00:00';
        this.start = '00:00';
        this.step = 15;
        this.value = this.props.value;
        this.currentValue = null;
        this.rest = [];

        this.listTimeOptions = this.listTimeOptions.bind(this);
        this.generateTimeRange = this.generateTimeRange.bind(this);
        this.generateFormattedTime = this.generateFormattedTime.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleTimeValueChanged = this.handleTimeValueChanged.bind(this);
    }

    onChange(e) {  }

    handleTimeValueChanged(e) {
        var isValid = true;
        
        if( e.target.value.length === 5) {
            var re=/^([01][0-9]|2[0-3]):[0-5][0-9]$/;
            
            isValid = re.test(e.target.value);

            var obj = {
                id: this.props.id,
                isValid: isValid
            };
    
            this.props.setTimeIsValid( obj );
        } else {
            isValid = false;
            // eslint-disable-next-line
            var obj = {
                id: this.props.id,
                isValid: isValid
            };
    
            this.props.setTimeIsValid( obj );
        }

        if( isValid ) {

            let timeValObj = {
                id: this.props.id,
                value: timeToInt(e.target.value),
                formattedValue: e.target.value    
            };

            this.props.timeValueChanged(timeValObj);
        }
    }

    listTimeOptions() {        
        return this.generateTimeRange().map((unformattedTime) => {
            const formattedTime = this.generateFormattedTime(unformattedTime);
            return {
                key: unformattedTime,
                val: formattedTime, 
            };
        });
    }

    generateTimeRange() {  }

    generateFormattedTime(time) {
        const ret = timeFromInt(time, false);
    
        if (this.format === 24) {
          return ret;
        }
    
        const found = ret.match(/^(\d+):/);
        const hour  = parseInt(found[1], 10);
    
        if (hour === 0) {
          return `${ret.replace(/^\d+/, '12')} AM`;
        } else if (hour < 12) {
          return `${ret} AM`;
        } else if (hour === 12) {
          return `${ret} PM`;
        }
    
        const newHour = hour < 22 ? `0${hour - 12}` : (hour - 12).toString();
    
        return `${ret.replace(/^\d+/, newHour)} PM`;
    }
    
    render() {

        let inputCss = this.props.isValid ? "" : "invalid";
        let errorMsg = this.props.isValid ? "" : "Please enter a valid time";

        if(this.props.id === "end-time" && this.props.placeholder) {
            if(this.props.placeholder !== "00:00" ) {
                var startTimeInputMask = 
                <InputMask 
                    id="time-input"
                    mask="99:99" 
                    defaultValue={ this.props.placeholder } 
                    className={ inputCss }
                    onChange={ this.handleTimeValueChanged }
                />
            }
        } 
        
        if( this.props.id === "start-time" ) {
            var endTimeInputMask = 
            <InputMask
                id="time-input" 
                mask="99:99" 
                defaultValue="00:00" 
                className={ inputCss }
                onChange={ this.handleTimeValueChanged }
            />
        }
        
        return(
            <label htmlFor="time-input">
                <span>{this.props.label}</span>
                { startTimeInputMask }
                { endTimeInputMask }
                <span className="error-msg">{errorMsg}</span>
            </label>
        )
    }
};

TimePicker.propTypes = propTypes;

export default TimePicker;

 