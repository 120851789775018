import React, { Component } from "react";
import IconTextLink from "./station-detailed/IconTextLink";
import StationTitle from "./StationTitle";
import StationReadings from "./StationReadings";
import GaugeDamChart from "./charts/GaugeDamChart";
import TimeSeriesChart from "./charts/TimeSeriesChart.js";
import RainChart from "./charts/RainChart.js";
import { Link } from 'react-router-dom';
import slugify from "../helpers/slugify";
import { 
	yyyymmdd, 
	formatGaugeAmount,
	getRainIcon,
	getTrendIcon
 } from "../helpers/gaugeHelpers"; 

class Card extends Component {
    constructor ( props ) {
        super( props );

        this.state = {
            precipTotals: null
        };

        this.parseDamData = this.parseDamData.bind(this);
		this.parseStreamData = this.parseStreamData.bind(this);
        this.parseRainData = this.parseRainData.bind(this);
        this.getAlarmStatus = this.getAlarmStatus.bind(this);
    }

    componentDidMount() {

    }

    shouldComponentUpdate(nextProps) {

        //if the last update value has not changed there is no need to re render
        if( this.props.data.last_updated !== nextProps.data.last_updated || this.props.doUpdate) {
            //console.log( this.props.data.last_updated, nextProps.data.last_updated, "Card updating"  );
            return true;
        } else if( this.props.precipTotals !== nextProps.precipTotals ) {
            return true;
        }
        else{
            //console.log( this.props.data.last_updated, nextProps.data.last_updated, "Card not updating"  );
            return false;
        }

    }


    // Takes the API data and converts into the format GaugeDamChart uses
    parseDamData(apiData){

        var damData = {
            maxHighHigh: apiData.dam_upper_limit,
            maxHigh: apiData.high_limit,
            minLow: apiData.dam_lower_limit,
            timeSeriesData: apiData.time_series_data
        }

		return damData;
	}

    // Takes the API data and converts into the format RainChart uses
	parseRainData(apiData){
		var rainData = {
            unitOfMeasure: apiData.unit_of_measure,
            timeSeriesData: apiData.time_series_data,
        }

        return rainData;
	}

    // Takes the API data and converts it into a format the WaterLevel graph component can use
	parseStreamData(apiData){
        
        var streamData = {
            unitOfMeasure: apiData.unit_of_measure,
            chartData: [ { timeSeriesData: apiData.time_series_data } ],
            normalLine: null,
            highHighLine: null,
        }

        //if normal is set add it to the graph
        if( apiData.normal_for_this_month !== null){
            streamData.normalLine = apiData.normal_for_this_month;
        }
        //if the high high is set add it to the graph
        if( apiData.high_high_alarm === true ){
            streamData.highHighLine = apiData.high_high_limit
        }

        return streamData;
    }

    getAlarmStatus( data ) {
        // set the low alarm
        let triggered = false;
        let currentWaterLevel = null;

        for (var i = 0; i < data.time_series_data.length; i++) {
            if( data.time_series_data[i].measured_value !== null ){
                currentWaterLevel = formatGaugeAmount( data.time_series_data[i].measured_value, false, "WL");
                break;
            }
        }

        if( data.low_alarm === true){ // set the low alarm
            if( currentWaterLevel && currentWaterLevel >= data.low_limit ){
                triggered = true;
            }
        }

        if( data.high_alarm === true){ // set the high alarm
            if( currentWaterLevel && currentWaterLevel >= data.high_limit ){
                triggered = true;
            }
        }
        
        if( data.high_high_alarm === true){  //if the high high is set add it to the graph
            if( currentWaterLevel && currentWaterLevel >= data.high_high_limit ){
                triggered = true;
            }
        }

        return triggered;
    }

    render() { 

        //console.log('Card Render Called');
        //const graphHeight = {height:"150px"};
        //const chartStyles = { width: "370px", height:"150px" }
        //console.log("precip totals: ",this.props.precipTotals);

        var cardData = this.props.data;
        //console.log(this.props.data);
        var id = this.props.id;
        //console.log(this.props);

        var viewData = {
            "gauge_id": cardData.station_id,
            "gauge_name": cardData.station_name,
            "unique_id": id,
            "type_of": cardData.type_of,
            "gauge_date": yyyymmdd( new Date(cardData.last_updated) ),
            "icon_name" : "plus",
            "gauge_readings" : [],
            "download_link": cardData.last_72hrs_csv_data_url,
            "download_text": "Download Tabular Data (last 72 hours)",
            "in_alarm_state": this.getAlarmStatus(cardData),
            "dam_safety_code": this.props.safetyCode,   
        };

        if( cardData.status === "Non-responsive"){
            viewData.missedData = true;
        } else {
            viewData.missedData = false;
        }

        var graphData = null;
        

        //var templateName = '#template-card-gauge';

        switch(this.props.cardType) {
            case 'dam-gauge':
                //display a dam widget
                graphData = this.parseDamData( cardData );
                viewData.icon_name = getTrendIcon(cardData);
                break;
            case 'rain-gauge':
                //display a rain widget
                graphData = this.parseRainData(cardData);
                viewData.icon_name = getRainIcon(cardData);
                break;
            case 'time-series':
                //display a stream widget
                graphData = this.parseStreamData(cardData);
                viewData.icon_name = getTrendIcon(cardData);
                break;
            default:
                console.warn('A gauge with an unknown type was passed to: ' + id );
            break;
        }

        let iconName = viewData.icon_name,
            //gaugeReadings = viewData.gauge_readings,
            gaugeName = viewData.gauge_name,
            gaugeDate = viewData.gauge_date,
            gaugeId = viewData.gauge_id,
            //uniqueID = viewData.unique_id,
            //damSvgInfo = viewData.damSvgInfo,
            downloadLink = viewData.download_link,
            downloadText = viewData.download_text,
            sensorName = this.props.data.sensor_name,
            damSafetyCodeCss = viewData.dam_safety_code ? "dam-safety-container "+ viewData.dam_safety_code : "dam-safety-container",
            missedDataCss = "";
        
        let alarmBarCss = ( viewData.in_alarm_state === true ) ? "card-gauge-alarm-status visible" : "card-gauge-alarm-status";

        //console.log(viewData.missedData);
            
        ( viewData.missedData ) ? missedDataCss = "card-missed-data-overlay visible" : missedDataCss = "card-missed-data-overlay";

        const stationSlug = this.props.data.slug;

        let downloadCSVLink = ( this.props.isLoggedIn && !viewData.missedData ) ? 
        <IconTextLink 
            icon={ "floppy-disk" }
            text={ downloadText }
            link={ downloadLink }
        /> : null;

        let downloadCSVLinkDam = ( this.props.isLoggedIn && !viewData.missedData ) ? 
        <a href={ downloadLink } className="icon-link">{ downloadText }</a> : null;

        //console.log(this.props);
        //maureen

        if( this.props.cardType === 'dam-gauge' ) {
            return(
                <div className="map-gauge-wrap">
                    <div className="card gauge card-gauge card-dam">
                        <div className={ alarmBarCss } ></div>
                        <div className="card-gauge-content">
                            <StationTitle 
                                icon={ iconName }
                                gaugeName={ gaugeName }
                                gaugeDate={ gaugeDate }
                                gaugeId={ gaugeId }
                                sensorName={ 'STORAGE' }
                                slug={ stationSlug }
                                missedData={ viewData.missedData }
                            />
                        </div>
                        <div className="card-gauge-graph">
                            <GaugeDamChart 
                                chartData={ graphData }
                            />
                            
                        </div>
                        <div className="card-gauge-content card-gauge-right">
                            <StationReadings  
                                readings={ this.props.data.time_series_data } 
                                isMissedData={viewData.missedData} 
                                unitOfMeasure={this.props.data.unit_of_measure} 
                                includeRateOfChange={true}
                                isLoggedIn={ this.props.isLoggedIn }
                                precipTotals={ this.props.precipTotals }
                            />
                            { downloadCSVLinkDam }
                        </div>
                        <div className="card-transparent-overlay"></div> 
                        
                        <div className={ missedDataCss }>
                            <span><Link to={ "/gauge/"+stationSlug }>No Data Available</Link></span>
                        </div>
                    </div>
                </div>        
            );
        }
        else if( this.props.cardType === 'time-series' || this.props.cardType === 'rain-gauge' ) {
            let showAlarms = (this.props.data.sensor_name === "PRECIP") ? false : true;
            let graphComponent = null;
            if(  this.props.cardType === 'time-series'){
                graphComponent = <TimeSeriesChart showAlarms={showAlarms} uniqueID={viewData.unique_id} chartData={ graphData } />
            }
            else {
                graphComponent = <RainChart uniqueID={viewData.unique_id} chartData={ graphData } />
            }
            
            let damSafetyDiv = null;
            if( viewData.dam_safety_code ){
                damSafetyDiv = <div className={ damSafetyCodeCss } aria-label={"Dam safety code status: "+ viewData.dam_safety_code}></div>
            }

            let errorCss = null;
            //console.log(this.props.hasError);
            if( this.props.hasError ) {
                errorCss = "error-message";
            }

            return(
                <div className="map-gauge-wrap" >
                    <div className="card gauge card-gauge">
                        <div className={ alarmBarCss } ></div>
                        <div className={ errorCss } >{ this.props.hasError }</div>
                        <div className="card-gauge-content">
                            { damSafetyDiv } 
                            <StationTitle 
                                icon={ iconName }
                                gaugeName={ gaugeName }
                                gaugeDate={ gaugeDate }
                                gaugeId={ gaugeId }
                                sensorName={ sensorName }
                                missedData={ viewData.missedData }
                                slug={stationSlug}
                            />
                            <StationReadings 
                                readings={ this.props.data.time_series_data } 
                                isMissedData={viewData.missedData} 
                                unitOfMeasure={this.props.data.unit_of_measure} 
                                gaugeType={ viewData.type_of }
                                precipTotals={ this.props.precipTotals }
                                missedData={ viewData.missedData }
                            />
                            { downloadCSVLink }
                        </div>
                        <div className="card-gauge-graph">
                            { graphComponent }
                        </div>
                        <div className="card-transparent-overlay"></div> 
                        <div className={ missedDataCss }>
                            <span><Link to={ "/gauge/"+stationSlug }>No Data Available</Link></span>
                        </div>
                    </div>
                </div>    
            );
        }

        // Error will be thrown if a unknonwn card type is passed
        throw new Error(`Unknown cardType prop passed to Card: ${this.props.cardType}`);

    }
}

export default Card;