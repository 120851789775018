import React, {Component} from "react";
import leftPad from "left-pad";

class DamSafetyLogListDetails extends Component {

    // Expected Props:
    // Expects data serailzied using the DamSafetyCodeLogSerializer

    render() {

        //generate the row for the log
        let icon = "safety-icon "+this.props.logDetails.dam_safety_code_colour;
        let category = this.props.logDetails.dam_safety_code_category;


        let dateTime = new Date(this.props.logDetails.last_modified);
        let year = dateTime.getFullYear();
        let month = leftPad( dateTime.getMonth() + 1,2,0 );
        let day = leftPad( dateTime.getDate(),2,0 );
        let hours = dateTime.getHours();
        //let minutes = dateTime.getMinutes();
        let minutes = leftPad( dateTime.getMinutes(),2,0 );
        let dateLastModified = year+"-"+month+"-"+day+" "+hours+":"+minutes;


        return (
            <div className="safety-log">
                <div className="date-category-container">
                    <span className="date-last-modified">{ dateLastModified }</span>
                    <div className="safety-icon-status">
                        <span className={icon} aria-hidden="true"></span>
                        <span className="category">
                            { category + " " }
                            
                        </span>
                    </div>    
                </div>
                <div className="log-creator">
                    <span>{ this.props.logDetails.created_by }</span><br/>
                </div>
                <div className="log-reason">
                    <span>{ this.props.logDetails.reason }</span>
                </div>
            </div>
        );


    }
}

export default DamSafetyLogListDetails;  