import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';
//import 'semantic-ui-css/components/dropdown.min.css';
import RadioButtonSeries from "./RadioButtonSeries";
import { getRoute, apiRequest } from "../../helpers/Api";
import Field from "../forms/Field";
import WidgetSizeLabel from "./WidgetSizeLabel";
import WidgetModalSaveBtn from "./WidgetModalSaveBtn";

class DashboardPrecipModalContent extends Component {
    constructor ( props ) {
        super( props ); 

        this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
        this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);
        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);
        this.getPrecipContent = this.getPrecipContent.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.fetchPrecipData = this.fetchPrecipData.bind(this);

        if( props.editModeData && props.editModeData !== undefined) {

            this.state = {
                station: props.editModeData.selectedStation,
                timeframe: props.editModeData.options.timeFrame,
                widgetSize: props.editModeData.options.size,
                showAccumulated: props.editModeData.options.showAccumulated,
                stationValid: true,
                timeframeValid: true,
                sizeValid: true,
                stationData: null,
                defaultDropdownSelection: props.editModeData.selectedStation,
                defaultDisplayType: null,
                displayTypeSelected: null,
                selectedGaugeID: props.editModeData.options.gaugeId,
            };
        } else {
            this.state = {
                station: null,
                timeframe: null,
                widgetSize: null,
                showAccumulated: false,
                stationValid: true,
                timeframeValid: true,
                sizeValid: true,
                stationData: null,
                selectedGaugeID: null,
            };
        }
    }

    componentDidMount() { this.fetchPrecipData() }


    handleRadioInputChange( value, name, event ) {
        switch( name ) {
            case "timeframe":
            this.setState({timeframe: value, timeframeValid: true });
            break;

            case "widget-size":
            this.setState({widgetSize: value, sizeValid: true});
            break;

            default:
            console.log( "An unknown widget type was selected" );
            break;
        }
    }

    handleCheckboxInputChange(value, name, event) {
        this.setState({ showAccumulated: value });
    }

    dropdownSelectionChanged(e, data) {
        let gaugeID = null;
        
        for( var i = 0; i < this.state.stationData.length; i++ ) {
            if( data.value === this.state.stationData[i].station_name) {
                for( var j = 0; j < this.state.stationData[i].gauge.length; j++ ) {
                    gaugeID = this.state.stationData[i].gauge[j].id
                }
            }
        }

        this.setState({ 
            station: data.value,
            stationValid: true,
            selectedGaugeID: gaugeID,
        });
    }

    addWidgetToDashboard() {
        let isFormValid = this.validateForm();
        let widgetSize = null;
        let widgetToUpdate = null;

        switch( this.state.widgetSize ) {
            case "large-widget":
            widgetSize = "large";
            break;
            case "medium-widget":
            widgetSize = "medium";
            break;
            case "small-widget":
            widgetSize = "small";
            break;
            case "small":
            widgetSize = "small";
            break;
            case "medium":
            widgetSize = "medium";
            break;
            case "large":
            widgetSize = "large";
            break;
            default:
            console.log( "An unknown widget size was selected" );
            break;
        }

        if( isFormValid ) {
            //console.log("form is good to go");
            if( this.props.editModeData  ) {
                if( this.props.editModeData.id ) {
                    widgetToUpdate = this.props.editModeData.id;
                }
            }

            let widgetObj = {
                type: "precip-gauge",
                selectedStation: this.state.station,
                widgetToUpdate: widgetToUpdate,
                order: "TODO",
                widgetType:"precip",
                options: {
                    size: widgetSize,
                    timeFrame: this.state.timeframe,
                    gaugeId: Number(this.state.selectedGaugeID),
                    showAccumulated: this.state.showAccumulated,
                }
            };

            this.props.addWidgetToScreen( widgetObj );
        } else {
            //console.log("sorry, try again");
        }
    }

    validateForm() {
        let formValid = true;
        //console.log( this.state );

        if(this.state.station || this.state.defaultDropdownSelection) {
            formValid = true;
            this.setState({ stationValid: true });
        } else {
            this.setState({ stationValid: false });
        }

        if(!this.state.timeframe) {
            formValid = false;
            this.setState({ timeframeValid: false });
        } else {
            this.setState({ timeframeValid: true });
        }

        if(!this.state.widgetSize) {
            formValid = false;
            this.setState({ sizeValid: false });
        } else {
            this.setState({ sizeValid: true });
        }

        return formValid;
    }

    getPrecipContent() {
        let data = this.state.stationData;

        //setup precip widget components options for modal
        let timeframes = [
            {label: "15m", value: "15m", id: "15m"},
            {label: "30m", value: "30m", id: "30m"},
            {label: "60m", value: "60m", id: "60m"},
            {label: "2h", value: "2h", id: "2h"},
            {label: "6h", value: "6h", id: "6h"},
            {label: "12h", value: "12h", id: "12h"},
            {label: "24h", value: "24h", id: "24h"},
            {label: "48h", value: "48h", id: "48h"},
            {label: "72h", value: "72h", id: "72h"}
        ];

        for( var i = 0; i < timeframes.length; i++ ) {
            if( timeframes[i].id === this.state.timeframe ) {
                timeframes[i].checked = true;
            } else {
                timeframes[i].checked = false;
            }
        }

        let displaySizes = [
            {label: <WidgetSizeLabel size="small" label="Small"/>, value: "small", id: "small"},
            {label: <WidgetSizeLabel size="medium" label="Medium"/>, value: "medium", id: "medium"},
        ];

        for( var j = 0; j < displaySizes.length; j++ ) {
            if( displaySizes[j].id === this.state.widgetSize ) {
                displaySizes[j].checked = true;
            } else {
                displaySizes[j].checked = false;
            }
        }

        let stationList= [];

        //build search dropdown list 
        for( let i = 0; i < data.length; i++ ) {
            var obj = {
                key: "station-"+i,
                value: data[i].station_name,
                text: data[i].station_name.toLowerCase(),
            }
            stationList.push( obj );
        }

        let dropdownValidationClass =  this.state.stationValid ? "" : "dropdown-validation-error";

        //add precip widget components to modal
        let precipContent = 
        <div >
            <h2>{ ( this.props.editModeData ? `EDIT` : `ADD A` ) } PRECIP GAUGE</h2> 
            <div className={"station-dropdown-container "+ dropdownValidationClass}>
                <Dropdown 
                    id="station-dropdown"
                    className={"all-stations " + dropdownValidationClass} 
                    placeholder='Select Station' 
                    fluid 
                    search 
                    selection 
                    options={stationList} 
                    onChange={ this.dropdownSelectionChanged }
                    defaultValue={ this.state.defaultDropdownSelection }
                    searchInput={{ autoFocus: true }}
                />
                <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a station</span>
            </div> 

            <RadioButtonSeries 
                title="Select a timeframe:" 
                groupName="timeframe"
                options={ timeframes }
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.timeframeValid}
                requiredValidationMessage="Please select a timeframe"
            />
            
            <fieldset className="show-discharge-fieldset">
                <Field 
                    type="checkbox"
                    label="Show accumulated rainfall"
                    name="show-accumulated"
                    required={false}
                    defaultChecked={ this.state.showAccumulated }
                    onChange={ this.handleCheckboxInputChange }
                />
            </fieldset>

            <RadioButtonSeries 
                title="Select a size:"
                groupName="widget-size"
                iconLabel={true}
                options={displaySizes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.sizeValid}
                requiredValidationMessage="Please select a display size"
            />

        </div>

        return precipContent;
    }

    fetchPrecipData() {
        let url = getRoute("stationAndGaugeNames", [], { type: "RAIN" });

        this.props.handleTogglePreloader(true);
        apiRequest(url)
		.then(result => {
            this.props.handleTogglePreloader(false);
            this.props.handleOpenModal();
            this.setState({ stationData: result });
        })
        .catch( (error) => {
            //console.log( 'Error', error );
            this.props.togglePreloader(false); 
        })
    }

    render() {
        let precipContent = null;

        if( this.state.stationData ) {
            precipContent = this.getPrecipContent();
        }

        return(
            <div className="dashboard-add-widget-modal-content">
                { precipContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addWidgetToDashboard } />
            </div>
        );
    }
}

export default DashboardPrecipModalContent;