import React, { Component } from "react";


class PageWrapper extends Component {
    constructor(props) {
        super(props);
        this.getClasses = this.getClasses.bind(this);
    }

    getClasses(){
        return "container-fluid";
    }
    
    render() {
        return(
            <div className={ (this.props.fullWidth ? "content-wrap-full" : "content-wrap" )} role="main" >
                <div className={this.getClasses()}>
                    <div className="row">
                        <div className="col-xs-12">
                            { this.props.children }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default PageWrapper;