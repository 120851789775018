import React from 'react';

import {
	formatGaugeAmount,
	yyyymmdd,
	hhmmampm
} from "../../helpers/gaugeHelpers";

const RainAccumulatedTotals = ({
	rainGaugeTotalCollection
}) => {
	//console.log( 'rainthinger', rainGaugeTotalCollection );

    return( 
        <div className="responsive-table-wrap">
        	<table className="rain-accumulated-totals table-lined">
        		<thead>
	        		<tr>
		        		<th>Station Name</th>
		        		<th>Last Measurement</th>
		        		{
		        			rainGaugeTotalCollection[0].accumulatedTotals.map( (total, index) => {
		        				return <th key={index}>{total.time}</th>
		        			})
		        		}
		        	</tr>
		        </thead>
		        <tbody>
		        	{
		        		rainGaugeTotalCollection.map( (station, index) => {
		        			const theTime = new Date(station.lastMeasurement);
							let lastUpdatedClass = (station.lastUpdatedClass ? station.lastUpdatedClass : '' );
							return (
						        	<tr key={index}>
						        		<td>{ station.stationName }</td>
						        		<td className={lastUpdatedClass}>{ `${ yyyymmdd(theTime) } ${ hhmmampm(theTime)}` }</td>
						        		{
						        			station.accumulatedTotals.map( (total, index) => {
						        				return <td key={index}>{ formatGaugeAmount( total.measured_value ) }</td>
						        			})
						        		}
						        	</tr>
						        )
		        		})
		        	}
	        	</tbody>
        	</table>
        </div>
    );
}; 

export default RainAccumulatedTotals; 