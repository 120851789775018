import React, { Component } from "react";
import createPlotlyComponent from 'react-plotly.js/factory'
import Plotly from "plotly.js/dist/plotly-cartesian";

const PlotlyComponent = createPlotlyComponent(Plotly);

class PlotlyChart extends Component { 
	
	constructor ( props ) {
		super( props );

		this.onChartHover = this.onChartHover.bind(this);		
	}

	onChartHover(e) {
		//console.log(e);
	}

	render() { 

		// always disable the logo
		const config = this.props.config;
		config['displaylogo'] = false;

		const layout = this.props.layout;
		//console.log(layout);

		return (
			<PlotlyComponent
	            style={ this.props.style } 
	            data={ this.props.data } 
	            layout={ layout } 
	            config={ config }
				useResizeHandler={true}
				onLegendClick={this.props.onPlotlyLegendClick}  
	            ref="plot"
	        />
	    )
	}

}

export default PlotlyChart;