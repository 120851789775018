import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"; 

import { 
    deleteDashboard,
    setDefaultDashboard,
    addNewDashboard,
    setModalContentOptions,
    showDashboardModal
} from "../../actions/Dashboard";
import { getRoute, apiRequest } from "../../helpers/Api";
import { 
    togglePreloader,
} from "../../actions/index";


class DashboardMultiMenu extends Component {
    constructor ( props ) {
        super( props ); 
        this.deleteDashboardClick = this.deleteDashboardClick.bind(this);
        this.setDefaultClick = this.setDefaultClick.bind(this);
        this.newDashboardClick = this.newDashboardClick.bind(this);
    }

    deleteDashboardClick(){


        this.props.setModalContentOptions({ type: 'removeDashboard'});
        this.props.showDashboardModal();

    }

    setDefaultClick() {
        this.props.togglePreloader(true);
                let theDashboard = null;
        for (var i = 0; i < this.props.dashboard.availableDashboards.length; i++) {
            if( this.props.dashboard.availableDashboards[i].id === this.props.dashboard.currentDashboardId ){
                theDashboard = this.props.dashboard.availableDashboards[i];
            }
        }

        apiRequest( getRoute('getDashboard',[this.props.dashboard.currentDashboardId]), {
              method: 'PUT',
              body: JSON.stringify({...theDashboard, is_default: true})
            })
             .then( theResult => {
                //console.log('successfully set to default', theResult);
                this.props.setDefaultDashboard( this.props.dashboard.currentDashboardId );
                this.props.togglePreloader(false);
            })  
            .catch( theError => {

                //console.log('default fail', theError);
                //TODO: Error for dashboard
            })
    }

    newDashboardClick() {

        this.props.setModalContentOptions({ type: 'new'});

    }

    render() {
        let deleteButton = null;

        if( this.props.dashboard.availableDashboards.length > 1 ){
            deleteButton =
            <li className="menu-item-wrap menu-item-extra-spacing">
                <button className="menu-item" onClick={this.deleteDashboardClick} >Delete Dashboard</button>
            </li>
        }

        let defaultDashboardAvailable = null;
        for (var i = 0; i < this.props.dashboard.availableDashboards.length; i++) {
            if( this.props.dashboard.availableDashboards[i].id === this.props.dashboard.currentDashboardId && this.props.dashboard.availableDashboards[i].is_default !== true){
                defaultDashboardAvailable = 
                <li className="menu-item-wrap">
                    <a className="menu-item" onClick={this.setDefaultClick}>Set Default</a>
                </li>;
               break;
            }
            
        }

        return(
            <div role="navigation" aria-labelledby="multi-menu-navigation">
                <h2 id="multi-menu-navigation" className="screen-reader-text">Menu for creating and deleting dashboards</h2>
                <ul className="dashboard-multi-menu menu-buttons">
                    <li className="menu-item-wrap">
                        <button className="menu-item" onClick={this.newDashboardClick}>New Dashboard</button>
                    </li>
                    { defaultDashboardAvailable }
                    { deleteButton }
                </ul>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return { 
        dashboard: state.dashboard,
        handleTogglePreloader: state.togglePreloader,

    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
        deleteDashboard: deleteDashboard,
        togglePreloader: togglePreloader,
        setDefaultDashboard : setDefaultDashboard,
        addNewDashboard : addNewDashboard,
        setModalContentOptions: setModalContentOptions,
        showDashboardModal: showDashboardModal,
    }, dispatch)    
}

export default connect( mapStateToProps, matchDispatchToProps )( DashboardMultiMenu ); 
