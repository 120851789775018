import React, { Component } from "react";
import { Dropdown } from 'semantic-ui-react';
import { getRoute, apiRequest } from "../../helpers/Api";
import RadioButtonSeries from "./RadioButtonSeries";
import WidgetSizeLabel from "./WidgetSizeLabel";
import WidgetModalSaveBtn from "./WidgetModalSaveBtn";

class DashboardDamModalContent extends Component {
    constructor ( props ) {
        super( props ); 

        this.handleRadioInputChange = this.handleRadioInputChange.bind(this);
        this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);
        this.addWidgetToDashboard = this.addWidgetToDashboard.bind(this);
        this.dropdownSelectionChanged = this.dropdownSelectionChanged.bind(this);
        this.getDamContent = this.getDamContent.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.fetchDamData = this.fetchDamData.bind(this);
        this.updateModalOptions = this.updateModalOptions.bind(this);

        if( props.editModeData && props.editModeData !== undefined) {
            let defaultDropdownSelection = null;
            let defaultDisplayTypeSelection = null;
            let selectedGaugeType = null;
            let gaugeId = null;
            let size = null;
            let timeFrame = null;
            let hasOpening = null;

            if( props.editModeData.options.stationId ) {
                if( props.editModeData.options.stationId === "HY014" ) {
                    defaultDropdownSelection = "CLAIREVILLE DAM";
                    defaultDisplayTypeSelection = "opening";
                    selectedGaugeType = "dam-gauge-opening";
                } else if( props.editModeData.options.stationId === "HY027" ) {
                    defaultDropdownSelection = "G ROSS LORD DAM";
                    defaultDisplayTypeSelection = "opening";
                    selectedGaugeType = "dam-gauge-opening";
                }
                
                hasOpening = true;
            } else {

                defaultDropdownSelection = props.editModeData.station;
                gaugeId = props.editModeData.options.gaugeId;
                size = props.editModeData.options.size;
                timeFrame = props.editModeData.options.timeFrame;
                hasOpening = false;                
                selectedGaugeType = props.editModeData.type;
                if( props.editModeData.options.gaugeType === "WL" || props.editModeData.options.gaugeType === "time-series" ) {
                    defaultDisplayTypeSelection = "water-level";
                } else {
                    defaultDisplayTypeSelection = "reservoir-level";
                }
            }

            this.state = {
                selectedStationID: props.editModeData.options.stationId,
                selectedGaugeHasOpening: hasOpening,
                station: props.editModeData.station,
                timeframe: timeFrame,
                selectedGaugeID: gaugeId,
                selectedGaugeType: selectedGaugeType,
                waterLevelID: null,
                storageID: null,
                widgetSize: size,
                stationValid: true,
                timeframeValid: true,
                sizeValid: true,
                stationData: null,
                defaultDropdownSelection: defaultDropdownSelection,
                defaultDisplayType: defaultDisplayTypeSelection,
                displayTypeSelected: null
            };
        } else {
            this.state = {
                station: null,
                timeframe: null,
                selectedGaugeID: null,
                selectedStationID: null,
                selectedGaugeType: null,
                waterLevelID: null,
                storageID: null,
                widgetSize: null,
                stationValid: true,
                timeframeValid: true,
                sizeValid: true,
                selectedGaugeHasOpening: null,
                stationData: null,
                displayTypeSelected: null,
            };
        }
    }

    componentDidMount() { 
        this.fetchDamData() 
    }


  

    updateModalOptions( data ) {
        let storageID = null;
        let wlID = null;

        for( var i = 0; i < this.state.stationData.length; i++ ) {
            if( data.value === this.state.stationData[i].station_name) {
                for( var j = 0; j < this.state.stationData[i].gauge.length; j++ ) {
                    switch( this.state.stationData[i].gauge[j].sensor_name ) {
                        case "STORAGE":
                        storageID = this.state.stationData[i].gauge[j].id
                        break;
                        case "WL":
                        wlID = this.state.stationData[i].gauge[j].id;
                        break;
                        default:
                        console.log( "The sensor name is missing or of an unknown type." );
                        break;
                    }
                }
            }
        }

        this.setState({ 
            station: data.value,
            stationValid: true,
            storageID: storageID,
            waterLevelID: wlID,
        });

        //gross or clairville was selected so run setup again to add opening option. 
        if( data.value === "G ROSS LORD DAM" ) {
            this.setState({ selectedGaugeHasOpening: true, selectedStationID: "HY027" });
        } else if( data.value === "CLAIREVILLE DAM" ) {
            this.setState({ selectedGaugeHasOpening: true, selectedStationID: "HY014" });
        } else {
            this.setState({ selectedGaugeHasOpening: false });
        }

        /*if( data.value === "STOUFFVILLE DAM" ) {
            this.setState({ 
                defaultDisplayType: "reservoir-level" ,
                selectedGaugeType: "WL",
                selectedGaugeID: 59,
                station: data.value,
                defaultDropdownSelection: data.value,
                selectedStationID: null
            });
        }*/

        if( data.value === "MILNE DAM" ) {
            this.setState({ 
                defaultDisplayType: "water-level" ,
                selectedGaugeType: "WL",
                selectedGaugeID: 108,
                station: data.value,
                defaultDropdownSelection: data.value,
                selectedStationID: null
            });
        }

        if( this.props.editModeData !== undefined ) {
            
            if( this.props.editModeData.options.gaugeType ) {
                for( let i = 0; i < this.state.stationData.length; i++ ) {
                    if( data.value === this.state.stationData[i].station_name ) {
                        for( let j = 0; j < this.state.stationData[i].gauge.length; j++ ) {
                            if( this.props.editModeData.options.gaugeType === "WL" || this.props.editModeData.options.gaugeType === "time-series" ) {
                                if( this.state.stationData[i].gauge[j].sensor_name === "WL" || this.state.stationData[i].gauge[j].sensor_name === "time-series" ) {
                                    this.setState({selectedGaugeID: this.state.stationData[i].gauge[j].id });    
                                }        
                            }

                            if( this.props.editModeData.options.gaugeType === "STORAGE" || this.props.editModeData.options.gaugeType === "dam-gauge" ) {
                                if( this.state.stationData[i].gauge[j].sensor_name === "STORAGE" || this.state.stationData[i].gauge[j].sensor_name === "dam-gauge" ) {
                                    //console.log(); 
                                    this.setState({selectedGaugeID: this.state.stationData[i].gauge[j].id });    
                                }        
                            }
                        }
                    }    
                }
            }
        }
    }

    handleRadioInputChange( value, name, event, btnID ) {

        //console.log(value);
        
        switch( name ) {
            case "timeframe":
            this.setState({timeframe: value, timeframeValid: true, });
            break;

            case "display-type":
            //console.log( value );
            if( value === "opening" ) {
                this.setState({ selectedGaugeType: "dam-gauge-opening", defaultDisplayType: btnID }); 
            } else {
                for( var i = 0; i < this.state.stationData.length; i++ ) {
                    for( var j = 0; j < this.state.stationData[i].gauge.length; j++ ) {
                        if( value.toString() === this.state.stationData[i].gauge[j].id.toString() ) {
                            this.setState({selectedGaugeID: value, displayTypeValid: true, selectedGaugeType: this.state.stationData[i].gauge[j].sensor_name, displayTypeSelected: btnID });
                        }
                    }
                }

                this.setState({ defaultDisplayType: btnID });
            }
            
            break;

            case "widget-size":
            this.setState({widgetSize: value, sizeValid: true});
            break;

            default:
            console.log("An unknown radio button type was selected");    
            break;
        }
    }

    handleCheckboxInputChange(e) {
        //console.log(e.target.value);
    }

    dropdownSelectionChanged(e, data) {
        this.updateModalOptions(data);
    }

    addWidgetToDashboard() { 
        let isFormValid = this.validateForm();
        let widgetObj = {};
        let widgetSize = null;

        switch( this.state.widgetSize ) {
            case "large-widget":
            widgetSize = "large";
            break;
            case "medium-widget":
            widgetSize = "medium";
            break;
            case "small-widget":
            widgetSize = "small";
            break;
            case "small":
            widgetSize = "small";
            break;
            case "medium":
            widgetSize = "medium";
            break;
            case "large":
            widgetSize = "large";
            break;
            default:
            console.log( "An unknown widget size was provided" );
            break;
        }

        if( isFormValid ) {
            let widgetToUpdate = null;

            if( this.props.editModeData  ) {
                if( this.props.editModeData.id ) {
                    widgetToUpdate = this.props.editModeData.id;
                }
            }

            if( this.state.selectedGaugeType === "dam-gauge-opening" ) {
                widgetObj = {
                    widgetToUpdate: widgetToUpdate,
                    type: "dam-gauge-opening",
                    order: "TODO",
                    station: this.state.station,
                    options: {
                        stationId: this.state.selectedStationID,
                    }
                };    
            } else {
                var widgetType = null;
                if( this.state.defaultDisplayType === "water-level" ) {
                    widgetType = "time-series";
                } 

                if( this.state.defaultDisplayType === "reservoir-level" ) {
                    //console.log( this.state );
                    widgetType = "dam-gauge";
                    widgetObj = {
                        type: widgetType,
                        order: "TODO",
                        widgetToUpdate: widgetToUpdate,
                        widgetType: "dam",
                        station: this.state.station,
                        options: {
                            size: widgetSize,
                            timeFrame: this.state.timeframe,
                            gaugeId: Number(this.state.waterLevelID),
                            gaugeType: "STORAGE",
    
                        }
                    };
                } else {
                    widgetObj = {
                        type: widgetType,
                        order: "TODO",
                        widgetToUpdate: widgetToUpdate,
                        widgetType: "dam",
                        station: this.state.station,
                        options: {
                            size: widgetSize,
                            timeFrame: this.state.timeframe,
                            gaugeId: Number(this.state.selectedGaugeID),
                            gaugeType: this.state.selectedGaugeType,
    
                        }
                    };
                }
            }

            //console.log(widgetObj);

            this.props.addWidgetToScreen( widgetObj );

        } else {
            //console.log("sorry, try again"); 
        }
    }

    validateForm() {
        let formValid = true;
        if(!this.state.station) {
            formValid = false;
            this.setState({ stationValid: false });
        } else {
            this.setState({ stationValid: true });
        }

        if( this.state.selectedGaugeType !== "dam-gauge-opening" ) {
            if(!this.state.selectedGaugeID) {
                formValid = false;
                this.setState({ displayTypeValid: false });
            }

            if(!this.state.widgetSize) {
                formValid = false;
                this.setState({ sizeValid: false });
            } else {
                this.setState({ sizeValid: true });
            }

            if(!this.state.timeframe) {
                formValid = false;
                this.setState({ timeframeValid: false });
            } else {
                this.setState({ timeframeValid: true });
            }
        }

        return formValid;
    }

    getDamContent() {
        let data = this.state.stationData;

        //setup dam widget components options for modal
        let timeframes = [
            {label: "15m", value: "15m", id: "15m"},
            {label: "30m", value: "30m", id: "30m"},
            {label: "60m", value: "60m", id: "60m"},
            {label: "2h", value: "2h", id: "2h"},
            {label: "6h", value: "6h", id: "6h"},
            {label: "12h", value: "12h", id: "12h"},
            {label: "24h", value: "24h", id: "24h"},
            {label: "48h", value: "48h", id: "48h"},
            {label: "72h", value: "72h", id: "72h"}
        ];

        for( var i=0; i < timeframes.length; i++ ) {
            if( timeframes[i].id === this.state.timeframe ) {
                timeframes[i].checked = true;
            } else {
                timeframes[i].checked = false;
            }
        }

        let waterLevelID = undefined;
        let storageID = undefined;        

        if( this.state.waterLevelID ) {
            waterLevelID = this.state.waterLevelID
        }

        if( this.state.storageID ) {
            storageID = this.state.storageID
        }

        let displayTypes = [];
        if( this.state.station ) {
            if( this.state.station === "CLAIREVILLE DAM" || this.state.station === "G ROSS LORD DAM" ) {
                displayTypes.push({label: "Opening", value: "opening", id: "opening"});
                displayTypes.push({label: "Water level", value: waterLevelID, id: "water-level"});
                displayTypes.push( {label: "Reservoir level", value: storageID, id: "reservoir-level"} );

            } else if( this.state.station === "STOUFFVILLE DAM" ) {
                displayTypes.push( {label: "Reservoir level", value: storageID, id: "reservoir-level"} );
                displayTypes.push({label: "Water level", value: waterLevelID, id: "water-level"});
            } else {
                displayTypes.push({label: "Water level", value: waterLevelID, id: "water-level"});
                displayTypes.push( {label: "Reservoir level", value: storageID, id: "reservoir-level"} );
            }

            for( var p=0; p < displayTypes.length; p++ ) {
                if( this.state.defaultDisplayType === displayTypes[p].id ) {
                    displayTypes[p].checked = true;
                } else {
                    displayTypes[p].checked = false;
                }
            }
        }
        
        let displaySizes = [
            //{label: <WidgetSizeLabel size="medium" label="Medium"/>, value: "medium-widget", id: "medium"},
            {label: <WidgetSizeLabel size="small" label="Small"/>, value: "small", id: "small"},
            {label: <WidgetSizeLabel size="medium" label="Medium"/>, value: "medium", id: "medium"},
        ];

        for( var j=0; j < displaySizes.length; j++ ) {
            if( displaySizes[j].id === this.state.widgetSize ) {
                displaySizes[j].checked = true;
            } else {
                displaySizes[j].checked = false;
            }
        }

        let stationList= [];

        //build search dropdown list 
        for( let i = 0; i < data.length; i++ ) {
            var obj = {
                key: "station-"+i,
                value: data[i].station_name,
                text: data[i].station_name.toLowerCase(),
            }

            stationList.push( obj );
        }

        let gaugeTypeRadioBtnSeries = null;

        //console.log(displayTypes);
        
        if( displayTypes.length ) {
            gaugeTypeRadioBtnSeries = 
            <RadioButtonSeries 
                title="Select type of display:"
                groupName="display-type"
                options={displayTypes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.displayTypeValid}
                requiredValidationMessage="Please select a display type"
            />
        }

        let displaySizesRadioButtons = null;
        let timeframeRadioButtons = null;

        if( this.state.selectedGaugeType !== "dam-gauge-opening" && this.state.selectedGaugeType) {
            displaySizesRadioButtons = 
            <RadioButtonSeries 
                title="Select a size:"
                groupName="widget-size"
                iconLabel={true}
                options={displaySizes}
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.sizeValid}
                requiredValidationMessage="Please select a display size"
            />

            timeframeRadioButtons = 
            <RadioButtonSeries 
                title="Select a timeframe:" 
                groupName="timeframe"
                options={ timeframes }
                handleRadioInputChange={this.handleRadioInputChange}
                required={true}
                isValid={this.state.timeframeValid}
                requiredValidationMessage="Please select a timeframe"
            />

        }

        let dropdownValidationClass =  this.state.stationValid ? "" : "dropdown-validation-error";

        //add dam widget components to modal
        let damContent = 
        <div >
            <h2>{ ( this.props.editModeData ? `EDIT` : `ADD A` ) } DAM GAUGE</h2> 

            <div className={"station-dropdown-container "+ dropdownValidationClass}>
                <Dropdown  
                    id="station-dropdown"
                    className={"all-stations " + dropdownValidationClass} 
                    placeholder='Select Station' 
                    fluid 
                    search 
                    selection 
                    options={stationList} 
                    onChange={ this.dropdownSelectionChanged }
                    defaultValue={ this.state.station }
                    searchInput={{ autoFocus: true }}
                />
                <span className={"station-validation-msg "+ dropdownValidationClass} >Please Select a station</span>

            </div> 

            { gaugeTypeRadioBtnSeries }

            { timeframeRadioButtons }

            { displaySizesRadioButtons }

        </div>

        return damContent; 
    }

    fetchDamData() {

        let url = getRoute("stationAndGaugeNames", [], { type: "DAM" });
        
        this.props.handleTogglePreloader(true);
        apiRequest(url)
		.then(result => {
            this.props.handleTogglePreloader(false);
            this.props.handleOpenModal();
            this.setState({ stationData: result });

            if( this.state.station ) {
                var data = { value: this.state.station };
                this.updateModalOptions( data );
            } 
        })
        .catch( (error) => {
            //console.log( 'Error', error );
            this.props.togglePreloader(false); 
            
            /*let errorMsg = "";
            switch(error) {
                default:
                errorMsg = <p>Could not connect to server.<br/> Please contact your network administrator.</p>;
                break;
            }
            
            this.props.togglePreloader(false);
            var obj = {
                isVisible: true,
                message: errorMsg,
            };

            this.props.showFetchError( obj );*/
        })
    }

    render() {
        let damContent = null;

        if( this.state.stationData ) {
            damContent = this.getDamContent();
        } 
        
        return(
            <div className="dashboard-add-widget-modal-content">
                { damContent }
                <WidgetModalSaveBtn  handleAddWidgetToDashboard={ this.addWidgetToDashboard } />
            </div>
        );
    }
}

export default DashboardDamModalContent;

