import React, { Component } from "react";
import PlotlyChart from "./PlotlyChart.js";
import { 
	formatGaugeAmount,
	formatMeasurement,
    hhmmampm,
    //getNonNullMax,
    //getNonNullMin,
 } from "../../helpers/gaugeHelpers";

class BatteryLevelChart extends Component {
    constructor ( props ) {
        super( props ); 
        this.parseBatteryData = this.parseBatteryData.bind(this);
        this.getYAxisRange = this.getYAxisRange.bind(this);
    }

    /// returns the Y axis range including and takes into concidersation any annotation lines
    getYAxisRange( data ){
        //var customValues = [];
        let measurements = [];

        for( var i = 0; i < data.timeSeriesData.length; i++ ) {
            if( data.timeSeriesData[i].measured_value !== null ) {
                measurements.push(data.timeSeriesData[i].measured_value);
            }
        }

        measurements = measurements.sort((a, b) => a - b);
        let lowestValue = measurements[0];
        let highestValue = measurements[measurements.length - 1];

        const yAxisRange = [Math.floor(lowestValue), Math.ceil(highestValue) ];

        return yAxisRange;

   }  

	parseBatteryData(batteryData){
       /*var percipAmnountBars = {
            x: [],
            y: [],
            type: 'bar',
            hoverinfo: 'none',
            marker: {color: 'rgb(43, 171, 226)'},
            yaxis: 'y2',
        }*/

        //console.log(batteryData);

        var range = this.getYAxisRange( batteryData );

        var battLine = {
            x: [],
            y: [],
            type: 'scatter',
            hoverinfo: 'text',
            text: [],
            fill: 'tonexty',
            mode: 'lines+markers',
            line: {
                color: 'rgb(245, 136, 31)',
                width: 2,
            },
        }

        
        var unitOfMeasure = formatMeasurement( batteryData.unitOfMeasure )
        //console.log(unitOfMeasure);

        var theBatteryData = batteryData.timeSeriesData.slice();
        theBatteryData.reverse();

        //console.log(theBatteryData);


        for (var i = 0; i < theBatteryData.length; i++) {

            var measuredValue = theBatteryData[i].measured_value;

            // setup date for the line chart
            var currentDate = new Date(theBatteryData[i].datetime_of_measurement);
            
            battLine.x.push(  currentDate );
            battLine.y.push( measuredValue );

            //var text = percipTotal + unitOfMeasure;
            
            // set the hover text
            battLine.text.push( formatGaugeAmount(measuredValue, unitOfMeasure)+', '+ hhmmampm(currentDate));

        }


            //sets up the layout variable in the plotly format
        var layout = {

            paper_bgcolor:'rgba(0, 0, 0, 0)',
            plot_bgcolor: 'rgba(0, 0, 0, 0)',

            showlegend: false,
            font: {
                family: "Lato",
                size:16,
            },
            xaxis: {
                showticklabels: true,
                showgrid: true,
                fixedrange: (this.props.allowModeBar ? false : true ),
                showline: false,

                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                tickcolor: 'rgba(0,0,0,0)',
                ticklen: 20,
                zeroline: false,
            },
            yaxis: {
                showticklabels: true,
                showgrid: true,
                fixedrange: (this.props.allowModeBar ? false : true ),
                showline: false,
                //autoRange: true,
                range: range,
                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                ticklen: 15,
                tickcolor: 'rgba(0,0,0,0)',
                title: 'Volts (v)',
                zeroline: false,
            
            },
            margin: {
                l: 70,
                r: 50,
                b: 80,
                t: 20,
                pad: 0
            },
        

        };

        //set the plotconfig settings
        var plotConfig = {
            displayModeBar: (this.props.allowModeBar ? true : false ),
        }

        return [ [battLine], layout, plotConfig ];
    }

    render() { 

        let height = 150;
        if( this.props.graphHeight ){
            height = this.props.graphHeight;
        }

        const graphHeight = {height: height+"px"};
        const chartStyles = { width: "100%", height:height+"px" };
        const chartData = this.parseBatteryData(this.props.chartData);
          
        return(

            <div style={ graphHeight } id={ this.props.uniqueID } ref="plot">
                <PlotlyChart
                    style={ chartStyles } 
                    data={ chartData[0] } 
                    layout={ chartData[1] } 
                    config={ chartData[2] } 
                />
            </div>

        );
    }
}

export default BatteryLevelChart;