import React, { Component } from "react";
import { Link } from 'react-router-dom';
import TimeSeriesChart from "../charts/TimeSeriesChart";
import RainChart from "../charts/RainChart";
import SvgIcon from "../SvgIcon";
import { yyyymmdd, hhmmampm, formatGaugeAmount } from "../../helpers/gaugeHelpers";

class LowerDonReport extends Component {
    constructor ( props ) {
        super( props ); 

        this.setupReportResultsView = this.setupReportResultsView.bind(this);
        this.getCalculatedTotalsTable = this.getCalculatedTotalsTable.bind(this);
    }

    shouldComponentUpdate( nextProps, nextState ) {
        if( this.props.chartData.timeStamp !== nextProps.chartData.timeStamp  ) {
            return true;
        }  

        if( this.props.showAlarms !== nextProps.showAlarms ) {
            return true;
        }
        
        return false
    }

    setupReportResultsView(data) {
        return data.map( (gauge, key) => {
            let chart = null;
            let saveChartText = "Save, "+ gauge.stationName + ": " + gauge.gaugeType + ", to dashboard";

            switch( gauge.gaugeType ) {
                case "PRECIP":
                if( gauge.chartData.timeSeriesData.length ) {
                    chart = 
                    <RainChart 
                        uniqueID={`chart-${key}`} 
                        chartData={ gauge.chartData } 
                        graphHeight={500}  
                        type="report" 
                        gaugeId={gauge.gaugeId}
                        stationName={gauge.stationName}
                        canSaveCharts={ this.props.canAcccesStandardReports }
                        saveChartToDashboard={this.props.saveChartToDashboard} 
                        saveChartText={ saveChartText }
                        gaugeType={ gauge.gaugeType }
                        allowModeBar={true}
                    />
                }  else {
                    chart = 
                    <div className="card-missed-data-overlay visible">
                        No Data Available
                    </div>
                }
                     
                break;
                default:
                    chart = 
                    <TimeSeriesChart 
                        uniqueID={`chart-${key}`} 
                        chartData={ gauge.chartData } 
                        graphHeight={500}  
                        type="report" 
                        showAlarms={ this.props.showAlarms }
                        gaugeId={gauge.gaugeId}
                        stationName={gauge.stationName}
                        canSaveCharts={ this.props.canAcccesStandardReports }
                        saveChartToDashboard={this.props.saveChartToDashboard} 
                        saveChartText={ saveChartText }
                        gaugeType={ gauge.gaugeType }
                        allowModeBar={true}
                    /> 
                    break;
            }

            return (
                <div key={key} className="report-chart-result">
                    <h2>{gauge.chartTitle} from { this.formatFromToDate(this.props.dateTime.range.from) } to { this.formatFromToDate(this.props.dateTime.range.to)}</h2>
                    {chart}
                </div>
            )
        })
    }
    
    formatFromToDate(date){
        return `${yyyymmdd(date)} at ${hhmmampm(date)}` 
    }


    getCalculatedTotalsTable( summariezedData ){

        var wlGauges = [];
        var precipGauges = [];
        for( var i = 0; i < summariezedData.length; i++ ) {
            if( summariezedData[i].total_unit_of_measure === "m" ) {
                wlGauges.push(summariezedData[i]);
            } 

            if( summariezedData[i].total_unit_of_measure === "mm" ) {
                precipGauges.push(summariezedData[i]);
            }
        }

        return (
            <div className="summariezed-data">
                <div className="responsive-table-wrap">
                    <h2>Water Level</h2>
                    <table className="table-lined">
                        <thead>
                            <tr>
                                <td>Watershed</td>
                                <td>Station Name</td>
                                <td>Min WL (m)</td>
                                <td>Max WL (m)</td>
                                <td>Time of Max WL</td>
                                <td>Max RoC (m/15min)</td>
                                <td>Total WL Rise (m)</td>
                                <td>Alarms Triggered</td>
                                <td>Amount from Alarms</td>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                wlGauges.map( (data, index ) => {
                                    return (
                                        <tr key={index}>
                                            <td>{ data.watershed}</td>
                                            <td>{ data.station_name }</td>
                                            <td>{ data.min }</td>
                                            <td>{ data.max }</td>
                                            <td>{ yyyymmdd( new Date(data.datetime_of_max) )} { hhmmampm( new Date(data.datetime_of_max) ) }</td>
                                            <td>{ data.max_roc }</td>
                                            <td>{ data.total_rise }</td>
                                            <td>{ (data.alarm_triggered ? data.alarm_triggered : 'None') }</td>
                                            <td>{ formatGaugeAmount( data.amount_from_alarms, false, "WL" ) }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <h2>Precipitation</h2>        
                    <table className="table-lined">
                        <thead>
                            <tr>
                                <td>Station ID</td>
                                <td>Station Name</td>
                                <td>Total (mm)</td>
                                <td>Max Intensity (mm/5min)</td>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                precipGauges.map( (data, index ) => {
                                    return (
                                        <tr key={ index }>
                                            <td>{ data.station_id }</td>
                                            <td>{ data.station_name }</td>
                                            <td>{ formatGaugeAmount(data.total) }</td>
                                            <td>{ formatGaugeAmount(data.max) }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    render() {
        var chart = null;
        if( this.props.chartData.report && this.props.chartData.report.charts ) {
            chart = this.setupReportResultsView(this.props.chartData.report.charts );
        }

        let dashboardLink = (this.props.canAcccesStandardReports) ?
        <a className="report-btn btn-link" onClick={ this.props.saveVisualizationToDashboard } ><SvgIcon icon="floppy-disk" /> Create a link to this report on your dashboard </a> : null;
        
        return(
            <div className="dam-report container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        { chart }

                        { this.getCalculatedTotalsTable( this.props.chartData.report.summariezedData ) }

                        <div className="report-btn-container report-result-buttons">
                            <a 
                                className="report-btn btn-link"
                                href={ this.props.chartData.report.downloadCSV }
                            >   
                                <SvgIcon icon="download" /> Download Tabular Data
                            </a>
                            
                            <Link className="report-btn btn-link" to={"/data-explorer"}><SvgIcon icon="download" /> Request Data</Link>

                            {dashboardLink}
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default LowerDonReport;