
// Defaults for options for add script
const addScriptDefaults = {
	id: null,
	callBack: null,
}

// Will add script too the footer can spcify options above, 
// id sets the ID of the script tag
// callBack will called once the script is ready 
export function addScript( url, options=addScriptDefaults ) {
	var script = document.createElement( "script" )
	script.type = "text/javascript";
	
	// Add the callback for the script
	if(options.callBack !== null){
		if(script.readyState) {  
			// IE
			script.onreadystatechange = function() {
				if ( script.readyState === "loaded" || script.readyState === "complete" ) {
					script.onreadystatechange = null;
					options.callBack();
				}
			};
		} else {  
			// Others
			script.onload = function() {
				options.callBack();
			};
		}
	}

	// set the ID
	if(options.id !== null){
		script.id = options.id;
	}

	// Set the url and add it to the document
	script.src = url;
	document.body.appendChild( script );
}

// Remove a script from the document
export function removeScript(id){
	var elem = document.getElementById(id);
 	elem.parentElement.removeChild(elem);
}


export default {
	addScript: addScript,
	removeScript: removeScript
}