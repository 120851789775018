import React, { Component } from "react";
import SelectedGaugeList from "../data-explorer/SelectedGaugeList";
import { Link } from 'react-router-dom';

class ReportBuilderMenu extends Component {
    constructor ( props ) {
        super( props ); 

        this.showClearAll = false;

        this.getBtnStatus = this.getBtnStatus.bind(this);
        this.getSelectedGaugesSection = this.getSelectedGaugesSection.bind(this);
    }

    getBtnStatus(reportType) {
        var css = null;
        if( reportType === this.props.selectedStandardReport ) {
            css = "report-template-btn active"
        }else if( this.props.reportResultsView ) {
            css = "report-template-btn disabled"
        } else {
            css = "report-template-btn";
        }
        
        return css;
    }

    getSelectedGaugesSection(stationsOnly) {
		let selectedStations = [];
        let stationData = this.props.allStationsAndGauges;
        var ctr = 0;

        if( stationData !== undefined ) {
            for (var i = 0; i < stationData.length; i++) {
                const theStation = stationData[i];
                let gauges = [];
                for (var b = 0; b < theStation.gauges.length; b++) {
                    if( theStation.gauges[b].selected ){
                        gauges.push(  {
                            id: theStation.gauges[b].id,
                            sensorName: theStation.gauges[b].sensorName,
                            removeable: true
                        });
                        ctr++;
                    } 
                }
    
                //only add the station if the gauge is filtered
                if( gauges.length ){
                    let station = {
                        id: theStation.id,
                        removeable: true,
                        stationName: theStation.stationName,
                        gauges: gauges,
                        showThirdPartyFlag: theStation.isThirdParty
                    }
    
                    selectedStations.push(station);
                }
            }

            if( selectedStations.length ) {
                this.showClearAll = true;
            } else {
                this.showClearAll = false;    
            }

            if( this.props.reportResults && ctr === 1 && !stationsOnly ) {
                selectedStations[0].gauges[0].removeable = false;
            }
            
            if( this.props.reportResults && selectedStations.length === 1 && stationsOnly ) {
                selectedStations[0].removeable = false;
            }
        }


		return (
			<div className="selected-stations">
				<SelectedGaugeList  
					selectedStations={selectedStations}
					stationsOnly={stationsOnly}
					removeGauges={this.props.removeGauges} 
                    noneSelectedMessage="No gauges selected" 
                    disableEditing={ this.props.disableEditMenu }
				/>
			</div>
		);
	}

    render() {
        var stationList = "";
        var menuOptions = null;
        var alarmCheckbox = null;
        let clearAllBtn = null;

        let addGaugeBtn = (!this.props.disableEditMenu) ? 
        <button 
            className="add-gauge-btn"
            onClick={ this.props.selectGaugeToAdd }
            > 
            <div className="icon-container">
                <span>+</span>
            </div>
        </button> : null;



        switch(this.props.reportType) {
            case "standard":
            stationList = this.getSelectedGaugesSection(true);
            clearAllBtn = null;

            if( this.showClearAll && !this.props.reportResults) {
                clearAllBtn = 
                <button
                    className="clear-all-btn"
                    onClick={ this.props.removeAllGauges }
                >
                    Clear All
                </button>
            }

            if( this.props.reportSelection === "DAM" || this.props.reportSelection === "LOWER_DON" || this.props.reportSelection === "TEMPORAL" ) {
                menuOptions = null;
            } else {
                menuOptions = 
                <div className="slideout-legeond-first">
                    <fieldset>
                        <legend className="slideout-heading">MY REPORT:</legend>
                        {stationList}
                        { addGaugeBtn }
                        { clearAllBtn }
                    </fieldset>
                </div>
            }

            if( this.props.reportSelection === "LOWER_DON" ) {
                alarmCheckbox = 
                <div className="checkbox-toggle">
						<fieldset>
                        <legend className="slideout-heading">OPTIONS</legend> 
							<label htmlFor="show-alarms">
								<input 
									id="show-alarms-toggle" 
									type="checkbox" 
									name="show-alarms" 
                                    value="false" 
                                    checked={ this.props.alarmsStatus }
									onChange={ this.props.showAlarmsToggle }
								/>
								Show Alarms
							</label>
						</fieldset>
					</div>
            }
            
            break;

            default:
            stationList = this.getSelectedGaugesSection(false);

            clearAllBtn = null;
            
            if( this.showClearAll && !this.props.reportResults) {
                clearAllBtn = 
                <button
                    className="clear-all-btn"
                    onClick={ this.props.removeAllGauges }
                >
                    Clear All
                </button>
            }
            // eslint-disable-next-line
            var menuOptions = 
            <div className="slideout-legeond-first">
                <fieldset>
                    <legend className="slideout-heading">MY REPORT:</legend>

                    {stationList}
                    { addGaugeBtn }
                    { clearAllBtn }
                </fieldset>
            </div>
            break;
        }

        /*
        <fieldset>
            <legend className="slideout-heading">SAVED REPORTS:</legend>
            <p>You have no saved reports</p>
        </fieldset>
        */

        return(
            <div className="">
                {menuOptions}
                {alarmCheckbox}

                { (this.props.canAcccesStandardReports) ? (
                    <fieldset role="navigation" aria-labelledby="report-templates-nav">
                        <h2 className="screen-reader-text" id="report-templates-nav" >Available report template navigation</h2>
                        <legend className="slideout-heading">REPORT TEMPLATES:</legend>
                        <button className={ this.getBtnStatus("WL") } id="stream" onClick={ (!this.props.reportResultsView) ? this.props.getReportTemplateData : null } >Stream Water Level</button>
                        <button className={ this.getBtnStatus("PRECIP") } id="rain" onClick={ (!this.props.reportResultsView) ? this.props.getReportTemplateData : null } >Precipitation</button>
                        <button className={ this.getBtnStatus("DAM") } id="dam" onClick={ (!this.props.reportResultsView) ? this.props.getReportTemplateData : null } >Dams</button>
                        <button className={ this.getBtnStatus("LOWER_DON") } id="lower-don" onClick={ (!this.props.reportResultsView) ? this.props.getReportTemplateData : null } >Lower Don River</button>
                        <button className={ this.getBtnStatus("TEMPORAL") } id="temporal" onClick={ (!this.props.reportResultsView) ? this.props.getReportTemplateData : null } >Temporal Rainfall</button>
                        <Link className={ this.getBtnStatus("BATTERY") } to={"/battery-data"}>Station Battery Status</Link>
                    </fieldset>
                ) : (null) }

                
            </div>
        );
    }
}

export default ReportBuilderMenu;