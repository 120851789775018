import React, {Component} from "react";

class ConfirmationModalContent extends Component {

    constructor ( props ) {
        super( props ); 
        this.confirmationGiven = this.confirmationGiven.bind(this);
    }

    confirmationGiven() {
        if( this.props.widgetId ) {
            this.props.userConfirmationGiven(this.props.widgetId);
        } else {
            this.props.handleUpdateCurrentSafetyCode();
        }
    }

    render() {
        // allow disabling buttons for saving states
        var disableConfirm = false;
        if( this.props.disableConfirm === true ){
            disableConfirm = true;
        }

        var disableDeny = false;
        if( this.props.disableDeny === true ){
            disableDeny = true;
        }

        return(
            <div className="confirmation-modal-container">
                <span>{ this.props.confirmationMessage }</span>
                <button disabled={disableConfirm} onClick={ this.confirmationGiven } className="confirmation-button" ref="theDiv" autoFocus tabIndex={0}>{ this.props.confirmationBtnLabel }</button>
                <button disabled={disableDeny} onClick={ this.props.handleCloseModal } className="deny-button">{ this.props.denyBtnLabel }</button>
            </div>
        );
    }

}

export default ConfirmationModalContent;  