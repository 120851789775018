import React, { Component } from "react";
import PlotlyChart from "./PlotlyChart.js";


import { 
    formatGaugeAmount,
    formatMeasurement,
    getNonNullMax,
    getNonNullMin,
    hhmmampm
 } from "../../helpers/gaugeHelpers";


/* 

EXAMPLE chartData prop:

chartData = {
    
    unitOfMeasure: apiData.unit_of_measure,  // the unit of measure from the api
    yAxisName: 'Some Name', //optional not all graphs show a yAxisLabel
    chartData: [
        {
            name: "Some Name", // optional, not all graphs use legends
            timeSeriesData: apiData.time_series_data, 
        },
        {
            name: "Some Name 2", // optional, not all graphs use legends
            timeSeriesData: apiData.time_series_data,
        }
    ],

    // feel like there would be a more flexable way to add lines,
    // for now this will work
    lowLowLine: null, // null is dont show
    lowLine: null, // null is dont show
    normalLine: null, // null is dont show
    highLine: null, // null is dont show
    highHighLine: null // null is dont show
        
}

*/

class DualAxisTimeSeries extends Component {

    constructor ( props ) {
        super( props );
        this.getDataFromApiData = this.getDataFromApiData.bind(this);
        this.getLayout = this.getLayout.bind(this);
        this.getConfig = this.getConfig.bind(this);
        this.addChartLine = this.addChartLine.bind(this);
        this.clearChartLines = this.clearChartLines.bind(this);

        this.getYAxisRange = this.getYAxisRange.bind(this);
        this.getSmallestXaxis = this.getSmallestXaxis.bind(this);
        this.getLargestXAxis = this.getLargestXAxis.bind(this);
        this.setupChartLines = this.setupChartLines.bind(this);
        this.onPlotlyLegendClick = this.onPlotlyLegendClick.bind(this);

        this.state = { redraw: false };

        //setup the chart line manager
        this.chartLines = {};
        this.clearChartLines();
        
        //console.log( this.props );
    }

    // resets the chart lines to be empty
    clearChartLines(){
        this.chartLines = {
            shapes :[],
            annotations: []
        }
    }


    // gets the smallest date in the provided chart datas
    getSmallestXaxis(){

        let smallestDate = 0;

        if(!this.props.chartData.chartData[0].timeSeriesData.length){
            var targetDate = new Date();
            targetDate.setDate(targetDate.getDate() + 10);
            smallestDate = targetDate.getTime();
        }
        else{
            smallestDate = new Date(this.props.chartData.chartData[0].timeSeriesData[ this.props.chartData.chartData[0].timeSeriesData.length-1].datetime_of_measurement).getTime();
        }


        for (var i = 1; i < this.props.chartData.chartData.length; i++) {
            if( this.props.chartData.chartData[i].timeSeriesData.length ){

                const axisDate =  new Date(this.props.chartData.chartData[i].timeSeriesData[ this.props.chartData.chartData[i].timeSeriesData.length-1].datetime_of_measurement).getTime();
                if( smallestDate > axisDate ){
                    smallestDate = axisDate;
                }
                
            }
        }

        return smallestDate;
    }

    //gets the largest date in the provided chart datas
    getLargestXAxis(){

        let largestDate = 0;
        if(this.props.chartData.chartData[0].timeSeriesData.length){
            largestDate = new Date(this.props.chartData.chartData[0].timeSeriesData[0].datetime_of_measurement).getTime();
        }

        for (var i = 1; i < this.props.chartData.chartData.length; i++) {

            if( this.props.chartData.chartData[i].timeSeriesData.length ){
                const axisDate = new Date(this.props.chartData.chartData[i].timeSeriesData[0].datetime_of_measurement).getTime();
                if( largestDate < axisDate ){
                    largestDate = axisDate;
                }
            }
        }
        return largestDate;
    }

    addChartLine(linePos, lineText, color, xStart, xEnd, axisAnchor){

        //let lineRef = (axisAnchor === 1 ? '' : axisAnchor );

        var shape = {
            type: 'line',
            x0: xStart,
            y0: linePos,
            x1: xEnd,
            y1: linePos,
            xref: 'x1',
            yref: 'y2',
            line: {
                color: color,
                width: 1,
            }
        };

        var annotation = {
            x: xStart,
            y: linePos,
            text: lineText,
            showarrow: false,
            xanchor: 'left',
            'yanchor' : 'bottom',
            borderpad: 3,
            xref: 'x1',
            yref: 'y2',
        };

        this.chartLines.shapes.push(shape);
        this.chartLines.annotations.push(annotation);
    }

    /// returns the Y axis range including and takes into concidersation any annotation lines
    getYAxisRange( data ){

         //an array of all the custom lines passed into the system
        //console.log( this.props.chartData );

        var rawValues = [ 
            this.props.chartData.lowLowLine, 
            this.props.chartData.lowLine, 
            this.props.chartData.normalLine, 
            this.props.chartData.highLine, 
            this.props.chartData.highHighLine 
        ];
        var customValues = [];

        //clean the custom valeus for any nulls for undefined
        for (var j = rawValues.length - 1; j >= 0; j--) {
            if( !isNaN(rawValues[j]) ){
                customValues.push(rawValues[j]);
            }
        }

        // loop over the plots and find the lowest and highest values for the provided data
        var lowestValue = getNonNullMin.apply(null, data[0].y);
        var highestValue = getNonNullMax.apply(null, data[0].y);
       
        for (var i = 1; i < data.length; i++) {
            let currentLow = getNonNullMin.apply(null, data[i].y);
            if( lowestValue > currentLow ){
                lowestValue = currentLow;
            }

            let currentHigh = getNonNullMax.apply(null, data[i].y);
            if( currentHigh > highestValue ){
                highestValue = currentHigh;
            }
        }

        //calculate the min and max for the y axis
        //get the lowest value shown on the graph and max
        if( customValues.length){
            lowestValue = getNonNullMin(lowestValue, getNonNullMin.apply(null, customValues) );
        }


        //get the highest value for the graph
        if( customValues.length){
            highestValue = getNonNullMax( highestValue, getNonNullMax.apply(null, customValues) );
        }


        var offsetMultiplyer = 0.1;
        const yAxisRange = [Math.floor(lowestValue - (lowestValue * offsetMultiplyer)), Math.ceil(highestValue + (highestValue * offsetMultiplyer)) ];

        return yAxisRange;

    }  

    //converts the
    getDataFromApiData(chartData, unitOfMeasure, color){
        var data = {
            x: [],
            y: [],
            yTickValues: [],
        }


        unitOfMeasure = formatMeasurement( unitOfMeasure )

        var theChartData = chartData.timeSeriesData.slice();
        theChartData.reverse();
        //var cleanChartData = [];

        //populate the Y tick values
        for (var i = 0; i < theChartData.length; i++) {

            // setup the values to be used
            var measuredValue = null;
            //console.log(theChartData[i].measured_value);
            

            if( theChartData[i].measured_value !== null && theChartData[i].measured_value !== "null" ){
                measuredValue = Math.round( theChartData[i].measured_value * 100 ) / 100;

                // convert the date to a localized time
                var theDate = new Date( theChartData[i].datetime_of_measurement );
                
                //add to the data object the values 
                data.x.push(  theDate );
                data.y.push( measuredValue );
                //console.log(theDate, datetimeOfMeasurement);

                //setup the yTickValues that is used custom text on the hover label
                var previousValue = 0;
                if( i !== 0 ){
                    //we can calculate the previous value
                    previousValue = theChartData[i].measured_value - theChartData[i-1].measured_value;
                    previousValue = Math.round( previousValue * 100 ) / 100;

                }
                var prevString = previousValue + unitOfMeasure;

                data.yTickValues.push(measuredValue + unitOfMeasure + ', ' + prevString + ' ' + hhmmampm( theDate ) );
            }
        }


       

        //Sets up the data in the plotly format
        //console.log( data.x );

        var graphData = 
          {
            x: data.x,
            y: data.y,
            name: chartData.name,
            type: 'scatter',
            fill: 'tonexty',
            text: data.yTickValues,
            hoverinfo: 'text',
            mode: 'lines+markers',
            line: {
                color: color,
                width: 0,
            }
          };
        

        return graphData;
    }

    setupChartLines(chartData){

        //x should start on the oldest valuethis.props.chartData.timeSeriesData
        var xStart = this.getSmallestXaxis();

        //x end should be the most recent data
        var xEnd = this.getLargestXAxis();



        //get the type of measurement to display on the lines
        var typeString = '';
        if(chartData.unitOfMeasure === '(m^3)/s'){
            typeString = " - Discharge";
        }
        else if(chartData.unitOfMeasure === 'm'){
            typeString = ' - WL';
        }

        // add the low low alarm
        if( chartData.lowLowLine  !== null && chartData.lowLowLine !== undefined ){
            this.addChartLine(
                chartData.lowLowLine,
                '&nbsp;&nbsp;LLA' + typeString + ' ('+ formatGaugeAmount(chartData.lowLowLine, chartData.unitOfMeasure, "WL") +')',
                'rgb(0, 0, 0)',
                xStart,
                xEnd,
                chartData.axisAnchor
            );
            
        }

        // add the low alarm
        if( chartData.lowLine  !== null && chartData.lowLine !== undefined ){
            this.addChartLine(
                chartData.lowLine,
                '&nbsp;&nbsp;LA' + typeString + ' ('+ formatGaugeAmount(chartData.lowLine, chartData.unitOfMeasure, "WL") +')',
                'rgb(0, 0, 0)',
                xStart,
                xEnd,
                chartData.axisAnchor
            );
            
        }


        //if normal is set add it to the graph
        if( chartData.normalLine !== null && chartData.normalLine !== undefined ){
            this.addChartLine(
                chartData.normalLine,
                '&nbsp;&nbsp;Normal' + typeString + ' ('+ formatGaugeAmount(chartData.normalLine, chartData.unitOfMeasure, "WL") +')',
                'rgb(0, 0, 0)',
                xStart,
                xEnd,
                chartData.axisAnchor
            );
        }

        //if the high is set add it to the graph
        if( chartData.highLine !== null && chartData.highLine !== undefined ){
            this.addChartLine(
                chartData.highLine,
                '&nbsp;&nbsp;HA' + typeString + ' (' +  formatGaugeAmount(chartData.highLine, chartData.unitOfMeasure, "WL") + ')',
                'rgb(255, 0, 0)',
                xStart,
                xEnd,
                chartData.axisAnchor
            );
        }


        //if the high high is set add it to the graph
        if( chartData.highHighLine !== null && chartData.highHighLine !== undefined ){
            this.addChartLine(
                chartData.highHighLine,
                '&nbsp;&nbsp;HHA' + typeString + ' (' +  formatGaugeAmount(chartData.highHighLine, chartData.unitOfMeasure, "WL") + ')',
                'rgb(255, 0, 0)',
                xStart,
                xEnd,
                chartData.axisAnchor
            );
        }
    }

    getLayout( data, chartData ){

       
        //sets up the layout variable in the plotly format

        let layout = {
            showlegend: true,
            legend: {
                x: 0.5,
                y: -.2,
                orientation: "h",
                xanchor:"center",
                yanchor:"top",
            },
            font: {
                family: "Lato",
                size:16,
            },
            xaxis: {
                showticklabels: true,
                showgrid: true,
                showline: false,
                fixedrange: false,
                zeroline: false,
                //range: [0, 10],

                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                tickcolor: 'rgba(0,0,0,0)',
                ticklen: 5,
            },
            xaxis2: {
                showticklabels: false,
                showgrid: true,
                showline: false,
                fixedrange: false,
                zeroline: false,
                ticks: 'outside',
                tickcolor: 'rgba(0,0,0,0)',
                ticklen: 5,
            },
            yaxis2: {
                showticklabels: true,
                showgrid: true,
                //range: data.yAxisRange,
                fixedrange: false,
                showline: false,
                zeroline: false,
                overlaying: 'y',
                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                ticklen: 5,
                tickcolor: 'rgba(0,0,0,0)',
                title: chartData.yAxis2Name,
            },
             yaxis: {
                showticklabels: true,
                showgrid: false,
                fixedrange: false,
                //overlaying: 'y',
                side: 'right',
                zeroline: false,

                //using ticks to increase spacing between label and axis
                ticks: 'outside',
                ticklen: 10,
                tickcolor: 'rgba(0,0,0,0)',
                title: chartData.yAxisName,
            },
            margin: {
                l: 80,
                r: 80,
                b: 80,
                t: 20,
                pad: 0
            },
        };            


        //always reset the chart lines
        this.clearChartLines();

        //could be conditional based on props
        this.setupChartLines(chartData);

        // Add the chart lines to the layout
        // Since clear lines is always called these will either be empty or contain lines
        layout.shapes = this.chartLines.shapes;
        layout.annotations = this.chartLines.annotations;

                      
        if( this.chartLines.shapes.length ){
            //only use the range if we are using annotations
            layout.yaxis.range = this.getYAxisRange( data );
        }

        return layout;
    }

    getConfig( data ){
        //set the plotconfig settings
        var plotConfig = {
            displayModeBar: (this.props.allowModeBar ? true : false ),
        }

        return plotConfig;
    }

    onPlotlyLegendClick() {
        //disable legend toggle functionality for dual axis as
        //there's an apparent bug that changes the yAxis values when storage is toggled off
        return false;
    }

    render() { 
        
        if( this.props.chartData.chartData[0].timeSeriesData.length === 0 ){
            // There is no data to display on this chart, skip adding the line
            return null;
        }

        let data = [];

        if( this.props.chartData.chartData.length !== 2 ){
            throw new Error('Dual axis time series chart called without two sets of data!')
        }

        let chartColors = ["rgb(253, 185, 19)", "rgb(43, 171, 226)"];

        //console.log(this.props.chartData);

        data.push( this.getDataFromApiData( this.props.chartData.chartData[0],  this.props.chartData.unitOfMeasure, chartColors[0] ) );
        data.push( this.getDataFromApiData( this.props.chartData.chartData[1],  this.props.chartData.unitOfMeasure, chartColors[1] ) );
        data[0].yaxis = 'y1';
        data[1].yaxis = 'y2';

        let layout = this.getLayout( data, this.props.chartData );
        let config = this.getConfig( data );

        let height = 150;
        if( this.props.graphHeight ){
            height = this.props.graphHeight;
        }

        const graphHeight = {height: height+"px"};
        const chartStyles = { width: "100%", height:height+"px" };
        
        return(
            <div style={ graphHeight } id={ this.props.uniqueID } >
                <PlotlyChart 
                    style={ chartStyles } 
                    data={ data } 
                    layout={ layout } 
                    config={ config } 
                    onPlotlyLegendClick={ this.onPlotlyLegendClick }
                />
            </div>
        );
    }
}

export default DualAxisTimeSeries;