import React, { Component } from "react";
import { Link } from 'react-router-dom';
import HeatMapChart from "../charts/HeatMapChart";
import SvgIcon from "../SvgIcon";
import { yyyymmdd, hhmmampm } from "../../helpers/gaugeHelpers";

class TemporalRainfallReport extends Component {
    constructor ( props ) {
        super( props ); 

        this.setupReportResultsView = this.setupReportResultsView.bind(this);
    }

    shouldComponentUpdate( nextProps, nextState ) {
        if( this.props.chartData.timeStamp !== nextProps.chartData.timeStamp  ) {
            return true;
        }  

        
        return false
    }

    setupReportResultsView(data) {
        let chart = null;
        //console.log(data);
        chart = <HeatMapChart uniqueID={`chart-heatmap`} chartData={ data } graphHeight={1500}  type="report" data={this.props}/>

        //console.log(chart);

        return (
            <div className="report-chart-result temporal">
                <h2>Temporal Rainfall from { this.formatFromToDate(this.props.dateTime.range.from) } to { this.formatFromToDate(this.props.dateTime.range.to)}</h2>
                {chart}
            </div>
        )
    }
    
    formatFromToDate(date){
        return `${yyyymmdd(date)} at ${hhmmampm(date)}` 
    }

    render() {

        var chart = null;
        if( this.props.chartData.report && this.props.chartData.report.charts ) {
            chart = this.setupReportResultsView(this.props.chartData.report.charts );
        }

        let dashboardLink = <a className="report-btn btn-link" onClick={ this.props.saveVisualizationToDashboard } ><SvgIcon icon="floppy-disk" /> Create a link to this report on your dashboard </a>;
        
        return(
            <div className="temporal-rainfall-report container-fluid">
                <div className="row">
                    <div className="col-sm-12"> 
                        { chart }

                        <div className="report-btn-container report-result-buttons">
                            <a 
                                className="report-btn btn-link"
                                href={ this.props.chartData.report.downloadCSV }
                            >   
                                <SvgIcon icon="download" /> Download Tabular Data
                            </a>

                            <Link className="report-btn btn-link" to={"/data-explorer"}><SvgIcon icon="download" /> Request Data</Link>

                            { dashboardLink }
                            
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default TemporalRainfallReport;