import React, { Component } from "react";
import { Link } from 'react-router-dom';
import SvgIcon from "../SvgIcon";
import IconTextLink from "./IconTextLink";
import StationReadings from "../StationReadings";
import TimeSeriesLength from "./TimeSeriesLength"; 
import RainChart from "../charts/RainChart";
import TimeSeriesChart from "../charts/TimeSeriesChart";
import slugify from "../../helpers/slugify";
import LoadingSVG from "../../images/gauge-placeholder.svg";

import { 
	yyyymmdd, 
	getRainIcon,
	getTrendIcon, 
	formatMeasurement,
 } from "../../helpers/gaugeHelpers"; 
	
class StationSecondary extends Component {

	constructor(props) {
        super(props);
        this.handleSpecificTimeSeriesRequest = this.handleSpecificTimeSeriesRequest.bind(this);
        this.handleGaugeSwitch = this.handleGaugeSwitch.bind(this);
 	}

 	formatViewData(stationData){

		let cardData = stationData.data;
				
		let viewData = {
			"gauge_id": cardData.station_id,
			"gauge_slug": cardData.slug,
			"gauge_name": this.props.stationData.nearbyName,
			"unique_id": cardData.station_name+" "+ cardData.sensor_name,
			"type_of": cardData.type_of,
			"gauge_date": yyyymmdd( new Date(cardData.last_updated) ),
			"icon_name" : "plus",
			"gauge_readings" : [],
			"download_link": cardData.last_72hrs_csv_data_url,
			"download_text": "Download Tabular Data (last 72 hours)",
		};

		this.currentGaugeType = cardData.type_of;

		switch(cardData.type_of) {
			case 'Dam Gauge':
				//display a dam widget
				viewData.graphData = this.parseStreamData( cardData );
				viewData.iconName = getTrendIcon(cardData);
				//console.log(this.graphData);
				break;
			case 'Rain Gauge':
				//display a rain widget 
				viewData.graphData = this.parseRainData(cardData);
				viewData.iconName = getRainIcon(cardData);
				//console.log(this.graphData);
				break;
			case 'Stream Gauge':
				//display a stream widget
				viewData.graphData = this.parseStreamData(cardData);
				viewData.iconName = getTrendIcon(cardData);
				break;

			case 'Air Temperature Gauge':
				viewData.graphData = this.parseStreamData(cardData);
				viewData.iconName = getTrendIcon(cardData);
				break;
			
			case 'Wind Gauge':
				viewData.graphData = this.parseStreamData(cardData);
				viewData.iconName = getTrendIcon(cardData);
				break;	

			case 'Pressure Gauge':
				viewData.graphData = this.parseStreamData(cardData);
				viewData.iconName = getTrendIcon(cardData);
				break;	
			
			case 'Humidity Gauge':
				viewData.graphData = this.parseStreamData(cardData);
				viewData.iconName = getTrendIcon(cardData);
				break;
			
			case 'Unknown Gauge':
				viewData.graphData = this.parseStreamData(cardData);
				viewData.iconName = getTrendIcon(cardData);
				break;		

			default:
				console.warn('A gauge with an unknown type was passed to: ' + viewData.dataid );
			break;
		}

		// get the battery level
		if( cardData.lowest_24h_battery_measured_value === null){
			viewData.lowed24battery = "No readings in the last 72 hours.";
		}
		else{
			viewData.lowed24battery = `Lowest Battery Level (24h): ${cardData.lowest_24h_battery_measured_value}v`; 
		}

		return viewData;
 	}


	// Takes the API data and converts into the format RainChart uses
	parseRainData(apiData){
		var rainData = {
			unitOfMeasure: apiData.unit_of_measure,
			timeSeriesData: apiData.time_series_data,
		}

		return rainData;
	}


	// Takes the API data and converts it into a format the WaterLevel graph component can use
	parseStreamData(apiData){
		var streamData = {
			unitOfMeasure: apiData.unit_of_measure,
			yAxisName: `${apiData.pretty_sensor_name} (${formatMeasurement( apiData.unit_of_measure)})`,
			chartData: [{
				timeSeriesData:apiData.time_series_data
			}],
			lowLowLine: null,
			lowLine: null,
			normalLine: null,
			highLine: null,
			highHighLine: null
		}


		//if normal is set add it to the graph
		if( apiData.normal_for_this_month !== null){
			streamData.normalLine = apiData.normal_for_this_month;
		}


		//if the high high is set add it to the graph
		if( apiData.high_high_alarm === true ){
			streamData.highHighLine = apiData.high_high_limit
		}

		return streamData;
	}

	handleSpecificTimeSeriesRequest(hour) {
		this.props.handleSpecificTimeSeriesRequest(hour, this.props.gaugeIndex);
	}

	handleGaugeSwitch(sensorName){
		const slug = slugify( this.props.stationData.data.station_name + '-' + sensorName );
		this.props.handleGaugeSwitch( slug, this.props.gaugeIndex, this.props.stationData.currentTimeSeriesLength );
	}
	

	render(){


		if( !this.props.stationData.data ){
			//need a loading screen
			return (
				<div className="station-detail-secondary gauge row">
					<div className="station-details-info col-xs-12 col-sm-6">
						<div className="gauge-icon-section">
							<h2 className="gauge-name">{ this.props.stationData.nearbyName }</h2>
						</div>
						<img src={ LoadingSVG } className="guage-loading-placeholder" alt="" />
					</div>
				</div>
			);
		}


		const viewData = this.formatViewData( this.props.stationData );

		let graphComponent = null;
		const chartId = `${viewData.gauge_slug}-chart`;

		switch( this.props.stationData.data.type_of ) {
			case 'Rain Gauge':
				graphComponent = <RainChart uniqueID={chartId} chartData={ viewData.graphData } type="report"   graphHeight="280" allowModeBar={true} />;
				break;
			default:
				graphComponent = <TimeSeriesChart uniqueID={chartId} chartData={ viewData.graphData } type="stationDetailSecondary" graphHeight="300" allowModeBar={true} />;
				break;
		}
		

		let stationGauges = null;
		if( this.props.stationData.data.station_gauges.length ){
			stationGauges = this.props.stationData.data.station_gauges.map((object, index) => {
				if( object.sensor_name === this.props.stationData.data.sensor_name ){
					return <li key={index} className="piped-nav-item"><span className="active">{object.pretty_sensor_name}</span></li>
				}
				else{
					return <li key={index} className="piped-nav-item"><a onClick={ () => { this.handleGaugeSwitch( object.sensor_name ) } }>{object.pretty_sensor_name}</a></li>
				}
			});

			stationGauges = <ul className="gauge-station-links gauge-section piped-nav">{stationGauges}</ul>;
		};

		let installation = null;
		if( this.props.stationData.data.installation_date ){
			installation = <div className="guage-installation">Installation Date: { yyyymmdd( new Date( this.props.stationData.data.installation_date ) ) }</div>
		}

		let lastService = null;
		if( this.props.stationData.data.last_service_date ){
			lastService = <div className="guage-installation">Last Serviced: { yyyymmdd( new Date( this.props.stationData.data.last_service_date ) ) }</div>
		}

		let serviceSection = null;

		if( installation ||  lastService ){
			serviceSection = <div className="gauge-section">{ installation }{ lastService }</div>
		}

		let tabularDataLink = this.props.isLoggedIn ? 
		<IconTextLink 
			icon={ "floppy-disk" }
			text={ viewData.download_text }
			link={ viewData.download_link }
		/> : null;

		let precipTotals = null;

		if( this.props.stationData.data.sensor_name === "PRECIP" ) {
			precipTotals = this.props.precipTotals;
		}

		//console.log(this.props.stationData );
		const stationSlug = slugify(this.props.stationData.data.station_name + "-" + this.props.stationData.data.sensor_name);
		const titleLink = <a href={ "/gauge/"+stationSlug }>{ viewData.gauge_name }</a>;

		return (
			<div className="station-detail-secondary gauge row">

				<div className="station-details-info col-xs-12 col-sm-6">

					<div className="gauge-icon-section">
			            <SvgIcon icon={viewData.iconName} />
			            <h2 className="gauge-name">{ titleLink }</h2>
			        </div>

			        <div className="gauge-identification gauge-section">
						<span className="gauge-date">{ viewData.gauge_date }</span>
						<span className="gauge-id">ID# { viewData.gauge_id }</span>
					</div>

					{ stationGauges }

					<div className="gauge-download-links gauge-section">
						{ tabularDataLink }
					</div>


					<StationReadings 
						readings={ this.props.stationData.data.time_series_data } 
						isMissedData={viewData.missedData} 
						unitOfMeasure={this.props.stationData.data.unit_of_measure} 
						gaugeType={ viewData.type_of }
						gaugeId={ viewData.gauge_id }
						gaugeSlug={ viewData.unique_id }
						precipTotals={ precipTotals }
					/>

					{ serviceSection }

					<span className="gauge-battery-level gauge-section">{ viewData.lowed24battery}</span>

					<div className="gauge-time-series-links gauge-section">
						<TimeSeriesLength timeSeriesOptions={ this.props.stationData.timeSeriesOptions } clickCallback={ this.handleSpecificTimeSeriesRequest } /> 
					</div>
				</div>

				<div className="col-xs-12 col-sm-5 col-sm-push-1 col-lg-push-0 col-lg-6">
					<div className="secondary-graph-container">
						{ graphComponent }
					</div>
				</div>

			</div>

		)
	}
}

export default StationSecondary;