import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import EditWidgetMenu from "../EditWidgetMenu";

class LinkWidget extends Component { 
	constructor( props ) {
		super(props);

		this.editWidget = this.editWidget.bind(this);
		this.removeWidget = this.removeWidget.bind(this);
		this.loadReportFromVisualization = this.loadReportFromVisualization.bind(this);
	}

	shouldComponentUpdate(nextProps) {
		if( this.props.updateRequired === nextProps.updateRequired ) {
			return true;
		} else {
			return false;
		}
	}

	editWidget( widgetId ) {
		this.props.handleEditWidget( this.props, null, widgetId );
	}

	removeWidget( widgetId ) {
		this.props.handleRemoveWidget(widgetId);
	}

	loadReportFromVisualization(e) {
		e.preventDefault();
		e.stopPropagation();
		
		let obj = {
			link: this.props.link,
			fromDate: this.props.reportFromDate,
			toDate: this.props.reportToDate,
			gaugeList: this.props.gaugeList,
		}

		this.props.handleReportRequestFromVisualization(obj);
	}

	render() {
		let editWidgetMenu = this.props.buildModeActive ? 
		<EditWidgetMenu 
			widgetId={ this.props.widgetId } 
			handleEditWidget={ this.editWidget }
			handleRemoveWidget={ this.removeWidget }
			linkWidget={ true }
			savedReport={ this.props.reportVisualization }
		/>
		: null;

		let link = null; 

		if( this.props.reportVisualization ) {
			if( this.props.gaugeList !== undefined ) {
				link = <a href="#" onClick={ this.loadReportFromVisualization } >{ this.props.name }</a>
			} else {
				link = <Link to={this.props.link}>{ this.props.name }</Link>
			}
			
		} else {
			link = <a href={ this.props.link } target="_blank" rel="noopener noreferrer">{ this.props.name }</a>
		}

		return (
			<div className="dashboard-grid-item dashboard-grid-item-link small">
				<div>
					{ link }
				</div>
				{ editWidgetMenu }
			</div>
		)
	}
}

export default LinkWidget;