import React from 'react';

const DamSafetyCode = ({ 
    colour,
    description,
}) => {
	

	function getColour(colour){
		let colourStyle = {
			backgroundColour: null
		}

		

		switch(colour) {
		    case "green":
		        colourStyle.backgroundColor = "green"
		        break;
		    case "blue":
		        colourStyle.backgroundColor = "blue"
		        break;
		    case "yellow":
		        colourStyle.backgroundColor = "yellow"
		        break;
		    case "orange":
		        colourStyle.backgroundColor = "orange"
		        break;
		    case "red":
		        colourStyle.backgroundColor = "red"
		        break;
		    default:
		    	colourStyle.backgroundColor = "#454d4f"
		    	break;
		        
		}

		return colourStyle;

	}

    return( 
        <div className="dam-safety-code">
            <span className="dam-safety-code-label">Dam Safety Code:</span>
            <span className="dam-safety-code-color" aria-hidden="true" style={ getColour(colour) }></span>
            <span className="dam-safety-description">{ description }</span>
        </div>
    );
}; 

export default DamSafetyCode; 