import React from 'react';
import { 
    formatGaugeAmount,
    //formatMeasurement,
    //getNonNullMax,
    //getNonNullMin
 } from "../../helpers/gaugeHelpers";

const GaugeDamChart = ({ chartData }) => {

    function calculateDamPixelHeight(gaugeData){

        var maxHighHigh = gaugeData.maxHighHigh;
        var maxHigh = gaugeData.maxHigh;
        var minLow = gaugeData.minLow;
        var newCurrent;
        var newMax;
        var percent;
        var px;
        var current = 0;

        // get the first non null value
        for (let i = 0; i < gaugeData.timeSeriesData.length; i++) {
            if( gaugeData.timeSeriesData[i] &&  gaugeData.timeSeriesData[i].measured_value !== null ){
                current = gaugeData.timeSeriesData[i].measured_value;
                break;
            }
        }

        //console.log(current);

    
        //Debugging values
        // maxHighHigh = 150;
        // maxHigh = 100;
        // minLow = 0;
        // current = 125;
    
        //positions in the svg where alarms live inside the viewbox
        var svgHighHigh = 9.11;
        var svgHigh = 34.5;
        var svgLow = 241.5;
    
        // original test
        // var damSvgInfo = {
        //     "hha" : "169.1m",
        //     "ha" : "164.9m",
        //     "la" : "161m",
        //     "barY": 178.19,
        //     "barHeight": 63
        // }
    
        var damSvgInfo = {
            "hha" :  formatGaugeAmount(maxHighHigh),
            "ha" : formatGaugeAmount(maxHigh),
            "la" : formatGaugeAmount(minLow),
            "barY": 178.19,
            "barHeight": 6223
        }
    
        if( current >= maxHighHigh){
            //svg bar needs to be the entire height
            //console.log('above or equil high high');
            damSvgInfo.barY = svgHighHigh;
            damSvgInfo.barHeight = svgLow - svgHighHigh;
    
        }
        else if( current <= minLow ){
            //svg bar needs no height
            //console.log('below or equil low');
            damSvgInfo.barY = svgLow;
            damSvgInfo.barHeight = 0;
    
        }
        else if( current > minLow && current < maxHigh){
            //svg needs to be between the low and high mark
            //console.log('current is between low and max high');
    
            //calculate where the bar should start
            newCurrent = current - minLow;
            newMax = maxHigh - minLow;
            percent = newCurrent / newMax;
    
            px = (svgLow - svgHigh) * (1 - percent) + svgHigh;
    
            damSvgInfo.barY = px;
            damSvgInfo.barHeight = svgLow - px;
    
    
    
        }
        else if (current >= maxHigh && current < maxHighHigh ){
            //svg must be between the high and high high
            //console.log('current is at high or between max high');
    
            //calculate where the bar should start
            newCurrent = current - maxHigh;
            newMax = maxHighHigh - maxHigh;
            percent = newCurrent / newMax;
    
            px = (svgHigh - svgHighHigh) * (1 - percent) + svgHighHigh;
    
            damSvgInfo.barY = px;
            damSvgInfo.barHeight = svgLow - px;
        }
        else{
            //console.log('Dam level condition was not caught')
        }
    
        return damSvgInfo;
    }

    const damSvgInfo = calculateDamPixelHeight(chartData)
    //console.log(chartData);

    return( 
        <svg 
            className="card-gauge-dam-chart" 
            xmlns="http://www.w3.org/2000/svg" 
            xmlnsXlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 162 256"
        >
            <rect y="9.11" width="66.64" height="231.6" fill="#ccc"/>
            <rect data-line="bar-line" y={ damSvgInfo.barY } width="66.64" height={ damSvgInfo.barHeight } fill="#2babe2"/>
            <line x1="72.5" y1="9.5" x2="72.5" y2="241.5" fill="none" stroke="#95989a"/>
            <line x1="87.5" y1="9.5" x2="72.5" y2="9.5" fill="none" stroke="#95989a"/>
            <line x1="87.5" y1="138" x2="72.5" y2="138" fill="none" stroke="#95989a"/>
            <line x1="87.5" y1="86.25" x2="72.5" y2="86.25" fill="none" stroke="#95989a"/>
            <line x1="87.5" y1="34.5" x2="72.5" y2="34.5" fill="none" stroke="#95989a"/>
            <line x1="87.5" y1="189.75" x2="72.5" y2="189.75" fill="none" stroke="#95989a"/>
            <line x1="87.5" y1="241.5" x2="72.5" y2="241.5" fill="none" stroke="#95989a"/>
            <text data-name="169.1m" className="cls-5" ><tspan x="89" y="13" data-alarm="hha">{ damSvgInfo.hha }</tspan></text>
            <text data-name="164.9m" className="cls-5"><tspan x="89" y="38" data-alarm="ha">{ damSvgInfo.ha }</tspan></text>
            <text data-name="161m" className="cls-5"><tspan x="89" y="245.5" data-alarm="la">{ damSvgInfo.la }</tspan></text>
        </svg>
    );
}; 

export default GaugeDamChart; 