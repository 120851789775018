import React, { Component } from "react";

class HeatMapChart extends Component {

    constructor ( props ) {
        super( props );
        this.getDataFromApiData = this.getDataFromApiData.bind(this);
        this.getStationNamesFromProps = this.getStationNamesFromProps.bind(this);
        this.getDateTimeValsFromProps = this.getDateTimeValsFromProps.bind(this);
        this.getStationWithMostRecords = this.getStationWithMostRecords.bind(this);
		this.getMeasurementsFromProps = this.getMeasurementsFromProps.bind(this);
		this.formatTable = this.formatTable.bind(this);
        this.getCellColor = this.getCellColor.bind(this);
        this.removeStationsWithNoData = this.removeStationsWithNoData.bind(this);
        this.getHighestZvalsPerStation = this.getHighestZvalsPerStation.bind(this);

        this.state = {
            xVals: null,
            zVals: null,
            yVals: null,
        };
    }

    componentDidMount() {
        this.getDataFromApiData( this.props);
    }
    
    //returns all of the station names from API data
    getStationNamesFromProps(data) {
        var stationNames = [];
        for( var i = 0; i < data.data.chartData.report.apiData.data.length; i++  ) {
            stationNames.push(data.data.chartData.report.apiData.data[i].station_name);
        }

        return stationNames;
    }

    //returns the datetime enteries for the Y axis
    getDateTimeValsFromProps(data) {
        var mostRecords = this.getStationWithMostRecords( data );
        var yAxisVals = [];
        for( var i = 0; i < mostRecords.length; i++ ) {
            yAxisVals.push( mostRecords[i].datetime_of_measurement_in_toronto_local );
        }

        //console.log(yAxisVals);

        return yAxisVals;
    }

    //return the ary with the most results for the y axis
    getStationWithMostRecords( data ) {
        var containerToSort = [];
        //console.log(data.chartData[0].chartData.chartData);
        for( var i = 0; i < data.chartData[0].chartData.chartData.length; i++ ) {
            //console.log(data.chartData[i].chartData);
            if( data.chartData[0].chartData.chartData[i].timeSeriesData !== undefined ) {
                containerToSort.push(data.chartData[0].chartData.chartData[i].timeSeriesData);
            }
        }

        //console.log(containerToSort);

        containerToSort.sort(function (a, b) { 
            return b.length - a.length;
        });

        return containerToSort[0];
    }

    //returns all of the measurements for the Z axis 
    getMeasurementsFromProps( data ) {
        //console.log(data);
        var measuredValues = [];
        var measuredValuesFromAllStations = [];
        for( var i = 0; i < data.chartData[0].chartData.chartData.length; i++ ) {
            measuredValues = [];
            for( var j = 0; j < data.chartData[0].chartData.chartData[i].timeSeriesData.length; j++ ) {
                measuredValues.push(data.chartData[0].chartData.chartData[i].timeSeriesData[j].measured_value);
            }
            measuredValuesFromAllStations.push(measuredValues);
        }

        //console.log(measuredValuesFromAllStations);
        return measuredValuesFromAllStations;
    }

    removeStationsWithNoData(xVals, zVals, yVals) {
        var ary = [];

        for( var i = 0; i < zVals.length; i++ ) {

            if( zVals[i].length !== 0 ) {
                ary.push(zVals[i]);
            }
        }
        
        var ctr = 0;
        var cleanZvals = [];
        var cleanXvals = [];

        for( var j = 0; j < ary.length; j++ ) {
            ctr = 0;

            for( var k = 0; k < ary[j].length; k++ ) {
                if( ary[j][k] !== null ) {
                    ctr++;
                } 
            }
            if(ctr !== 0) {
                cleanZvals.push(ary[j]);
                cleanXvals.push(xVals[j]);
            }

            //console.log( cleanZvals );
        }

        this.setState({
            xVals: cleanXvals,
            zVals: cleanZvals,
            yVals: yVals,
        });

        this.getHighestZvalsPerStation(cleanZvals);
    }

    getDataFromApiData(chartData, unitOfMeasure, color){

        //get the station names for the x axis
        var xVals = this.getStationNamesFromProps(this.props);

        var yVals = this.getDateTimeValsFromProps(this.props);

        var zVals = this.getMeasurementsFromProps(this.props);

        this.removeStationsWithNoData(xVals,zVals,yVals);
	}

	getCellColor( value ) {
		var style={};
		if( value > 0 && value < 1 ) {
			style = {
				backgroundColor: "#95d5f1"
			};
		} else if( value >= 1 && value < 5 ) {
			style = {
				backgroundColor: "#407992",
				color: "#fff"
			};
		} else if( value >= 5 && value < 10 ) {
			style = {
				backgroundColor: "#234f63",
				color: "#fff"
			};
		} else if( value >= 10 ) {
			style = {
				backgroundColor: "#163d4e",
				color: "#fff"
			};
		} 

		return style;
    }
    
    getHighestZvalsPerStation(vals) {
        let highestValsPerStation = [];

        for( var i = 0; i < vals.length; i++ ) {
            var total = 0;

            for( var j = 0; j < vals[i].length; j++ ) {
                total += vals[i][j];
            }

            highestValsPerStation.push( total.toFixed(2) );
        }

        this.setState({ highestValsPerStation: highestValsPerStation });
    }
	
	formatTable( stationTitles, dateTimes, measurements ) {
		var titles = stationTitles.map(( col, index )=>{
			return <th key={ index }>{col}</th>
        });

		var dateTime = dateTimes.map(( date, index )=>{
			return date
        });
        
        var highValues = this.state.highestValsPerStation.map(( val, index ) => {
            return <th key={ index }>{ val }</th>
        });

		var tableRows = [];
        var cols = [];
        

		
		for( var i = 0; i < dateTime.length; i++ ) {
			cols = [];
			for( var j = 0; j < measurements.length; j++ ) {
				cols.push(<td style={ this.getCellColor(measurements[j][0]) } key={ "measurement"+j }>{ measurements[j][0] }</td>);
				measurements[j].shift();		
			}

			tableRows.push(
				<tr key={ i }>
					<td>{dateTime[i]}</td>
					{cols}
				</tr>
			);
		}

		var tableContent = 
		<table>
			<thead>
				<tr >
					<th></th>
                    { titles }
				</tr>
                <tr>
                    <th></th>
                    { highValues }
                </tr>
			</thead>
			<tbody>
				{ tableRows }	
			</tbody>
		</table>

		return tableContent;
    }
    
    render() {

        let table = null;
        
        if( this.state.xVals ) {
            table = this.formatTable(this.state.xVals, this.state.yVals, this.state.zVals); 
        }

        return(
            <div>
				<div  className="responsive-table-wrap heat-map">
                    { table }
                </div>
            </div>
        );

    }
}

export default HeatMapChart;