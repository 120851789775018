import { formatMeasurement } from "./gaugeHelpers";
import  getRoute from "./Api";

export function generateReport(props) {
    var isValidReport = true;
    props.throwSearchInputError("");
    var gaugeList = [];

    for( var i = 0; i < props.dateTimeSelections.stationsAndAssociatedGauges.length; i++ ) {
        let station = props.dateTimeSelections.stationsAndAssociatedGauges[i];    
        for( var j = 0; j < station.gauges.length; j++ ) {
            if( station.gauges[j].selected ) {
                gaugeList.push( station.gauges[j].id );
            }
        }
    }

    if( gaugeList.length === 0 ) {
        isValidReport = false;
        props.throwSearchInputError("Please select a station gauge from the list.");
    } else {
        isValidReport = true;
        props.throwSearchInputError("");
    }

    if( props.dateTimeSelections.selectedStandardReport === "DAM" || props.dateTimeSelections.selectedStandardReport === "LOWER_DON" || props.dateTimeSelections.selectedStandardReport === "TEMPORAL" ) {
        gaugeList = [];
    }

    // Check to see if the from is set
    if( !props.dateTimeSelections.dateTime.formattedRange.from  || !props.dateTimeSelections.dateTime.formattedRange.to ) {
        isValidReport = false;
        props.throwValidationError("Please fix the issues above and re-submit.");
        props.throwDateRangeError("Please select a date range.");
    } else {
        if( !props.dateTimeSelections.reportResults ) {
            props.throwDateRangeError("");
        }
    }

    if( isValidReport ) {
        if( !props.dateTimeSelections.reportResults ) {
            props.throwValidationError("");
            props.throwDateRangeError("");
            props.throwSearchInputError("");
        }

        //get the dates from props
        var dateFrom = props.dateTimeSelections.dateTime.range.from;
        var dateTo = props.dateTimeSelections.dateTime.range.to;

        // set the hours for date from
        var fromHours = Math.floor( props.dateTimeSelections.startTime / 60 / 60 );
        var fromMin = ( props.dateTimeSelections.startTime / 60 ) % 60;
        dateFrom.setHours(fromHours, fromMin);

        //set the hours for the date To
        var toHours = Math.floor( props.dateTimeSelections.endTime / 60 / 60 );
        var toMin = ( props.dateTimeSelections.endTime / 60 ) % 60;
        dateTo.setHours(toHours, toMin);

        //build an array of selected PKs
        let getVars = {};

        switch( props.dateTimeSelections.reportType ) {
            case "standard":
                var templateId = null;
                switch( props.dateTimeSelections.selectedStandardReport ) {
                    case "TEMPORAL":
                    templateId = 5;
                    break;

                    case "WL":
                    templateId = 4;
                    break;

                    case "PRECIP":
                    templateId = 3;
                    break;

                    case "LOWER_DON":
                    templateId = 2;
                    break;

                    case "DAM":
                    templateId = 1;
                    break;

                    default:
                    break;
                }
                getVars = {
                    'type_of': 'standard_report',
                    'timestamp_of_measurement_range_0': parseInt(dateFrom.getTime()/1000,10), // returns UTC
                    'timestamp_of_measurement_range_1': parseInt(dateTo.getTime()/1000,10), // returns UTC
                    'template_id': templateId,

                }

                if(gaugeList.length){
                    getVars.gauge_ids = gaugeList.join(',');
                }

                var url = getRoute('reportBuilder', [], getVars);

                return url;

            default:
                
                getVars = {
                    'type_of': 'custom_report',
                    'gauge_ids': gaugeList.join(','),
                    'timestamp_of_measurement_range_0': parseInt(dateFrom.getTime()/1000,10), // returns UTC
                    'timestamp_of_measurement_range_1': parseInt(dateTo.getTime()/1000,10), // returns UTC
                }
                var url2 = getRoute('reportBuilder', [], getVars);
                return url2;
        }


    } else {
        return null;
    }
}

function parseRainData(gauge){
    var rainData = {
        unitOfMeasure: gauge.unit_of_measure,
        timeSeriesData: gauge.time_series_data,
    }

    return rainData;
}


function parseTimeSeriesData( gauges ){
    let chartData = {
        unitOfMeasure: gauges[0].unit_of_measure,  // the unit of measure from the api
        yAxisName: `${gauges[0].pretty_sensor_name} (${formatMeasurement( gauges[0].unit_of_measure)})`, //optional not all graphs show a yAxisLabel
        chartData: [],

        // Do not need lines for the multi graph
        lowLowLine: null, // null is dont show
        lowLine: null, // null is dont show
        normalLine: null, // null is dont show
        highLine: null, // null is dont show
        highHighLine: null // null is dont show

    };

    chartData.chartData = gauges.map( (gauge, index ) => {
        return { name: gauge.station_name, timeSeriesData: gauge.time_series_data}
    });

    // can only output lines if a single chart
    if( chartData.chartData.length === 1){
         // set the low low alarm
        if( gauges[0].low_low_alarm === true){
            chartData.lowLowLine = gauges[0].low_low_limit;
        }

        // set the low alarm
        if( gauges[0].low_alarm === true){
            chartData.lowLine = gauges[0].low_limit;
        }

        //if normal is set add it to the graph
        if( gauges[0].normal_for_this_month !== null){
            chartData.normalLine = gauges[0].normal_for_this_month;
        }

        // set the high alarm
        if( gauges[0].high_alarm === true){
            chartData.highLine = gauges[0].high_limit;
        }

        //if the high high is set add it to the graph
        if( gauges[0].high_high_alarm === true ){
            chartData.highHighLine = gauges[0].high_high_limit
        }
    }

    return chartData
}

// will parse a dual axis chart,
// will use the axis lines of the first gauge passed in
// gauge 1 will go on left and gauge 2 on right
function parseDualAxisChart( gauges ){

    if( gauges.length !== 2){
        throw new Error('parseDualAxisChart was called and only a single gauge passed')
    }
   let chartData = {
        unitOfMeasure: gauges[1].unit_of_measure,  // the unit of measure from the api
        yAxisName: `${gauges[0].pretty_sensor_name} (${formatMeasurement( gauges[0].unit_of_measure)})`, //optional not all graphs show a yAxisLabel
        yAxis2Name: `${gauges[1].pretty_sensor_name} (${formatMeasurement( gauges[1].unit_of_measure)})`, //optional not all graphs show a yAxisLabel
        chartData: [],

        // Do not need lines for the multi graph
        lowLowLine: null, // null is dont show
        lowLine: null, // null is dont show
        normalLine: null, // null is dont show
        highLine: null, // null is dont show
        highHighLine: null // null is dont show

    };

    chartData.chartData = gauges.map( (gauge, index ) => {
        return { name: gauge.pretty_sensor_name, timeSeriesData: gauge.time_series_data}
    });

    //WL should be the second one and we are using it for the lines

     // set the low low alarm
    if( gauges[1].low_low_alarm === true){
        chartData.lowLowLine = gauges[1].low_low_limit;
    }

    // set the low alarm
    if( gauges[1].low_alarm === true){
        chartData.lowLine = gauges[1].low_limit;
    }

    //if normal is set add it to the graph
    if( gauges[1].normal_for_this_month !== null){
        chartData.normalLine = gauges[1].normal_for_this_month;
    }

    // set the high alarm
    if( gauges[1].high_alarm === true){
        chartData.highLine = gauges[1].high_limit;
    }

    //if the high high is set add it to the graph
    if( gauges[1].high_high_alarm === true ){
        chartData.highHighLine = gauges[1].high_high_limit
    }

    chartData.axisAnchor = 2;
    

    return chartData
}


function parseCustomReportData(data, plotGraphOnSeperateContainer){

    const theData = data;

    let stationData = [];

    if( !plotGraphOnSeperateContainer ){

        let likeGauges = [];

        //group the like gauges
        for (var i = 0; i < theData.length; i++) {

            //grouping does not occur for rain gauges
            if( theData[i].sensor_name === 'PRECIP***' ){
                likeGauges[ theData[i].sensor_name + i ] = [];
                likeGauges[ theData[i].sensor_name + i ].push( theData[i] );
            }
            else{
                //create a new array record for this gauge type
                if( likeGauges[ theData[i].sensor_name ] === undefined ){
                    likeGauges[ theData[i].sensor_name ] = [];
                }
                likeGauges[ theData[i].sensor_name ].push( theData[i] );
            }
        }

        //build the data a rray
        stationData =  Object.keys(likeGauges).map( ( gaugeKey ) => {

            let chartData = null;

            //chose the appropriate parser
            switch( likeGauges[gaugeKey][0].sensor_name ) {
                case 'PRECIP***':
                    chartData = parseRainData( likeGauges[gaugeKey][0] );
                    break;
                default:
                    chartData = parseTimeSeriesData( likeGauges[gaugeKey] )
                    //console.log(chartData);

            }

            const stationNames = likeGauges[gaugeKey].map((gauge) => {
                return `${gauge.pretty_station_name} (${ gauge.station_id})`;
            })

            //console.log(likeGauges[gaugeKey][0]);

            return {
                chartData:chartData,
                chartTitle:  `Showing ${likeGauges[gaugeKey][0].sensor_name} for ${stationNames.join(', ')}`,
                gaugeType: likeGauges[gaugeKey][0].sensor_name,
                gaugeId: likeGauges[gaugeKey][0].id,
            };

        });
    }
    else{
        //setup a chart for each result
        // eslint-disable-next-line
        for (var i = 0; i < theData.length; i++) {

            let chartData = null;

            //chose the appropriate parser
            switch( theData[i].sensor_name ) {
                case 'PRECIP':
                    chartData = parseRainData( theData[i] );
                    break;
                default:
                    chartData = parseTimeSeriesData( [theData[i]] )

            }

            //console.log(theData[i]);

            stationData.push({
                chartData:chartData,
                chartTitle: `Showing ${theData[i].pretty_sensor_name} for ${theData[i].station_name} (${theData[i].station_id})`,
                gaugeType: theData[i].sensor_name,
                gaugeId: theData[i].id,
                stationName: theData[i].station_name
            });
        }

        //console.log("***stationData***");
        //console.log(stationData);
        //console.log("****************");
    } // End if group like gauges
    return stationData;

}

function parseDamReportData(data){

    //console.log(data);

    //Dam data will group stations on a single graph
    var stations = [];

    //group the stations
    for (var i = 0; i < data.data.length; i++) {

        if( stations[ data.data[i].station_id ] === undefined ){
            stations[ data.data[i].station_id ] = [];
        }

        stations[ data.data[i].station_id ].push( data.data[i] );

    }  

    //console.log(data);

    // create the chart data
    let charts = [];
    let keys = Object.keys( stations );
    //console.log(stations);
    // eslint-disable-next-line
    for (var i = 0; i < keys.length; i++) {

        // sort stations
        const stortedStations = stations[keys[i]].sort(function(a ,b){

            if( a.sensor_name === 'WL' && b.sensor_name !== 'WL'){
                //water level gauges goes after anything else
                return 1;
            }
            //order of anything eles does not matter
            return 0;
        });


        
        for (let b = 0; b < stortedStations.length; b++) {
            charts.push({
                chartData: parseTimeSeriesData( [stortedStations[b]] ),
                chartTitle: `Showing ${stortedStations[b].pretty_sensor_name.toUpperCase()} for ${stortedStations[b].station_name.toUpperCase()} (${stortedStations[b].station_id})`,
                gaugeType: stortedStations[b].sensor_name
            });
        }



        //loop over the neighbouring gauges and add them to the charts list
        // eslint-disable-next-line
        for (var b = 0; b < stortedStations.length; b++) {
            for (var c = 0; c < stortedStations[b].neighbouring_gauges.length; c++) {
                let chatData = null;
                //chose the appropriate parser
                switch( stortedStations[b].neighbouring_gauges[c].sensor_name ) {
                    case 'PRECIP':
                        chatData = parseRainData( stortedStations[b].neighbouring_gauges[c] );
                        break;
                    default:
                        chatData = parseTimeSeriesData( [stortedStations[b].neighbouring_gauges[c]] );

                }

                charts.push({
                    chartData: chatData,
                    chartTitle: `Showing ${stortedStations[b].neighbouring_gauges[c].pretty_sensor_name.toUpperCase()} for ${stortedStations[b].neighbouring_gauges[c].station_name.toUpperCase()} (${stortedStations[b].neighbouring_gauges[c].station_id})`,
                    gaugeType: stortedStations[b].neighbouring_gauges[c].sensor_name,
                    gaugeId: stortedStations[b].neighbouring_gauges[c].id
                });
            }
        }

    }

    return charts;
}

function parseLowerDon( data, plotGraphOnSeperateContainer ){
    let charts = [];

    const theData = data.data;
    let remainingGauges = [];

    let dundas = null;
    let donTod = null;

    if(  plotGraphOnSeperateContainer === true ){
        //just add all charts to remaining gauges
        for (var i = 0; i < theData.length; i++) {
            //add it to the remaining gauges
            remainingGauges.push( theData[i] )
        }

        remainingGauges = remainingGauges.sort(function(a ,b){
            if( b.station_name === 'DON AT TODMORDEN' && b.sensor_name === 'WL'){
                //console.log('found tod' );
                return 1;
            }
            else if( b.station_name ===  "DUNDAS" && b.sensor_name === "WL" ){
                //console.log('find dundas');
                return 1;
            }
            //order of anything eles does not matter
            return 0;
        });
        
    }
    else{

        //logic for adding don and dundas on the same graph
        // eslint-disable-next-line
        for (var i = 0; i < theData.length; i++) {
            
            if( theData[i].station_id ===  "HY019" && theData[i].sensor_name === "WL") {
                donTod = theData[i];
            }
            else if( theData[i].station_id ===  "HY079" && theData[i].sensor_name === "WL") {
                dundas = theData[i];
            }
            else{
                //add it to the remaining gauges
                remainingGauges.push( theData[i] )
            }
        }

        if( dundas === null || donTod === null){
            throw new Error('Lower Don report did not contain a match for both dundas and donTod');
        }
        else{
            //add em to the chart
            let firstChart = parseTimeSeriesData([donTod, dundas]);
            firstChart.customLines = [];

            //console.log(donTod, dundas);
            //if normal is set add it to the graph
            if( donTod.normal_for_this_month !== null){
                firstChart.customLines.push({
                    lineAt: donTod.normal_for_this_month,
                    lineName: `NORMAL at Todmorden (${donTod.normal_for_this_month}m)`,
                    colour: 'rgb(0, 0, 0)'
                });

            }

            //if the high high is set add it to the graph
            if( donTod.high_high_alarm === true ){
                firstChart.customLines.push({
                    lineAt: donTod.high_high_limit,
                    lineName: `HHA Don at Todmorden (${donTod.high_high_limit}m)`,
                    colour: 'rgb(0, 0, 0)'
                });
            }

                //if normal is set add it to the graph
            if( dundas.normal_for_this_month !== null){
                firstChart.customLines.push({
                    lineAt: dundas.normal_for_this_month,
                    lineName: `NORMAL Dundas (${dundas.normal_for_this_month}m)`,
                    colour: 'rgb(255, 0, 0)'
                });
            }

            //if the high high is set add it to the graph
            if( dundas.high_high_alarm === true ){
                firstChart.customLines.push({
                    lineAt: dundas.high_high_limit,
                    lineName: `HHA Dundas (${dundas.high_high_limit}m)`,
                    colour: 'rgb(255, 0, 0)',
                });
            }
            
            //TODO: add the custom lines here

            charts.push({
                chartData: firstChart,
                chartTitle: `Showing WATER LEVEL for Don @ Todmorden, Dundas`,
                gaugeType: 'WL'
            });
        }
    }


    //add the rest of the charts
    // eslint-disable-next-line
    for (var i = 0; i < remainingGauges.length; i++) {
        let chartData = null;
        switch( remainingGauges[i].sensor_name ) {
            case 'PRECIP':
                chartData = parseRainData( remainingGauges[i] );
                break;
            default:
                chartData = parseTimeSeriesData( [remainingGauges[i]] );

        }

        //console.log( remainingGauges[i] );

        charts.push({
            chartData: chartData,
            chartTitle: `Showing ${remainingGauges[i].pretty_sensor_name} for ${remainingGauges[i].station_name} (${remainingGauges[i].station_id})`,
            gaugeType: remainingGauges[i].sensor_name,
            gaugeId: remainingGauges[i].id,
            stationName: remainingGauges[i].station_name
        });
    }

    // get the chart for the top water level

    return charts;

}


export function parseReportResultData(data, plotGraphOnSeperateContainer) {
    // setup an additional data
    let report = {
        charts: [],
        type_of: data.type_of,
        template: data.template,
        downloadCSV: data.download_csv_url,
        summariezedData: data.summariezed_data,
        apiData: data
    };

    if( report.type_of === 'standard_report' ){
        switch ( report.template ){
            case 'Dams': 
                // dam report
                report.charts = parseDamReportData(data);
                break;

            case 'Lower Don':
                // lower don charts
                report.charts = parseLowerDon( data, plotGraphOnSeperateContainer );
                break;
            default:
                //default ot using the custom report  data
                report.charts = parseCustomReportData( data.data, plotGraphOnSeperateContainer );
                break;
        }

    }
    else{
        report.charts = parseCustomReportData( data.data, plotGraphOnSeperateContainer );
    }

    return report;
}


export function buildSearchString(searchString, gauges, useage) {
    let str = "";
    //console.log(searchString);
    //console.log(gauges);

    for( var i=0; i < gauges.length; i++ ) {
        switch( useage ) {
            case "reports": 
            str = searchString += " "+gauges[i].extraSearchTerms;
            break
    
            case "explorer":
            str = searchString += " "+gauges[i].extra_search_terms;
            break;

            default:
            console.log( "An unknown support type was used" );
            break;
        }
    }

    var regex = new RegExp(',', 'g');
    str = str.replace(regex, '');

    //console.log(str);

    return str;
}