import React, { Component } from "react";
import InputMask from 'react-input-mask';

export class Field extends Component {

	/*	
	Required Props

	Strings:
	name -- unique name for this input
	label -- the label for this form
	type -- field type -- your typical string for an input text/email/password/ect
	required -- true/false -> is this field required?
	
	Events
	onChange -- the on change event
	isValid -- boolean if this field is valid

	Optional Props
	id -- unique id for this input, will default by using id_{name}
	requiredValidationMessage -- overides the default required validation message
	customInput -- overired the input used and simply outputs what ever is set in here
	*/ 

	constructor(props) {
		super(props);
		this.inputChanged = this.inputChanged.bind(this);
		this.focusDiv = this.focusDiv.bind(this); 
	}

	componentDidMount() {
		//console.log(this.props);
		if( this.props.shouldGetFocus ) {
			this.focusDiv(); 
		}
	}

	focusDiv() {
        var _this = this;
        setTimeout(function(){ _this.refs.theDiv.focus(); },1000);  
    }

	inputChanged(event){
		let value = null;

		switch(this.props.type) {
		 	case 'checkbox':
		 		value = event.target.checked;
			break;

			case 'text':
				//added 30 character limit to standard input fields
				var theMaxLength = 30;
				if( this.props.maxLength ){
					theMaxLength = this.props.maxLength;
				}
				value = event.target.value.substring(0,theMaxLength);
			break;
			
			default:
				value = event.target.value;
			break;
		}

		this.props.onChange(value, event.target.getAttribute('name'), event);
	}


	render(){
		
		let id = (this.props.id ? this.props.id : `id_${this.props.name}`);
		const requiredString = ( this.props.required ? <span className="form-field-required">*</span> : null );
		const isValidClass = ( (this.props.isValid === undefined || this.props.isValid === true) ? '' :   'form-field-validation-error' );

		//use the custom input if set
		if(this.props.customInput){
			return (
				<div className={`form-field ${isValidClass}`}>
					<label htmlFor={id}>{this.props.label}:{requiredString}</label>
					{ this.props.customInput }
					<span className="validation-message">{ this.props.validationMessage }</span>
				</div>
			)
		}

		//use standard inputs
		switch(this.props.type) {
			case 'textarea':
				return (
					<div className={`form-field ${isValidClass}`}>
						<label htmlFor={id}>{this.props.label}:{requiredString}</label>
						<textarea type={this.props.type} name={this.props.name} id={id} onChange={this.inputChanged} defaultValue={this.props.value} />
						<span className="validation-message">{ this.props.validationMessage }</span>
					</div>
				)
			
			case 'textarea2':
				return (
					<div className={`form-field ${isValidClass}`}>
						<label htmlFor={id}>{this.props.label}:{requiredString}</label>
						<textarea type={this.props.type} name={this.props.name} id={id} onChange={this.inputChanged} value={this.props.value} />
						<span className="validation-message">{ this.props.validationMessage }</span>
					</div>
				)	
				

		    case 'checkbox':
		    	// html for a checkbox input
		    	return (
		    		<div className={`form-field ${isValidClass}`}>
		                <label htmlFor={id} className="styled-checkbox-label">
		                    <input type="checkbox" name={this.props.name} id={id} onChange={this.inputChanged} value={this.props.value} defaultChecked={this.props.defaultChecked} />
		                    {this.props.label} {requiredString}
		                </label>
		                <span className="validation-message">{ this.props.validationMessage }</span>
		            </div>
		    	)
		    default:
				// a defeult input 

				var theMaxLength = 30;
				if( this.props.maxLength ){
					theMaxLength = this.props.maxLength;
				}
				
				var truncatedValue = null;
				if( (typeof this.props.value === 'string' || this.props.value instanceof String) && this.props.value !== null ) {
					truncatedValue = this.props.value.substring(0,theMaxLength);
				}
				else{
					truncatedValue = this.props.value;
				}

		       	return (
		       		<div className={`form-field ${isValidClass}`}>
						<label htmlFor={id}>{this.props.label}:{requiredString}</label>
						<input type={this.props.type} name={this.props.name} id={id} onChange={this.inputChanged} value={ truncatedValue }  ref="theDiv" tabIndex={0}/>
						<span className="validation-message">{ this.props.validationMessage }</span>
					</div>
				)
		} 
	

	}
}
export default Field;


export class PhoneField extends Component {
	/*	
	Required Props
	
	Same as standard fields

	Additional Required Props

	Strings:
	mask -- what the mask should be for this input
	defaultValue -- what the default value is
	*/

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);

	}

	onChange(event) {
		this.props.onChange(event.target.value, event.target.getAttribute('name'), event);
	}

	render(){
		let fieldProps = null;

		if( !this.props.defaultValue ) {
			fieldProps = {
				customInput : (
					<InputMask 
						id={this.props.id}
						name={this.props.name}
						mask={this.props.mask}
						value={this.props.value}
						onChange={this.onChange}/>
				),
				...this.props,
			};
		} else {
			fieldProps = {
				customInput : (
					<InputMask 
						id={this.props.id}
						name={this.props.name}
						mask={this.props.mask}
						defaultValue={this.props.defaultValue}
						onChange={this.onChange}/>
				),
				...this.props,
			};
		}

		

		return (
			<Field {...fieldProps} />
		)

	}

}

export class DateField extends Component {
	/*	
	Required Props
	
	Same as standard fields

	Additional Required Props

	Strings:
	mask -- what the mask should be for this input
	defaultValue -- what the default value is
	*/

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);

	}

	onChange(event) {
		this.props.onChange(event.target.value, event.target.getAttribute('name'), event);
	}

	render(){
		//console.log(this.props); 
		let fieldProps = null;

		fieldProps = {
			customInput : (
				<InputMask 
					id={this.props.id}
					name={this.props.name}
					mask={this.props.mask}
					value={this.props.value}
					onChange={this.onChange}/>
			),
			...this.props,
		};

		return (
			<Field {...fieldProps} />
		)

	}

}

